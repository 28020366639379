import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { Product, VariantOption } from "@spartacus/core";

@Component({
  selector: 'cx-product-variant-color',
  templateUrl: './custom-product-variant-color.component.html',
  styleUrls: [ './custom-product-variant-color.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class CustomProductVariantColorComponent {

  readonly maxColorsView = 5;

  @Input() product: Product;
  @Output() currentVariant = new EventEmitter<VariantOption>();

  get variants(): VariantOption[] {
    return this.product?.variantOptions;
  }

  setCurrentVariant(variant: VariantOption) {
    this.currentVariant.emit(variant);
  }
}

<ng-container *ngIf="{
  component: component$ | async,
  customTitle: customTitle$ | async,
  eyebrow: eyebrow$ | async,
  isYmmCategoryPage: isYmmCategoryPage$ | async
} as data">
  <div class="{{data.component.styleClasses}} container-page-title">
    <div class="page-title__image" *ngIf="vehicleImg">
      <img [alt]="data.customTitle" [src]="vehicleImg"/>
    </div>
    <div class="page-title__title">
      <h1 class="pdp-page-title">
        {{data.customTitle}}
      </h1>

      <ng-container *ngIf="data.isYmmCategoryPage; else checkEyebrow">
        <h3 class="page-title__eyebrow">{{ ymmCategoryEyebrow }}</h3>
      </ng-container>

      <ng-template #checkEyebrow>
        <ng-container *ngIf="data.eyebrow">
          <h3 class="page-title__eyebrow">{{ data.eyebrow }}</h3>
        </ng-container>
      </ng-template>
    </div>
  </div>
</ng-container>

import { NgModule } from '@angular/core';
import { CmsConfig, I18nConfig, provideConfig } from "@spartacus/core";
import { orderTranslationChunksConfig, orderTranslations } from "@spartacus/order/assets";
import { OrderRootModule, ORDER_FEATURE } from "@spartacus/order/root";
import { CustomOrderModule } from './custom-order.module';
import { provideOutlet } from '@spartacus/storefront';
import { CustomOrderItemListComponent } from './Components/custom-order-confirmation/custom-order-confirmation-itens/custom-order-item-list/custom-order-item-list.component';
import { CustomOrderItemListModule } from './Components/custom-order-confirmation/custom-order-confirmation-itens/custom-order-item-list/custom-order-item-list.module';
import { CustomOrderDetailItemsComponent } from './Components/custom-order-details/custom-order-detail-items/custom-order-detail-items.component';
@NgModule({
  declarations: [],
  imports: [OrderRootModule, CustomOrderItemListModule],
  providers: [
    provideConfig(<CmsConfig>{
      featureModules: {
        [ORDER_FEATURE]: {
          module: () =>
            import('./custom-order.module').then((m) => m.CustomOrderModule),
        },
      },
    }),
    provideConfig(<I18nConfig>{
      i18n: {
        resources: orderTranslations,
        chunks: orderTranslationChunksConfig,
      },
    }),
    provideOutlet({
      id: 'customOrderItemList',
      component: CustomOrderItemListComponent,
    }),
    provideOutlet({
      id: 'customOrderDetailItems',
      component: CustomOrderDetailItemsComponent,
    }),
  ],
})
export class OrderFeatureModule {}

export const ymm = {
  ymm: {
    garageTitle: 'Garage',
    year: 'Year',
    make: 'Make',
    model: 'Model',
    garage: {
      myGarage: 'My Garage',
      shop: 'Shop',
      change: 'Change',
      protectMy: 'Protect My Vehicle',
      noResultsFor: 'No Results for',
      resultsFor: 'Results for',
      fitsFor: 'For Your Vehicle',
      subheading: 'Select the item you want to protect.',
      helpText:
        'Don’t know your year, make and model? Click here for tips on how to find it or send us a picture of your vehicle and we can help you identify.',
      selectYourVehicle:
        'Select your <span class="configurator-heading__highlight">Vehicle</span>',
    },
  },
};

export const productLines = {
  productLines: {
    actions: {
      cancel: 'Cancel',
      next: 'Next',
      back: 'back',
      skipStep: 'Skip {{step}}',
      skip: 'Skip',
      save: 'Save'
    }
  }
}

import { Component } from '@angular/core';
import { PaymentMethodsComponent } from '@spartacus/storefront';

@Component({
  selector: 'cx-payment-methods',
  templateUrl: './custom-payment-methods.component.html',
})
export class CustomPaymentMethodsComponent extends PaymentMethodsComponent {
  
}

import { Component, Input } from '@angular/core';
import { TalkToUs } from "../../core/talk-to-us.model";

@Component({
  selector: 'talk-to-us-cards',
  templateUrl: './talk-to-us-cards.component.html',
  styleUrls: [ './talk-to-us-cards.component.scss' ]
})
export class TalkToUsCardsComponent {
  @Input() title: string;
  @Input() subtitle?: string;
  @Input() tab?: boolean;
  @Input() styleClasses?: string;
  @Input() helpCenter?: boolean;

  @Input() talkToUs: TalkToUs;

}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { InputService } from '../../input.service.service';
import {
  CustomFeedbackInputError,
  InputFeedbackType,
} from '../../model/input.model';

@Component({
  selector: 'simple-input',
  templateUrl: './simple-input.component.html',
  styleUrls: ['./simple-input.component.scss'],
})
export class SimpleInputComponent {
  @Input() form!: FormGroup;
  @Input() id!: string;
  @Input() name!: string;
  @Input() label?: string;
  @Input() type?: string = 'text';
  @Input() required!: string;
  @Input() customErrorMessage!: string;
  @Input() autocomplete?: string;
  @Input() placeholder: string = 'tes';
  @Input() maxLength: number = 255;
  @Input() containerClass?: string;
  @Input() labelClass?: string;
  @Input() inputClass?: string;
  @Input() mask: string = '';
  @Input() hasIcon?: boolean;

  @Output() callbackOnFocus = new EventEmitter<any>();
  @Output() callbackOnChange = new EventEmitter<any>();
  InputFeedbackType = InputFeedbackType;
  hasError: boolean = false;

  // constructor(private translate: TranslateService, private inputService: InputService) { }
  constructor(private inputService: InputService) {}

  ngOnInit(): void {
    // this.placeholder && this.translate.get(this.placeholder).subscribe(res => this.placeholder = res);
    // this.label && this.translate.get(this.label).subscribe(res => this.label = res);
  }

  getError(): CustomFeedbackInputError {
    const errors = this.getErrors();
    this.hasError = errors.length > 0;

    return errors[0];
  }

  getErrors(): CustomFeedbackInputError[] {
    return this.inputService.getErrors(this.name, this.form);
  }

  classError() {
    return {
      'is-invalid': this.getError(),
      'has-feedback': this.hasError,
    };
  }

  onFocus(event: FocusEvent) {
    this.callbackOnFocus.emit(event);
  }

  onChange(event: Event) {
    this.callbackOnChange.emit(event);
  }
}

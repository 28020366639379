import { createReducer, on } from "@ngrx/store";
import { Addon, AddonsSelection, ConfigurationSelection, productConfigInitialState, ProductConfigStepSelection, ProductLineSelection } from "./product-configuration.state";
import { deepCloning } from "../../../../../../tools/tools";
import { ProductConfigurationActions } from "./index";
import { buildKeyChainForProductLines } from "../facade/product-configuration.tools";
import { ProductConfigStepType } from "../models/product-configuration.models";

export const productConfigurationReducer = createReducer(
  productConfigInitialState,
  on(ProductConfigurationActions.setState, (_, { state }) => {
    return state;
  }),
  on(ProductConfigurationActions.loadSubmodelsOk,
    (state, { submodels, productLine, vehicleCode }) => {
      return {
        ...state,
        submodels: [
          ...( state.submodels ?? [] ),
          {
            productLine,
            submodels,
            vehicleCode
          }
        ]
      }
    }),
  on(ProductConfigurationActions.loadSubModelOptionsOk,
    (state, { subModel, productLine, options, vehicle }) => {
      return {
        ...state,
        subModelOptions: [
          ...( state.subModelOptions ?? [] ),
          {
            productLine,
            subModel,
            options,
            vehicle
          }
        ]
      }
    }),
  on(ProductConfigurationActions.setSubModel,
    (state, { subModel, productLine }) => {
      return {
        ...state,
        activeSubModel: {
          productLine,
          subModel
        }
      }
    }),
  on(ProductConfigurationActions.loadProductConfigurationLoading,
    (state, { loading }) => {
      return {
        ...state,
        productConfigLoading: loading
      }
    }),
  on(ProductConfigurationActions.loadProductConfigurationOk,
    (state, { config }) => {
      const keyChain = buildKeyChainForProductLines(config.metaData);
      return {
        ...state,
        configurations: {
          ...state.configurations,
          [ keyChain ]: config
        }
      }
    }),
  on(ProductConfigurationActions.saveConfigSelection, (state, { vehicle }) => {
    const selection = deepCloning(state.activeSelectionConfig);
    selection.vehicle = vehicle;
    return {
      ...state,
      activeSelectionConfig: {} as any,
      productConfigurations: {
        ...state.productConfigurations,
        [ selection.productCode ]: selection
      }
    };
  }),
  on(ProductConfigurationActions.clearConfiguratorState, (state, { productCode }) => {
    let productConfigurations = deepCloning(state.productConfigurations);
    if (productCode && productConfigurations[ productCode ]) {
      delete productConfigurations[ productCode ];
    }
    return {
      ...state,
      configurator: {},
      productConfigurations
    }
  }),
  on(ProductConfigurationActions.resetConfiguratorState, (state, { productCode }) => {
    let productConfigurations = deepCloning(state.productConfigurations);
    if (productCode && productConfigurations[ productCode ]) {
      delete productConfigurations[ productCode ];
    }
    return {
      ...state,
      configurator: {},
      productConfigurations,
      activeSelectionConfig: {
        productCode,
        selections: []
      }
    }
  }),
  on(ProductConfigurationActions.initializeConfigurator, (state, { productCode, activeSelectionConfig }) => {
    activeSelectionConfig = activeSelectionConfig ?? { productCode } as ConfigurationSelection;
    return {
      ...state,
      activeSelectionConfig
    }
  }),
  on(ProductConfigurationActions.setSelectionProperty, (state, { prop, value }) => {
    return {
      ...state,
      activeSelectionConfig: {
        ...state.activeSelectionConfig,
        [ prop ]: value
      }
    }
  }),
  on(ProductConfigurationActions.clearEmbroiderySelection, (state) => {
    const selections = deepCloning(state.activeSelectionConfig.selections ?? []);
    const idx = selections.findIndex(s => s.stepType === ProductConfigStepType.EMBROIDERY);
    if (idx >= 0) {
      selections.splice(idx, 1);
    }
    return {
      ...state,
      activeSelectionConfig: {
        ...state.activeSelectionConfig,
        selections
      }
    }
  }),
  on(ProductConfigurationActions.setEmbroiderySelection, (state, { selection }) => {
    const selections = deepCloning(state.activeSelectionConfig.selections ?? []);
    const idx = selections.findIndex(s => s.stepType === ProductConfigStepType.EMBROIDERY);
    if (idx >= 0) {
      selections[ idx ] = selection;
    } else {
      selections.push(selection);
    }
    return {
      ...state,
      activeSelectionConfig: {
        ...state.activeSelectionConfig,
        selections
      }
    }
  }),
  on(ProductConfigurationActions.toggleProductLine, (state, { productLine, option }) => {
    const selections: ProductConfigStepSelection[] = deepCloning(state.activeSelectionConfig.selections ?? []);
    let idx = selections?.findIndex(s => s.stepType === ProductConfigStepType.PRODUCT_LINES
      && ( s as ProductLineSelection ).id === productLine.id);
    const line: ProductLineSelection = {
      stepType: ProductConfigStepType.PRODUCT_LINES,
      id: productLine.id,
      description: productLine.userInterfaceDescription,
      option: option
    }
    if (idx >= 0) {
      const existedLine = selections[ idx ] as ProductLineSelection;
      if (existedLine.option.basePartNumber === option.basePartNumber) {
        selections.splice(idx, 1);
      } else {
        selections[ idx ] = line;
      }
    } else {
      selections.push(line);
    }
    return {
      ...state,
      activeSelectionConfig: {
        ...state.activeSelectionConfig,
        selections
      }
    }
  }),
  on(ProductConfigurationActions.setLogoSelection, (state, { selection }) => {
    const selections = deepCloning(state.activeSelectionConfig.selections ?? []);
    const idx = selections.findIndex(s => s.stepType === ProductConfigStepType.LOGOS);
    if (idx >= 0) {
      selections[ idx ] = selection;
    } else {
      selections.push(selection);
    }
    return {
      ...state,
      activeSelectionConfig: {
        ...state.activeSelectionConfig,
        selections
      }
    }
  }),
  on(ProductConfigurationActions.clearLogoSelection, (state) => {
    const selections = deepCloning(state.activeSelectionConfig.selections ?? []);
    const idx = selections.findIndex(s => s.stepType === ProductConfigStepType.LOGOS);
    if (idx >= 0) {
      selections.splice(idx, 1);
    }
    return {
      ...state,
      activeSelectionConfig: {
        ...state.activeSelectionConfig,
        selections
      }
    }
  }),
  on(ProductConfigurationActions.toggleAddon,
    (state, { sku, productBase, price, product, removeSelection, addonVariants }) => {
      const selections = deepCloning(state.activeSelectionConfig.selections ?? []);
      const addonIdx = selections?.findIndex(s => s.stepType === ProductConfigStepType.ADDONS);
      const toAdd: Addon = {
        sku,
        productBase,
        price,
        product,
        addonVariants: addonVariants
      };
      if (addonIdx < 0 && !removeSelection) {
        selections.push({
          stepType: ProductConfigStepType.ADDONS,
          addons: [ toAdd ]
        } as AddonsSelection)
      } else {
        const addonsSelection = selections[ addonIdx ] as AddonsSelection;
        const i = addonsSelection?.addons?.findIndex(a => a.productBase === productBase);
        if (i >= 0) {
          addonsSelection.addons.splice(i, 1);
        } else if (!removeSelection) {
          addonsSelection.addons.push(toAdd);
        }
      }
      return {
        ...state,
        activeSelectionConfig: {
          ...state.activeSelectionConfig,
          selections
        }
      };
    })
)

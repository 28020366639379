import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoaderComponent } from './loader.component';
import { LocalLoaderModule } from './local-loader/local-loader.module';
import { I18nModule } from '@spartacus/core';

@NgModule({
  declarations: [
    LoaderComponent
  ],
  imports: [
    CommonModule,
    I18nModule,
    LocalLoaderModule
  ],
  exports: [
    LoaderComponent
  ]
})

export class LoaderModule { }

<div class="cx-product-intro" *ngIf="product$ | async as product">
  <div class="brand bold-common-font">{{ product.brand }}</div>

  <h1 class="name bold-common-font">{{ product.name }}</h1>

  <div *ngIf="!isSkuPage" class="rating">
    <div class="stars">
      <cx-star-rating [rating]="product.ratingReview || 0" (click)="showReviews()"></cx-star-rating>
      <span class="count">({{ product.ratingCount || 0 }})</span>
      <span class="bi bi-chevron-down chevron"></span>
    </div>

    <div class="js-product-review-selector">
      <button *ngIf="areReviewsAvailable$ | async"
        class="btn btn-link review medium-common-font"
        [attr.aria-label]="'productSummary.showReviewsDetailed' | cxTranslate: { rating: product.ratingReview | number: '1.0-1', count: product.ratingCount }">
        <span (click)="showReviews()"> {{ 'productSummary.writeAReviewDeconstructed.review' | cxTranslate }} </span>
        <span (click)="showQa()"> {{ 'productSummary.writeAReviewDeconstructed.qa' | cxTranslate }} </span>
      </button>
    </div>
  </div>

  <div class="divider"></div>
</div>
import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, InjectionToken, Injector, ViewChild, ViewEncapsulation } from "@angular/core";
import { VehicleSelectionComponent } from "../../configurators/vehicle-selection/vehicle-selection.component";
import { debounceTime, distinctUntilChanged, filter, map } from "rxjs/operators";
import { ProductConfiguratorStepsService } from "../../core/facade/product-configurator-steps.service";
import { combineLatest, Subscription } from "rxjs";
import { EmbroideryComponent } from "../../configurators/embroidery/embroidery.component";
import { ProductLineComponent } from "../../configurators/product-line/product-line.component";
import { isNotNullable } from "@spartacus/core";
import { ProductConfigStepType } from "../../core/models/product-configuration.models";
import { LogosComponent } from "../../configurators/logos/logos.component";
import { AddonsComponent } from "../../configurators/addons/components/addons/addons.component";

export const STEP_INDEX = new InjectionToken<number>('STEP_INDEX');


@Component({
  selector: 'product-config-dialog',
  templateUrl: './product-config-dialog.component.html',
  styleUrls: ['./product-config-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class ProductConfigDialogComponent implements AfterViewInit {
  @ViewChild('container') private container: ElementRef;
  private subscription = new Subscription();

  private componentMapper = {
    [ProductConfigStepType.VEHICLE]: VehicleSelectionComponent,
    [ProductConfigStepType.EMBROIDERY]: EmbroideryComponent,
    [ProductConfigStepType.PRODUCT_LINES]: ProductLineComponent,
    [ProductConfigStepType.LOGOS]: LogosComponent,
    [ProductConfigStepType.ADDONS]: AddonsComponent,
  };

  stepConfig$ = this.stepsService.steps$.pipe(
    filter((steps) => steps?.length > 0)
  );

  currentStep$ = this.stepsService.currentStep$

  components$ = combineLatest([
    this.stepsService.currentStep$.pipe(distinctUntilChanged()),
    this.stepConfig$.pipe(filter(isNotNullable)),
  ]).pipe(
    distinctUntilChanged(([step], [currStep]) => step === currStep),
    map(([step, steps]) => {
      return steps.map((s, index) => ({
        component: this.componentMapper[s.stepType],
        injector: Injector.create({
          providers: [{ provide: STEP_INDEX, useValue: index + 1 }],
        }),
      }));
    })
  );
  constructor(private stepsService: ProductConfiguratorStepsService) {}

  ngAfterViewInit(): void {
    this.subscription.add(
      this.stepsService.currentStep$
        .pipe(distinctUntilChanged(), debounceTime(200))
        .subscribe((_) => {
          this.container.nativeElement.scrollTop = 0;
        })
    );
  }
}

<ng-container *ngIf="order as order">
  <div class="container mt-4">

    <div class="row justify-content-end">

      <!-- TALK TO US -->
      <div class="cx-summary col-sm-12 col-md-6">
        <section class="order-summary-contact-us">
          <h5 class="order-summary-contact-us__title">
            Contact Us for Returns, questions, or Help.
          </h5>

          <standard-talk-to-us ></standard-talk-to-us>
        </section>
      </div>

      <!-- SUMMARY -->
      <div class="cx-summary col-sm-12 col-md-6 col-lg-4 col-xl-4">
        <ng-template [cxOutletContext]="order" cxOutlet="CustomOrderSummary">
        </ng-template>
      </div>
    </div>
  </div>

</ng-container>

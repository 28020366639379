import { NgModule } from "@angular/core";
import { StoreModule } from "@ngrx/store";
import { PRODUCT_CONFIGURATION_KEY } from "./product-configuration.state";
import { productConfigurationReducer } from "./product-configuration.reducer";
import { EffectsModule } from "@ngrx/effects";
import { ProductConfigurationEffect } from "./product-configuration.effect";

@NgModule({
  imports: [
    StoreModule.forFeature(PRODUCT_CONFIGURATION_KEY, productConfigurationReducer),
    EffectsModule.forFeature([ ProductConfigurationEffect ])
  ]
})
export class ProductConfigurationStoreModule {

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfiguratorAccordionItemComponent } from './configurator-accordion-item.component';



@NgModule({
  declarations: [
    ConfiguratorAccordionItemComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ConfiguratorAccordionItemComponent
  ]

})
export class ConfiguratorAccordionItemModule { }

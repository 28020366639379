import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { EventService, Product, TranslationService, WindowRef } from '@spartacus/core';
import { CurrentProductService, ProductIntroComponent } from '@spartacus/storefront';
import { Observable, Subscription } from 'rxjs';
import { SkuPageService } from '../../sku-landing-page/skupage.service';

/**
 * A version of the cx-product-intro used on mobile above the media component
 * Previously the site was injecting two versions of cx-product-intro, with different
 * elements hidden via css, causing duplicate subscriptions and API calls on load & variant
 * change.
 */
@Component({
    selector: 'cx-product-title',
    templateUrl: './custom-product-title.component.html',
    styleUrls: [ './custom-product-title.component.scss' ],
    changeDetection: ChangeDetectionStrategy.OnPush
  })
export class CustomProductTitleComponent extends ProductIntroComponent implements OnInit, OnDestroy {
    override product$: Observable<Product | null>;
    isSkuPage: boolean;
    protected qaComponentId = 'QATurnToFlexCmsComponent';
    protected qaTranslationKey = `TabPanelContainer.tabs.${ this.qaComponentId }`;
    private subscriptions = new Subscription();

    constructor(
      currentProductService: CurrentProductService,
      translationService: TranslationService,
      winRef: WindowRef,
      eventService: EventService,
      private skuPageService: SkuPageService,
    ){
      super(currentProductService, translationService, winRef, eventService);
    }

    ngOnInit(): void {
      this.product$ = this.currentProductService.getProduct();
      this.isSkuPage = this.skuPageService.isSKUPage();
    }

    ngOnDestroy(): void {
      this.subscriptions.unsubscribe();
    }

    protected override getReviewsComponent(): HTMLElement | null {
      return !this.isSkuPage && this.winRef.document.querySelector('.js-product-review-selector');
    }
  
    private customGetTabByLabel(
      label: string,
      tabsComponent: HTMLElement
    ): HTMLElement | undefined {
      const tabElements: HTMLCollectionOf<HTMLElement> =
        tabsComponent.getElementsByTagName('button');
      return Array.from(tabElements).find((buttonElement) =>
        buttonElement.innerText.includes(label)
      );
    }
  
    private customClickTabIfInactive(tab: HTMLElement): void {
      if (
        !tab.classList.contains('active') ||
        tab.classList.contains('toggled')
      ) {
        tab.click();
      }
    }
  
    private customGetTabsComponent(): HTMLElement | null {
      return this.winRef.document.querySelector('cx-tab-paragraph-container');
    }
  
    showQa() {
      this.translationService
        .translate(this.qaTranslationKey)
        .subscribe((qaTabLabel) => {
          const tabsComponent = this.customGetTabsComponent()
          const qaTab =
            tabsComponent && this.customGetTabByLabel(qaTabLabel, tabsComponent);
  
          if (qaTab) {
            this.customClickTabIfInactive(qaTab);
            setTimeout(() => {
              qaTab.scrollIntoView({ behavior: 'smooth' });
              qaTab.focus({ preventScroll: true });
            });
          }
        })
        .unsubscribe();
    }
}
import { Component, Input } from '@angular/core';
import { LoaderConfig } from '../loader.model';

@Component({
  selector: 'local-loader',
  templateUrl: './local-loader.component.html',
  styleUrls: ['./local-loader.component.scss']
})

export class LocalLoaderComponent {

  @Input() loader: LoaderConfig = {status: true};

  constructor() { }
}

import { NgModule } from '@angular/core';
import { cartBaseTranslationChunksConfig, cartBaseTranslations } from "@spartacus/cart/base/assets";
import { ADD_TO_CART_FEATURE, CartBaseRootModule, CART_BASE_FEATURE, MINI_CART_FEATURE, CART_BASE_CORE_FEATURE } from "@spartacus/cart/base/root";
import { CmsConfig, I18nConfig, provideConfig } from "@spartacus/core";
import { CustomCartDetailsComponent } from './components/cart-details/cart-details.component';
import { CartTopContentModule } from './components/cart-top-content/cart-top-content.module';
import { CustomSimpleOrderSummaryModule } from './components/custom-simple-order-summary/custom-simple-order-summary.module';
import { AllCartMessagesModule } from './components/all-cart-messages/all-cart-messages.module';

@NgModule({
  declarations: [],
  imports: [
    CartBaseRootModule,
    CartTopContentModule,
    CustomSimpleOrderSummaryModule
  ],
  providers: [
    provideConfig(<CmsConfig>{
      featureModules: {
        [CART_BASE_FEATURE]: {
          module: () =>
            import('./custom-cart-base.module').then(
              (m) => m.CustomCartBaseModule
            ),
        },
      },
    }),
    provideConfig(<CmsConfig>{
      featureModules: {
        [MINI_CART_FEATURE]: {
          module: () =>
            import('@spartacus/cart/base/components/mini-cart').then(
              (m) => m.MiniCartModule
            ),
        },
      },
    }),
    provideConfig(<CmsConfig>{
      featureModules: {
        [ADD_TO_CART_FEATURE]: {
          module: () =>
            import(
              './components/add-to-cart/add-to-cart.module'
            ).then((m) => m.CustomAddToCartModule),
        },
      },
    }),
    provideConfig(<CmsConfig>{
      cmsComponents: {
        CartDetailsComponent: {
          component: CustomCartDetailsComponent,
        },
      },
    }),
    provideConfig(<I18nConfig>{
      i18n: {
        resources: cartBaseTranslations,
        chunks: cartBaseTranslationChunksConfig,
      },
    }),

  ],
})
export class CartBaseFeatureModule {}

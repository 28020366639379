import { NgModule } from "@angular/core";
import { LeadtimeDetailsModalComponent } from "./leadtime-details.component";
import { I18nModule } from "@spartacus/core";
import { CommonModule } from "@angular/common";

@NgModule({
    declarations: [LeadtimeDetailsModalComponent],
    imports: [CommonModule, I18nModule],
    exports: [LeadtimeDetailsModalComponent]
})
export class LeadtimeDetailsModule { }

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GuestOrderTrackingComponent } from './guest-order-tracking.component';
import { CmsConfig, I18nModule, provideConfig } from '@spartacus/core';
import { FormErrorsModule } from '@spartacus/storefront';
import { ReactiveFormsModule } from '@angular/forms';
import { OrderDetailsModule } from '@spartacus/order/components';
import { CustomOrderSharedModule } from '../custom-order-shared/custom-order-shared.module';



@NgModule({
  declarations: [GuestOrderTrackingComponent],
  exports: [GuestOrderTrackingComponent],
  imports: [
    CommonModule,
    FormErrorsModule,
    I18nModule,
    ReactiveFormsModule,
    OrderDetailsModule,
    CustomOrderSharedModule,
  ],
  providers: [
    provideConfig({
      cmsComponents: {
        GuestOrderTrackingComponent: {
          component: GuestOrderTrackingComponent,
        },
      },
    } as CmsConfig),
  ],
})
export class GuestOrderTrackingModule {}

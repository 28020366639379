import { Component } from '@angular/core';
import { SkuPageService } from 'src/app/spartacus/features/sku-landing-page/skupage.service';

@Component({
  selector: 'handler-fitment-details',
  templateUrl: './handler-fitment-details.component.html',
  styleUrls: ['./handler-fitment-details.component.scss']
})

export class HandlerFitmentDetailsComponent {
  isSKUPage = this.skuPageService.isSKUPage();

  constructor(
    private skuPageService: SkuPageService
  ) { }
}

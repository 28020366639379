import { createAction, props } from "@ngrx/store";
import { Make, Model, Year, YMM } from "../../models/garage.model";

const LOAD_YEARS = '[GARAGE] Load Years';
const LOAD_YEARS_SUCCESS = '[GARAGE] Load Years Success';

const GET_MAKES = '[GARAGE] Get Makes';
const GET_MAKES_SUCCESS = '[GARAGE] Get Makes Success';

const GET_MODELS = '[GARAGE] Get Models';
const GET_MODELS_SUCCESS = '[GARAGE] Get Models Success';

const UPDATE_FIT = '[GARAGE] Update Fit';
const UPDATE_FIT_OK = '[GARAGE] Update Fit Ok';
const UPDATE_FIT_BUSY = '[GARAGE] Update Fit Busy'

export const loadYears = createAction(
  LOAD_YEARS
);

export const loadYearsSuccess = createAction(
  LOAD_YEARS_SUCCESS,
  props<{ years: Year[] }>()
);

export const getMakes = createAction(
  GET_MAKES,
  props<{ year: Year }>()
);

export const getMakesSuccess = createAction(
  GET_MAKES_SUCCESS,
  props<{ makes: Make[], year: Year }>()
);

export const getModels = createAction(
  GET_MODELS,
  props<{ year: Year, make: Make }>()
);

export const getModelsSuccess = createAction(
  GET_MODELS_SUCCESS,
  props<{ models: Model[], year: Year, make: Make }>()
);

export const updateFit = createAction(
  UPDATE_FIT,
  props<{ code: string; ymm: YMM }>()
);

export const updateFitOk = createAction(
  UPDATE_FIT_OK,
  props<{ fit: boolean }>()
);

export const updateFitBusy = createAction(
  UPDATE_FIT_BUSY,
  props<{ busy: boolean }>()
);


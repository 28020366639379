<div *ngIf="order as order" class="order-detail-summary">
  <div class="bg-secondary order-detail-summary__info-container">
    <div class="container">
      <div class="bg-secondary order-detail-summary__info"> <span>Order Number:</span> {{order.code}}</div>
      <div class="bg-secondary order-detail-summary__info mt-2"><span> Order Placed:</span>
        {{converterData(order.created?.toString())}}
      </div>
    </div>
  </div>

  <div class="container">
    <div class="row  mt-4">
      <div class="col-lg-5 col-md-5 col-sm-12">
        <table class="order-detail-summary__table">
          <tbody>
            <tr>
              <td class="order-detail-summary__td-title">Status</td>
              <td class="text-capitalize">{{order.statusDisplay}}</td>
            </tr>
            <tr>
              <td class="order-detail-summary__td-title">Shipping Method</td>
              <td>{{order.deliveryMode?.name}}</td>
            </tr>
            <tr>
              <td class="order-detail-summary__td-title">Ship To</td>
              <td [innerHTML]="getDeliveryAddressCard(order.deliveryAddress)"> </td>
            </tr>
          </tbody>
        </table>
      </div>

      <ng-container *ngIf="order.paymentInfo as paymentDetails">
        <div class="col-lg-3 col-md-3 col-sm-12">
          <div class="order-detail-summary__payment-info-card">
            <div>
              <div class="order-detail-summary__payment-info-title">
                Payment Method
              </div>
            </div>

            <div class="order-detail-summary__payment-info-container">
              <div class="order-detail-summary__payment-info-item">
                {{paymentDetails?.cardType?.name}}
              </div>

              <div class="order-detail-summary__payment-info-item">
                {{paymentDetails?.cardNumber}}
              </div>

              <div class="order-detail-summary__payment-info-item">
                {{paymentDetails?.accountHolderName}}
              </div>

              <div class="order-detail-summary__payment-info-item">
                {{paymentDetails?.expiryMonth}}/{{paymentDetails?.expiryYear}}
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-3 col-md-3 col-sm-12">
          <div class="order-detail-summary__payment-info-card">
            <div>
              <div class="order-detail-summary__payment-info-title">
                Billing Address
              </div>
            </div>

            <div class="order-detail-summary__payment-info-container">
              <div class="order-detail-summary__payment-info-item" [innerHTML]="getBillingAddressCard(paymentDetails.billingAddress)"></div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
 <g clip-path="url(#clip0_5245_30548)">
  <path
   d="M12.6909 7.47678C12.8954 7.25332 12.8954 6.89104 12.6909 6.66759C12.4863 6.44414 12.1547 6.44414 11.9501 6.66759L7.87575 11.1182L6.02423 9.09566C5.81966 8.87221 5.488 8.87221 5.28344 9.09566C5.07888 9.31911 5.07888 9.6814 5.28344 9.90485L7.50579 12.3324C7.71036 12.5559 8.04202 12.5559 8.24658 12.3324C8.25263 12.3258 8.2585 12.3191 8.2642 12.3122L12.6909 7.47678Z"
   fill="#222222" />
  <path fill-rule="evenodd" clip-rule="evenodd"
   d="M12.0755 1.03548C15.6016 2.33772 17.9847 5.73375 18 9.49749C18 9.97239 17.9592 10.4372 17.8878 10.907C17.3366 14.5482 14.428 17.5867 10.8202 18.3221C7.11544 19.0727 3.26272 17.4028 1.29808 14.1652C0.333627 12.5718 -0.120536 10.6771 0.0274499 8.82338C0.175436 6.9594 0.889849 5.19754 2.07373 3.75231C4.4466 0.856744 8.56468 -0.261651 12.0755 1.03548ZM16.9794 9.49749C16.9642 12.8016 14.8668 15.8708 11.7438 16.9994C10.1619 17.5714 8.432 17.6377 6.81436 17.1781C5.25797 16.7338 3.87507 15.8146 2.83917 14.5737C0.690833 11.9947 0.445891 8.20546 2.1809 5.35075C3.89548 2.52157 7.29915 1.00484 10.5497 1.65851C13.8207 2.3173 16.3671 4.98816 16.8927 8.28717C16.9539 8.6855 16.9794 9.09405 16.9794 9.49749Z"
   fill="#222222" />
 </g>
 <defs>
  <clipPath id="clip0_5245_30548">
   <rect width="18" height="18" fill="white" transform="translate(0 0.5)" />
  </clipPath>
 </defs>
</svg>
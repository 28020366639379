/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CmsConfig, provideConfig, provideDefaultConfig } from '@spartacus/core';
import { PageComponentModule } from '@spartacus/storefront';
import { CarouselModule, MediaModule } from '@spartacus/storefront';
import { CustomBannerSatisfiedCustomersItemComponent } from './banner-satisfied-customers-item.component';

@NgModule({
  imports: [CommonModule, PageComponentModule, CarouselModule, MediaModule],
  providers: [
    provideConfig({
      cmsComponents: {
        SatisfiedCustomerBannerComponent: {
        // CHANGETHISLATER: {
          component: CustomBannerSatisfiedCustomersItemComponent,
        },
      },
    } as CmsConfig),
  ],
  declarations: [CustomBannerSatisfiedCustomersItemComponent],
  exports: [CustomBannerSatisfiedCustomersItemComponent],
})
export class BannerSatisfiedCustomerItemModule {}

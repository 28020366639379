import { ChangeDetectionStrategy, Component } from '@angular/core';
import { OrderDetailItemsComponent } from '@spartacus/order/components';

@Component({
  selector: 'app-custom-order-detail-items',
  templateUrl: './custom-order-detail-items.component.html',
  styleUrls: ['./custom-order-detail-items.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomOrderDetailItemsComponent extends OrderDetailItemsComponent {

}

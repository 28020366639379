import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomProductVariantColorComponent } from './custom-product-variant-color.component';
import { RouterModule } from '@angular/router';
import { UrlModule } from '@spartacus/core';
import { CustomMediaComponent } from "../../../../../components/_CUSTOM/media/custom-media.component";

@NgModule({
  declarations: [
    CustomProductVariantColorComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    UrlModule,
    CustomMediaComponent
  ],
  exports: [
    CustomProductVariantColorComponent
  ]
})

export class CustomProductVariantColorModule {
}

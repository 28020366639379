import { Component, ElementRef } from '@angular/core';
import { AnonymousConsentsConfig, AnonymousConsentsService, WindowRef } from '@spartacus/core';
import { AnonymousConsentDialogComponent, LaunchDialogService } from '@spartacus/storefront';
interface CheckboxItem {
  id: string;
  label: string;
  checked: boolean;
}
@Component({
  selector: 'app-custom-anonymous-consent-dialog',
  templateUrl: './custom-anonymous-consent-dialog.component.html',
  styleUrls: ['./custom-anonymous-consent-dialog.component.scss']
})

export class CustomAnonymousConsentDialogComponent extends AnonymousConsentDialogComponent {

  checkedCheckboxes: string[] = [];
  constructor(
    private winRef: WindowRef,
    config: AnonymousConsentsConfig,
    anonymousConsentsService: AnonymousConsentsService,
    el: ElementRef,
    launchDialogService: LaunchDialogService) {
    super(config, anonymousConsentsService, el, launchDialogService);
  }

  checkboxes: CheckboxItem[] = [
    { id: 'ad_storage', label: 'Storage', checked: false },
    { id: 'ad_user_data', label: 'Userdata', checked: false },
    { id: 'ad_personalization', label: 'Personalization', checked: false },
    { id: 'analytics_storage', label: 'Analytics', checked: false }
  ];

  onCheckboxChange(event: any, checkbox: CheckboxItem) {
    checkbox.checked = event.target.checked;
    if (checkbox.checked) {
      this.checkedCheckboxes.push(checkbox.id);
      this.pushConsentToDataLayer(checkbox);
    } else {
      const index = this.checkedCheckboxes.indexOf(checkbox.id);
      if (index !== -1) {
        this.checkedCheckboxes.splice(index, 1);
        this.removeConsentFromDataLayer(checkbox);
      }
    }
  }

  pushConsentToDataLayer(checkbox: CheckboxItem) {
    if (this.winRef.isBrowser()) {
      const consentData: any = {};
      const _window: any = this.winRef.nativeWindow;
      _window.dataLayer.push({ ecommerce: null });
      this.checkboxes.forEach(checkbox => {
        if (checkbox.checked) {
          if (checkbox.label == 'Storage') {
            consentData['functionality_storage'] = "granted";
            consentData[checkbox.id] = "granted";
          } else if (checkbox.label == 'Personalization') {
            consentData['personalization_storage'] = "granted";
            consentData[checkbox.id] = "granted";
          } else if (checkbox.label == 'Userdata') {
            consentData['security_storage'] = "granted";
            consentData[checkbox.id] = "granted";
          }
          else if (checkbox.label == 'Analytics') {
                      consentData[checkbox.id] = "granted";
                  }
        } else {
          consentData[checkbox.id] = "denied";
        }
      })
      _window.dataLayer.push({
        "event": "consent_update",
        consent: consentData
      })
    }
  }
  removeConsentFromDataLayer(checkbox: CheckboxItem) {
    if (this.winRef.isBrowser()) {
      const _window: any = this.winRef.nativeWindow;
      _window.dataLayer.push({ ecommerce: null });
      const consentData: any = {};
      this.checkboxes.forEach(cb => {
        consentData[cb.id] = cb.checked ? "granted" : "denied";
      });
      _window.dataLayer.push({
        "event": "consent_update",
        "consent": consentData
      });
    }
  }

  selectAllCheckboxes() {
    this.checkboxes.forEach(checkbox => {
      checkbox.checked = true;
      this.pushConsentToDataLayer(checkbox);
    });
    this.closePopup();
  }

  rejectAllCheckboxes() {
    this.checkboxes.forEach(checkbox => {
      checkbox.checked = false;
      this.removeConsentFromDataLayer(checkbox);
    });
    this.closePopup();
  }


  closePopup() {
    setTimeout(() => {
      this.launchDialogService.closeDialog("close");
    }, 1000);
  }
  closeConsent() {
    setTimeout(() => {
      this.launchDialogService.closeDialog("close");
    }, 2000);
  }

  isSelectAllChecked(): boolean {
    return this.checkboxes.every(checkbox => checkbox.checked);
  }


}

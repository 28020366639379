<ng-container *ngIf="components$ | async as components">
  <div [attr.aria-label]="ariaLabel | cxTranslate" role="region" tabindex="-1">
    <ng-container *ngFor="let component of components; let i = index">
      <ng-container *ngIf="component">
        <div class="container-item">
          <button (click)="select(i, $event)" [attr.aria-expanded]="i === activeTabNum" [class.active]="i === activeTabNum">
            <div class="container">
              <div class="container-title">
                <span>{{ component.title | cxTranslate: { param: tabTitleParams[i] | async } }}</span>
                <span aria-hidden="true" class="accordion-icon"></span>
              </div>
            </div>
          </button>

          <div [class.active]="i === activeTabNum" class="cx-tab-paragraph-content" role="presentation" tabindex="0">
            <ng-template [cxOutletContext]="{}" [cxOutlet]="component.flexType">
              <ng-container (cxComponentRef)="tabCompLoaded($event)" [cxComponentWrapper]="component"></ng-container>
            </ng-template>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
</ng-container>

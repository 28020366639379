import { NgModule } from "@angular/core";
import { TalkToUsComponent } from "./talk-to-us.component";
import { CmsConfig, provideConfig } from "@spartacus/core";
import { AsyncPipe, CommonModule, NgIf } from "@angular/common";
import { TalkToUsCardsModule } from "../talk-to-us-cards/talk-to-us-cards.module";

@NgModule({
  declarations: [ TalkToUsComponent ],
  imports: [
    CommonModule,
    TalkToUsCardsModule
  ],
  providers: [
    provideConfig({
      cmsComponents: {
        CustomerServiceOpeningHoursComponent: {
          component: TalkToUsComponent
        }
      }
    } as CmsConfig)
  ]
})
export class TalkToUsModule {

}

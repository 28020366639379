import { Component } from '@angular/core';
import { fitmentListMock } from './fitment-details.mock';
import { Observable, of } from 'rxjs';
import { CurrentProductService } from '@spartacus/storefront';
import { map } from 'rxjs/operators';
import { SkuOption } from 'src/app/core/model/sku-option';

@Component({
  selector: 'fitment-details',
  templateUrl: './fitment-details.component.html',
  styleUrls: ['./fitment-details.component.scss']
})

export class FitmentDetailsComponent {
  fitmentList$: Observable<SkuOption[]> = this.currentProductService.getProduct().pipe(
    map((product) => product?.skuOptions || [])
  );

  constructor (
    private currentProductService: CurrentProductService
  ) {}
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomProductVariantsContainerComponent } from './product-variants-container.component';
import { CmsConfig, I18nModule, provideConfig } from '@spartacus/core';
import { CustomProductVariantColorSelectorComponent } from '../product-variant-color-selector/product-variant-color-selector.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomProductVariantSelectorComponent } from '../product-variant-selector/product-variant-selector.component';
import { MediaModule } from "@spartacus/storefront";
import { ProductConfiguratorComponentsModule } from "../../../configurator/components/product-configurator-components.module";
import { SvgIconComponent } from "../../../../../../components/_CUSTOM/svg/components/svg-icon/svg-icon.component";
import { ProductFitComponent } from "../../../product-fit/product-fit.component";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        I18nModule,
        MediaModule,
        ProductConfiguratorComponentsModule,
        SvgIconComponent,
        ReactiveFormsModule,
        CustomProductVariantColorSelectorComponent,
        CustomProductVariantSelectorComponent,
        ProductFitComponent
    ],
  declarations: [
    CustomProductVariantsContainerComponent,
  ],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        ProductVariantSelectorComponent: {
          component: CustomProductVariantsContainerComponent,
        },
      },
    }),
  ],
  exports: [
    CustomProductVariantsContainerComponent,
  ]
})
export class CustomProductVariantsContainerModule {
}

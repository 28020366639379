import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomSimpleOrderSummaryComponent } from './custom-simple-order-summary.component';
import { CmsConfig, I18nModule, provideConfig } from '@spartacus/core';
import { IconsModule } from 'src/app/components/_CUSTOM/icons/icons.module';
import { AllCartMessagesModule } from '../all-cart-messages/all-cart-messages.module';
import { CustomCartProceedToCheckoutModule } from '../custom-cart-proceed-to-checkout/custom-cart-proceed-to-checkout.module';

@NgModule({
  declarations: [
    CustomSimpleOrderSummaryComponent
  ],
  imports: [
    CommonModule,
    I18nModule,
    AllCartMessagesModule,
    CustomCartProceedToCheckoutModule
  ],
  exports: [
    CustomSimpleOrderSummaryComponent
  ],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        SimpleOrderSummary: {
          component: CustomSimpleOrderSummaryComponent,
        },
      },
    }),
  ]
})

export class CustomSimpleOrderSummaryModule { }

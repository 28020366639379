import { createReducer, on } from "@ngrx/store";
import { ProductDetailsState } from "./product-details.state";
import { ProductDetailsActions } from "./index";
import { deepCloning } from "../../../../../tools/tools";

const initialState: ProductDetailsState = {
  product: {},
  variants: {},
  selectedVariants: [],
  prices: {},
  gallery: {}
}

export const productDetailsReducer = createReducer(
  initialState,
  on(ProductDetailsActions.loadProductSuccess, (state, {product, scope}) => {
    return {...state, ...{product}}
  }),
  on(ProductDetailsActions.startVariants,
    (state, { options, productCode }) => {
      const variants = options
        .map(({ qualifier }) => ( {
            [ qualifier ]: {
              qualifier,
              options: {}
            }
          } )
        )
        .reduce((prev, next) => ( { ...prev, ...next } ));
      return {
        ...state,
        variants: {
          ...state.variants,
          [ productCode ]: variants,
        }
      }
    }),
  on(ProductDetailsActions.selectOption,
    (state,
     { qualifier, option }) => {
      const variants = deepCloning(state.selectedVariants);
      const idx = variants?.findIndex(variant => variant.qualifier === qualifier);
      if (idx >= 0) {
        variants.splice(idx, variants.length - idx);
      }
      return {
        ...state,
        selectedVariants: [
          ...( variants ?? [] ),
          { qualifier, option }
        ]
      }
    }),
  on(ProductDetailsActions.clearSelectedVariantOptions, (state) => {
    return {
      ...state,
      selectedVariants: []
    }
  }),
  on(ProductDetailsActions.loadVariantOptionOk,
    (state, { product, qualifier, options, selectedOptions }) => {
      const optionsKeyChain = selectedOptions?.map(opt => opt.option.value).join('||');
      const keyChain = `${ product }${ optionsKeyChain }`;
      let selectedVariants;
      if (state.selectedVariants?.length) {
        selectedVariants = deepCloning(state.selectedVariants).map(
          v => {
            if (v.qualifier.toLowerCase().indexOf('color') >= 0) {
              const loadedVariant = options.find(v => v.qualifier === v.qualifier);
              if (qualifier && loadedVariant) {
                v.option.image = loadedVariant.image;
              }
            }
            return v;
          }
        );
      }

      return {
        ...state,
        variants: {
          ...state.variants,
          [ product ]: {
            ...state.variants[ product ],
            [ qualifier ]: {
              ...state.variants[ product ][ qualifier ],
              activeKeyChain: keyChain,
              options: {
                ...state.variants[ product ][ qualifier ].options,
                [ keyChain ]: options,
              }
            }
          }
        },
        selectedVariants
      }
    }),
  on(ProductDetailsActions.updatePriceOk, (state, { price, keyChain }) => {
    return {
      ...state,
      prices: {
        ...state.prices,
        [ keyChain ]: price
      },
      sku: price.sku
    };
  }),
  on(ProductDetailsActions.loadGalleryOk, (state, { images, keyChain }) => {
    if (!images) {
      return state;
    }
    return {
      ...state,
      gallery: {
        ...state.gallery,
        [ keyChain ]: images
      }
    };
  })
);

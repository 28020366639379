import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ImageGroup, ImageType } from '@spartacus/core';
import { map } from "rxjs/operators";
import { ProductDetailsService } from "../../core/facade/product-details.service";
import { CustomCurrentProductService } from '../../current-product.service';

@Component({
  selector: 'product-medias-thumbnails',
  templateUrl: './product-medias-thumbnails.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductMediasThumbnailsComponent {
  @Input() active: number;
  @Input() maxGalleryItems = 4;
  @Output() changeMedia = new EventEmitter<{ container: ImageGroup, index: number, mediaType: string }>();
  @Output() openGallery = new EventEmitter<number>();

  product$ = this.customCurrentProductService.getProduct();
  thumbs$ = this.productDetailsService.activeGallery$.pipe(
    map((images) => images?.[ ImageType.GALLERY ] as ImageGroup[] ?? [])
  );

  constructor(
    private productDetailsService: ProductDetailsService,
    private customCurrentProductService: CustomCurrentProductService
  ) { }

  identify(i: number, item: ImageGroup): number {
    return item?.[ 'thumbnail' ]?.galleryIndex;
  }

  openImage(container: ImageGroup, index: number, mediaType: string) {
    this.changeMedia.emit({
      container,
      index,
      mediaType
    });
  }

  onOpenGallery(tabIndex = 0) {
    this.openGallery.emit(tabIndex);
  }
}

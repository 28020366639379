import { Component, Input, OnInit } from '@angular/core';
import { AuthService, WindowRef } from '@spartacus/core';
import { LoginComponent } from '@spartacus/user/account/components';
import { UserAccountFacade } from '@spartacus/user/account/root';

@Component({
  selector: 'cx-login',
  templateUrl: './login.component.html',
})

export class CustomLoginComponent extends LoginComponent {

  constructor(
    private _auth: AuthService,
    private _userAccount: UserAccountFacade,
    private winRef: WindowRef
  ) {
    super(_auth, _userAccount);
  }

  closeHamburger() {
    const cxHeaderElement = document.getElementById('cx-header');
    const cxHamburgerElement = document.getElementsByClassName('cx-hamburger');
    cxHeaderElement?.classList.remove('is-expanded');
    cxHamburgerElement[0]?.classList.remove('is-active');
  }

  openMenu () {
    if (this.winRef.isBrowser()) {
      const button = this.winRef.document.querySelectorAll('.js-loggedInButton')[1] as HTMLButtonElement;
      button.click();
    }
  }
}

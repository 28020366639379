import { createFeatureSelector, createSelector } from "@ngrx/store";
import { PRODUCT_CONFIGURATION_KEY, ProductConfigurationState } from "./product-configuration.state";
import { Vehicle } from "../../../../ymm/core/models/garage.model";

export const productConfigurationFeatureKey = createFeatureSelector<ProductConfigurationState>(PRODUCT_CONFIGURATION_KEY);

export const selectSubModelsState = createSelector(
  productConfigurationFeatureKey,
  state => state.submodels
);

export const selectSubModels = (productLine: string, vehicleCode: string) => createSelector(
  productConfigurationFeatureKey,
  state => state?.submodels
    ?.find(s => s.productLine === productLine && s.vehicleCode === vehicleCode)
);

export const selectSubModelOptionsState = createSelector(
  productConfigurationFeatureKey,
  state => state.subModelOptions
);

export const selectSubModelOptions = (productLine: string, subModel: string, vehicle: Vehicle) => createSelector(
  productConfigurationFeatureKey,
  state => state.subModelOptions
    ?.find(s => s.productLine === productLine
      && s.subModel === subModel
      && s.vehicle.code === vehicle.code
    )
);

export const selectActiveSelection = createSelector(
  productConfigurationFeatureKey,
  state => state.activeSelectionConfig
);

export const selectConfigurationForProduct = (keyChain: string) => createSelector(
  productConfigurationFeatureKey,
  state => state.configurations ? state.configurations[ keyChain ] : null
);

export const selectConfigurationLoading = createSelector(
  productConfigurationFeatureKey,
  state => state.productConfigLoading
);

export const selectConfiguratorForProduct = (productCode: string) => createSelector(
  productConfigurationFeatureKey,
  state => {
    return !!state.productConfigurations
      ? state.productConfigurations[ productCode ]
      : null
  }
)


<div class="container">

  <div class="custom-register-guest row justify-content-between">

    <div class="col-12 col-lg-6 col-xl-4">
      <div class="d-flex  justify-content-center h-100" style="flex-direction: column;">
        <h3 class="custom-register-guest__title">Checkout Faster Next Time</h3>
        <p>
          Want easy order tracking and faster checkout? Choose a password to create your account for
          <span style="font-weight: 700;">{{ email }}</span>
        </p>
      </div>
    </div>

    <div class="col-12 col-lg-6 col-xl-7 guest-register">
      <section class="row">
        <div class="col-12 col-md-9">
          <form (ngSubmit)="submit()" [formGroup]="guestRegisterForm">
            <div class="d-flex">
              <div class="flex-1">
                <input
                  [attr.aria-label]="'register.password.placeholder' | cxTranslate"
                  class="form-control mb-0 h-100" cxPasswordVisibilitySwitch
                  cxPasswordVisibilitySwitch
                  formControlName="password"
                  [maxlength]="20"
                  name="password"
                  placeholder="{{ 'register.password.placeholder' | cxTranslate }}"
                  required="true"
                  style="padding: 15px 16px 16px 16px;" type="password"/>
              </div>
              <button class="btn btn-secondary" type="submit">
                {{ 'customLoginForm.createAccount' | cxTranslate }}
              </button>
            </div>
            <div class="guest-register__policy">
              <cx-form-errors [control]="guestRegisterForm.get('password')"></cx-form-errors>
              By clicking 'Create account', you acknowledge you have
              read and agreed to our Terms of Use and Privacy Policy.
            </div>

          </form>
        </div>

        <div class="col-12 col-md-3">
          <div class="guest-register__password-rules">
            Passwords must have 8-20 characters and
            contain 2 alpha and 1 special character
          </div>
        </div>
      </section>
    </div>
  </div>
</div>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CmsConfig, provideConfig } from '@spartacus/core';
import { PageTitlePLPComponent } from './page-title-plp.component';

@NgModule({
  declarations: [
    PageTitlePLPComponent
  ],
  imports: [
    CommonModule
  ],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        pageTitlePLP: {
          component: PageTitlePLPComponent,
        },
      },
    }),
  ],
})

export class PageTitlePLPModule { }

import { Component } from '@angular/core';
import { CardComponent } from '@spartacus/storefront';

@Component({
  selector: 'cx-card',
  templateUrl: './card.component.html',
})

export class CustomCardComponent extends CardComponent {

}

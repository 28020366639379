<cx-media
  #defaultImage
  *ngIf="!isZoomed"
  [container]="container"
  class="cx-default-image-zoom">
</cx-media>
<div #zoomContainer *ngIf="isZoomed" class="cx-zoom-container">
  <cx-media
    #zoomedImage
    (mousemove)="pointerMove($event)"
    (touchend)="clearTouch()"
    (touchmove)="touchMove($event)"
    [container]="container"
    class="cx-image-zoomed">
  </cx-media>
</div>

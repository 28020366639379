import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomProductIntroComponent } from './product-intro.component';
import { CmsConfig, I18nModule, provideConfig } from '@spartacus/core';
import { StarRatingModule } from '@spartacus/storefront';
import { ProductPriceModule } from '../product-price/product-price.module';

@NgModule({
  declarations: [
    CustomProductIntroComponent
  ],
  imports: [
    CommonModule,
    I18nModule,
    StarRatingModule,
    ProductPriceModule,
  ],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        ProductIntroComponent: {
          component: CustomProductIntroComponent,
        },
      },
    }),
  ],
  exports:[CustomProductIntroComponent]
})
export class CustomProductIntroModule { }

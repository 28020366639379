import { AnonymousConsentDialogComponent, DIALOG_TYPE, LayoutConfig } from "@spartacus/storefront";
import { CustomAnonymousConsentDialogComponent } from "./custom-anonymous-consent-dialog/custom-anonymous-consent-dialog.component";

export const defaultAnonymousConsentLayoutConfig: LayoutConfig = {
  launch: {
    ANONYMOUS_CONSENT: {
      inlineRoot: true,
      component: CustomAnonymousConsentDialogComponent,
      dialogType: DIALOG_TYPE.DIALOG,
    },
  },
};

import { NgModule } from "@angular/core";
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import { ProductDetailsEffects } from "./product-details.effects";
import { PRODUCT_DETAILS_FEATURE_KEY } from "./product-details.state";
import { productDetailsReducer } from "./product-details.reducer";

@NgModule({
  imports: [
    StoreModule.forFeature(PRODUCT_DETAILS_FEATURE_KEY, productDetailsReducer),
    EffectsModule.forFeature([ ProductDetailsEffects ])
  ]
})
export class ProductDetailsStoreModule {

}

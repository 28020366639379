import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdditionDetailsComponent } from './addition-details.component';
import { CmsConfig, I18nModule, provideConfig } from '@spartacus/core';
import { MediaModule } from '@spartacus/storefront';
import { CapitalizeModule } from 'src/app/components/pipes/capitalize/capitalize.module';

@NgModule({
  declarations: [
    AdditionDetailsComponent
  ],
  imports: [
    CommonModule,
    I18nModule,
    MediaModule,
    CapitalizeModule
  ],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        AdditionalDetails: {
          component: AdditionDetailsComponent,
        },
      },
    }),
  ],
  exports: [
    AdditionDetailsComponent
  ]
})

export class AdditionalDetailsModule { }

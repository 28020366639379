import { createFeatureSelector, createSelector } from "@ngrx/store";
import { GARAGE_KEY, GarageState } from "../garage.state";
import { Make, Year } from "../../models/garage.model";

export const garageFeatureSelector = createFeatureSelector<GarageState>(GARAGE_KEY);


export const selectYears = createSelector(
  garageFeatureSelector,
  state => state.years
);

const selectYMM = createSelector(
  garageFeatureSelector,
  state => state.inventory
);

export const selectMakes = (year: Year) => createSelector(
  selectYMM,
  ymm => ymm?.length ? ymm
      .filter(y => y.models.some(m => m.year === year))
      .map(ymm => ymm.make)
      .sort((a,b) => { return a > b ? 1 : a < b ? -1 : 0; })
    : []
)

export const selectModels = (year: Year, make: Make) => createSelector(
  selectYMM,
  ymm => ymm?.length ? ymm
      .find(y => y.make === make)?.models
      ?.find(m => m.year === year)
      ?.models
    : []
);

export const selectVehicles = createSelector(
  garageFeatureSelector,
  state => {
    const garage = state.vehicles?.garage;
    if (garage) {
      const keys = Object.keys(garage);
      if (keys) {
        return keys.map(k => garage[ k ])
          .sort((v1, v2) =>
            v1.code === state.vehicles.active
              ? -1 : v2.code === state.vehicles.active
                ? 1
                : v1.code <= v2.code
                  ? -1
                  : 1)
      }
    }
    return [];
  }
);

export const selectActiveVehicleCode = createSelector(
  garageFeatureSelector,
  state => state.vehicles?.active ?? ''
);

export const selectActiveVehicle = createSelector(
  garageFeatureSelector,
  state => {
    return state.vehicles?.garage && state.vehicles.active
      ? state.vehicles?.garage[ state.vehicles.active ]
      : undefined
  }
);

export const selectFit = createSelector(
  garageFeatureSelector,
  state => state.fit
);

export const selectFitBusy = createSelector(
  garageFeatureSelector,
  state => state.fitBusy
);


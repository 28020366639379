import { Component } from '@angular/core';
import { CustomItemListComponent } from 'src/app/spartacus/features/cart/components/custom-item-list/custom-item-list.component';

@Component({
  selector: 'app-custom-order-item-list',
  templateUrl: './custom-order-item-list.component.html',
  styleUrls: ['./custom-order-item-list.component.scss']
})
export class CustomOrderItemListComponent extends CustomItemListComponent {
  
}

import { Component, ChangeDetectionStrategy, OnDestroy, ViewContainerRef, ComponentRef } from "@angular/core";
import { isNotNullable, ImageType, ImageGroup } from "@spartacus/core";
import { LaunchDialogService, LAUNCH_CALLER } from "@spartacus/storefront";
import { Subscription, BehaviorSubject, combineLatest } from "rxjs";
import { tap, filter } from "rxjs/operators";
import { ProductDetailsService } from "../../core/facade/product-details.service";
import { ProductMediasDialogComponent } from "../product-medias-dialog/product-medias-dialog.component";

@Component({
  selector: 'cx-product-images',
  styleUrls: [ './product-medias.component.scss' ],
  templateUrl: './product-medias.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductMediasComponent implements OnDestroy {

  private subscriptions = new Subscription();
  active = 0;
  activeContainer = new BehaviorSubject(null);
   
  gallery$ = this.productDetailsService.activeGallery$
    .pipe(
      tap(_ => this.activeContainer.next(null)),
      filter(isNotNullable),
      tap((images) => {
        const image = images?.[ ImageType.PRIMARY ] ?? {};
        this.active = 0;
        images?.PRIMARY?.[ `product` ] && this.activeContainer.next(image);
      })
    );

    constructor(private productDetailsService: ProductDetailsService,
      private dialogService: LaunchDialogService,
      private vcr: ViewContainerRef,) {
      }

  openImage(container: ImageGroup, i: number) {
    this.activeContainer.next(container);
    const image = container?.[ 'product' ]
      ?? container?.[ 'thumbnail' ]
      ?? container?.[ 'zoom' ];
    this.activeContainer.next(null);
    setTimeout(() => {
      this.activeContainer.next(container);
      this.active = i;
    }, 500);
  }

  openGallery(tabIndex = 0): void {
    const component = this.dialogService.launch(
      LAUNCH_CALLER.PRODUCT_IMAGE_ZOOM,
      this.vcr
    );
    if (component) {
      this.subscriptions.add(
        combineLatest([
          component,
          this.dialogService.dialogClose
        ]).pipe(
          tap(([ comp ]) => {
            const component =  comp as ComponentRef<ProductMediasDialogComponent> ;
              component.instance.active = this.active;
              component.instance.activeTab = tabIndex;
          }),
          filter(([ , close ]) => Boolean(close)),
          tap(([ comp ]) => {
            this.dialogService.clear(LAUNCH_CALLER.PRODUCT_IMAGE_ZOOM);
            comp?.destroy();
          })
        ).subscribe()
      );
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

}


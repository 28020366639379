import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BreakpointService, CmsComponentData } from "@spartacus/storefront";
import { map, switchMap, tap } from "rxjs/operators";
import { CmsService } from "@spartacus/core";
import { CmsBannerLOQComponent, CmsBannerLOQComponentEntry } from '../../banner-legacy-of-quality.model';
import { combineLatest } from "rxjs";
import { environment } from 'src/environments/environment';
import { breakpointMediaMapKey } from 'src/app/components/utils/cms-utils';

@Component({
  selector: 'cx-legacy-of-quality-panel',
  templateUrl: './banner-legacy-of-quality.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BannerLegacyOfQualityComponent {
  banner!: any
  banners$ = this.componentData.data$.pipe(
    tap((data) => {
      this.banner = data
    }),
    switchMap(({ components }) => {
      const banners$ = components
        .split(' ')
        .map((entry: string) => this.cmsService.getComponentData<CmsBannerLOQComponentEntry>(entry));
      return combineLatest(banners$);
    }),
  );

  bg$ = this.breakpointService.breakpoint$.pipe(
    map(breakpoint => {
      if (!this.banner.media) {
        return {};
      }
      const mediaKey = breakpointMediaMapKey.get(breakpoint) ?? 'desktop';
      return { 'background-image': `url(${ environment.baseUrl }${ this.banner?.media[ mediaKey ]?.url })` };
    })
  );

  constructor(private componentData: CmsComponentData<CmsBannerLOQComponent>,
              private cmsService: CmsService,
              private breakpointService: BreakpointService) {
  }

}
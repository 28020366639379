<ng-container *ngIf="items$ | async as items">
<ng-container *ngIf="title$ | async as title">
<div class="container">
 <div  class="product-reference__title" *ngIf="items.length > 0">
   {{title}}
 </div>
  </div>

</ng-container>
<cx-custom-carousel [autoplayOn]="false"  [items]="items" [template]="carouselItem" class="mb-6" [showArrowButtons]="true" itemWidth="230px">
</cx-custom-carousel>

<ng-template #carouselItem let-item="item">
  <custom-product-carousel-item [product]="item" ></custom-product-carousel-item>
</ng-template>

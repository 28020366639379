import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewEncapsulation } from "@angular/core";
import { ImageGroup, Product } from "@spartacus/core";
import { ProductDetailsPrice } from "../../../../../core/models/product-details.models";
import { AddonTogglePayload, AddonVariant } from "../../core/addons-model";
import { deepCloning } from "../../../../../../../../tools/tools";

@Component({
  selector: 'addons-card',
  templateUrl: './addons-card.component.html',
  styleUrls: [ './addons-card.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class AddonsCardComponent implements OnChanges {
  @Input() product: Product;
  @Output() toggleSelection = new EventEmitter<AddonTogglePayload>();
  @Input() selection?: { selected: boolean, variants?: AddonVariant[] };
  image: ImageGroup;
  detailsPrice: ProductDetailsPrice;
  private addonVariants: AddonVariant[];

  ngOnChanges(changes: SimpleChanges): void {
    if (changes[ 'product' ] && this.product) {
      this.image = this.product.images[ 'PRIMARY' ];
      this.setProductPrice();
    }
  }

  onImageChange(image: ImageGroup): void {
    if (image) {
      this.image = image;
    }
  }

  onPriceChange(price: ProductDetailsPrice): void {
    if (price && Object.keys(price)?.length > 0) {
      this.detailsPrice = price;
    } else {
      this.setProductPrice();
    }
  }

  toggleAddon(removeSelection?: boolean): void {
    this.toggleSelection.emit({
      sku: this.detailsPrice.sku,
      productBase: this.product.code,
      price: this.detailsPrice,
      product: this.product,
      addonVariants: deepCloning(this.addonVariants),
      removeSelection
    });
  }

  private setProductPrice(): void {
    const sku = !this.product.variantOptions?.length && this.product.code;
    this.detailsPrice = {
      sku,
      prices: {
        price: this.product.price,
        priceWithDiscount: this.product.priceWithDiscount,
        discount: this.product.discount,
        discountPercentage: this.product.discountPercentage,
      }
    }
  }

  onVariantStateChanges(addonVariants: AddonVariant[]) {
    this.addonVariants = addonVariants;
  }
}

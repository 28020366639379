import { Component, Input } from "@angular/core";
import { RoutingService } from "@spartacus/core";
import { map } from "rxjs/operators";

@Component({
  selector: 'category-carousel-item',
  templateUrl: './custom-category-carousel-item.component.html',
  styleUrls: ['custom-category-carousel-item.component.scss'],
})
export class CustomCategoryCarouselItemComponent {
  @Input() category: any;
  url$ = this.routingService.getRouterState().pipe(
    map(urlState => {
      let modifiedUrl = urlState.state.url;
      const queryStringIndex = modifiedUrl.indexOf('?');
      if (queryStringIndex !== -1) {
        modifiedUrl = modifiedUrl.slice(0, queryStringIndex);
      }
      modifiedUrl = modifiedUrl
        .split('/')
        .filter((p) => p !== 'covercraft' && p !== 'USD' && p !== 'en')
        ?.join('/');

      modifiedUrl = modifiedUrl.replace(/%20/g, ' ');
      let cName = this.category.code;
      return modifiedUrl + '/' + cName;
    })
  );

  constructor(
    private routingService: RoutingService
  ) {
  }
}

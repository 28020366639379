<div *ngIf="loading$ | async; else consentManagementForm">
  <div class="cx-spinner">
    <cx-spinner></cx-spinner>
  </div>
</div>

<ng-template #consentManagementForm>
  <div role="status" [attr.aria-label]="'common.loaded' | cxTranslate"></div>
  <ng-container *ngIf="templateList$ | async as templateList">
    <div class="row w-100 cx-consent-toggles">
      <div class="col-12">
        <cx-consent-management-form
          *ngFor="let consentTemplate of templateList"
          [consentTemplate]="consentTemplate"
          [requiredConsents]="requiredConsents"
          (consentChanged)="onConsentChange($event)"
        ></cx-consent-management-form>
      </div>
    </div>
  </ng-container>
</ng-template>

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { I18nModule } from "@spartacus/core";
import { map } from "rxjs/operators";
import { CommonModule } from "@angular/common";
import { GarageService } from "../../core/facade/garage.service";

@Component({
  selector: 'cx-garage-icon-component',
  templateUrl: './garage-icon.component.html',
  styleUrls: [ './garage-icon.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    I18nModule
  ]
})
export class GarageIconComponent {
  q$ = this.garageService.getVehicles().pipe(map(v => v.length));

  constructor(private garageService: GarageService) {
  }

  openModal() {
    this.garageService.openGarageDialog();
  }

}

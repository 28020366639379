import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { ProductListItemContext, ProductListItemContextSource } from '@spartacus/storefront';
import { Product } from "@spartacus/core";

@Component({
  selector: 'cx-product-grid-item',
  templateUrl: './custom-product-grid-item.component.html',
  styleUrls: [ './custom-product-grid-item.component.scss' ],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    ProductListItemContextSource,
    {
      provide: ProductListItemContext,
      useExisting: ProductListItemContextSource,
    },
  ],
})

export class CustomProductGridItemComponent {
  @Input() product: Product | undefined;
}

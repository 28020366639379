import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'capitalize'
})

export class CapitalizePipe implements PipeTransform {
  transform(value: string): string {
    return this.capitalize(value);
  }

  capitalize = (value: string): string => {
    if (!value) return '';
    return value?.toLowerCase()?.split(' ')?.map(word => word.charAt(0).toUpperCase() + word.slice(1) )?.join(' ');
  }
}

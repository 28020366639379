import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CmsConfig, I18nModule, I18nTestingModule, provideConfig } from '@spartacus/core';
import { CarouselModule, DIALOG_TYPE, IconModule, KeyboardFocusModule, LayoutConfig, MediaModule, StarRatingModule } from '@spartacus/storefront';
import { CustomProductImageZoomViewComponent } from './product-image-zoom-view/product-image-zoom-view.component';
import { TabsModule } from '../../shared/components/tabs';
import { ProductMediasDialogComponent } from "./product-medias-dialog/product-medias-dialog.component";
import { ProductMediasComponent } from "./product-medias-component/product-medias.component";
import { ProductMediasThumbnailsComponent } from "./product-medias-thumbnails/product-medias-thumbnails.component";
import { ProductVideosThumbnailsComponent } from './product-videos-thumbnails/product-videos-thumbnails.component';
import { CustomProductVariantsContainerModule } from '../variants/components/product-variants-container/product-variants-container.module';
import { AddonsConfiguratorModule } from '../configurator/configurators/addons/addons-configurator.module';
import { CustomProductTitleModule } from '../product-title/custom-product-title.module';

@NgModule({
  imports: [
    CommonModule,
    I18nModule,
    MediaModule,
    CarouselModule,
    IconModule,
    KeyboardFocusModule,
    TabsModule,
    StarRatingModule,
    CustomProductVariantsContainerModule,
    CustomProductTitleModule
  ],
  providers: [
    provideConfig(<LayoutConfig>{
      launch: {
        PRODUCT_IMAGE_ZOOM: {
          inline: true,
          component: ProductMediasDialogComponent,
          dialogType: DIALOG_TYPE.DIALOG,
        },
      },
    }),
    provideConfig(<CmsConfig>{
      cmsComponents: {
        ProductImagesComponent: {
          component: ProductMediasComponent,
        },
      },
    }),
  ],
  declarations: [
    ProductMediasComponent,
    ProductMediasDialogComponent,
    ProductMediasThumbnailsComponent,
    ProductVideosThumbnailsComponent,
    CustomProductImageZoomViewComponent,
  ],
  exports:[ProductMediasThumbnailsComponent]
})
export class CustomProductImageZoomModule {}

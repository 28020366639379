import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl } from "@angular/forms";
import { ProductConfigurationService } from "../../core/facade/product-configuration.service";
import { GarageService } from "../../../../ymm/core/facade/garage.service";
import { CurrentProductService } from "@spartacus/storefront";
import { BehaviorSubject, combineLatest, Subscription } from "rxjs";
import { filter, map, shareReplay, switchMap, take, tap } from "rxjs/operators";
import { isNotNullable, Product } from "@spartacus/core";
import { ProductDetailsService } from "../../../core/facade/product-details.service";
import { SubModelOption } from "../../core/store/product-configuration.state";
import { DescriptionValuePair } from "../../../../../../core/model/common";
import { Vehicle } from "../../../../ymm/core/models/garage.model";
import { DataLayerService } from 'src/app/spartacus/features/data-layer/data-layer.service';


@Component({
  selector: 'vehicle-selection',
  templateUrl: './vehicle-selection.component.html',
  styleUrls: [ './vehicle-selection.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class VehicleSelectionComponent implements OnInit, OnDestroy {
  private fitmentSnapShot: { subModel?: string, subModelOption?: string } = {};
  private subscriptions = new Subscription();
  private product: Product;
  private vehicle: Vehicle;
  /**
   * The form and its controls
   */
  subModelControl = new FormControl({value: '', disabled: true});
  subModelOptionControl = new FormControl('');
  form = this.fb.group({
    subModel: this.subModelControl,
    subModelOption: this.subModelOptionControl
  });
  /**
   * The current product
   */
  product$ = this.currentProductService.getProduct()
    .pipe(filter(isNotNullable), shareReplay(1));

  /**
   * Indicates the fitment for the active vehicle
   * The local fit is a hack to handle the case where the
   * configuration returns nothing.
   * // TODO: Update the fit endpoint (backend) to
   * // accept the variant
   */
  private localFit$ = new BehaviorSubject(true);
  fit$ = combineLatest([
    this.garageService.currentFit$,
    this.localFit$
  ]).pipe(map(([ fit, localFit ]) => fit && localFit));


  vehicle$ = this.garageService.getActiveVehicle()
    .pipe(
      shareReplay(1),
      tap(vehicle => {
        if (this.vehicle && this.vehicle?.code !== vehicle?.code) {
          this.productConfigService.resetConfiguratorState(this.product.code);
        }
        this.vehicle = vehicle;
      }),
    );

  /**
   * The fitment area only appears if:
   *  - there is an active vehicle
   *  - and the ymm form is valid.
   *  - and the product fits the vehicle
   * If the user changes the YEAR or the MAKE,
   * the fitment area is hidden
   * */
  displayFitmentArea$ = combineLatest([
    this.garageService.ymmFormState$.pipe(
      tap(_ => this.localFit$.next(true)),
      map(f => f.status === 'VALID')
    ),
    this.vehicle$
  ]).pipe(
    map(([ formIsValid, activeVehicle ]) => activeVehicle && formIsValid),
    tap(fit => {
      this.subModelControl.enable()
      if (!fit) {
        this.subModelControl.setValue(null);
        this.subModelOptionControl.setValue(null);
        this.productConfigService.setConfiguratorProp('subModel', null);
        this.productConfigService.setSubModelOption(null);
        this.subModelOptions$.next(undefined);
        this.subModelControl.disable();

      }
    })
  );

  subModelOptions$ = new BehaviorSubject<SubModelOption>(undefined);
  subModels$ = combineLatest([
    this.product$,
    this.vehicle$.pipe(filter(isNotNullable))
  ]).pipe(
    switchMap(([ product, vehicle ]) =>
      this.productConfigService.getSubModels(product.code, product.productLine, vehicle.code)
    ),
    filter(isNotNullable),
    tap(_ => {
      this.productConfigService.setSubModelOption(null);

      this.subModelOptionControl.setValue(null);
      this.subModelOptions$.next(undefined);
      if (this.fitmentSnapShot.subModel) {
        this.subModelControl.setValue(this.fitmentSnapShot.subModel);
        this.fitmentSnapShot.subModel = undefined;
      } else {
        this.subModelControl.setValue(null);
      }
    }),
    shareReplay(1)
  )


  constructor(private fb: FormBuilder,
              private productConfigService: ProductConfigurationService,
              private garageService: GarageService,
              private currentProductService: CurrentProductService,
              private productDetailsService: ProductDetailsService,
              private dataLayerService: DataLayerService) {
  }

  ngOnInit(): void {
    this.currentProductService.getProduct()
      .pipe(take(1))
      .subscribe(p => this.product = p);
    this.productConfigService.getConfigSelection()
      .pipe(take(1))
      .subscribe(({ subModel, subModelOption }) => {
        this.fitmentSnapShot = { subModel: subModel?.value, subModelOption: subModelOption?.value };
        if (subModel) {
          this.changeSubModel(subModel?.value, subModelOption?.value);
        }
      })

    this.trackConfirmVehicle();
  }

  onSubModelChange(event: Event) {
    const subModelValue = ( event.target as HTMLSelectElement )?.value;
    this.changeSubModel(subModelValue);
  }

  onSubModelOptionChange(option: DescriptionValuePair) {
    this.productConfigService.setSubModelOption(option);

  }

  private changeSubModel(subModelValue: string, subModelOption?: string): void {
    this.productConfigService.setSubModelOption(null);
    this.subModelOptionControl.setValue(null);
    this.subModelOptions$.next(undefined);
    this.subscriptions.add(
      this.subModels$.pipe(
        map(subModelList => subModelList?.submodels.find(s => s.value === subModelValue)),
        take(1)
      ).subscribe(subModel => {
        this.productConfigService.setConfiguratorProp('subModel', subModel);
      })
    );
    if (this.product.hasConfigurationSteps) {
      this.loadConfiguration(subModelValue);
    }
    if (!this.product.hasAdditionalProductLines) {
      this.loadSubModelOptions(subModelValue, subModelOption);
    } else {
      this.subModelOptions$.next(undefined);
    }
  }

  private loadConfiguration(subModelValue: string): void {
    this.subscriptions.add(
      combineLatest([
        this.vehicle$,
        this.productDetailsService.getSelectedVariants(),
      ]).pipe(
        take(1)
      ).subscribe(([ vehicle, variants ]) => {
        const color = variants
          ?.find(v => v.qualifier.indexOf('color') >= 0)
          ?.option;
        this.productConfigService.getConfiguration(this.product, color.value, subModelValue, vehicle.ymm)
          .pipe(
            filter(config => !!config?.initialized),
            take(1))
          .subscribe(config => {
            this.localFit$.next(config.steps?.length > 1);
          });
      })
    );
  }

  private loadSubModelOptions(subModelValue: string, selected?: string): void {
    this.subscriptions.add(
      combineLatest([
        this.subModels$,
        this.garageService.getActiveVehicle()
      ]).pipe(
        switchMap(([ subModels, vehicle ]) => {
          return this.productConfigService.getSubModelOptions(subModels.productLine, subModelValue, vehicle)
        }),
        filter(isNotNullable),
        take(1)
      ).subscribe((subModelOptions: SubModelOption) => {
        const first = subModelOptions?.options?.[ 0 ];
        if (selected) {
          const selectedOption = subModelOptions.options.find(option => option.value === selected);
          this.subModelOptionControl.setValue(selected);
          this.productConfigService.setSubModelOption(selectedOption);

        } else {
          if (subModelOptions && !first?.description) {
            this.subModelOptionControl.setValue(null);
            this.subModelOptions$.next(undefined);
            this.productConfigService.setSubModelOption(first);


          }
        }
        if (subModelOptions?.options?.length > 0 && !!first.description) {
          this.subModelOptions$.next(subModelOptions);
        }
      })
    );
  }

  private trackConfirmVehicle() {
    this.dataLayerService.confirmVehicleEvent();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}

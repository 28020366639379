import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { OccEndpointsService } from "@spartacus/core";





@Injectable({ providedIn: 'root' })
export class CustomNewsletterService {
  constructor(
    private http: HttpClient,
    private endpointService: OccEndpointsService
  ) {}

  private get url(): string {
    return `${this.endpointService.getBaseUrl()}`;
  }
  postNewsletterSignup( formData: any) {
    const url = `${this.url}/listrak/signup`;
    return this.http.post(url, formData);
  }
}
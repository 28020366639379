import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { LAUNCH_CALLER, LaunchDialogService } from "@spartacus/storefront";
import { distinctUntilKeyChanged, filter, map, shareReplay, skip, switchMap, take, takeUntil, tap, withLatestFrom } from "rxjs/operators";
import { ProductConfigurationService } from "../../core/facade/product-configuration.service";
import { AddonsSelection, EmbroiderySelection, LogoSelection, ProductLineSelection } from "../../core/store/product-configuration.state";
import { ProductConfigStepType } from '../../core/models/product-configuration.models';
import { GarageService } from "../../../../ymm/core/facade/garage.service";
import { combineLatest, Observable, Subject } from "rxjs";
import { ProductDetailsService } from "../../../core/facade/product-details.service";
import { CustomCurrentProductService } from '../../../current-product.service';
import { isNotNullable, RoutingService, WindowRef } from '@spartacus/core';
import { SkuPageService } from 'src/app/spartacus/features/sku-landing-page/skupage.service';
import { isColor } from "../../core/facade/product-configuration.tools";
import { ProductConfiguratorStepsService } from "../../core/facade/product-configurator-steps.service";

@Component({
  selector: 'product-configurator',
  templateUrl: './product-configurator.component.html',
  styleUrls: [ './product-configurator.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class ProductConfiguratorComponent implements OnInit {
  isSKUPage = this.skuPageService.isSKUPage();
  product$ = this.currentProductService.getProduct()
    .pipe(shareReplay(1));
  stopHydration$ = new Subject();

  ProductConfigStepType = ProductConfigStepType;

  selection$ =
    this.currentProductService.getProduct()
      .pipe(
        filter(isNotNullable),
        distinctUntilKeyChanged('code'),
        switchMap(({ code }) => this.productConfigService.getConfiguratorForProduct(code)),
        shareReplay(1)
      );

  selectedVariants$ = this.productDetailsService.getSelectedVariants().pipe(shareReplay(1));

  disabled$ = this.selectedVariants$.pipe(
    map(variants => variants?.some(v => v.qualifier.toLowerCase().indexOf('color') >= 0 && !v.option.active))
  );

  vehicle$ = this.garageService.getActiveVehicle().pipe(shareReplay(1));

  productLines$ = this.selection$.pipe(
    map(selections => selections?.selections
      ?.filter(s => s.stepType === ProductConfigStepType.PRODUCT_LINES) as ProductLineSelection[])
  );

  embroidery$ = this.selection$.pipe(
    map(selections => selections?.selections
      ?.find(s => s.stepType === ProductConfigStepType.EMBROIDERY) as EmbroiderySelection)
  );

  logo$ = this.selection$.pipe(
    map(selections => selections?.selections
      ?.find(s => s.stepType === ProductConfigStepType.LOGOS) as LogoSelection)
  );

  embroideryAndLogo$: Observable<{ logo: LogoSelection; embroidery: EmbroiderySelection }> = combineLatest([
    this.logo$,
    this.embroidery$
  ]).pipe(
    map(
      ([ logo, embroidery ]) =>
        Boolean(logo || embroidery) ? { logo, embroidery } : null
    )
  );

  addons$ = this.selection$.pipe(
    map(selection => selection?.selections
      ?.find(s => s.stepType === ProductConfigStepType.ADDONS) as AddonsSelection),
    filter(addon => addon?.addons?.length > 0)
  );

  subModelOptions$ =
    this.selection$.pipe(
      map(selections => {
        return selections?.subModelOption?.description;
      }),
    );

  subModel$ =
    this.selection$.pipe(
      map(selections => {
        return selections?.subModel?.description;
      }),
    );

  constructor(private productConfigService: ProductConfigurationService,
              private dialog: LaunchDialogService,
              private currentProductService: CustomCurrentProductService,
              private garageService: GarageService,
              private productDetailsService: ProductDetailsService,
              private skuPageService: SkuPageService,
              private routingService: RoutingService,
              private stepService: ProductConfiguratorStepsService,
              private winRef: WindowRef) {
  }

  ngOnInit(): void {
    this.tryHydrateConfiguration();
  }

  /**
   * Try to hydrate the configuration if there is a selection pre-filled for
   * this product. It also makes sure that the vehicle for the selection
   * is the same and that there is a color for variants selected for consistence.
   * If not it will remove the selection to prevent erroneous add
   * to cart. Notice however that we need make sure it will not execute
   * after the selection is made. This is why we use the stopHydration$.
   * @private
   */
  private tryHydrateConfiguration(): void {
    combineLatest([
      this.selection$.pipe(filter(isNotNullable)),
      this.product$,
      this.selectedVariants$.pipe(filter(v => v?.length > 0)),
      this.vehicle$
    ]).pipe(
      takeUntil(this.stopHydration$),
    ).subscribe(([ selection, product, selectedVariants, activeVehicle ]) => {
      if (product.hasConfigurationSteps) {
        const colorVariant = selectedVariants.find(s => isColor(s.qualifier));
        if (!!selection) {
          if (!colorVariant || selection.vehicle.code !== activeVehicle.code) {
            this.productConfigService.clearConfiguratorState(product.code);
          } else {
            this.productConfigService.getConfiguration(
              product,
              colorVariant.option.value,
              selection.subModel.value,
              selection.vehicle.ymm
            );

          }
        }
      }

      this.stopHydration$.next();
    });
  }

  redirectToPDP(): void {
    this.winRef.isBrowser() && this.currentProductService.getProduct().subscribe(product => {
      this.winRef.nativeWindow.location = `/product/${ product.baseProduct }`;
    });
  }

  /**
   * Open the configurator dialog.
   * Notice the stopHydration$ that will make sure we won't
   * execute the hydration after the selection is made, but only on
   * load of the component. Also, we restore some information to
   * prevent erroneous configuration for a product.
   */
  openDetailsDialog() {
    this.stopHydration$.next();
    this.currentProductService.getProduct()
      .pipe(
        switchMap(
          ({ code }) => {
            let config;
            this.productConfigService.getConfiguratorForProduct(code)
              .pipe(take(1))
              .subscribe(c => config = c);
            return this.productConfigService.initializeConfigurator(code, config);
          }
        ),
        take(1)
      ).subscribe(_ => {
      this.dialog.openDialog(LAUNCH_CALLER.PRODUCT_CONFIGURATOR)
        .pipe(
          withLatestFrom(this.dialog.dialogClose),
          take(1)
        )
        .subscribe(([ _, reason ]) => {
          if (reason === 'save') {
            this.productConfigService.saveConfigSelector();
          }
          this.stepService.setStep(1);
          this.productConfigService.restoreConfiguratorState();
        });
    });
    this.routingService.getRouterState()
      .pipe(
        skip(1),
        take(1)
      ).subscribe(routerState => {
      this.dialog.closeDialog('Cancel');
    })

  }
}

<div class="container">
  <div class="container-breadcrumb">
    <nav [attr.aria-label]="'common.breadcrumbs' | cxTranslate" tabindex="-1">
      <ol [attr.aria-label]="'common.breadcrumbs' | cxTranslate">
        <li *ngFor="let crumb of crumbs$ | async">
          <a *ngIf="crumb.link" [attr.aria-label]="'navigation.navigateTo' | cxTranslate: { nav: crumb.label }"
            [innerHTML]="crumb.label"
            [routerLink]="crumb.link">
          </a>

          <span *ngIf="!crumb.link"> {{crumb.label}} </span>
        </li>
      </ol>
    </nav>

    <ng-container *ngIf="eyebrow$ | async as eyebrow">
      <h3 class="page-title__eyebrow">{{ eyebrow }}</h3>
    </ng-container>

    <ng-container *ngIf="customTitle$ | async as title">
      <h1 class="page-title__heading"> {{ title }} </h1>
      <span aria-atomic="true" aria-live="polite" class="cx-visually-hidden">
        {{ lastestTitle$ | async }}
      </span>
    </ng-container>
  </div>
</div>

<ng-container *ngIf="order as order">
  <ng-container *ngIf="order.consignments as consignments">
    <div *ngIf="consignments.length > 0;" class="container">

      <div *ngFor="let consignment of consignments; let i = index">
        <div class="order-detail-items__status-container row d-flex align-items-center mt-4">
          <div class="col-lg-4 col-md-4 col-sm-12  order-detail-items__status-item order-detail-items__status-item__title">
            <!-- Consigned -->SHIPMENT {{ consignment.shipmentNumber }}
          </div>
          <div class="col-lg-4 col-md-4 col-sm-12  order-detail-items__status-item">STATUS: <span
            style="font-weight: 500; text-transform: uppercase;">{{ consignment.statusDisplay }}</span></div>
          <div class="col-lg-4 col-md-4 col-sm-12  order-detail-items__status-item order-detail-status-item__mobile">
            <div class="tracking-order-copy-text pr-1"> TRACKING:</div>

            <ng-container *ngIf="consignment.trackingID && consignment.covercraftCarrier?.baseUrl; else noTrackingIdConsignment">
              <a [href]="getTrackingUrl(consignment)"
                 [title]="consignment.trackingID"
                 class="tracking-order-copy"
                 target="_blank">
                {{ consignment.trackingID }}
              </a>
            </ng-container>

            <ng-template #noTrackingIdConsignment>
              <div [ngClass]="{'not-available': !consignment.trackingID}" class="tracking-order-copy">
                {{ consignment.trackingID ?? 'NOT AVAILABLE' }}
              </div>
            </ng-template>

          </div>
        </div>
        <div class="cx-list-item">
          <ng-template
            [cxOutletContext]="{
              items: consignment.entries,
              readonly: true,
            }"
            cxOutlet="customOrderItemList">
          </ng-template>
        </div>
      </div>
    </div>
  </ng-container>
  <!-- unconsigned entries -->
  <ng-container *ngIf="order?.unconsignedEntries?.length">
    <div *ngIf="order?.statusDisplay" class="cx-list-header">
    </div>
    <!-- delivery unconsigned entries -->
    <ng-container *ngIf="deliveryUnConsignedEntries?.length">
      <div class="container">
        <div *ngIf="deliveryUnConsignedEntries?.length"
             class="order-detail-items__status-container row d-flex align-items-center mt-4">
          <div
            class="col-lg-4 col-md-4 col-sm-12  order-detail-items__status-item order-detail-items__status-item__title">
            SHIPMENT {{ order.shipmentNumber }}
            <!-- unsconsigned -->
          </div>
          <div class="col-lg-4 col-md-4 col-sm-12  order-detail-items__status-item">STATUS: <span
            style="font-weight: 500; text-transform: uppercase;">{{ order.deliveryStatusDisplay }}</span></div>
          <div class="col-lg-4 col-md-4 col-sm-12  order-detail-items__status-item order-detail-status-item__mobile">
            <div class="tracking-order-copy-text"> TRACKING</div>
            <div class="tracking-order-copy bg-secondary" style="font-weight: 500; text-transform: uppercase; color: #ffffff; text-decoration: none;">
              NOT AVAILABLE
            </div>
          </div>
        </div>
        <div class="cx-list-item">
          <ng-template [cxOutletContext]="{
              items: deliveryUnConsignedEntries,
              readonly: true,

            }" cxOutlet="customOrderItemList">
          </ng-template>
        </div>
      </div>
    </ng-container>
    <!-- pickup unconsigned entries, b2b does not have pickup items -->
    <ng-container *ngIf="pickupUnconsignedEntries?.length">
      <div *ngIf="pickupUnconsignedEntries?.length"
           class="order-detail-items__status-container row d-flex align-items-center mt-4">
        <div
          class="col-lg-4 col-md-4 col-sm-12  order-detail-items__status-item order-detail-items__status-item__title">
          SHIPMENT {{ order.shipmentNumber }}
          <!-- unsconsigned -->
        </div>
        <div class="col-lg-4 col-md-4 col-sm-12  order-detail-items__status-item">STATUS: <span
          style="font-weight: 500; text-transform: uppercase;">{{ order.deliveryStatusDisplay }}</span></div>
        <div class="col-lg-4 col-md-4 col-sm-12  order-detail-items__status-item order-detail-status-item__mobile">
          <div class="tracking-order-copy-text"> TRACKING</div>
          <div class="tracking-order-copy bg-secondary"
               style="font-weight: 500; text-transform: uppercase; color: #ffffff; text-decoration: none;">
            NOT AVAILABLE
          </div>
        </div>
      </div>
      <div class="cx-list-item">
        <ng-template [cxOutletContext]="{
              items: pickupUnconsignedEntries,
              readonly: true,

            }" cxOutlet="customOrderItemList">
        </ng-template>
      </div>
    </ng-container>
  </ng-container>

  <!-- replenishment other entries -->
  <ng-container *ngIf="order?.entries && order?.replenishmentOrderCode">
    <div class="cx-list">
      <!-- <div *ngIf="order?.statusDisplay" class="cx-list-header">
   <div class="cx-list-status">
    {{
    'orderDetails.statusDisplay_' + order?.statusDisplay | cxTranslate
    }}
   </div>
  </div> -->
      <div class="order-detail-items__status-container row d-flex align-items-center mt-4">
        <div
          class="col-lg-4 col-md-4 col-sm-12  order-detail-items__status-item order-detail-items__status-item__title">
          SHIPMENT {{ order.shipmentNumber }}
          <!-- unsconsigned -->

        </div>
        <div class="col-lg-4 col-md-4 col-sm-12  order-detail-items__status-item">STATUS: <span
          style="font-weight: 500; text-transform: uppercase;">{{ order.deliveryStatusDisplay }}</span></div>
        <div class="col-lg-4 col-md-4 col-sm-12  order-detail-items__status-item order-detail-status-item__mobile">
          <div class="tracking-order-copy-text"> TRACKING</div>
          <div class="tracking-order-copy bg-secondary"
               style="font-weight: 500; text-transform: uppercase; color: #ffffff; text-decoration: none;">
            NOT AVAILABLE
          </div>
        </div>
      </div>
      <div class="cx-list-item">
        <ng-template [cxOutletContext]="{
              items: order.entries,
              readonly: true,

            }" cxOutlet="customOrderItemList">
        </ng-template>
      </div>
    </div>
  </ng-container>

</ng-container>

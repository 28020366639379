<div class="cx-tabs">
  <ul 
    class="nav nav-tabs" 
    role="tablist"
  >
    <li 
      class="nav-item" 
      role="button"
      [ngClass]="{ active: tab.active }"
      (click)="selectTab(tab)"
      *ngFor="let tab of tabs" 
    >
      {{ tab.title }}
    </li>
  </ul>
  <div class="tab-content" [ngClass]="{ scrollable }">
    <ng-content></ng-content>
  </div>
</div>
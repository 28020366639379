import { Injectable } from "@angular/core";
import { OCC_HTTP_TOKEN, OccProductSearchAdapter, PRODUCT_SEARCH_PAGE_NORMALIZER, ProductSearchPage, SearchConfig } from "@spartacus/core";
import { Observable } from "rxjs";
import { HttpContext } from "@angular/common/http";

@Injectable({ providedIn: 'root' })
export class CustomOccProductSearchAdapter extends OccProductSearchAdapter {
  override search(
    query: string,
    searchConfig: SearchConfig = this.DEFAULT_SEARCH_CONFIG
  ): Observable<ProductSearchPage> {
    const context = new HttpContext().set(OCC_HTTP_TOKEN, {
      sendUserIdAsHeader: true,
    });

    return this.http
      .get(this.getSearchEndpoint(query, searchConfig), { context })
      .pipe(this.converter.pipeable(PRODUCT_SEARCH_PAGE_NORMALIZER));
  }
}

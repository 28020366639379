import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Subject } from 'rxjs';
import { CustomCurrentProductService } from 'src/app/spartacus/features/product/current-product.service';

@Component({
  selector: 'cx-product-description-component',
  templateUrl: './product-description-section.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./product-description-section.scss']
})
export class ProductDescriptionComponent{
    isCollapsed = true
    product$:any = new Subject<any>()
    mockedBades = [
        {
            image: 'https://i.pinimg.com/originals/32/ce/5a/32ce5ae0555afc83f66cbc13e52e68c9.png',
            code: 'Indoor / Outdoor Use'
        },
        {
            image: 'https://i.pinimg.com/originals/32/ce/5a/32ce5ae0555afc83f66cbc13e52e68c9.png',
            code: 'Dry Climates'
        },
        {
            image: 'https://i.pinimg.com/originals/32/ce/5a/32ce5ae0555afc83f66cbc13e52e68c9.png',
            code: 'High Temperatures'
        },
        {
            image: 'https://i.pinimg.com/originals/32/ce/5a/32ce5ae0555afc83f66cbc13e52e68c9.png',
            code: '365 day use'
        },
    ]

    currentProduct = this.productService.getProduct().subscribe((data)=>{
        if(!data){return null}
        this.product$.next(data)
        return data
    })

    constructor(
        protected component: CmsComponentData<any>,
        protected productService: CustomCurrentProductService
    ) {}

    toggleCollapse() {
        this.isCollapsed = !this.isCollapsed
    }
}

/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CmsConfig, DeferLoadingStrategy, I18nModule, provideConfig, UrlModule, } from '@spartacus/core';
import { CarouselModule, MediaModule, PageComponentModule } from '@spartacus/storefront';
import { CustomProductCarouselComponent } from './custom-product-carousel.component';
import { CustomProductCarouselItemModule } from '../custom-product-carousel-item/custom-product-carousel-item.module';
import { CustomCarouselModule } from 'src/app/components/_SPA-Components/custom-carousel';


@NgModule({
  imports: [
    CommonModule,
    CarouselModule,
    MediaModule,
    RouterModule,
    UrlModule,
    I18nModule,
    PageComponentModule,
    CustomProductCarouselItemModule,
    CustomCarouselModule,
  ],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        ProductCarouselComponent: {
          component: CustomProductCarouselComponent,
          disableSSR: true,
          deferLoading: DeferLoadingStrategy.DEFER
        },
      },
    }),
  ],
  declarations: [ CustomProductCarouselComponent ],
  exports: [ CustomProductCarouselComponent ],
})
export class CustomProductCarouselModule {
}

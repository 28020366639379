import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CartValidationGuard } from '@spartacus/cart/base/core';
import { CartNotEmptyGuard, CheckoutAuthGuard, CheckoutDeliveryAddressComponent } from '@spartacus/checkout/base/components';
import { CmsConfig, I18nModule, FeaturesConfigModule, provideConfig } from '@spartacus/core';
import { SpinnerModule } from '@spartacus/storefront';
import { CustomCheckoutDeliveryAddressComponent } from './custom-checkout-delivery-address.component';
import { AddressFormModule } from 'src/app/components/_SPA-Components/custom-myaccount/address-book';
import { CustomCardModule } from '../../../shared/components/card';


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    AddressFormModule,
    CustomCardModule,
    SpinnerModule,
    I18nModule,
    FeaturesConfigModule,
  ],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        CheckoutDeliveryAddress: {
          component: CustomCheckoutDeliveryAddressComponent,
          guards: [CheckoutAuthGuard, CartNotEmptyGuard, CartValidationGuard],
        },
      },
    }),
  ],
  declarations: [CustomCheckoutDeliveryAddressComponent],
  exports: [CustomCheckoutDeliveryAddressComponent],
})
export class CustomCheckoutDeliveryAddressModule {}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomProductCardComponent } from './custom-product-card.component';
import { WishlistButtonModule } from '../../shared/components/wishlist-button/wishlist-button.module';
import { I18nModule, UrlModule } from '@spartacus/core';
import { RouterModule } from '@angular/router';
import { CardModule, MediaModule, StarRatingModule } from '@spartacus/storefront';
import { ProductVariantsModule } from '@spartacus/product/variants';
import { CustomProductVariantColorModule } from '../product-list/custom-product-variant-color/custom-product-variant-color.module';
import { ProductPriceModule } from '../product-price/product-price.module';
import { CustomProductBadgesModule } from '../product-list/custom-product-badges/custom-product-badges.module';
import { CustomMediaComponent } from "../../../../components/_CUSTOM/media/custom-media.component";

@NgModule({
  declarations: [
    CustomProductCardComponent
  ],
    imports: [
        CommonModule,
        I18nModule,
        RouterModule,
        WishlistButtonModule,
        MediaModule,
        ProductVariantsModule,
        CustomProductVariantColorModule,
        StarRatingModule,
        UrlModule,
        ProductPriceModule,
        CustomProductBadgesModule,
        CustomMediaComponent
    ],
  exports: [
    CustomProductCardComponent
  ]
})

export class CustomProductCardModule { }

import { NgModule } from "@angular/core";
import { CmsConfig, PRODUCT_NORMALIZER, ProductAdapter, ProductSearchAdapter, ProductSearchService, provideConfig } from "@spartacus/core";
import { SecondImageNormalizer } from "./core/occ/converters/second-image-normalizer";
import { CurrentProductService, ProductFacetService, ProductListComponentService } from "@spartacus/storefront";
import { CustomProductListComponentService } from "./product-list/container/custom-product-list-component.service";
import { CustomOccProductSearchAdapter } from "./core/occ/custom-occ-product-search.adapter";
import { CustomProductSearchService } from "./core/facade/custom-product-search.service";
import { CustomProductFacetService } from "./core/facade/custom-product-facet.service";
import { ProductCoreModule } from "./core/product-core.module";
import { ProductOverviewComponent } from "./product-overview/product-overview.component";
import { CustomCurrentProductService } from "./current-product.service";
import { AdditionalDetailsModule } from "./additional-details/additional-details.module";
import { CustomOccProductAdapter } from "./core/occ/custom-occ-product-adapter";
import { ProductConfiguratorFeatureModule } from "./configurator/product-configurator-feature.module";
import { ProductTabsFeatureModule } from "./product-tabs-feature.module";

@NgModule({
  imports: [
    ProductCoreModule,
    ProductConfiguratorFeatureModule,
    ProductTabsFeatureModule,
    AdditionalDetailsModule
  ],
  providers: [
    {
      provide: PRODUCT_NORMALIZER,
      useExisting: SecondImageNormalizer,
      multi: true
    },
    {
      provide: ProductListComponentService,
      useExisting: CustomProductListComponentService
    },
    {
      provide: ProductSearchAdapter,
      useExisting: CustomOccProductSearchAdapter
    },
    {
      provide: ProductSearchService,
      useExisting: CustomProductSearchService
    },
    {
      provide: ProductFacetService,
      useExisting: CustomProductFacetService
    },
    {
      provide: CurrentProductService,
      useClass: CustomCurrentProductService
    },
    {
      provide: ProductAdapter,
      useExisting: CustomOccProductAdapter
    },
    provideConfig({
      cmsComponents: {
        ProductOverview: {
          component: ProductOverviewComponent
        }
      }
    } as CmsConfig)
  ]
})
export class CustomProductModule {

}

<ng-container *ngIf="cart$ | async as cart">
  <ng-container *ngIf="entries$ | async as entries">
    <div class="row m-b-20">
      <div class="col-12 col-sm-12 col-md-8 col-lg-8">
        <div *ngIf="cart.totalItems !== undefined" class="cart-details-wrapper">
          <div *ngIf="cart.totalItems > 0" class="cart-details-wrapper">
            <cx-cart-validation-warnings></cx-cart-validation-warnings>

            <h4 class="cart-title extra-bold-common-font">
              Your Cart <span style="font-size: 18px;">({{cart.totalItems}} items)</span>
            </h4>

            <h6 class="cx-total">
              {{ 'cartDetails.cartName' | cxTranslate: { code: removeZeros(cart.code) } }}
            </h6>

            <ng-container *ngIf="{ promotions: promotions$ | async } as contentPromotion">
              <ng-container *ngIf="contentPromotion.promotions.length">
                <custom-cart-promotion [promotions]="contentPromotion.promotions" cssClasses="cart-details-promotion medium-common-font m-t-8"> </custom-cart-promotion>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>

import { Component } from "@angular/core";

@Component({
  selector: 'standard-talk-to-us',
  template: `
    <ng-container [cxComponentWrapper]="component"/>`,
  styles: [ ':host{display: block}' ]
})
export class StandardTalkToUsComponent {
  readonly component = {
    typeCode: 'CustomerServiceOpeningHoursComponent',
    flexType: 'CustomerServiceOpeningHoursComponent',
    uid: 'DefaultContactUsComponent'
  };
}

import { PageMeta } from "@spartacus/core";
import { Injectable } from "@angular/core";
import { SeoMetaService } from "@spartacus/storefront";

@Injectable({ providedIn: 'root' })
export class CustomSeoMetaService extends SeoMetaService {
  protected override set meta(meta: PageMeta) {
    super.meta = meta;
    this.title = meta.title;
    this.keywords = meta.keywords;
    this.description = meta.description;
    this.canonicalUrl = meta.canonicalUrl;
  }

  protected set keywords(value: string | undefined) {
    if (value) {
      this.addTag({ name: 'keywords', content: value || '' });
    } else {
      this.ngMeta.removeTag('name="keywords"');
    }
  }

  protected override set description(value: string | undefined) {
    if (value) {
      this.addTag({ name: 'description', content: value || '' });
      this.addTag({ name: 'og:description', content: value });
    } else {
      this.ngMeta.removeTag('name="description"');
      this.ngMeta.removeTag('name="og:description"');
    }
  }

  protected override set canonicalUrl(url: string | undefined) {
    this.setCanonicalUrl(url)
  }

  setCanonicalUrl(url: string | undefined) {
    this.pageMetaLinkService?.setCanonicalLink(url);
    if (url) {
      this.addTag({ name: 'og:url', content: url });
    } else {
      this.ngMeta.removeTag('name="og:url"');
    }
  }
}

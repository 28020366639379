import { NgModule } from "@angular/core";
import { ProductDetailsStoreModule } from "./store/product-details-store.module";
import { provideConfig } from "@spartacus/core";
import { productDetailsOccConfig } from "./occ/product-details-occ.config";

@NgModule({
  imports: [
    ProductDetailsStoreModule
  ],
  providers: [
    provideConfig(productDetailsOccConfig)
  ]
})
export class ProductCoreModule {

}

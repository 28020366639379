<svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:svgjs="http://svgjs.com/svgjs" [attr.width]="width" [attr.height]="height" x="0" y="0" viewBox="0 0 64 64"
    style="enable-background:new 0 0 512 512" xml:space="preserve" class="">
    <g>
        <path xmlns="http://www.w3.org/2000/svg"
            d="m4.59 59.41a2 2 0 0 0 2.83 0l24.58-24.58 24.59 24.58a2 2 0 0 0 2.83-2.83l-24.59-24.58 24.58-24.59a2 2 0 0 0 -2.83-2.83l-24.58 24.59-24.59-24.58a2 2 0 0 0 -2.82 2.82l24.58 24.59-24.58 24.59a2 2 0 0 0 0 2.82z"
            fill="currentColor" class="">

        </path>
    </g>
</svg>
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CustomFormValidators } from '@spartacus/storefront';
import { switchMap, take } from 'rxjs/operators';
import { GlobalMessageService, GlobalMessageType } from '@spartacus/core';
import { RecaptchaWidget } from "../../spartacus/features/recaptcha/recaptcha.widget";
import { CustomNewsletterService } from "../../spartacus/features/newsletter/newsletter.service";
import { RecaptchaFactoryService } from "../../spartacus/features/recaptcha/recaptcha-factory.service";
import { ListrakService } from "../../spartacus/features/listrak/core/listrak.service";
import { DataLayerService } from 'src/app/spartacus/features/data-layer/data-layer.service';

@Component({
  selector: 'custom-footer-latest-news',
  templateUrl: './custom-footer-latest-news.component.html',
  styleUrls: [ './custom-footer-latest-news.component.scss' ],
})

export class CustomFooterLatestNewsComponent implements OnInit, AfterViewInit, OnDestroy {
  containerId = 'footerCaptchaContainer';
  captchaWidget: RecaptchaWidget | undefined;
  subscription = new Subscription();

  newsletterForm: UntypedFormGroup = this.fb.group({
    emailAddress: [
      '',
      [ Validators.required, CustomFormValidators.emailValidator ],
    ],
    source: 'footer',
  });

  constructor(
    protected fb: UntypedFormBuilder,
    protected newsletterService: CustomNewsletterService,
    protected recaptchaFactory: RecaptchaFactoryService,
    protected globalMessageService: GlobalMessageService,
    protected listrakService: ListrakService,
    protected dataLayerService: DataLayerService
  ) {
  }

  ngOnInit(): void {
    this.listrakService.emailCapture('footerEmailAddress');
  }

  ngAfterViewInit(): void {
    this.captcha();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  captcha() {
    this.subscription.add(
      this.recaptchaFactory.create(this.containerId).subscribe(widget => {
        this.captchaWidget = widget;
      })
    );
  }

  collectDataFromRegisterForm(formData: any) {
    const { emailAddress, recaptchaToken, source } = formData;

    return {
      emailAddress: emailAddress.toLowerCase(),
      recaptchaToken,
      source,
    };
  }

  submitForm() {
    if (this.newsletterForm.valid) {
      this.captchaWidget
        ?.validate()
        .pipe(
          switchMap((token) => {
            const data = this.collectDataFromRegisterForm(
              this.newsletterForm.value
            );
            data.recaptchaToken = token;
            data.source = 'footer';

            return this.newsletterService.postNewsletterSignup(data);
          }),
          take(1)
        )
        .subscribe(
          (response) => {
            this.globalMessageService.add(
              'Thank you for signing up.',
              GlobalMessageType.MSG_TYPE_CONFIRMATION
            );
            this.newsletterForm.reset();
            this.dataLayerService.subscribeNewsletterEvent();
          },
          (error) => {
            this.globalMessageService.add(
              'Subscribe Failed',
              GlobalMessageType.MSG_TYPE_ERROR
            );
          }
        );
    } else {
      this.newsletterForm.markAllAsTouched();
    }
  }
}

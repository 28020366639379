<ng-container *ngIf="steps$ | async as steps">
<section class="product-lines-steps">
  <ng-container *ngFor="let step of steps.items; index as i">
    <div [ngClass]="{
        'active': i + 1 === steps.active,
        'checked': i + 1 < steps.active }"
         class="product-lines-step">

      <div class="d-flex align-items-center">

        <!-- step state indicator -->
        <div [ngClass]="{'bg-success': i + 1 < steps.active}"
             class="product-lines-step__indicator">

          <!-- Current indicator centered bullet -->
          <div class="product-lines-step__indicator--current"></div>

          <!-- Check mark for checked step -->
          <div class="product-lines-step__indicator--check" >
            <svg-icon iconId="iconCheckMark"></svg-icon>
          </div>
        </div>

        <!-- Top line -->
        <div class="product-lines-step__line"></div>

      </div>

      <!-- Step Sequence -->
      <div class="product-lines-step__order">
        Step {{ i + 1 }}
      </div>

      <!-- Step Label -->
      <div class="product-lines-step__label">
        {{step.label}}
      </div>
    </div>
  </ng-container>
</section>
</ng-container>

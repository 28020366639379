import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomProductStampsComponent } from './custom-product-stamps.component';
import { MediaModule } from '@spartacus/storefront';
import { CmsConfig, provideConfig } from '@spartacus/core';



@NgModule({
  declarations: [CustomProductStampsComponent],
  imports: [CommonModule, MediaModule],
  exports: [CustomProductStampsComponent],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        ProductStampsComponent: {
          component: CustomProductStampsComponent,
        },
      },
    }),
  ],
})
export class CustomProductStampsModule {}

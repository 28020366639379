import { Injectable } from "@angular/core";
import { combineLatest, Observable } from "rxjs";
import { ConfiguratorManagerBase } from "../../core/facade/configurator-manager-base.service";
import { map, withLatestFrom } from "rxjs/operators";
import { ConfiguratorManagerContract } from "../../core/facade/configurator-manager.contract";
import { ConfigurationSelection, EmbroiderySelection, ProductConfigStepSelection } from "../../core/store/product-configuration.state";
import { ProductConfigStepType, ProductConfiguratorButton } from "../../core/models/product-configuration.models";
import { ProductConfigurationService } from "../../core/facade/product-configuration.service";
import { ProductConfiguratorStepsService } from "../../core/facade/product-configurator-steps.service";

@Injectable({ providedIn: 'root' })
export class EmbroideryManagerService
  implements ConfiguratorManagerContract {

  private nextButton$ = combineLatest([
    this.managerBase.nextButton$,
    this.productConfigService.getCurrentSelection()
  ]).pipe(
    map(([ btn, selection ]) => {
      const embroiderySelection = selection.selections
        ?.find(s => s.stepType === ProductConfigStepType.EMBROIDERY) as EmbroiderySelection;
      btn.disabled = !embroiderySelection || !embroiderySelection.text || !embroiderySelection.font;
      return btn;
    })
  );


  private buttons$ = combineLatest([
    this.managerBase.backButton$,
    this.nextButton$,
    this.managerBase.saveButton$
  ]);

  constructor(private managerBase: ConfiguratorManagerBase,
              private productConfigService: ProductConfigurationService,
              private stepService: ProductConfiguratorStepsService) {
  }

  getCartPayloadPart(selection: ConfigurationSelection): string[] {
    const embroidery = selection.selections
      ?.find(e => e.stepType === ProductConfigStepType.EMBROIDERY) as EmbroiderySelection;
    if (!embroidery) {
      return [];
    }
    return [
      `embroidery::code:${ embroidery.embroidery.code }`,
      `embroidery::colorCode:${ embroidery.color?.code }`,
      `embroidery::fontCode:${ embroidery.font?.code }`,
      `embroidery::text:${ embroidery.text }`,
    ];
  }

  getButtons(): Observable<ProductConfiguratorButton[]> {
    return this.buttons$;
  }

  getPrice(stepSelection: ProductConfigStepSelection) {
    const embroiderySelection = stepSelection as EmbroiderySelection;
    return {
      price: embroiderySelection.price?.value,
      priceWithDiscount: embroiderySelection.price?.value
    }
  }

}

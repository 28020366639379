import { Injectable } from "@angular/core";
import { StatePersistenceService } from "@spartacus/core";
import { Store } from "@ngrx/store";
import { GarageState } from "../store/garage.state";
import { GarageActions, GarageSelectors } from "../store";
import { Observable } from "rxjs";

@Injectable({ providedIn: 'root' })
export class GarageStatePersistenceService {
  constructor(private statePersistenceService: StatePersistenceService,
              private store: Store<GarageState>) {
  }

  sync(): void {
    this.statePersistenceService.syncWithStorage({
      key: 'garage',
      state$: this.getGarageState(),
      onRead: (state) => this.onRead(state)
    });
  }

  private getGarageState(): Observable<GarageState> {
    return this.store.select(GarageSelectors.garageFeatureSelector);
  }

  private onRead(state: GarageState): void {
    if (state) {
      const safeState = {
        inventory: state.inventory ?? [],
        years: state.years ?? [],
        vehicles: state.vehicles ?? { active: '' },
        fit: state.fit
      }
      this.store.dispatch(GarageActions.setState(safeState));
    }
  }
}

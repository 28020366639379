import { ChangeDetectionStrategy, Component, ElementRef, HostListener, Optional, } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CmsSearchBoxComponent, PageType, RoutingService, WindowRef } from '@spartacus/core';
import { CmsComponentData, SearchBoxComponent, SearchBoxComponentService, SearchBoxConfig } from '@spartacus/storefront';
import { Observable, of } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { GarageService } from 'src/app/spartacus/features/ymm/core/facade/garage.service';
import { Vehicle } from 'src/app/spartacus/features/ymm/core/models/garage.model';
import { CustomSearchResults } from './custom-search-box.model';
import { CustomSearchBoxComponentService } from './custom-search-box-component.service';
import { DataLayerService } from 'src/app/spartacus/features/data-layer/data-layer.service';

const CUSTOM_SEARCH_BOX_CONFIG: SearchBoxConfig = {
  minCharactersBeforeRequest: 1,
  displayProducts: true,
  displaySuggestions: true,
  maxProducts: 13,
  maxSuggestions: 5,
  displayProductImages: true,
};

const HAS_SEARCH_RESULT_CLASS = 'has-searchbox-results';
const SEARCHBOX_IS_ACTIVE = 'searchbox-is-active';

@Component({
  selector: 'cx-searchbox',
  templateUrl: './custom-search-box.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomSearchBoxComponent extends SearchBoxComponent {
  isVehicleActive = false
  activeVehicle!: Vehicle
  searchForm: UntypedFormGroup = this.fb.group(
    {
      query: [ '', Validators.required ],
    }
  );

  override results$: Observable<CustomSearchResults> = this.config$.pipe(
    switchMap((config) => this.customSearch.getResults(config))
  );

  constructor(protected fb: UntypedFormBuilder,
              protected customSearch: CustomSearchBoxComponentService,
              protected garageService: GarageService,
              protected override searchBoxComponentService: SearchBoxComponentService,
              @Optional() protected override componentData: CmsComponentData<CmsSearchBoxComponent>,
              protected override winRef: WindowRef,
              protected override routingService: RoutingService,
              private elementRef: ElementRef,
              private dataLayerService: DataLayerService) {
    super(searchBoxComponentService, componentData, winRef, routingService);
  }


  protected override config$: Observable<SearchBoxConfig> = (
    this.componentData?.data$ || of({} as any)
  ).pipe(
    map((config) => {
      const isBool = (obj: SearchBoxConfig, prop: string): boolean =>
        obj[ prop as keyof SearchBoxConfig ] !== 'false' &&
        obj[ prop as keyof SearchBoxConfig ] !== false;

      return {
        ...CUSTOM_SEARCH_BOX_CONFIG,
        ...config,
        displayProducts: isBool(config, 'displayProducts'),
        displayProductImages: isBool(config, 'displayProductImages'),
        displaySuggestions: isBool(config, 'displaySuggestions'),
        ...this.config,
      };
    }),
    tap((config) => ( this.config = config ))
  );

  override search(query: string, noVehicle?: boolean): void {
    if (!this.isVehicleActive && !noVehicle) {
      this.getActiveVehicle()
    }
    this.config.maxProducts = CUSTOM_SEARCH_BOX_CONFIG.maxProducts
    this.config.maxSuggestions = CUSTOM_SEARCH_BOX_CONFIG.maxSuggestions

    this.isVehicleActive ?
      this.customSearch.search(query, this.config, this.activeVehicle) :
      this.customSearch.search(query, this.config)

    this.open();
  }

  override ngOnInit() {
    this.getActiveVehicle()
    this.subscription = this.routingService
      .getRouterState()
      .pipe(filter((data) => !data.nextState))
      .subscribe((data) => {
        if (
          !(
            data.state.context?.id === 'search' &&
            data.state.context?.type === PageType.CONTENT_PAGE
          )
        ) {
          this.chosenWord = '';
        }
      });
  }

  protected override blurSearchBox(event: UIEvent): void {
    this.searchBoxComponentService.toggleBodyClass(SEARCHBOX_IS_ACTIVE, false);
  }

  removeActiveVehicleFilter(query: string) {
    this.disableClose();
    this.isVehicleActive = false
    this.search(query, true)
  }

  checkEmptyValue() {
    if (!this.chosenWord) {
      this.winRef.document.body.classList.remove(HAS_SEARCH_RESULT_CLASS)
    }
  }

  getActiveVehicle() {
    let activeVehicle = this.garageService.getActiveVehicle()
    activeVehicle.subscribe((data) => {
      this.isVehicleActive = data !== undefined
      this.activeVehicle = data
    })
  }

  goToSkuPage(event: MouseEvent, partNumber: string) {
    this.winRef.isBrowser() && this.winRef.location.replace(`/sku/${ partNumber }`);
    this.close(event, true);
  }

  @HostListener('document:click', [ '$event' ])
  onClick(event: MouseEvent) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.close(event, true);
    }
  }

  trackSelectItem(product: any) {
    this.dataLayerService.selectItemEvent(product);
  }

  submit(event: any, searchInput: HTMLInputElement):void {
    this.results$
      .pipe(take(1))
      .subscribe(results => {
        if (results?.partNumbers?.length === 1) {
          this.routingService.go({
            cxRoute: 'product',
            params: { productSku: results.partNumbers[ 0 ] }
          });
        } else if (results?.products?.length === 1) {
          this.routingService.go({
            cxRoute: 'product',
            params: { code: results.products[ 0 ].code }
          });
        } else {
          this.launchSearchResult(event, searchInput.value);
          this.updateChosenWord(searchInput.value);
        }
        searchInput.blur();
        this.close(event, true);
      });
  }
}


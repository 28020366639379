import { Injectable } from "@angular/core";
import { BehaviorSubject, combineLatest } from "rxjs";
import { delay, filter, map, shareReplay, take } from "rxjs/operators";
import { ProductConfigurationService } from "./product-configuration.service";

@Injectable({ providedIn: 'root' })
export class ProductConfiguratorStepsService {
  private currentStepEvent = new BehaviorSubject(1);
  private isLastStepEvent = new BehaviorSubject(false);
  private openStepEvent = new BehaviorSubject(1);

  isLastStep$ = this.isLastStepEvent.asObservable();
  currentStep$ = this.currentStepEvent.asObservable();
  openStep$ = this.openStepEvent.asObservable();

  steps$ = this.productConfigService.getConfigurationForCurrentProduct().pipe(
    map((p) => p?.steps?.filter((s) => !s.hideStep)),
    shareReplay(1)
  );

  currentConfigStep$ = combineLatest([this.steps$, this.currentStep$]).pipe(
    map(([steps, step]) => {
      return steps?.length ? steps[step - 1] : undefined;
    })
  );

  hasPrevious$ = combineLatest([this.currentStep$, this.steps$]).pipe(
    map(([current, steps]) => current > 1)
  );

  constructor(private productConfigService: ProductConfigurationService) {}

  next(setDelay: boolean = false): void {
    combineLatest([this.currentStep$, this.steps$])
      .pipe(
        filter(([step, steps]) => step < steps.length + 1),
        take(1)
      )
      .subscribe(([step, steps]) => {        
        if (step < steps.length) {
          this.currentStepEvent.next(step + 1);
          this.isLastStepEvent.next(false);
        } else if (step === steps.length) {
          this.currentStepEvent.next(step);
          this.isLastStepEvent.next(false);
          this.openStepEvent.next(null);

        } else {
          this.isLastStepEvent.next(true);
          this.openStepEvent.next(null);
        }
        if (step === 1) {
          this.openStepEvent.next(step + 1)
        } else {
          this.openStepEvent.next(null);
        }
        
      });
  }

  back(): void {
    combineLatest([this.currentStep$, this.hasPrevious$])
      .pipe(
        filter(([_, hasPrevious]) => hasPrevious),
        take(1)
      )
      .subscribe(([step, _]) => {
        this.currentStepEvent.next(1);
        this.isLastStepEvent.next(false);
      });
  }

  setStep(step: number): void {
    this.steps$
      .pipe(
        filter((steps) => step <= steps.length && step > 0),
        take(1)
      )
      .subscribe((steps) => {
        this.currentStepEvent.next(step);
        this.openStepEvent.next(step);
        this.isLastStepEvent.next(false);
      });
  }

  setOpenStep(step: null | number){
    this.openStepEvent.next(step)
  }
}

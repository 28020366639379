import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { BreakpointService, CmsComponentData } from '@spartacus/storefront';
import { map, tap } from 'rxjs/operators';
import { breakpointMediaMapKey } from 'src/app/components/utils/cms-utils';
import { environment } from 'src/environments/environment';
import { SatisfiedCustomerBanner } from '../banner-satisfied-customers.model';

@Component({
  selector: 'cx-banner-satisfied-customer-item',
  templateUrl: 'banner-satisfied-customers-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomBannerSatisfiedCustomersItemComponent {
  @Input() properties: any
  banner!: SatisfiedCustomerBanner
  stars = Array(5).fill({filled:true})

  banners$ = this.componentData.data$.pipe(
    tap((data)=>{
      this.banner = data
      return data
    })
  );

  productImage$ = this.breakpointService.breakpoint$.pipe(
    map(breakpoint => {
      if (!this.banner?.media) {
        return {};
      }
      const mediaKey = breakpointMediaMapKey.get(breakpoint) ?? 'desktop';
      return { 'background-image': `url(${ environment.baseUrl }${ this.banner?.media[ mediaKey ]?.url })` };
    })
  );

  constructor(
    private componentData: CmsComponentData<any>,
    private breakpointService: BreakpointService,
  ) {}

}




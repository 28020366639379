<app-loader></app-loader>
<toaster-component></toaster-component>

<ng-template [cxOutlet]="StorefrontOutlets.STOREFRONT" cxPageTemplateStyle>
  <ng-template cxOutlet="cx-header">
    <header
      id="cx-header"
      cxSkipLink="cx-header"
      [cxFocus]="{ disableMouseFocus: true }"
      [class.is-expanded]="isExpanded$ | async"
      (keydown.escape)="collapseMenu()"
      (click)="collapseMenuIfClickOutside($event)"
    >
      <cx-page-layout section="header_1" class="header"></cx-page-layout>

      <div class="container">
        <cx-page-layout section="header_2" class="header"></cx-page-layout>
        <cx-page-layout section="header_3" class="header"></cx-page-layout>
        <cx-page-layout section="navigation"></cx-page-layout>
      </div>

      <div class="border-bottom-header w-100 border border-gray-600"></div>
    </header>

    <cx-page-slot position="BottomHeaderSlot"></cx-page-slot>
    <cx-global-message aria-atomic="true" aria-live="assertive"></cx-global-message>
  </ng-template>

  <main cxSkipLink="cx-main" [cxFocus]="{ disableMouseFocus: true }">
    <router-outlet></router-outlet>
  </main>

  <custom-footer></custom-footer>
</ng-template>

<custom-templates></custom-templates>

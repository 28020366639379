/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  AuthGuard,
  CmsConfig,
  I18nModule,
  provideConfig,
} from '@spartacus/core';
import { CustomPaymentMethodsComponent } from './custom-payment-methods.component';
import { SpinnerModule } from '@spartacus/storefront';
import { CardModule } from '@spartacus/storefront';

@NgModule({
  imports: [CommonModule, I18nModule, SpinnerModule,CardModule],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        AccountPaymentDetailsComponent: {
          component: CustomPaymentMethodsComponent,
          guards: [AuthGuard],
        },
      },
    }),
  ],
  declarations: [CustomPaymentMethodsComponent],
  exports: [CustomPaymentMethodsComponent],
})
export class CustomPaymentMethodsModule {}

<ng-container *ngIf="paymentMethods$ | async as paymentMethods">
  <div class="cx-payment container">
    <!-- <div class="cx-header">
      <h2 class="bold-common-font covercraft-header">
        {{ 'paymentMethods.paymentMethods' | cxTranslate }}
      </h2>
    </div> -->

    <div class="cx-body">
      <div class="cx-msg">
        {{
          'paymentMethods.newPaymentMethodsAreAddedDuringCheckout' | cxTranslate
        }}
      </div>
      <div *ngIf="loading$ | async; else cards"><cx-spinner></cx-spinner></div>
      <ng-template #cards>
        <div
          role="status"
          [attr.aria-label]="'common.loaded' | cxTranslate"
        ></div>
        <div class="cx-existing row">
          <div
            class="cx-payment-card col-sm-12 col-md-12 col-lg-6"
            *ngFor="let paymentMethod of paymentMethods; let i = index"
          >
            <div class="cx-payment-inner">
              <cx-card
                [index]="i"
                [border]="true"
                [fitToContainer]="true"
                [content]="getCardContent(paymentMethod) | async"
                (deleteCard)="deletePaymentMethod(paymentMethod)"
                (setDefaultCard)="setDefaultPaymentMethod(paymentMethod)"
                (editCard)="setEdit(paymentMethod)"
                [editMode]="editCard === paymentMethod.id"
                (cancelCard)="cancelCard()"
              ></cx-card>
            </div>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</ng-container>

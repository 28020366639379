import { Injectable } from "@angular/core";
import { BreadcrumbMeta, PageMetaService, WindowRef } from "@spartacus/core";
import { BreadcrumbSchemaBuilder } from "@spartacus/storefront";
import { CustomBreadCrumbService } from "./custom-breadcrumb.service";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";


@Injectable({
  providedIn: 'root',
})
export class CustomBreadcrumbSchemaBuilder extends BreadcrumbSchemaBuilder {

  constructor(
    pageMetaService: PageMetaService,
    private breadcrumbService: CustomBreadCrumbService,
    private winRef: WindowRef
  ) {
    super(pageMetaService);
  }

  override build(): Observable<any> {
    return this.breadcrumbService.crumbs$.pipe(
      map((breadcrumbMeta: BreadcrumbMeta[] | null) => this.collectBreadcrumb(breadcrumbMeta)));
  }

  private collectBreadcrumb(breadcrumbMeta: BreadcrumbMeta[] | null): any {
    let breadcrumbs: Array<any> = [];

    breadcrumbs = breadcrumbMeta.map((crumb, index) => {
      return {
        '@type': 'ListItem',
        position: index + 1,
        name: crumb.label || 'home',
        item: crumb.link || breadcrumbMeta[index + 1]
          ? `${this.winRef.location.origin}${crumb.link}`
          : `${this.winRef.location.href}`
      };
    });

    return {
      '@context': 'https://schema.org',
      '@type': 'BreadcrumbList',
      'itemListElement': breadcrumbs,
    };
  }
}
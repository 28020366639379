import { Component } from '@angular/core';
import { CmsProductCarouselComponent as model, OccEndpointsService, Product, RoutingService, WindowRef } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { forkJoin, Observable, of } from 'rxjs';
import { filter, first, map, switchMap } from 'rxjs/operators';
import { CustomCmsProductCarouselComponent as customModel } from './custom-product-carousel.model';
import { HttpClient } from '@angular/common/http';
import { CustomCurrentProductService } from '../../current-product.service';
import { DataLayerService } from 'src/app/spartacus/features/data-layer/data-layer.service';

@Component({
  selector: 'app-custom-product-carousel',
  templateUrl: './custom-product-carousel.component.html',
  styleUrls: [ './custom-product-carousel.component.scss' ]
})

export class CustomProductCarouselComponent {
  categoryFilledComponent!: boolean;
  canRender = this.winRef.isBrowser();

  searchConfig = {
    fields: 'fields=products(FULL)%2Cfacets%2Cbreadcrumbs%2Cpagination(DEFAULT)%2Csorts(DEFAULT)%2CfreeTextSearch%2CcurrentQuery&query=%3Arelevance%3AallCategories%3A',
    pageSize: '&pageSize=5',
  }

  private get url(): string {
    return `${ this.endpointService.getBaseUrl() }`;
  }

  private componentData$: Observable<model> = this.componentData.data$.pipe(
    filter((data) => Boolean(data))
  );

  title$: Observable<string | undefined> = this.componentData$.pipe(
    map((data) => data.title)
  );

  category$: Observable<string | undefined> = this.componentData.data$.pipe(
    map((data) => {
      const categoryCode = data.categoryCodes?.trim().split(' ')[ 0 ]
      this.categoryFilledComponent = !!categoryCode;
      return categoryCode
    })
  );

  items$: Observable<Observable<Product>[]> =
    this.componentData.data$.pipe(
      switchMap((data) => {
        const categoryCode = data.categoryCodes?.trim().split(' ')[ 0 ];
        const emptyProductList: Observable<Product>[] = [ of() ];
        if (data.productCodes) {
          const productCodes: string[] = data.productCodes?.trim().split(' ');
          return [ productCodes.map(code => this.customCurrentProductService.loadProduct(code)) ];
        }
        if (!categoryCode) {
          return [ emptyProductList ];
        }
        const url = `${ this.url }/products/search?${ this.searchConfig.fields }${ categoryCode }${ this.searchConfig.pageSize }&lang=en&curr=USD`;
        return this.http.get(url).pipe(
          map((response: any) => response.products.map((product: Product) => of(product)))
        );
      })
    );

  constructor(private componentData: CmsComponentData<customModel>,
              private routingService: RoutingService,
              private endpointService: OccEndpointsService,
              private http: HttpClient,
              private customCurrentProductService: CustomCurrentProductService,
              private winRef: WindowRef,
              private dataLayerService: DataLayerService) {
  }

  viewAll(categoryCode: string) {
    this.routingService.go({
      cxRoute: 'category',
      params: { code: categoryCode },
    });
  }

  ngOnInit(): void {
    this.items$.pipe(
      first(),
      filter(items => items.length > 0)
    ).subscribe(items => {
      forkJoin(items).pipe(first()).subscribe(products => {
        this.dataLayerService.viewItemListEvent(products, 'Product Carousel');
      })
    });
  }
}

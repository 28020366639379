import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomOrderItemListComponent } from './custom-order-item-list.component';
import { I18nModule } from '@spartacus/core';
import { CartSharedModule } from '@spartacus/cart/base/components';
import { CustomItemListRowModule } from 'src/app/spartacus/features/cart/components/custom-item-list-row/custom-item-list-row.module';
import { CustomOrderItemListRowModule } from '../custom-order-item-list-row/custom-order-item-list-row.module';
import { provideOutlet } from '@spartacus/storefront';



@NgModule({
  declarations: [CustomOrderItemListComponent],
  imports: [
    CommonModule,
    I18nModule,
    CartSharedModule,
    CustomOrderItemListRowModule,
  ],
  exports: [CustomOrderItemListComponent],
})
export class CustomOrderItemListModule {}

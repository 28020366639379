import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { RecaptchaWidget } from './recaptcha.widget';
import { GRecaptcha } from './g.recaptcha';
import { OccEndpointsService, WindowRef } from "@spartacus/core";


@Injectable({ providedIn: 'root' })
export class RecaptchaFactoryService {
  private readonly active: { [ containerId: string ]: RecaptchaWidget } = {};
  private _siteKey: string | undefined;
  private _gRecaptcha: GRecaptcha | undefined;

  constructor(
    private http: HttpClient,
    private endpointService: OccEndpointsService,
    private winRef: WindowRef
  ) { }

  create(containerId: string): Observable<RecaptchaWidget> {
    const isAbleToImplement = this.isAbleToImplement(containerId);

    if(!this.winRef.isBrowser() || !isAbleToImplement) {
      return of(undefined);
    }

    return this.add(containerId)
      .pipe(
        tap(widget => {
          widget.disposed$
            .pipe(take(1))
            .subscribe(_ => {
              delete this.active[ containerId ];
            });
          this.active[ containerId ] = widget;
        })
      );
  }

  private add(containerId: string): Observable<RecaptchaWidget> {
    return this.getSiteKey()
      .pipe(
        take(1),
        map(siteKey => {
          const w = window as any;
          this._gRecaptcha = w?.grecaptcha as any;
          return new RecaptchaWidget(containerId, siteKey, this._gRecaptcha!);
        })
      );
  }

  private getSiteKey(): Observable<string> {
    const url = this.endpointService.buildUrl('getRecaptchaKey');
    const options = {
      responseType: 'text' as const,
    };
    return this._siteKey
      ? of(this._siteKey)
      : this.http.get(url, options)
        .pipe(
          tap(siteKey => this._siteKey = siteKey)
        );
  }

  isAbleToImplement(elementID: string): boolean {
    const element = this.winRef.document.querySelectorAll(`#${elementID}`)[0] as Element;
    return element && element?.children?.length === 0;
  }
}

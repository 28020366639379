import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { AppRoutingModule, ViewConfig } from "@spartacus/storefront";
import { AppComponent } from './app.component';
import { SpartacusModule } from './spartacus/spartacus.module';
import { I18nConfig, provideConfig } from "@spartacus/core";
import { translationChunksConfig, translations } from "src/assets/translations/translations";
import { GtmModule } from '@spartacus/tracking/tms/gtm';
import { TmsConfig } from "@spartacus/tracking/tms/core";
import { environment } from "src/environments/environment";
import { CustomHttpRequestInterceptor } from "./core/interceptors/interceptors";
import { SvgSpriteComponent } from "./components/_CUSTOM/svg/components/svg-sprite/svg-sprite.component";
import { CustomMainModule } from "./layout/main.custom.module";

@NgModule({
  declarations: [ AppComponent ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    HttpClientModule,
    AppRoutingModule,
    StoreModule.forRoot({}, {
        runtimeChecks: {
          strictStateImmutability: false,
          strictActionImmutability: false,
        }
      }
    ),
    EffectsModule.forRoot([]),
    SpartacusModule,
    CustomMainModule,
    GtmModule,
    SvgSpriteComponent,
    // StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: !isDevMode() }),
  ],
  providers: [
    provideConfig(<I18nConfig>{
      i18n: {
        resources: translations,
        chunks: translationChunksConfig,
      },
    }),
    provideConfig({
      tagManager: {
        gtm: {
          gtmId: environment.gtmKey,
          events: [],
        },
      },
    } as TmsConfig),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomHttpRequestInterceptor,
      multi: true,
    },
    provideConfig({
      view: {
        infiniteScroll: {
          active: true,
          productLimit: 500,
          showMoreButton: true,
        },
      },
    } as ViewConfig),
  ],
  bootstrap: [ AppComponent ],
})
export class AppModule {
}

import { Component } from '@angular/core';
import { CartItemContextSource } from '@spartacus/cart/base/components';
import { CartItemContext } from '@spartacus/cart/base/root';
import { CustomItemListRowComponent } from 'src/app/spartacus/features/cart/components/custom-item-list-row/custom-item-list-row.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: '[custom-order-item-list-row], custom-order-item-list-row',
  templateUrl: './custom-order-item-list-row.component.html',
  styleUrls: ['./custom-order-item-list-row.component.scss'],
  providers: [
    CartItemContextSource,
    { provide: CartItemContext, useExisting: CartItemContextSource },
  ],
})
export class CustomOrderItemListRowComponent extends CustomItemListRowComponent {

  envoirment = environment
}

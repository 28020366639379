import { Component } from '@angular/core';
import { CurrentProductService, Media } from '@spartacus/storefront';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AdditionalDetailsAttribute } from './additional-details.model';
import { SkuPageService } from '../../sku-landing-page/skupage.service';

@Component({
  selector: 'addition-details',
  templateUrl: './addition-details.component.html',
  styleUrls: ['./addition-details.component.scss']
})

export class AdditionDetailsComponent {

  isActive$: Observable<boolean> = of(this.skuPageService.isSKUPage());
  partNumber = this.skuPageService.getCurrentPartNumber();
  product$ = this.currentProductService.getProduct();

  image$: Observable<Media | undefined>= this.product$.pipe(
    map(product => {

      if (product?.skuOptions?.length) {
        return product.skuOptions[0].image;
      };

      return undefined;
    })
  );

  skuOptions$: Observable<string[]> = this.product$.pipe(
    map(product => {

      if (product?.skuOptions?.length) {
        let options = [];

        if (product.skuOptions[0].option.toLocaleLowerCase().includes(`with`)) {
          options = product.skuOptions[0].option.toLocaleLowerCase().split(`with`);
        } else {
          options = [product.skuOptions[0].option.toLocaleLowerCase()];
        }

        return this.cleanArray(options);
      };

      return [];
    })
  );

  attributes$: Observable<AdditionalDetailsAttribute[]> = this.product$.pipe(
    map(product => {

      if (product?.baseOptions?.length) {
        let attributes: AdditionalDetailsAttribute[] = [];

        product?.baseOptions?.forEach(option => {
          option?.selected?.variantOptionQualifiers?.forEach(itemQualifier => {
            attributes.push({
              qualifier: itemQualifier.qualifier,
              name: itemQualifier.name
            });
          });
        });


        return attributes
      };

      return [];
    })
  );

  constructor (
    private currentProductService: CurrentProductService,
    private skuPageService: SkuPageService
  ) {}

  cleanArray (array: string[]): string[] {
    let cleanedArray = array.filter(item => item.trim() !== '');
    cleanedArray = cleanedArray.map(item => item.trim());
    return cleanedArray;
  };

}

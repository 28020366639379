import { ChangeDetectionStrategy, Component } from "@angular/core";
import { map } from "rxjs/operators";
import { combineLatest, Observable } from "rxjs";
import { Addons, ProductConfigStepType } from "../../../../core/models/product-configuration.models";
import { AddonsSelection } from "../../../../core/store/product-configuration.state";
import { ProductConfigurationService } from "../../../../core/facade/product-configuration.service";
import { AddonItemViewConfig, AddonTogglePayload } from "../../core/addons-model";


@Component({
  selector: 'addons',
  templateUrl: 'addons.component.html',
  styleUrls: ['./addons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddonsComponent {
  addons$: Observable<AddonItemViewConfig[]> = combineLatest([
    this.productConfigService.getConfigurationForCurrentProduct(),
    this.productConfigService.getConfigSelection()
  ]).pipe(
    map(([ config, configSelection ]) => {
      const addons = config.steps
        ?.find(s => s.stepType === ProductConfigStepType.ADDONS) as Addons
      const addonsSelection = configSelection.selections
        ?.find(s => s.stepType === ProductConfigStepType.ADDONS) as AddonsSelection;
      return addons.products.map(product => {
        const selectedAddon = addonsSelection?.addons?.find(a => a.productBase === product.code);
        return {
          product,
          selection: selectedAddon
            ? { selected: true, variants: selectedAddon.addonVariants }
            : { selected: false }
        }
      });
    })
  );

  constructor(private productConfigService: ProductConfigurationService) {
  }

  trackByFn(i: number, { product }: AddonItemViewConfig): string {
    return product.code;
  }

  onToggleAddon(payload: AddonTogglePayload): void {
    this.productConfigService.toggleAddon(payload);
  }
}

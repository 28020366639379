import { NgModule } from '@angular/core';
import { CmsConfig, I18nModule, UrlModule, provideConfig } from '@spartacus/core';
import { NewsletterComponent } from './newsletter.component';
import {  FormErrorsModule, NgSelectA11yModule, PageSlotModule, SpinnerModule } from '@spartacus/storefront';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  declarations: [NewsletterComponent],
  imports: [
    PageSlotModule,
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    UrlModule,
    I18nModule,
    SpinnerModule,
    FormErrorsModule,
    NgSelectModule,
    NgSelectA11yModule,
  ],
  providers: [
    provideConfig({
      cmsComponents: {
        NewsletterComponent: {
          component: NewsletterComponent,
        },
      },
    } as CmsConfig),
  ],
})
export class NewsletterModule {}

import { FormControlStatus } from "@angular/forms";

export type Year = number;
export type Make = string;
export type Model = string;

export enum VehicleType {
  CAR = 1,
  TRUCK = 2,
  VAN = 3,
  SUV = 4,
}

export const vehicleTypeImageMap: Map<VehicleType, string> = new Map<VehicleType, string>([
  [ VehicleType.CAR, '/assets/static/vehicles/car.webp' ],
  [ VehicleType.SUV, '/assets/static/vehicles/suv.webp' ],
  [ VehicleType.TRUCK, '/assets/static/vehicles/truck.webp' ],
  [ VehicleType.VAN, '/assets/static/vehicles/van.webp' ],
]);


export interface YMM {
  year: Year;
  make: Make;
  model: Model;
}

export interface Vehicle {
  vehicleType: VehicleType;
  code: string;
  ymm: YMM;
  name: string;
  path: string;
  image?: string;
}

export interface YMMInventory {
  make: string;
  models: {
    year: Year,
    models: Model[]
  }[]
}

export interface VehicleImagePayload {
  vehicleType: VehicleType;
  url: string;
}

export enum GarageTitleType {
  YMM_FITS_FOR = 'YMM_FITS_FOR',
  NO_RESULTS = 'NO_RESULTS',
  PROTECT_MY = 'PROTECT_MY',
  RESULTS_FOR = 'PROTECT_MY',
}

export interface GarageTitle {
  heading: string;
  type: GarageTitleType;
}

export interface YmmFormStateChangeEvent {
  status: FormControlStatus,
  ymm?: YMM
}

import { CommonModule } from '@angular/common';
import { NgModule, ViewContainerRef } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import {
  CheckoutPlaceOrderComponent,
  CheckoutAuthGuard,
  CartNotEmptyGuard,
} from '@spartacus/checkout/base/components';
import {
  CmsConfig,
  I18nModule,
  provideConfig,
  provideDefaultConfig,
  UrlModule,
} from '@spartacus/core';
import { AtMessageModule } from '@spartacus/storefront';
import { CustomCheckoutPlaceOrderComponent } from './custom-checkout-place-order.component';
import { IconsModule } from 'src/app/components/_CUSTOM/icons/icons.module';

@NgModule({
  imports: [
    AtMessageModule,
    CommonModule,
    RouterModule,
    UrlModule,
    I18nModule,
    ReactiveFormsModule,
    IconsModule
  ],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        CheckoutPlaceOrder: {
          component: CustomCheckoutPlaceOrderComponent,
          guards: [CheckoutAuthGuard, CartNotEmptyGuard],
        },
      },
    }),
  ],
  declarations: [CustomCheckoutPlaceOrderComponent],
  exports: [CustomCheckoutPlaceOrderComponent],
})
export class CustomCheckoutPlaceOrderModule  {}

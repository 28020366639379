<img
  *ngIf="media && media.src"
  [attr.src]="media.src"
  [attr.srcset]="media.srcset"
  [attr.alt]="media.alt"
  [attr.role]="media.role"
  [attr.loading]="loading"
  (load)="loadHandler()"
  (error)="errorHandler()"
  [width]="width"
  [height]="height"
  [attr.fetchpriority]="fetchPriority"
/>

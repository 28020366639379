import { NgModule } from "@angular/core";
import { ModelAugmentationModule } from "./model-augmentation.module";
import { CustomCmsModule } from "./cms/custom-cms.module";

@NgModule({
  imports: [
    ModelAugmentationModule,
    CustomCmsModule
  ]
})
export class CustomCoreModule {

}

<div class="variant-selector">
  <div [hidden]="context !== 'PRODUCT'"
       class="variant-name bold-common-font">
    {{ sizeLabel }}
  </div>
  <select
    (change)="changeValue($event)"
    [formControl]="variantControl"
    class="form-control variant-select">
    <option hidden value="">
      {{ 'productVariant.select' | cxTranslate }} {{ sizeLabel }}
    </option>
    <option *ngFor="let v of variants" [value]="v.value">
      {{ v.name }}
    </option>
  </select>
</div>

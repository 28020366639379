import { Injectable } from "@angular/core";
import { Converter, ImageType, Occ, Product } from "@spartacus/core";

@Injectable({providedIn: 'root'})
export class SecondImageNormalizer implements Converter<Occ.Product, Product>{
    convert(source: Occ.Product, target?: Product): Product {
      if(source.secondImage){
        target.secondImage = {
          url: source.secondImage,
          format: 'Product',
          imageType: ImageType.PRIMARY
        };
      }
      return  target;
    }

}

export const environment = {
  production: false,
  baseUrl: 'https://api.cv1f27614d-covercraf1-d1-public.model-t.cc.commerce.ondemand.com/',
  storefrontUrl: 'https://covercraft.cv1f27614d-covercraf1-s1-public.model-t.cc.commerce.ondemand.com/covercraft/',
  gtmKey: 'GTM-M4BDV6ZS',
  gtmEnv: 'staging',
  googleMerchantId: '10499760',
  captchaSiteKey: '6Lf4jygpAAAAAALWHXQ_I9bSWwI37f6ypjLSSwDA',
  catpchaSecretKey: '6Lf4jygpAAAAAJK-iz4Qcr81o4gHdoHTq4l7jfI6',
  defaultCarImage: 'https://covercraft.cv1f27614d-covercraf1-d1-public.model-t.cc.commerce.ondemand.com/covercraft/en/USD/assets/car-garage.jpg',
  zenDeskUrl: 'https://support.covercraft.com/hc/en-us/requests/new',
  metaPixelId: '1675428182599283'
};

import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { BehaviorSubject } from "rxjs";
import { take } from "rxjs/operators";
import { ImageGroup, ImageType } from "@spartacus/core";
import { ProductDetailsService } from "../../core/facade/product-details.service";
import { FocusConfig, ICON_TYPE, LaunchDialogService } from "@spartacus/storefront";
import { SafeResourceUrl } from '@angular/platform-browser';
import { CustomCurrentProductService } from '../../current-product.service';

@Component({
  selector: 'product-medias-dialog',
  templateUrl: './product-medias-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductMediasDialogComponent implements OnInit {
  focusConfig: FocusConfig = {
    trap: true,
    block: true,
    autofocus: 'button',
    focusOnEscape: true,
  };

  product$ = this.currentProductService.getProduct();
  iconType = ICON_TYPE;
  active = 0;
  activeContainer = new BehaviorSubject<ImageGroup>(null);
  mediaType: string = 'image';
  activeVideo: SafeResourceUrl;
  activeTab = 0;

  constructor(
    private productDetailsService: ProductDetailsService,
    private dialogService: LaunchDialogService,
    private currentProductService: CustomCurrentProductService
  ) { }

  ngOnInit(): void {
    this.productDetailsService.activeGallery$
      .pipe(
        take(1)
      ).subscribe((gallery) => {
      const images = gallery?.[ ImageType.GALLERY ] as ImageGroup[];
      if (images?.length) {
        this.activeContainer.next(images[ this.active ?? 0 ]);
      }
    });
  }

  close(reason: string): void {
    this.dialogService.closeDialog(reason);
  }

  changeMedia(container: ImageGroup, index: number, mediaType: string) {
    this.activeContainer.next(container);
    this.active = index;
    this.mediaType = mediaType;
  }

  changeVideo(
    container: ImageGroup,
    index: number,
    mediaType: string,
    videoUrl: SafeResourceUrl
  ) {
    this.activeContainer.next(container);
    this.active = index;
    this.mediaType = mediaType;
    this.activeVideo = videoUrl;
  }
}


import {
  ChangeDetectionStrategy,
  Component,
} from '@angular/core';
import { CouponDialogComponent } from '@spartacus/storefront';

@Component({
  selector: 'cx-coupon-dialog',
  templateUrl: './custom-coupon-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class CustomCouponDialogComponent extends CouponDialogComponent {
  
}
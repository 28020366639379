import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { AuthGuard, CmsConfig, I18nModule, provideDefaultConfig, UrlModule, } from '@spartacus/core';
import { CardModule, CmsPageGuard, IconModule, KeyboardFocusModule, ListNavigationModule, PageLayoutComponent, SpinnerModule } from '@spartacus/storefront';
import { CustomMyCouponsComponent } from './custom-my-coupons.component';
import { CustomCouponCardComponent } from './coupon-card/custom-coupon-card.component';
import { CustomCouponDialogComponent } from './coupon-card/coupon-dialog/custom-coupon-dialog.component';
import { CouponClaimComponent } from './coupon-claim/coupon-claim.component';
import { defaultCouponLayoutConfig } from './default-coupon-card-layout.config';
import { BannerLegacyOfQualityModule } from "../../../../spartacus/features/marketing/components/banner-legacy-of-quality/banner-legacy-of-quality.module";

@NgModule({
    declarations: [
        CustomMyCouponsComponent,
        CustomCouponCardComponent,
        CustomCouponDialogComponent,
        CouponClaimComponent,
    ],
    providers: [
        provideDefaultConfig(<CmsConfig>{
            cmsComponents: {
                MyCouponsComponent: {
                    component: CustomMyCouponsComponent,
                    guards: [AuthGuard],
                },
                CouponClaimComponent: {
                    component: CouponClaimComponent,
                    guards: [AuthGuard],
                },
            },
        }),
        provideDefaultConfig(defaultCouponLayoutConfig),
    ],
    exports: [CustomMyCouponsComponent, CouponClaimComponent],
    imports: [
        CommonModule,
        CardModule,
        SpinnerModule,
        I18nModule,
        RouterModule,
        UrlModule,
        IconModule,
        ListNavigationModule,
        RouterModule.forChild([
            {
                // @ts-ignore
                path: null,
                canActivate: [AuthGuard, CmsPageGuard],
                component: PageLayoutComponent,
                data: { cxRoute: 'couponClaim' },
            },
        ]),
        KeyboardFocusModule,
        BannerLegacyOfQualityModule
    ]
})
export class CustomMyCouponsModule {
}

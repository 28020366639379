/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { Component } from '@angular/core';
import { ConsentManagementComponent } from '@spartacus/storefront';

@Component({
  selector: 'cx-consent-management',
  templateUrl: './consent-management.component.html',
})
export class CustomConsentManagementComponent extends ConsentManagementComponent {

}

<ng-container *ngIf="variants$ | async as items">
  <ng-container *ngFor="let item of items; trackBy: trackByFn">

    <ng-container *ngIf="item.qualifier.toLowerCase().indexOf('color') >= 0; else variantSelector">
      <cx-product-variant-color-selector
        (changeVariant)="onVariantChange($event, item)"
        [selected]="item.selected"
        [variants]="item.options">
      </cx-product-variant-color-selector>
    </ng-container>

    <ng-template #variantSelector>
      <cx-product-variant-selector
        class="d-block mt-3"
        (changeVariant)="onVariantChange($event, item)"
        [name]="item.qualifier"
        [selected]="item.selected"
        [sizeLabel]="product.sizeLabel ?? ''"
        context="ADDON"
        [variants]="item.options">
      </cx-product-variant-selector>
    </ng-template>

  </ng-container>

</ng-container>

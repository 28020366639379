import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import {
  CmsConfig,
  I18nModule,
  provideConfig,
  UrlModule,
} from '@spartacus/core';
import { MediaModule } from '@spartacus/storefront';
import { IconModule } from '@spartacus/storefront';
import { CustomHighlightPipe } from './custom-highlight.pipe';
import { CustomSearchBoxComponent } from './custom-search-box.component';
import { ReactiveFormsModule } from '@angular/forms';
import { CustomProductCardModule } from "./product-card/product-card.module";

@NgModule({
    providers: [
        provideConfig(<CmsConfig>{
            cmsComponents: {
                SearchBoxComponent: {
                    component: CustomSearchBoxComponent,
                },
            },
        }),
    ],
    declarations: [CustomSearchBoxComponent, CustomHighlightPipe],
    exports: [CustomSearchBoxComponent, CustomHighlightPipe],
    imports: [
        CommonModule,
        RouterModule,
        MediaModule,
        IconModule,
        UrlModule,
        I18nModule,
        ReactiveFormsModule,
        CustomProductCardModule
    ]
})
export class CustomSearchBoxModule {}

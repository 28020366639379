import { DIALOG_TYPE, LayoutConfig } from '@spartacus/storefront';
import { CustomCouponDialogComponent } from './coupon-card/coupon-dialog/custom-coupon-dialog.component';

export const defaultCouponLayoutConfig: LayoutConfig = {
  launch: {
    COUPON: {
      inlineRoot: true,
      component: CustomCouponDialogComponent,
      dialogType: DIALOG_TYPE.DIALOG,
    },
  },
};

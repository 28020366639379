import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomOrderGuestFormFlexComponentComponent } from './custom-order-guest-form-flex.component';
import { CustomOrderConfirmationModule } from '../custom-order-confirmation.module';
import { CmsConfig, FeatureLevelDirective, FeaturesConfigModule, provideConfig } from '@spartacus/core';
import { CustomOrderGuestRegisterFormModule } from '../custom-order-guest-register-form/custom-order-guest-register-form.module';



@NgModule({
  declarations: [CustomOrderGuestFormFlexComponentComponent],
  imports: [
    CommonModule,
    CustomOrderGuestRegisterFormModule,
    FeaturesConfigModule
  ],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        FastOrderConfirmationGuestForm: {
          component: CustomOrderGuestFormFlexComponentComponent,
        },
      },
    }),
  ],
})
export class CustomOrderGuestFormFlexModule {}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CheckoutAuthGuard, NotCheckoutAuthGuard } from '@spartacus/checkout/base/components';
import { CmsConfig, I18nModule, UrlModule, provideConfig, provideDefaultConfig } from '@spartacus/core';
import { FormErrorsModule } from '@spartacus/storefront';
import { CustomCheckoutLoginComponent } from './custom-checkout-login.component';
import { CustomCheckoutLoginFormModule } from './custom-checkout-login-form/custom-checkout-login-form.module';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    I18nModule,
    FormsModule,
    ReactiveFormsModule,
    FormsModule,
    ReactiveFormsModule,
    FormErrorsModule,
    CustomCheckoutLoginFormModule,
    UrlModule,
    RouterModule,
  ],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        GuestCheckoutLoginComponent: {
          component: CustomCheckoutLoginComponent,
          guards: [NotCheckoutAuthGuard],
        },
      },
    }),
  ],
  declarations: [CustomCheckoutLoginComponent],
  exports: [CustomCheckoutLoginComponent],
})
export class CustomCheckoutLoginModule {}

<ng-container *ngIf="activePrice">
  <div [ngClass]="pageId$ | async" class="cx-product-price">

    <!-- 'From' indicator -->

    <div class="from">
      <ng-container *ngIf="displayFrom">
        {{ 'productDetails.priceFrom' | cxTranslate }}
      </ng-container>
    </div>

    <!-- Price -->
    <div [attr.aria-label]="'productDetails.productPrice' | cxTranslate" class="price-sale">
      <span [ngClass]="{ withDiscount: activePrice?.priceWithDiscount?.value > 0 }" class="price">
        {{ activePrice.priceWithDiscount?.value ? activePrice.priceWithDiscount?.formattedValue : activePrice.price?.formattedValue }}
      </span>

      <span *ngIf="activePrice.discount?.value > 0" class="sale">
        {{ 'productDetails.priceSale' | cxTranslate }}
      </span>
    </div>

    <!-- Discount -->
    <div class="discount">
      <ng-container *ngIf="activePrice.discount?.value > 0">
        <span class="was">
          {{ 'productDetails.priceWas' | cxTranslate }}

          <span class="text-line-through">
            {{ activePrice.price?.formattedValue }}
          </span>
        </span>

        <span class="save">
          {{ 'productDetails.priceSave' | cxTranslate: { value: activePrice.discount.formattedValue, percentage: activePrice.discountPercentage } }}
        </span>
      </ng-container>
    </div>
  </div>
</ng-container>


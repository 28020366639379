import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageTitleComponent } from './page-title.component';
import { CmsConfig, provideConfig } from '@spartacus/core';

@NgModule({
  declarations: [
    PageTitleComponent
  ],
  imports: [
    CommonModule
  ],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        pageTitle: {
          component: PageTitleComponent,
        },
      },
    }),
  ],
  exports: [
    PageTitleComponent
  ]
})

export class PageTitleModule { }

import { Observable, Subject } from 'rxjs';
import { GRecaptcha } from './g.recaptcha';

export class RecaptchaWidget {
  private visible = false;
  private token: string | undefined;
  private readonly _widgetId: string;
  private recaptchaExecuted = new Subject<string>();
  private disposed = new Subject();

  executed$ = this.recaptchaExecuted.asObservable();
  disposed$ = this.disposed.asObservable();

  constructor(private containerId: string,
              private siteKey: string,
              private readonly _gRecaptcha: GRecaptcha) {

    this._widgetId = _gRecaptcha.render(
      containerId,
      {
        sitekey: this.siteKey,
        callback: (token: string) => {
          this.token = token;
          this.recaptchaExecuted.next(token);
        }
      });

  }

  reset(): void {
    this._gRecaptcha.reset(this._widgetId);
  }

  toggle(): void {
    this.visible = true;
  }

  validate(): Observable<string> {
    this._gRecaptcha.execute(this._widgetId);
    return this.executed$;
  }

  dispose(): void {
    this.disposed.next();
  }

}

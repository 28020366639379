<svg width="41" height="40" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M8.57363 15.6049C8.24223 15.6049 7.97363 15.8735 7.97363 16.2049C7.97363 16.5363 8.24223 16.8049 8.57363 16.8049C8.90483 16.8049 9.17363 16.5363 9.17363 16.2049C9.17363 15.8735 8.90483 15.6049 8.57363 15.6049Z" fill="#222222"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M12.9774 15.6049C12.646 15.6049 12.3774 15.8735 12.3774 16.2049C12.3774 16.5363 12.646 16.8049 12.9774 16.8049C13.3086 16.8049 13.5774 16.5363 13.5774 16.2049C13.5774 15.8735 13.3086 15.6049 12.9774 15.6049Z" fill="#222222"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M10.7753 17.6306C10.4441 17.6306 10.1753 17.8992 10.1753 18.2306C10.1753 18.562 10.4441 18.8306 10.7753 18.8306C11.1067 18.8306 11.3753 18.562 11.3753 18.2306C11.3753 17.8992 11.1067 17.6306 10.7753 17.6306Z" fill="#222222"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M15.1791 17.6306C14.8477 17.6306 14.5791 17.8992 14.5791 18.2306C14.5791 18.562 14.8477 18.8306 15.1791 18.8306C15.5105 18.8306 15.7791 18.562 15.7791 18.2306C15.7791 17.8992 15.5105 17.6306 15.1791 17.6306Z" fill="#222222"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M24.2209 25.2905C23.4075 25.2905 22.7455 24.6285 22.7455 23.8151C22.7455 23.0015 23.4075 22.3397 24.2209 22.3397C25.0343 22.3397 25.6963 23.0015 25.6963 23.8151C25.6963 24.6285 25.0343 25.2905 24.2209 25.2905ZM24.2209 21.7397C23.0765 21.7397 22.1455 22.6707 22.1455 23.8151C22.1455 24.9595 23.0765 25.8905 24.2209 25.8905C25.3653 25.8905 26.2963 24.9595 26.2963 23.8151C26.2963 22.6707 25.3653 21.7397 24.2209 21.7397Z" fill="#222222"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M9.66593 25.2905C8.85233 25.2905 8.19033 24.6285 8.19033 23.8151C8.19033 23.0015 8.85233 22.3397 9.66593 22.3397C10.4793 22.3397 11.1413 23.0015 11.1413 23.8151C11.1413 24.6285 10.4793 25.2905 9.66593 25.2905ZM9.66593 21.7397C8.52133 21.7397 7.59033 22.6707 7.59033 23.8151C7.59033 24.9595 8.52133 25.8905 9.66593 25.8905C10.8103 25.8905 11.7413 24.9595 11.7413 23.8151C11.7413 22.6707 10.8103 21.7397 9.66593 21.7397Z" fill="#222222"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M24.1771 17.1688V15.8168H26.9591C26.9897 15.8168 27.0185 15.811 27.0461 15.8024L28.9399 18.6958H25.7041C24.8621 18.6958 24.1771 18.0108 24.1771 17.1688ZM29.7351 18.815L26.4779 13.8388C26.4229 13.7546 26.3293 13.7036 26.2287 13.703L22.6617 13.6812H22.6599C22.4951 13.6812 22.3609 13.8142 22.3599 13.9792C22.3589 14.145 22.4923 14.28 22.6581 14.2812L26.0641 14.3022L26.6629 15.2168H23.8771C23.7115 15.2168 23.5771 15.3512 23.5771 15.5168V17.1688C23.5771 18.3416 24.5313 19.2958 25.7041 19.2958H29.1841V20.1766H27.7241C27.5585 20.1766 27.4241 20.3108 27.4241 20.4766C27.4241 20.6422 27.5585 20.7766 27.7241 20.7766H29.1841V21.4976H27.7241C27.5585 21.4976 27.4241 21.6318 27.4241 21.7976C27.4241 21.9634 27.5585 22.0976 27.7241 22.0976H29.1835C29.1769 22.8848 28.5349 23.5232 27.7463 23.5232H27.0857C26.9201 23.5232 26.7857 23.6576 26.7857 23.8232C26.7857 23.989 26.9201 24.1232 27.0857 24.1232H27.7463C28.8699 24.1232 29.7841 23.2092 29.7841 22.0854V18.9792C29.7841 18.9208 29.7671 18.8638 29.7351 18.815Z" fill="#222222"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M21.1709 20.5287H5.5917V11.7241C5.5917 11.2279 5.9953 10.8241 6.4917 10.8241H17.5851C19.5623 10.8241 21.1709 12.4327 21.1709 14.4099V20.5287ZM17.5851 10.2241H6.4917C5.6645 10.2241 4.9917 10.8969 4.9917 11.7241V22.6233C4.9917 23.4503 5.6645 24.1233 6.4917 24.1233H6.7185C6.8841 24.1233 7.0185 23.9889 7.0185 23.8233C7.0185 23.6575 6.8841 23.5233 6.7185 23.5233H6.4917C5.9953 23.5233 5.5917 23.1195 5.5917 22.6233V21.1287H21.1709V22.6233C21.1709 23.1195 20.7671 23.5233 20.2709 23.5233H12.7515C12.5859 23.5233 12.4515 23.6575 12.4515 23.8233C12.4515 23.9889 12.5859 24.1233 12.7515 24.1233H20.2709C21.0981 24.1233 21.7709 23.4503 21.7709 22.6233V14.4099C21.7709 12.1019 19.8931 10.2241 17.5851 10.2241Z" fill="#222222"/>
</svg>

import { OccConfig } from "@spartacus/core";

export const garageOccConfig: OccConfig = {
  backend: {
    occ: {
      endpoints: {
        garageYears: 'vehicle-type/year',
        garageMakes: 'vehicle-type/make',
        garageModels: 'vehicle-type/model',
        garageImage: 'vehicle-type/vehicle/imageurl',
        updateFit: 'vehicle-type/vehicle/fit'
      } as any
    }
  }
}

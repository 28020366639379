import { Component, Input } from '@angular/core';
import { Address } from '@spartacus/core';
import { Order } from '@spartacus/order/root';
import { AddressCardService } from 'src/app/components/_SPA-Components/custom-myaccount/address-book/address-card.service';

@Component({
  selector: 'custom-order-shared-overview',
  templateUrl: './custom-order-shared-overview.component.html',
  styleUrls: ['./custom-order-shared-overview.component.scss'],
})
export class CustomOrderSharedOverviewComponent {
  @Input() order: Order;

  constructor (
    private addressCardService: AddressCardService
  ) {}

  getDeliveryAddressCard (address: Address) {
    return this.addressCardService.getAddressCard(address).join(`|`).replaceAll(`|`, `<br>`);
  }

  getBillingAddressCard (address: Address) {
    const billingAddress = {...address, ...{
      firstName: this.order.paymentInfo?.accountHolderName,
      lastName: ``,
    }}

    return this.addressCardService.getAddressCard(billingAddress).join(`|`).replaceAll(`|`, `<br>`);
  }

  converterData(dataString: string): string {
    const dataObj = new Date(dataString);
    return `${(dataObj.getMonth() + 1).toString().padStart(2, '0')}/${dataObj
      .getDate()
      .toString()
      .padStart(2, '0')}/${dataObj.getFullYear()}`;
  }


  transformString(input: string): string {
    const pipeIndex = input?.indexOf('|');

    if (pipeIndex !== -1) {
      return input?.substring(pipeIndex + 1);
    } else {
      return input;
    }
  }
}

import { NgModule } from "@angular/core";
import { AddonsComponent } from "./components/addons/addons.component";
import { AddonsCardComponent } from "./components/addons-card/addons-card.component";
import { AddonsVariantsComponent } from "./components/addons-variants/addons-variants.component";
import { MediaModule } from "@spartacus/storefront";
import { I18nModule } from "@spartacus/core";
import { CommonModule } from "@angular/common";
import { CustomProductVariantColorSelectorComponent } from "../../../variants/components/product-variant-color-selector/product-variant-color-selector.component";
import { CustomProductVariantSelectorComponent } from "../../../variants/components/product-variant-selector/product-variant-selector.component";
import { ConfiguratorAccordionItemModule } from "../../components/configurator-accordion-item/configurator-accordion-item.module";

@NgModule({
  imports: [
    CommonModule,
    MediaModule,
    I18nModule,
    CustomProductVariantColorSelectorComponent,
    CustomProductVariantSelectorComponent,
    ConfiguratorAccordionItemModule
  ],
  declarations: [
    AddonsComponent,
    AddonsCardComponent,
    AddonsVariantsComponent
  ],
  exports:[
    CustomProductVariantColorSelectorComponent,
    CustomProductVariantSelectorComponent
  ]
})
export class AddonsConfiguratorModule {

}

import { Vehicle } from "../../../../ymm/core/models/garage.model";
import { DescriptionValuePair } from "../../../../../../core/model/common";
import { Price, Product, VariantOptionQualifier } from "@spartacus/core";
import { Embroidery, EmbroideryColor, EmbroideryFont, LogoItem, ProductConfigStepType, ProductConfiguration, ProductLineOption } from "../models/product-configuration.models";
import { ProductDetailsPrice, QualifierOptionValue } from "../../../core/models/product-details.models";
import { AddonVariant } from "../../configurators/addons/core/addons-model";


export const PRODUCT_CONFIGURATION_KEY = 'product-configuration';


export interface ConfigurationSelectionBase {
  stepType: ProductConfigStepType;
}

export interface ProductLineSelection extends ConfigurationSelectionBase {
  id?: string;
  description?: string;
  option?: ProductLineOption;
}

export interface EmbroiderySelection extends ConfigurationSelectionBase {
  text: string;
  color: EmbroideryColor;
  font: EmbroideryFont;
  price: Price;
  embroidery: Embroidery;
}

export interface LogoSelection extends ConfigurationSelectionBase {
  item: LogoItem;
}

export interface Addon {
  sku: string;
  productBase: string;
  price: ProductDetailsPrice;
  product: Product;
  addonVariants?: AddonVariant[]
}

export interface AddonsSelection extends ConfigurationSelectionBase {
  addons: Addon[];
}

export type ProductConfigStepSelection =
  ProductLineSelection
  | EmbroiderySelection
  | LogoSelection
  | AddonsSelection;

export interface ConfigurationSelection {
  productCode?: string;
  productLine?: string;
  subModel?:  DescriptionValuePair,
  subModelOption?: DescriptionValuePair;
  vehicle?: Vehicle;
  selections: ProductConfigStepSelection[]
}

export interface SubModelOption {
  vehicle: Vehicle;
  productLine: string;
  subModel: string;
  options: DescriptionValuePair[];
}

export interface ProductConfigurationState {
  productConfigLoading: boolean;
  submodels?: {
    productLine: string;
    vehicleCode: string;
    submodels: DescriptionValuePair[];
  }[];
  subModelOptions?: SubModelOption[];
  activeSelectionConfig?: ConfigurationSelection;
  productConfigurations: {
    [ productCode: string ]: ConfigurationSelection
  };
  configurations?: {
    [ keyChain: string ]: ProductConfiguration;
  }

}

export const productConfigInitialState: ProductConfigurationState = {
  productConfigLoading: false,
  subModelOptions: [],
  activeSelectionConfig: {
    selections: []
  },
  productConfigurations: {},
  configurations: {}
};

<div class="medias-dialog-holder">
  <section [cxFocus]="focusConfig" class="medias-dialog">

    <!-- Close Button -->
    <button
      (click)="close('cross click')"
      [attr.aria-label]="'productImageZoomDialog.close' | cxTranslate"
      class="btn medias-dialog__close"
      type="button">
      <cx-icon [type]="iconType.CLOSE"></cx-icon>
    </button>

    <div class="row h-100 medias-dialog__container">
      <div class="col-12 col-lg-6 h-100">
        <section *ngIf="mediaType === 'image'" class="medias-dialog__zoom h-100">
          <cx-product-image-zoom-view
            [container]="activeContainer | async"
            [galleryIndex]="active"
            class="d-block h-100">
          </cx-product-image-zoom-view>
          <div class="product-gallery__representative">
            {{ 'productCarousel.representativeImageShown' | cxTranslate }}
          </div>
        </section>
        <section *ngIf="mediaType === 'video'" class="medias-dialog__zoom h-100">
          <iframe
            [src]="activeVideo"

            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen
            frameborder="0"
            title="YouTube video player"></iframe>
        </section>
      </div>

      <!-- THUMBNAILS -->
      <aside class="col-12 col-lg-6">

        <cx-tabs [scrollable]="true" [activeTabIndex]="activeTab">

          <!-- Image Thumbnails -->
          <cx-tab title="{{ 'productCarousel.images' | cxTranslate }}">
            <product-medias-thumbnails
              (changeMedia)="changeMedia($event.container, $event.index, $event.mediaType)"
              [active]="active"
              [maxGalleryItems]="0">
            </product-medias-thumbnails>
          </cx-tab>

          <!-- Vídeos Thumbnails -->
          <ng-container *ngIf="product$ | async as product">
            <cx-tab *ngIf="product?.galleryVideos?.length" title="{{ 'productCarousel.videos' | cxTranslate }}">
              <product-videos-thumbnails
                (changeMedia)="changeVideo($event.container, $event.index, $event.mediaType, $event.videoUrl)"
                [active]="active"
                [maxGalleryItems]="0">
              </product-videos-thumbnails>
            </cx-tab>
          </ng-container>
        </cx-tabs>
      </aside>
    </div>

  </section>
</div>

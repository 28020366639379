<ng-container *ngIf="facetList$ | async as facetList">
  <div *ngIf="facetList.activeFacets && facetList.activeFacets.length > 0"
       id="cx-active-facets-groupName">
    {{ 'productList.appliedFilter' | cxTranslate }}
  </div>

  <a
    (keydown.space)="removeFilterWithSpacebar($event)"
    *ngFor="let facet of facetList?.activeFacets"
    [attr.aria-label]="'productList.activeFilter' | cxTranslate: { filter: facet.facetValueName }"
    [cxFocus]="{ key: getFocusKey(facetList, facet) }"
    [queryParams]="getLinkParams(facet)"
    role="button"
    [routerLink]="url$ | async">
    <span>
      <span>{{facet.facetName}}:</span>
      {{ facet.facetValueName }}
    </span>
    <cx-icon [type]="closeIcon" aria-hidden="true"></cx-icon>
  </a>
</ng-container>

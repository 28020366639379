import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OutletModule, OutletRefModule, PageLayoutModule, PageSlotModule } from '@spartacus/storefront';
import { LandingPage2TemplateComponent } from './components/landing-page2-template/landing-page2-template.component';
import { CartPageTemplateComponent } from './components/cart-page-template/cart-page-template.component';
import { LocalLoaderModule } from 'src/app/components/_CUSTOM/loader/local-loader/local-loader.module';
import { SearchResultsListPageTemplateModule } from './components/search-results-list-page-template/search-results-list-page-template.module';
import { ProductDetailsPageTemplateModule } from './components/product-details-page-template/product-details-page-template.module';
import { TemplatesComponent } from "./templates.component";

@NgModule({
  declarations: [
    TemplatesComponent,
    LandingPage2TemplateComponent,
    CartPageTemplateComponent,
  ],
  imports: [
    CommonModule,
    OutletModule,
    OutletRefModule,
    PageLayoutModule,
    PageSlotModule,
    LocalLoaderModule,

    SearchResultsListPageTemplateModule,
    ProductDetailsPageTemplateModule
  ],
  exports: [
    TemplatesComponent
  ]
})

export class TemplatesModule { }

import { ImageGroup, Product, VariantOptionQualifier } from "@spartacus/core";
import { ProductDetailsPrice, QualifierOptionValue } from "../models/product-details.models";


export const PRODUCT_DETAILS_FEATURE_KEY = 'productDetails';

export interface ProductDetailVariant {
  qualifier: string;
  selected?: VariantOptionQualifier;
  activeKeyChain?: string;
  options?: {
    [ keyChain: string ]: VariantOptionQualifier[];
  }
}

export type StoredVariants = {
  [ productCode: string ]: {
    [ qualifier: string ]: ProductDetailVariant
  };
};

export interface ProductDetailsState {
  product?: Product,
  sku?: string;
  variants: StoredVariants;
  selectedVariants: QualifierOptionValue[];
  prices: {
    [ keyChain: string ]: ProductDetailsPrice
  },
  gallery: {
    [ keyChain: string ]: ImageGroup
  }
}

<div *ngIf="cart$ | async as cart" class="container-simple-order-summary">
  <div class="cx-summary-heading m-b-12 bold-common-font">
    {{'customCheckout.simpleSummary.title' | cxTranslate}}
  </div>

  <all-cart-messages></all-cart-messages>

  <div class="border-div-summary border-size-1 m-tb-12"></div>

  <div class="row">
    <div class="col-6 bold-common-font">
      {{'customCheckout.summary.total' | cxTranslate}}
    </div>

    <div class="col-6 bold-common-font text-right">
      {{ cart.totalPriceWithTax?.formattedValue }}
    </div>
  </div>

  <custom-cart-proceed-to-checkout *ngIf="true"></custom-cart-proceed-to-checkout>
</div>

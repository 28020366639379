import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { OrderEntry } from '@spartacus/cart/base/root';
import { Consignment, Order } from '@spartacus/order/root/model/order.model';

@Component({
  selector: 'custom-order-shared-items',
  templateUrl: './custom-order-shared-items.component.html',
  styleUrls: [ './custom-order-shared-items.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomOrderSharedItemsComponent {
  private _order: Order;

  public get order(): Order {
    return this._order;
  }

  @Input()
  public set order(order: Order) {
    this._order = order;
    let counter = 1
    order.consignments?.forEach((consignment) => {
      consignment.shipmentNumber = counter++
    })
    order.shipmentNumber = counter++

  }

  @Input() pickupConsignments: Consignment[] | undefined;
  @Input() deliveryConsignments: Consignment[] | undefined;

  @Input() pickupUnconsignedEntries: OrderEntry[] | undefined;
  @Input() deliveryUnConsignedEntries: OrderEntry[] | undefined;

  getTrackingUrl(consignment: Consignment): string {
    if (!consignment?.trackingID || !consignment.covercraftCarrier?.baseUrl) {
      return '';
    }
    return `${ consignment.covercraftCarrier?.baseUrl }${ consignment.trackingID }`
  }

  shipmentNumber: number = 0;

  constructor() {
    this.shipmentNumber = 0;
  }

}

<ng-container *ngIf="cart$ | async as cart">
 <!-- <div class="cx-review-cart-total d-none d-lg-block d-xl-block mb-2">
  {{ 'cartItems.cartTotal' | cxTranslate: { count: cart.totalUnitCount } }}:
  {{ cart.totalPrice?.formattedValue }}
 </div> -->
 <!-- <custom-cart-promotion [promotions]="
      (cart.appliedOrderPromotions || []).concat(
        cart.potentialOrderPromotions || []
      )
    " cssClasses="cart-details-promotion medium-common-font">
 </custom-cart-promotion> -->
</ng-container>

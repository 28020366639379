import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'simple-toggle',
  templateUrl: './simple-toggle.component.html',
  styleUrls: ['./simple-toggle.component.scss']
})
export class SimpleToggleComponent implements OnInit {
  @Input() checked!: boolean;
  @Input() label?: string
  @Input() invertLabel?: boolean;
  @Output() callbackOnChange = new EventEmitter<boolean>();

  ngOnInit(): void {
  }

  handlerClick (element: Event) {
    const input = element.currentTarget as HTMLInputElement;
    this.callbackOnChange.emit(input?.checked);
  }
}

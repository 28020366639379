import { Component } from '@angular/core';
import { CmsProductReferencesComponent, Product, ProductReference, ProductReferenceService } from '@spartacus/core';
import { CmsComponentData, CurrentProductService, ProductListItemContextSource, ProductReferencesComponent } from '@spartacus/storefront';
import { GarageService } from '../../../ymm/core/facade/garage.service';
import { forkJoin, Observable, of } from 'rxjs';
import { withLatestFrom, tap, switchMap, filter, map, first } from 'rxjs/operators';
import { CustomProductReferenceService } from '../../core/facade/custom-product-reference.serive';
import { DataLayerService } from 'src/app/spartacus/features/data-layer/data-layer.service';

@Component({
  selector: 'custom-product-references',
  templateUrl: './custom-product-references.component.html',
  styleUrls: ['./custom-product-references.component.scss'],
})
export class CustomProductReferencesComponent extends ProductReferencesComponent {
  constructor(
    protected override cmsComponentData: CmsComponentData<CmsProductReferencesComponent>,
    protected override currentProductService: CurrentProductService,
    protected override productReferenceService: ProductReferenceService,
    protected service: CustomProductReferenceService,
    private dataLayerService: DataLayerService
  ) {
    super(cmsComponentData, currentProductService, productReferenceService);
  }

  override items$: Observable<Observable<Product | undefined>[]> =
    this.productCode$.pipe(
      filter(code => !!code),
      switchMap((productCode) => {
        return this.service.loadReferences(
          productCode).pipe(map((productReferences)=>{


            const targets = (productReferences as any)?.references.map(
              (reference: any) => of(reference.target as Product)
            );

            return targets
          }));
      }),

    );

  ngOnInit(): void {
    this.items$.pipe(
      first(),
      filter(items => items.length > 0)
    ).subscribe(items => {
      forkJoin(items).pipe(first()).subscribe(products => {
        this.dataLayerService.viewItemListEvent(products, 'Cross Sell');
      })
    });
  }

}

<div class="cx-anonymous-consent-dialog" [cxFocus]="focusConfig" (esc)="close('Escape clicked')">
    <div class="cx-dialog-content">
        <div *ngIf="loading$ | async; else dialogBody">
            <cx-spinner></cx-spinner>
        </div>
        <ng-template #dialogBody>
            <div role="status" [attr.aria-label]="'common.loaded' | cxTranslate"></div>
            <div class="cx-dialog-header">
                <h3>
                    {{ 'anonymousConsents.dialog.title' | cxTranslate }}
                </h3>
                <button type="button" class="close" [attr.aria-label]="'common.close' | cxTranslate"
                    (click)="close('Cross click')">
                    <span aria-hidden="true">
                        <cx-icon [type]="iconTypes.CLOSE"></cx-icon>
                    </span>
                </button>
            </div>
            <!-- Separator -->
            <div class="cx-dialog-description" *ngIf="showLegalDescription">
                {{ 'anonymousConsents.dialog.legalDescription' | cxTranslate }}
                <div class="cx-dialog-separator col-sm-12 d-xs-block d-sm-block d-md-none"></div>
            </div>
            <!-- Actions -->
            <div class="cx-dialog-buttons">
                <a tabindex="0" class="btn-link cx-action-link" (click)="rejectAllCheckboxes()">{{
                    'anonymousConsents.dialog.clearAll' | cxTranslate
                    }}</a>
                <a tabindex="0" class="btn-link cx-action-link" (click)="selectAllCheckboxes()">{{
                    'anonymousConsents.dialog.selectAll' | cxTranslate
                    }}</a>
            </div>
            <!-- Modal Body -->
            <div class="cx-dialog-body">
                <div class="container">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-check" *ngFor="let checkbox of checkboxes.slice(0, 2)">
                                <input class="form-check-input" type="checkbox" [id]="checkbox.id"
                                    (change)="onCheckboxChange($event, checkbox)" [checked]="checkbox.checked">
                                <label class="form-check-label" [for]="checkbox.id">{{ checkbox.label }}</label>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-check" *ngFor="let checkbox of checkboxes.slice(2)">
                                <input class="form-check-input" type="checkbox" [id]="checkbox.id"
                                    (change)="onCheckboxChange($event, checkbox)" [checked]="checkbox.checked">
                                <label class="form-check-label" [for]="checkbox.id">{{ checkbox.label }}</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </div>
</div>
/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CmsConfig, provideConfig, provideDefaultConfig } from '@spartacus/core';
import { GenericLinkModule, PageComponentModule } from '@spartacus/storefront';
import { CarouselModule, MediaModule } from '@spartacus/storefront';
import { CustomBannerCarouselItemComponent } from './custom-banner-carousel-item.component';

@NgModule({
  imports: [CommonModule, PageComponentModule, CarouselModule, MediaModule, GenericLinkModule],
  providers: [
    provideConfig({
      cmsComponents: {
          CovercraftRotatingBannerComponent: {
          component: CustomBannerCarouselItemComponent,
        },
      },
    } as CmsConfig),
  ],
  declarations: [CustomBannerCarouselItemComponent],
  exports: [CustomBannerCarouselItemComponent],
})
export class CustomBannerCarouselItemModule {}

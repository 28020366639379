<div class="talk-to-us {{styleClasses}}">
  <ng-container *ngIf="talkToUs">
    <section class="talk-to-us__cards">

      <ng-container *ngIf="talkToUs.isActiveLiveChatComponent">
        <a [href]="talkToUs.liveChatUrl"
           class="talk-to-us__link"
           rel="noopener noreferrer"
           style="list-style: none;"
           target="_blank">
          <div class="talk-to-us__card talk-to-us__card--chat">
            <div class="talk-to-us__card--icon">
              <svg-icon iconId="iconChat"></svg-icon>
            </div>
            <div class="talk-to-us__card--title" [innerHTML]="talkToUs.liveChatText"></div>
          </div>
        </a>
      </ng-container>

      <ng-container *ngIf="talkToUs.isActiveHourComponent">
        <a [href]="talkToUs.timePhoneUrl"
           class="talk-to-us__link"
           rel="noopener noreferrer"
           target="_blank">
          <div class="talk-to-us__card talk-to-us__card--phone">
            <div class="talk-to-us__card--icon">
              <span class="icomoon-cellphone"></span>
            </div>
            <div>
              <div class="talk-to-us__card--title" [innerHTML]="talkToUs.contactPhone"></div>
              <div class="talk-to-us__card--subtitle" [innerHTML]="talkToUs.timeText"></div>
            </div>
          </div>
        </a>
      </ng-container>

    </section>
  </ng-container>
</div>

<div
  class="floating-select-container"
  *ngIf="options"
>
  <label *ngIf="label"> {{ label }}: </label>

  <div [ngClass]="{'active': showOptions}" (click)="open()">
    <div class="floating-select js-floating-select">
      <span class="select">{{currentOption?.value}}</span> <ng-content></ng-content>
      <i class="icon bi bi-chevron-down"></i>
    </div>

    <ul class="results">
      <li
        *ngFor="let item of options"
        [attr.data-code]="item.code"
        (click)="selectOption(item)"
      >
        {{item.value}}
      </li>
    </ul>
  </div>
</div>

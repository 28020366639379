import { Injectable } from '@angular/core';
import { CustomBasePageMetaResolver } from './custom-base-page-meta-resolvers';
import {
  CmsService,
  isNotNullable,
  PageLinkService,
  Product,
  ProductPageMetaResolver,
  ProductScope,
  ProductService,
  RoutingService,
  TranslationService,
  UrlCommands, WindowRef,
} from '@spartacus/core';
import { Observable, of } from 'rxjs';
import { filter, map, pluck, switchMap, tap } from "rxjs/operators";

import { PageKeywordsResolver } from "./custom-page-resolver";

@Injectable({ providedIn: 'root' })
export class CustomProductPageMetaResolver
  extends ProductPageMetaResolver
  implements PageKeywordsResolver
{
  constructor(
    routingService: RoutingService,
    productService: ProductService,
    translation: TranslationService,
    CustomBasePageMetaResolver: CustomBasePageMetaResolver,
    pageLinkService: PageLinkService,
    private winRef: WindowRef,
    private cmsService: CmsService
  ) {
    super(
      routingService,
      productService,
      translation,
      CustomBasePageMetaResolver,
      pageLinkService
    );
  }

  /**
   * Resolves the title for the product page.
   * If the product contains the metaTitle defined,
   * it will use it instead of the default.
   * The default is used only if this value is not provided.
   */
  override resolveTitle(): Observable<string> {
    return this.product$.pipe(
      switchMap((product) =>
        product.metaTitle ? of(product.metaTitle) : super.resolveTitle()
      )
    );
  }

  /**
   * Resolve the description using the meta description
   * if available. Otherwise, it will fall back to
   * the default behavior
   */
  override resolveDescription(): Observable<string> {
    return this.product$.pipe(
      switchMap((product) =>
        product.metaDescriptionText
          ? of(product.metaDescriptionText)
          : super.resolveDescription()
      )
    );
  }

  override resolveCanonicalUrl(): Observable<string> {
    return this.product$.pipe(
      switchMap((product) => this.findBaseProduct(product)),
      map((product) => {
        const url = this.getProductUrl({
          cxRoute: 'product',
          params: product,
        });
        return this.pageLinkService.getCanonicalUrl({}, this.formatCanonicalURL(url));
      })
    );
  }

  /**
   * Resolve the keywords for the product
   */
  resolveKeywords(): Observable<string | undefined> {
    return this.product$.pipe(map((product) => product.metaKeywords));
  }

  private getProductUrl(commands: UrlCommands) {
    return `${this.winRef.location.origin}${this.routingService.getUrl(
      commands
    )}`;
  }

  private formatCanonicalURL(inputString: string): string {
    const parts = inputString.split('/');
    const lastPart = parts[parts.length - 1].toUpperCase();
    const rest = parts.slice(0, -1).join('/').toLowerCase();
    return rest + '/' + lastPart;
  }
}

<ng-container *ngIf="product$ | async as product">
  <div class="container features-container" style="word-break: break-all;">
    <div class="row">
      <div class="col-sm-6 col-12" *ngIf="product.installationVideo">
        <div class="youtube-frame">
          <iframe [src]="product?.installationVideo?.videoUrl + '?rel=0' | safeUrl" frameborder="0" allowfullscreen></iframe>
        </div>
      </div>

      <div class="col-sm-6 col-12">
        <p class="font-weight-bold resources-title m-b-12">
          {{ 'productInstallation.resources' | cxTranslate }}
        </p>

        <ul class="pdfs" *ngIf="product.specifications?.length">
          <li *ngFor="let item of product.specifications">
            <a [href]="environment.baseUrl + item.url" target="_blank" class="font-weight-bold guide-download">
              {{item.title}}
            </a>
          </li>
        </ul>

        <!-- TALK TO US -->
        <section class="tab-talk-to-us">
          <p class="font-weight-bold talk-to-us-title">
            {{ 'productInstallation.talkToUs.title' | cxTranslate }}
          </p>

          <p class="talk-to-us-description">
            {{ 'productInstallation.talkToUs.description' | cxTranslate }}
          </p>

          <standard-talk-to-us></standard-talk-to-us>
        </section>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="children$ | async as children">
  <ng-container *ngFor="let child of children">
    <ng-container *ngIf="child">
      <ng-template [cxOutlet]="child.flexType" [cxOutletContext]="{}">
        <div class="container">
          <ng-container [cxComponentWrapper]="child"></ng-container>
        </div>
      </ng-template>
    </ng-container>
  </ng-container>
</ng-container>

import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { I18nModule, VariantOptionQualifier } from '@spartacus/core';
import { FormControl, ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { CapitalizeModule } from 'src/app/components/pipes/capitalize/capitalize.module';

@Component({
  selector: 'cx-product-variant-selector',
  templateUrl: './product-variant-selector.component.html',
  styleUrls: [ './product-variant-selector.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    I18nModule,
    CapitalizeModule
  ]
})
export class CustomProductVariantSelectorComponent {
  private _selected?: VariantOptionQualifier;
  private _variants: VariantOptionQualifier[];
  @Input() sizeLabel: string = '';
  @Input() context: 'PRODUCT' | 'ADDON' = 'PRODUCT';
  @Input() name: string = '';
  @Output() changeVariant = new EventEmitter<VariantOptionQualifier>();
  variantControl = new FormControl('');

  get variants(): VariantOptionQualifier[] {
    return this._variants;
  }

  @Input() set variants(value: VariantOptionQualifier[]) {
    if (JSON.stringify(value) !== JSON.stringify(this._variants)) {
      this._variants = value;
    }
  }

  get selected(): VariantOptionQualifier {
    return this._selected;
  }

  @Input()
  set selected(value: VariantOptionQualifier) {
    this._selected = value;
    this.variantControl.setValue(value?.value ?? '');
  }

  @Input()
  set disabled(value: boolean) {
    if (value === true) {
      this.variantControl.disable();
    } else {
      this.variantControl.enable();
    }
  }

  changeValue(event: Event): void {
    const value = ( event.target as HTMLSelectElement ).value;
    const variant = this._variants.find((v) => v.value == value);
    this.changeVariant.emit(variant);
  }

}

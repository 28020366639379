/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CmsConfig, provideConfig, provideDefaultConfig, UrlModule } from '@spartacus/core';


import { CustomProductReferencesComponent } from './custom-product-references.component';
import { CarouselModule, MediaModule, ProductListItemContextSource } from '@spartacus/storefront';
import { CustomProductCarouselModule } from '../custom-product-carousel/custom-product-carousel.module';
import { CustomProductCarouselItemComponent } from '../custom-product-carousel-item/custom-product-carousel-item.component';
import { CustomProductCarouselItemModule } from '../custom-product-carousel-item/custom-product-carousel-item.module';
import { CustomCarouselModule } from 'src/app/components/_SPA-Components/custom-carousel';

@NgModule({
  imports: [
    CommonModule,
    CarouselModule,
    MediaModule,
    RouterModule,
    UrlModule,
    CustomProductCarouselItemModule,
    CustomCarouselModule,
  ],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        ProductReferencesComponent: {
          component: CustomProductReferencesComponent,
        },
      },
    }),
    { provide: ProductListItemContextSource },
  ],

  declarations: [CustomProductReferencesComponent],
  exports: [CustomProductReferencesComponent],
})
export class CustomProductReferencesModule {}

<ng-container *ngIf="product$ | async as product">
  <div class="cx-product-variant-color-selector">
    <div class="color-message medium-common-font">{{ 'productVariantColor.physicalColorMessage' | cxTranslate }}</div>

    <div class="color-swatches">
      <div class="color-title medium-common-font">
        <ng-container *ngIf="product.vehicleSpecific && !isSKUPage; else simpleColorLabel">
          <strong class="bold-common-font">
            {{ 'productVariantColor.colorStep' | cxTranslate }}
          </strong>
        </ng-container>
        <ng-template #simpleColorLabel>
            <strong class="bold-common-font">
            {{ 'productVariantColor.color' | cxTranslate }}
            </strong>
        </ng-template>
        <span *ngIf="selected">
          {{ getColorName() }}
        </span>
      </div>

      <ul class="colors-options">
        <li (click)="handlerColorClick(variant)" *ngFor="let variant of variants; trackBy: trackBy" [ngClass]="{ active: checkSelected(variant, selected) }" role="button">
          <div class="colors-options__stripe" *ngIf="!variant.active"></div>
          <cx-media [container]="{url: variant.image}"></cx-media>
        </li>
      </ul>
    </div>
  </div>
</ng-container>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomCheckoutReviewPaymentComponent } from './custom-checkout-review-payment.component';
import { IconModule } from '@spartacus/storefront';
import { CmsConfig, I18nModule, UrlModule, provideConfig } from '@spartacus/core';
import { RouterModule } from '@angular/router';
import { CartNotEmptyGuard, CheckoutAuthGuard } from '@spartacus/checkout/base/components';
import { CustomCardModule } from '../../../shared/components/card';

@NgModule({
  declarations: [
    CustomCheckoutReviewPaymentComponent
  ],
  imports: [
    CommonModule,
    CustomCardModule,
    I18nModule,
    UrlModule,
    RouterModule,
    IconModule,
  ],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        CheckoutReviewPayment: {
          component: CustomCheckoutReviewPaymentComponent,
          guards: [CheckoutAuthGuard, CartNotEmptyGuard],
        },
      },
    }),
  ],
  exports: [
    CustomCheckoutReviewPaymentComponent
  ]
})

export class CustomCheckoutReviewPaymentModule { }

import { Injectable } from "@angular/core";
import { CmsService, ContentPageMetaResolver, isNotNullable, PageHeadingResolver, TranslationService } from "@spartacus/core";
import { Observable, of } from "rxjs";
import { CustomBasePageMetaResolver } from "./custom-base-page-meta-resolvers";
import { filter, map, mergeMap } from "rxjs/operators";
import { PageHeadingEyebrowResolver } from "./custom-page-resolver";
import { GarageService } from "../../../spartacus/features/ymm/core/facade/garage.service";

@Injectable({ providedIn: 'root' })
export class CustomContentPageResolver
  extends ContentPageMetaResolver
  implements PageHeadingResolver, PageHeadingEyebrowResolver {

  private ymmId = 'ymm-category-page';

  constructor(private customBasePageResolver: CustomBasePageMetaResolver,
              private cmsService: CmsService,
              private garageService: GarageService,
              private translation: TranslationService) {
    super(customBasePageResolver);
  }

  resolveEyebrow(): Observable<string> {
    return this.cmsService.getCurrentPage().pipe(
      mergeMap(page => {
        return page?.pageId === this.ymmId
          ? this.translation.translate('ymmCategoryPage.vehicleTitle')
          : of('')
      })
    );
  }

  resolveHeading(): Observable<string> {
    return this.cmsService.getCurrentPage().pipe(
      mergeMap(page => {
        return page?.pageId === this.ymmId
          ? this.resolveYmmHeading()
          : super.resolveTitle();
      })
    );
  }

  resolveKeywords(): Observable<string | undefined> {
    return this.customBasePageResolver.resolveKeywords();
  }

  private resolveYmmHeading(): Observable<string> {
    return this.garageService.getActiveVehicle().pipe(
      filter(isNotNullable),
      map(({name})=> name ?? '')
    );
  }

}

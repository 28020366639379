import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { I18nModule, UrlModule } from '@spartacus/core';
import { IconModule } from '@spartacus/storefront';
import { MediaModule } from '@spartacus/storefront';
import { CustomCarouselComponent } from './custom-carousel.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    IconModule,
    MediaModule,
    UrlModule,
    I18nModule,
  ],
  declarations: [CustomCarouselComponent],
  exports: [CustomCarouselComponent],
})
export class CustomCarouselModule {}

import { createAction, props } from "@ngrx/store";
import { ImageGroup, Product, ProductScope, VariantOptionQualifier } from "@spartacus/core";
import { LoadVariantOptions, LoadVariantOptionsOk, ProductDetailsPrice, QualifierOptionValue, SelectOptionPayload } from "../models/product-details.models";

export const CUSTOM_LOAD_PRODUCT = '[Product] Custom Load Product Data';
export const CUSTOM_LOAD_PRODUCT_SUCCESS = '[Product] Custom Load Product Data Success';
export const CUSTOM_LOAD_PRODUCT_FAIL = '[Product] Custom Load Product Data Fail';

const START_VARIANTS = '[PRODUCT DETAILS] Start Variants';

const LOAD_VARIANT_OPTION = '[PRODUCT DETAILS] Load Variant Option';
const LOAD_VARIANT_OPTION_OK = '[PRODUCT DETAILS] Load Variant Option OK';
const LOAD_VARIANT_OPTION_PROCESSING = '[PRODUCT DETAILS] Load Variant Option Processing';

const SET_ACTIVE_VARIANT_OPTION = '[PRODUCT DETAILS] Set Active Variant Option';
const CLEAR_SELECTED_VARIANT_OPTIONS = '[PRODUCT DETAILS] Clear Selected Variant Options';

const UPDATE_PRICE = '[PRODUCT DETAILS] Update Price';
const UPDATE_PRICE_OK = '[PRODUCT DETAILS] Update Price Ok';

const LOAD_GALLERY = '[PRODUCT DETAIL] Load Gallery';
const LOAD_GALLERY_OK = '[PRODUCT DETAIL] Load Gallery Ok';

export const loadProduct = createAction(
  CUSTOM_LOAD_PRODUCT,
  props<{ code: string, scope?: '' | ProductScope }>()
);

export const loadProductSuccess = createAction(
  CUSTOM_LOAD_PRODUCT_SUCCESS,
  props<{ product: Product, scope: '' | ProductScope }>()
);

export const loadProductFail = createAction(
  CUSTOM_LOAD_PRODUCT_FAIL
);

export const startVariants = createAction(
  START_VARIANTS,
  props<{ productCode: string, options: VariantOptionQualifier[] }>()
);

export const loadVariantOptions = createAction(
  LOAD_VARIANT_OPTION,
  props<LoadVariantOptions>()
);

export const loadVariantOptionOk = createAction(
  LOAD_VARIANT_OPTION_OK,
  props<LoadVariantOptionsOk>()
);

export const loadVariantsProcessing = createAction(
  LOAD_VARIANT_OPTION_PROCESSING,
  props<{ isProcessing: boolean }>()
);

export const selectOption = createAction(
  SET_ACTIVE_VARIANT_OPTION,
  props<SelectOptionPayload>()
);

export const clearSelectedVariantOptions = createAction(
  CLEAR_SELECTED_VARIANT_OPTIONS
)

export const updatePrice = createAction(
  UPDATE_PRICE,
  props<{ product: string, options: QualifierOptionValue[], subModelOption?: string }>()
);

export const updatePriceOk = createAction(
  UPDATE_PRICE_OK,
  props<{ price: ProductDetailsPrice, keyChain: string }>()
);

export const loadGallery = createAction(
  LOAD_GALLERY,
  props<{ product: string, options: QualifierOptionValue[] }>()
);

export const loadGalleryOk = createAction(
  LOAD_GALLERY_OK,
  props<{ keyChain: string, images: ImageGroup }>()
);


import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AnonymousConsent, AnonymousConsentsService, ConsentTemplate, GlobalMessageService, GlobalMessageType } from '@spartacus/core';
import { CustomFormValidators, LaunchDialogService } from '@spartacus/storefront';
import { Observable, Subscription } from 'rxjs';
import { CustomNewsletterService } from './newsletter.service';
import { RecaptchaWidget } from '../recaptcha/recaptcha.widget';
import { RecaptchaFactoryService } from '../recaptcha/recaptcha-factory.service';
import { switchMap, take } from 'rxjs/operators';
import { ListrakService } from '../listrak/core/listrak.service';
import { DataLayerService } from 'src/app/spartacus/features/data-layer/data-layer.service';

@Component({
  selector: 'app-newsletter',
  templateUrl: './newsletter.component.html',
  styleUrls: ['./newsletter.component.scss'],
})

export class NewsletterComponent implements OnInit, AfterViewInit, OnDestroy {
  newsletterForm: UntypedFormGroup = this.fb.group({
    firstName: ['', Validators.required],
    lastName: ['', Validators.required],
    emailAddress: [
      '',
      [Validators.required, CustomFormValidators.emailValidator],
    ],
    source: 'newsletter',
    termsandconditions: [false, Validators.requiredTrue],
  });

  anonymousConsent$?: Observable<{
    consent: AnonymousConsent | undefined;
    template: string;
  }>;

  additionalRegistrationConsents?: {
    template: ConsentTemplate;
    required: boolean;
  }[];

  containerId = 'newsletterCaptchaContainer';
  private captchaWidget: RecaptchaWidget | undefined;
  private subscription = new Subscription();

  constructor(
    protected fb: UntypedFormBuilder,
    protected anonymousConsentsService: AnonymousConsentsService,
    protected newsletterService: CustomNewsletterService,
    private recaptchaFactory: RecaptchaFactoryService,
    protected globalMessageService: GlobalMessageService,
    protected listrakService: ListrakService,
    protected dataLayerService: DataLayerService
  ) {}

  ngOnInit(): void {
    this.listrakService.emailCapture()
  }

  ngAfterViewInit(): void {
    this.captcha();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  captcha () {
    this.subscription.add(
      this.recaptchaFactory.create(this.containerId).subscribe(widget => {
        this.captchaWidget = widget;
      })
    );
  }

  submitForm() {
    if (this.newsletterForm.valid) {
    this.captchaWidget
      ?.validate()
      .pipe(
        switchMap((token) => {
          const data = this.collectDataFromRegisterForm(
            this.newsletterForm.value
          );
          data.recaptchaToken = token;
          data.source = 'newsletter';

          return this.newsletterService.postNewsletterSignup(data);
        }),
        take(1)
      )
      .subscribe(
        (response) => {
          this.globalMessageService.add(
            'Thank you for signing up.',
            GlobalMessageType.MSG_TYPE_CONFIRMATION
          );
          this.newsletterForm.reset()
          this.dataLayerService.subscribeNewsletterEvent();
        },
        (error) => {
          this.globalMessageService.add(
            'Subscribe Failed',
            GlobalMessageType.MSG_TYPE_ERROR
          );
        }
      );


    } else {
      this.newsletterForm.markAllAsTouched();
    }
  }

  isConsentGiven(consent: AnonymousConsent | undefined): boolean {
    return this.anonymousConsentsService.isConsentGiven(consent);
  }

  collectDataFromRegisterForm(formData: any) {
    const { firstName, lastName, emailAddress, source, recaptchaToken } = formData;

    return {
      name: firstName,
      lastName,
      emailAddress: emailAddress.toLowerCase(),
      recaptchaToken,
      source
    };
  }
}

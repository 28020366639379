import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'wishlist-button',
  templateUrl: './wishlist-button.component.html',
  styleUrls: ['./wishlist-button.component.scss']
})

export class WishlistButtonComponent {
  @Input() isFloating = true;
  @HostBinding('class.wishlist-floater') class = this.isFloating;
}

import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { LoaderService } from './loader.service';
import { LoaderConfig } from './loader.model';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})

export class LoaderComponent {

  loader$ : Observable<LoaderConfig> = this.loaderService.loader$;

  constructor(private loaderService: LoaderService) { }
}

import { NgModule } from "@angular/core";
import { CustomCategoryCarouselItemComponent } from "./custom-category-carousel-item.component";
import { CommonModule } from "@angular/common";

@NgModule({
  declarations: [
    CustomCategoryCarouselItemComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    CustomCategoryCarouselItemComponent
  ]
})

export class CustomCategoryCarouselItemModule {}

<div style="display: inline-block;" class="anonymousIconContainer ">
 <div class="bi-person-fill mr-2 anonymousAccountIcon"  
   style="display: inline-block;" tabindex="0" (keypress)="showDivWrapper()"
>
 </div>

 <div class="anonymous-wrapper wrapper" [ngClass]="{'isAnonOpen' : mostrarWrapper}" >
  <div class="ml-1 row border-bottom mr-1">
   <div class="col-1 d-flex align-items-center">
    <div class="bi bi-person-fill " ></div>

   </div>
   <div style="font-size: 14px; " class=" bold-common-font col-10">
    SIGN IN FOR THE BEST EXPERIENCE
   </div>
  </div>

  <ul class="ml-0 mt-2" style="font-size: 14px;" >
   <li>Speedier checkout</li>
   <li>Convenient order tracking</li>
   <li>Access to your saved vehicles</li>
  </ul>
  <a href="/login" style="border-bottom: none;">

  <button type="button" class="btn btn-block btn-secondary"  style="border-bottom: none;" tabindex="0">
   Sign IN
  </button>
    </a>
    <p class="mb-0 mt-1">New to Covercraft? <a href="/login/register" class="link-secondary bold-common-font ">Create an account </a></p>

 </div>
</div>
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AbstractControl, FormBuilder, FormControl, ReactiveFormsModule, ValidatorFn } from "@angular/forms";
import { BehaviorSubject, Subscription } from "rxjs";
import { distinctUntilChanged, filter, map, skipWhile, switchMap, take, tap } from "rxjs/operators";
import { EventService, I18nModule, isNotNullable, RoutingService } from "@spartacus/core";
import { SvgIconComponent } from "src/app/components/_CUSTOM/svg/components/svg-icon/svg-icon.component";
import { Make, Model, Year, YMM } from "../../core/models/garage.model";
import { GarageService } from "../../core/facade/garage.service";
import { buildYmmCode } from "../../core/facade/ymm.service";
import { YmmFormChangedEvent } from "../../core/events/garage.events";

/**
 * YMM Form
 * Allow users to select and shop products
 * by selecting the Year, make and model of the
 * vehicle.
 *
 * Standalone component as it doesn't need
 * to be mapped for a CMS component but only
 * be embedded in another component.
 *
 * Also notice that all styles is in global scope
 * as this component might change according to its
 * placing on the page.
 */
@Component({
  selector: 'cx-ymm-form',
  templateUrl: './ymm-form.component.html',
  styleUrls: [ './ymm-form.component.scss' ],
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    I18nModule,
    SvgIconComponent
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class YmmFormComponent implements OnInit, OnDestroy {
  private subscriptions = new Subscription();
  @Input() headingTranslationKey = 'ymm.garage.protectMy';
  @Input() showHeading = true;
  @Input() displayShopButton = true;
  @Input() hideHelpText = false;
  @Input() fillActive = false;

  @Output() startShopping = new EventEmitter<YMM>();

  private _makeEvent = new BehaviorSubject<Make[]>([]);
  makes$ = this._makeEvent.asObservable();

  private _modelEvent = new BehaviorSubject<Model[]>([]);
  models$ = this._modelEvent.asObservable();


  yearControl = new FormControl<Year>(null);
  makeControl = new FormControl<Make>({ value: null, disabled: true });
  modelControl = new FormControl<Model>({ value: null, disabled: true });

  form = this.fb.group({
    year: this.yearControl,
    make: this.makeControl,
    model: this.modelControl
  }, { validators: [ this.ymmValidator() ] });


  years$ = this.garageService.getYears();


  isOpen = false;

  constructor(private fb: FormBuilder,
              private garageService: GarageService,
              private routingService: RoutingService,
              private eventService: EventService,) {
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe()
  }

  ngOnInit(): void {
    this.garageService.garageState$.subscribe( state => {
      this.isOpen = (state == 'FORM');
    });

    this.fillForm();
    this.subscriptions.add(
      this.yearControl.valueChanges
        .pipe(
          distinctUntilChanged(),
          tap(_ => this.garageService.changeYmmFormState({ status: 'INVALID' })),
          filter(isNotNullable)
        )
        .subscribe(year => {
          this.makeControl.enable();
          this.makeControl.setValue(null);
          this.modelControl.setValue(null, { emitEvent: false });
          this.modelControl.disable();
          this.garageService.getMakes(year)
            .pipe(
              skipWhile(v => !v.length),
              take(1)
            )
            .subscribe(makes => {
              this._makeEvent.next(makes);
            })
        })
    );

  }


  shop(): void {
    const ymm = this.form.value as YMM;
    this.garageService.setVehicle(ymm);
    this.startShopping.emit(ymm);
    this.garageService.getActiveVehicle().pipe(
      filter(active => active?.code === buildYmmCode(ymm)),
      take(1)
    ).subscribe(vehicle => {
      this.garageService.changeGarageState('ACTIVE');
      this.routingService.go({
        cxRoute: 'ymm', params: { ymm: vehicle.path }
      });
    });
  }


  ymmValidator(): ValidatorFn {
    return (control: AbstractControl) => {
      const value = control.value;
      return value?.year && value.make && value.model
        ? null
        : { ymm: true }
    }
  }

  toggleForm(): void {
    this.isOpen = !this.isOpen;
    this.garageService.changeGarageState(this.isOpen ? "FORM" : "ACTIVE");
  }

  onMakeChange(event: Event) {
    const value = ( event?.target as HTMLSelectElement )?.value;
    if (value) {
      this.modelControl.enable();
      this.modelControl.setValue(null, { emitEvent: false });
      this.garageService.changeYmmFormState({ status: 'INVALID' });
      this.garageService.getModels(this.yearControl.value, value)
        .pipe(
          filter(models => Boolean(models.length)),
          take(1)
        ).subscribe(models => {
        this._modelEvent.next(models);
      });
    }
  }

  onModelChange(event: Event) {
    const value = ( event?.target as HTMLSelectElement )?.value;
    if (!this.displayShopButton) {
      const ymm = this.form.value as YMM;
      if (ymm.year && ymm.make && ymm.model) {
        this.garageService.setVehicle(ymm);
        this.garageService.changeYmmFormState({ status: 'VALID', ymm });
        this.eventService.dispatch(
          new YmmFormChangedEvent({ status: 'VALID', ymm })
        );
      }
    }
  }

  private fillForm(): void {
    if (!this.fillActive) {
      this.garageService.changeYmmFormState({ status: 'INVALID' });
      this.eventService.dispatch(
        new YmmFormChangedEvent({ status: 'INVALID' })
      );
      return;
    }
    const sub = this.garageService.getActiveVehicle()
      .pipe(
        filter((active) => isNotNullable(active)),
        switchMap(vehicle => {
          const { year, make } = vehicle.ymm;
          return this.garageService.getMakes(year)
            .pipe(
              switchMap((makes) =>
                this.garageService.getModels(year, make)
                  .pipe(
                    map((models) => {
                      return {
                        vehicle,
                        makes,
                        models
                      }
                    }),
                  )
              )
            )
        }),
        tap(({ makes, models }) => {
          this._makeEvent.next(makes);
          this._modelEvent.next(models);
        }),
        take(1)
      ).subscribe(({ vehicle }) => {
      this.form.patchValue(vehicle.ymm);
      this.makeControl.enable();
      this.modelControl.enable();
      this.garageService.changeYmmFormState({ status: 'VALID', ymm: vehicle.ymm });
      this.eventService.dispatch(
        new YmmFormChangedEvent({ status: 'VALID', })
      );
    });

    this.subscriptions.add(sub);
  }
}

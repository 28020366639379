import { Component } from '@angular/core';
import { StorefrontComponent } from '@spartacus/storefront';

@Component({
  selector: 'cx-custom-store-front',
  templateUrl: './custom-store-front.component.html',
  styleUrls: ['./custom-store-front.component.scss']
})

export class CustomStoreFrontComponent extends StorefrontComponent {

}

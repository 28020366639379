import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AtMessageModule, MediaModule } from "@spartacus/storefront";
import { ActiveCartFacade, CartItemComponentOptions, MultiCartFacade, OrderEntry, SelectiveCartFacade } from "@spartacus/cart/base/root";
import { I18nModule } from "@spartacus/core";

@Component({
  selector: 'item-child-row',
  templateUrl: './item-child-row.component.html',
  styleUrls: [ './item-child-row.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    MediaModule,
    I18nModule,
    AtMessageModule
  ]
})
export class ItemChildRowComponent {
  @Input() item: OrderEntry;
  @Input() cartId: string;
  @Input() userId: string;
  @Input() readonly: boolean;
  @Input() options: CartItemComponentOptions = {
    isSaveForLater: false,
    optionalBtn: null,
    displayAddToCart: false,
  };

  constructor(private activeCartService: ActiveCartFacade,
              private selectiveCartService: SelectiveCartFacade,
              private multiCartService: MultiCartFacade) {
  }


  removeEntry(): void {
    const item = this.item;
    if (this.options.isSaveForLater) {
      this.selectiveCartService.removeEntry(item);
    } else if (this.cartId && this.userId) {
      this.multiCartService.removeEntry(
        this.userId,
        this.cartId,
        item.entryNumber as number
      );
    } else {
      this.activeCartService.removeEntry(item);
    }
  }
}

import { Component, OnInit } from "@angular/core";
import { combineLatest, Subject } from "rxjs";
import { distinctUntilChanged, filter, map, takeUntil } from "rxjs/operators";
import { CommonModule } from "@angular/common";
import { SvgIconComponent } from "../../../../components/_CUSTOM/svg/components/svg-icon/svg-icon.component";
import { SkuPageService } from "../../sku-landing-page/skupage.service";
import { GarageService } from "../../ymm/core/facade/garage.service";
import { CurrentProductService } from "@spartacus/storefront";
import { isNotNullable } from "@spartacus/core";
import { DataLayerService } from 'src/app/spartacus/features/data-layer/data-layer.service';

@Component({
  selector: 'product-fit',
  templateUrl: './product-fit.component.html',
  standalone: true,
  imports: [
    CommonModule,
    SvgIconComponent
  ]
})
export class ProductFitComponent implements OnInit {
  /**
   * Define a sku page which disable the fit component.
   */
  isSkuPage = this.skuPageService.isSKUPage();
  /**
   * The fit will be displayed if there is
   * a fit defined, calculated when a vehicle is selected.
   * This returns an object with the fit and vehicle information.
   */
  fitVehicle$ = combineLatest([
    this.garageService.getActiveVehicle(),
    this.garageService.currentFit$.pipe(filter(isNotNullable)),
    this.garageService.fitIsBusy$,
    this.currentProductService.getProduct()
  ]).pipe(
    map(([ vehicle, fit, fitIsBusy, product ]) => {
      return this.isSkuPage || !vehicle || !product.vehicleSpecific || fitIsBusy
          ? null
          : { vehicle, fit }
      }
    )
  )

  private ngUnsubscribe = new Subject<void>();

  constructor(private skuPageService: SkuPageService,
              private garageService: GarageService,
              private currentProductService: CurrentProductService,
              private dataLayerService: DataLayerService) {
  }

  ngOnInit(): void {
    this.trackNoFitment();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  private trackNoFitment(): void {
    if (!this.isSkuPage) {
      combineLatest([
        this.garageService.getActiveVehicle(),
        this.garageService.currentFit$.pipe(filter(isNotNullable)),
        this.currentProductService.getProduct()
      ]).pipe(
        takeUntil(this.ngUnsubscribe),
        distinctUntilChanged())
      .subscribe(([vehicle, fit, product]) => {
        if (vehicle && product.vehicleSpecific && !fit) {
          this.dataLayerService.noFitmentEvent();
        }
      });
    }
  }

}

<ng-container *ngIf="order$ | async as order">

   <ng-container *cxFeatureLevel="'6.4'">
   <div *ngIf="
         isGuestCustomer &&
         orderGuid &&
         order?.paymentInfo?.billingAddress?.email
       ">
   <custom-order-guest-register-form [guid]="orderGuid"
    [email]="order.paymentInfo?.billingAddress?.email"></custom-order-guest-register-form>
   </div>
   </ng-container>
   <ng-container *cxFeatureLevel="'!6.4'">
   <div *ngIf="isGuestCustomer">
   <custom-order-guest-register-form [guid]="orderGuid"
    [email]="order.paymentInfo.billingAddress.email"></custom-order-guest-register-form>
   </div>
   </ng-container>

</ng-container>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomOrderSharedOverviewComponent } from './custom-order-shared-overview/custom-order-shared-overview.component';
import { CustomOrderSharedItemsComponent } from './custom-order-shared-items/custom-order-shared-items.component';
import { CustomOrderSharedTotalsComponent } from './custom-order-shared-totals/custom-order-shared-totals.component';
import { TalkToUsCardsModule } from '../../../../../components/_SPA-Components/talk-to-us/components/talk-to-us-cards/talk-to-us-cards.module';
import { OutletModule } from '@spartacus/storefront';
import { CartSharedModule } from '@spartacus/cart/base/components';
import { CustomOrderDetailsModule } from '../custom-order-details/custom-order-details.module';
import { I18nModule } from "@spartacus/core";
import { StandardTalkToUsModule } from "../../../../../components/_SPA-Components/talk-to-us/components/standard-talk-to-us/standard-talk-to-us.module";



@NgModule({
  declarations: [
    CustomOrderSharedOverviewComponent,
    CustomOrderSharedItemsComponent,
    CustomOrderSharedTotalsComponent,
  ],
    imports: [ CommonModule, TalkToUsCardsModule, CartSharedModule, OutletModule, I18nModule, StandardTalkToUsModule ],
  exports: [
    CustomOrderSharedOverviewComponent,
    CustomOrderSharedItemsComponent,
    CustomOrderSharedTotalsComponent,
  ],
})
export class CustomOrderSharedModule {}

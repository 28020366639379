import { YMM } from "../models/garage.model";

export const buildYmmCode = ({ year, make, model }: YMM) =>
  `${ year }|${ make }|${ model }`;

export const setRequestYmm = (ymm: YMM): string =>
  `${ ymm.year }-${ ymm.make.toLowerCase() }-${ ymm.model.toLowerCase() }`
    .replace(/\s/g, '-')
    .trim()


import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from "@angular/common";
import { CurrentProductService, MediaModule } from "@spartacus/storefront";
import { I18nModule, VariantOptionQualifier, WindowRef } from "@spartacus/core";
import { SkuPageService } from 'src/app/spartacus/features/sku-landing-page/skupage.service';
import { take, shareReplay } from "rxjs/operators";

@Component({
  selector: 'cx-product-variant-color-selector',
  templateUrl: './product-variant-color-selector.component.html',
  styleUrls: [ './product-variant-color-selector.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    I18nModule,
    MediaModule
  ],
  standalone: true
})

export class CustomProductVariantColorSelectorComponent {
  @Input() selected?: VariantOptionQualifier;
  @Input() variants: VariantOptionQualifier[];
  @Output() changeVariant = new EventEmitter<VariantOptionQualifier>();
  product$ = this.currentProductService.getProduct()
      .pipe(shareReplay(1));
  isSKUPage = this.skuPageService.isSKUPage();


  constructor(private currentProductService: CurrentProductService,
              private skuPageService: SkuPageService,
              private winRef: WindowRef) {
  }

  trackBy(index: number, variant: VariantOptionQualifier): string {
    return variant.value;
  }

  handlerColorClick(variant: VariantOptionQualifier) {
    this.skuPageService.isSKUPage()
      ? this.navigateToVariant(variant.value)
      : this.changeVariant.emit(variant);
  }

  navigateToVariant(variantOptionQualifiersValue: string) {
    this.currentProductService.getProduct()
      .pipe(take(1))
      .subscribe((product) => {
        const currentPartNumber = this.skuPageService.getCurrentPartNumber();
        const findNextVariant = () => product?.variantOptions
          ?.find(option => option?.variantOptionQualifiers
            ?.find(itemQualifier => itemQualifier?.value === variantOptionQualifiersValue)) || undefined;
        const nextVariant = findNextVariant();
        const shouldNavigate = () => {
          return nextVariant &&
            nextVariant?.finalPartNumber !== undefined &&
            nextVariant?.finalPartNumber !== `undefined` &&
            nextVariant?.finalPartNumber !== currentPartNumber;
        }
        if (this.winRef.isBrowser() && shouldNavigate()) {
          this.winRef.nativeWindow.location = `/sku/${ nextVariant.finalPartNumber }`;
        }
      });

  }

  getColorName() {
    const currentProductCode = this.skuPageService.getCurrentProductCode();
    const currentColor: VariantOptionQualifier = this.skuPageService.isSKUPage() ? this.variants.find(variant => variant.sku === currentProductCode) : this.selected;
    return currentColor?.name
  }

  checkSelected(variant: VariantOptionQualifier, selected: VariantOptionQualifier) {
    const currentProductCode = this.skuPageService.getCurrentProductCode();
    return this.skuPageService.isSKUPage() ? variant.sku === currentProductCode : variant.value === selected?.value
  }
}


<ng-template [cxOutlet]="CartOutlets.ITEM">
  <ng-template [cxOutlet]="CartOutlets.ITEM_CONFIGURATOR_ISSUES"></ng-template>

  <div [ngClass]="compact ? 'cx-compact row' : 'row my-4'">
    <div class="col-2 cx-image-container">
      <a [routerLink]="item.product?.url" tabindex="0">
        <cx-media [container]="item.product?.images?.['PRIMARY']" format="product"></cx-media>
      </a>
    </div>

    <div class="cx-info col-10">
      <section class="cx-info-container row">
        <div [ngClass]="compact ? '' : ' col-md-6 col-lg-6 col-xl-6'">
          <div *ngIf="item.product?.name" class="cx-name">
            <a [routerLink]="item.product?.url" class="cx-link">
              <h3 class="bold-common-font" style="font-size: 14px;"> {{ item.product?.name }}</h3>
            </a>
          </div>

          <div class="order-mobile-price">
            <div *ngIf="item?.priceWithDiscount?.value !== item.basePrice?.value; else noDiscount" class="cx-value mt-2">
              <span class="custom-order-price text-primary" style="font-size: 14px; font-weight: 700;">
                {{ item?.priceWithDiscount.formattedValue }}
                <span class="custom-order-price__mobile-qty">QTY: {{ item.quantity }} </span>
              </span>
            </div>

            <ng-template #noDiscount>
              <div class="cx-value mt-2">
                <span class="custom-order-price text-primary">
                  {{ item?.basePrice?.formattedValue }} <span class="custom-order-price__mobile-qty">QTY:
                  {{ item.quantity }}</span>
                </span>
              </div>
            </ng-template>
          </div>

          <div class="mt-2 custom-info">
            <div *ngIf="item.yearMakeModel" class=" d-flex align-items-center" style="font-weight: 600;">
              <i class="bi bi-check-circle-fill mr-1 text-success"></i>
              {{ removePipe(item.yearMakeModel) }}
              {{ item.subModel }}
            </div>

            <div *ngIf="item?.product?.allVariantsValues?.length > 0">
              <div *ngFor="let variant of item?.product?.allVariantsValues; let i = index">
                <div *ngIf="!variant.hiddenFromUi" class="mt-1">
                  <span class="bold-common-font">{{ variant.qualifier }}: </span> <var>{{ variant.name }}</var>
                </div>
              </div>
            </div>

            <div *ngIf="item?.vehicleApp?.length >= 1">
              <div *ngFor="let option of item.vehicleApp; let i = index" class="mt-1">
                <span class="bold-common-font">Option {{ i + 1 }} - </span>
                <span [innerHTML]="option.options"></span>
              </div>
            </div>

            <div *ngIf="item.partNumber" class="mt-1">
              <span class="bold-common-font">Part #: </span> {{ item.partNumber }}
            </div>

            <div>
              <div *ngFor="let specification of item.product?.specifications" class="mt-2">
                <a [href]="envoirment.baseUrl + specification.url" class="order-item__information" target="_blank">
                  {{ specification.title }}
                </a>
              </div>
            </div>

            <div></div>
          </div>

          <ng-template [cxOutlet]="CartOutlets.ITEM_DETAILS"></ng-template>
        </div>

        <div [ngClass]="compact ? '' : ' col-sm-2 col-md-2 col-lg-2 col-xl-2'" class="custom-order-price order-mobile-hidden">

          <div *ngIf="item?.priceWithDiscount?.value !== item.basePrice?.value; else noDiscountItem" class="cx-value  text-center">
            <div class="custom-price bold-common-font text-center">
              {{ item?.priceWithDiscount?.formattedValue }}

              <div class="custom-order-price__box-discount mt-1">
                {{ item?.basePrice?.formattedValue }}
              </div>
            </div>
          </div>

          <ng-template #noDiscountItem>
            <div class="cx-value text-center">
              <span class="custom-price bold-common-font text-center">
                {{ item?.basePrice?.formattedValue }}
              </span>
            </div>
          </ng-template>
        </div>

        <div [ngClass]="compact ? '' : ' col-sm-2 col-md-2 col-lg-2 col-xl-2'" class="custom-order-price order-mobile-hidden">
          <div class="custom-order-price__box bold-common-font text-secondary text-center">
            {{ item.quantity }}
          </div>
        </div>

        <div [ngClass]="compact ? '' : ' col-sm-2 col-md-2 col-lg-2 col-xl-2'" class="custom-order-price order-mobile-hidden">
          <div *ngIf="item?.priceWithDiscount?.value !== item.basePrice?.value; else noDiscountItemTotal" class="cx-value text-center">
            <span class="custom-price bold-common-font text-center">
              {{ item?.totalPrice.formattedValue }}
            </span>
          </div>

          <ng-template #noDiscountItemTotal>
            <div class="cx-value text-center">
              <span class="custom-price bold-common-font text-center">
                {{ item?.totalPrice?.formattedValue }}
              </span>
            </div>
          </ng-template>
        </div>

        <div [ngClass]="compact ? '' : ' col-12'">
          <div class="px-3">
            <ng-container *ngFor="let child of item.children">
              <item-child-row [item]="child" [readonly]="true"></item-child-row>
            </ng-container>
          </div>
        </div>
      </section>

    </div>
  </div>
</ng-template>

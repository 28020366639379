import { Component } from '@angular/core';
import { ActiveCartFacade } from "@spartacus/cart/base/root";

@Component({
  selector: 'custom-simple-order-summary',
  templateUrl: './custom-simple-order-summary.component.html',
  styleUrls: [ './custom-simple-order-summary.component.scss' ]
})

export class CustomSimpleOrderSummaryComponent {
  cart$ = this.activeCartService.getActive();

  constructor(
    private activeCartService: ActiveCartFacade
  ) {
  }
}

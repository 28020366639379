import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { InputService } from '../../input.service.service';
import { CustomFeedbackInputError } from '../../model/input.model';

@Component({
  selector: "simple-checkbox",
  templateUrl: "./checkbox.component.html",
  styleUrls: ["./checkbox.component.scss"],
})
export class CheckboxComponent {
  @Input() form!: FormGroup;
  @Input() id!: string;
  @Input() name!: string;
  @Input() label?: string;
  @Input() required!: string;
  @Input() containerClass?: string;
  @Input() labelClass?: string;
  @Input() inputClass?: string;
  @Input() isDanger?: boolean = false;
  hasError: boolean = false;

  // constructor(private translate: TranslateService, private inputService: InputService) { }
  constructor(private inputService: InputService) {}

  ngOnInit(): void {
    // this.label && this.translate.get(this.label).subscribe(res => this.label = res);
  }

  getError(field: any, form: any): CustomFeedbackInputError {
    const errors = this.getErrors(field, form);
    this.hasError = errors.length > 0;

    return errors[0];
  }

  getErrors(field: any, form: any): CustomFeedbackInputError[] {
    return this.inputService.getErrors(field, form);
  }

  markThis() {
    const $input = this.form.get(this.name);
    const value = $input && $input.value;

    $input?.setValue(!value);
  }

  classError(field: any, form: any) {
    return {
      "is-invalid": this.getError(field, form),
      "has-feedback": this.hasError,
    };
  }
}

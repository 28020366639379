import { Component, Input } from '@angular/core';
import { ProductListComponentService } from '@spartacus/storefront';
import { map } from 'rxjs/operators';

@Component({
  selector: 'no-results-found',
  templateUrl: './no-results-found.component.html',
})
export class NoResultsFoundComponent {

  model$ = this.productListComponentService.model$;
  pagination$ = this.model$.pipe(
    map((model) => {
      const { currentPage, pageSize, totalPages, totalResults } =
        model.pagination;
      return {
        firstResult: currentPage * pageSize + 1,
        lastResult:
          totalPages <= 1 || currentPage + 1 === totalPages
            ? totalResults
            : (currentPage + 1) * pageSize,
        totalResults,
        freeTextSearch: model.freeTextSearch,
      };
    })
  );

  constructor(
    private productListComponentService: ProductListComponentService
  ) {}

  sortList(sortCode: string): void {
    this.productListComponentService.sort(sortCode);
  }
}

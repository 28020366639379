import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomProductSummaryComponent } from './product-summary.component';
import { CmsConfig, I18nModule, provideConfig } from '@spartacus/core';
import { leadtimeDetailsModalConfig } from './product-summary-modal-config';
import { LeadtimeDetailsModule } from './leadtime-details-modal/leadtime-details.module';

@NgModule({
  declarations: [
    CustomProductSummaryComponent
  ],
  imports: [
    CommonModule,
    I18nModule,
    LeadtimeDetailsModule
  ],
  providers: [
    provideConfig(leadtimeDetailsModalConfig),
    provideConfig(<CmsConfig>{
      cmsComponents: {
        ProductSummaryComponent: {
          component: CustomProductSummaryComponent,
        },
      },
    }),
  ],
})
export class CustomProductSummaryModule { }

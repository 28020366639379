import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CmsConfig, I18nModule, provideConfig, provideDefaultConfig } from '@spartacus/core';
import { PromotionsModule } from '@spartacus/storefront';
import { CustomCheckoutReviewOverviewComponent } from './custom-checkout-review-overview.component';
import { CheckoutAuthGuard, CartNotEmptyGuard } from '@spartacus/checkout/base/components';
import { CustomCartPromotionModule } from '../../../cart/components/custom-cart-promotion/custom-cart-promotion.module';

@NgModule({
  declarations: [CustomCheckoutReviewOverviewComponent],
  imports: [
    CommonModule,
    PromotionsModule,
    I18nModule,
    CustomCartPromotionModule,
  ],
  exports: [CustomCheckoutReviewOverviewComponent],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        CheckoutReviewOverview: {
          component: CustomCheckoutReviewOverviewComponent,
          guards: [CheckoutAuthGuard, CartNotEmptyGuard],
        },
      },
    }),
  ],
})
export class CustomCheckoutReviewOverviewModule {}

import { ChangeDetectionStrategy, Component, Inject, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { combineLatest } from "rxjs";
import { map } from "rxjs/operators";
import { ProductConfigStepType, ProductLine, ProductLineOption } from "../../core/models/product-configuration.models";
import { ProductLineSelection } from "../../core/store/product-configuration.state";
import { ProductConfigurationService } from "../../core/facade/product-configuration.service";
import { ProductConfiguratorStepsService } from "../../core/facade/product-configurator-steps.service";
import { STEP_INDEX } from '../../dialog/product-config-dialog/product-config-dialog.component';
import { WindowRef } from '@spartacus/core';


@Component({
  selector: 'product-line',
  templateUrl: './product-line.component.html',
  styleUrls: ['./product-line.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class ProductLineComponent {

  line$ = combineLatest([
    this.stepService.currentStep$,
    this.productLinesService.getConfigurationForCurrentProduct(),
    this.productLinesService.getConfigSelection(),
  ]).pipe(
    map(([step, productLines, config]) => {
      const productLine = productLines?.steps[this.step - 1] as ProductLine;
      const selected = config?.selections?.find(
        (s) =>
          s.stepType === ProductConfigStepType.PRODUCT_LINES &&
          (s as ProductLine).id === productLine.id
      ) as ProductLineSelection;
      return {
        productLine,
        selected,
      };
    })
  );

  constructor(
    private productLinesService: ProductConfigurationService,
    private stepService: ProductConfiguratorStepsService,
    private winRef: WindowRef,
    @Inject(STEP_INDEX) public step: number
  ) {}

  selectItem(opt: ProductLineOption, productLine: ProductLine, nextStep: boolean) {

    this.productLinesService.toggleProductLine(productLine, opt);
    if (nextStep) {
        if (this.winRef.isBrowser()) {          
          const $elements = document.querySelectorAll('.js-show-container');
          $elements.forEach(function ($el) {
            $el.classList.remove('show-container');
          });
        }
        setTimeout(()=>{
          this.stepService.next();
        },1000)
    }

  }
}

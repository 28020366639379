import { Component } from '@angular/core';
import { CmsService } from '@spartacus/core';
import { CmsComponentData, PageLayoutService } from '@spartacus/storefront';

@Component({
  selector: 'page-title',
  templateUrl: './page-title.component.html',
  styleUrls: ['./page-title.component.scss']
})

export class PageTitleComponent {
  component$ = this.component.data$;
  title$ = this.pageLayoutService.page$;

  constructor (
    private pageLayoutService: PageLayoutService,
    private component: CmsComponentData<any>
  ) {}
}

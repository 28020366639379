import { Component } from '@angular/core';
import { CartItemContextSource, CartItemListRowComponent } from '@spartacus/cart/base/components';
import { ListrakService } from '../../../listrak/core/listrak.service';
import { Subscription } from 'rxjs';
import { ActiveCartFacade } from "@spartacus/cart/base/root";
import { Product, WindowRef } from '@spartacus/core';
import { DataLayerService } from 'src/app/spartacus/features/data-layer/data-layer.service';


@Component({
  selector: '[custom-cart-item-list-row], custom-cart-item-list-row',
  templateUrl: './custom-item-list-row.component.html',
})
export class CustomItemListRowComponent extends CartItemListRowComponent {

  constructor(
    cartItemContextSource: CartItemContextSource,
    private activeCartService: ActiveCartFacade,
    private listrakService: ListrakService,
    private winRef: WindowRef,
    private dataLayerService: DataLayerService
  ) {
    super(cartItemContextSource)
  }


  getItemUpdatable() {
    return this.item.updateable;
  }

  removePipe(value: string): string {
    return value.replace(/\|/g, ' ');
  }

  override removeItem() {
    const subscription = new Subscription();

    subscription.add(
      this.activeCartService.getActive().subscribe(cart => {
        subscription.unsubscribe();

        (cart.totalItems - 1) === 0 && this.listrakService.clearCart();
        this.quantityControl.setValue(0);
        this.quantityControl.markAsDirty();
      })
    );
  }

  RemoveCartItem(product: Product){
    const subscription = new Subscription();
    subscription.add(
      this.activeCartService.getActive().subscribe(cart => {
        subscription.unsubscribe();

        (cart.totalItems - 1) === 0 && this.listrakService.clearCart();
          
        this.dataLayerService.removeFromCartEvent(product, this.quantityControl.value);
        this.quantityControl.setValue(0);
        this.quantityControl.markAsDirty();
      })
    );
  }

}

import { Component } from '@angular/core';
import { Product } from '@spartacus/core';
import { CurrentProductService } from '@spartacus/storefront';
import { Observable } from 'rxjs';

@Component({
  selector: 'custom-product-stamps',
  templateUrl: './custom-product-stamps.component.html',
  styleUrls: ['./custom-product-stamps.component.scss'],
})
export class CustomProductStampsComponent {
  product$: Observable<Product>;

  constructor(currentProductService: CurrentProductService) {
    this.product$ = currentProductService.getProduct()
  }
}

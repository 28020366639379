import { ChangeDetectionStrategy, Component, HostListener } from '@angular/core';
import { LaunchDialogService } from '@spartacus/storefront';

@Component({
  selector: 'cx-leadtime-details',
  templateUrl: './leadtime-details.component.html',
  styleUrls: [ './leadtime-details.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LeadtimeDetailsModalComponent{
  source$ = this.dialogService.data$

  constructor(
    private dialogService: LaunchDialogService,
  ) {}

  @HostListener('document:keydown.escape')
  closeOnEscape(): void {
    this.close();
  }

  close() {
    this.dialogService.closeDialog(null);
  }
}

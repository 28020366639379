<ng-container *ngIf="{
  component: component$ | async,
  title: title$ | async
} as data">
  <div class="{{data.component.styleClasses}} container-page-title">
    <h1 class="custom-page-title">
      {{data.title.title}}
    </h1>
  </div>
</ng-container>

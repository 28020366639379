import { NgModule } from "@angular/core";
import { provideConfig } from "@spartacus/core";
import { recaptchaOccConfig } from "./recaptcha-occ.config";

@NgModule({
  providers: [
    provideConfig(recaptchaOccConfig)
  ]
})
export class RecaptchaModule {

}

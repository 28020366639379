import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomProductFacetNavigationComponent } from './custom-product-facet-navigation.component';
import { CmsConfig, ConfigModule, I18nModule } from '@spartacus/core';
import { IconModule } from '@spartacus/storefront';
import { CustomFacetListModule } from './custom-facet-list/custom-facet-list.module';

@NgModule({
  imports: [
    CommonModule,
    IconModule,
    I18nModule,
    CustomFacetListModule,
    ConfigModule.withConfig(<CmsConfig>{
      cmsComponents: {
        ProductRefinementComponent: {
          component: CustomProductFacetNavigationComponent,
        },
      },
    }),
  ],
  declarations: [CustomProductFacetNavigationComponent],
  exports: [CustomProductFacetNavigationComponent],
})
export class CustomProductFacetNavigationModule { }

<ng-template cxOutlet="cx-footer">
  <footer class="cx-footer" cxSkipLink="cx-footer" [cxFocus]="{ disableMouseFocus: true }">
    <div class="container">
      <div class="row m-b-40">
        <div class="col-md-6">
          <cx-page-slot position="FooterJoinTheCommunity"></cx-page-slot>
        </div>

        <div class="col-md-6 m-t-sm-40">
          <cx-page-slot position="FooterLastestNews"></cx-page-slot>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6 m-b-80 m-b-sm-20">
          <cx-page-slot position="FooterBannerParagraph"></cx-page-slot>
        </div>

        <div class="col-md-6">
          <div class="row">
            <div class="col-md-12 m-t-20">
              <cx-page-slot position="FooterNavigationComponent"></cx-page-slot>
            </div>

            <div class="col-md-12">
              <cx-page-slot position="JsScriptVerifiedSeals"></cx-page-slot>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12 m-t-40">
          <cx-page-slot position="NoticeText"></cx-page-slot>
        </div>
      </div>
    </div>

    <cx-page-slot position="AnonymousConsentManagementBannerComponent"></cx-page-slot>
  </footer>
</ng-template>

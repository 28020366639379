import { OccConfig } from "@spartacus/core";

export const productConfiguratorOccConfig = {
  backend: {
    occ: {
      endpoints: {
        subModels: '/products/submodels',
        subModelOptions: '/products/options',
        productLines: '/products/product-lines',
        configurationSteps: '/products/configuration-steps'
      }
    }
  }
} as OccConfig

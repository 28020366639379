<ng-container *ngIf="banners$ | async as banners">
  <div class="family-covercraft">
    <div class="container col-12 mx-0">
      <div class="row">
        <div class="col-12 col-sm-6 family-covercraft__header">
          <h2 class="family-covercraft__title ">
            {{banner.title}}
          </h2>
        </div>
        <div class="col-12 col-sm-6 family-covercraft__header">
          <p class="family-covercraft__paragraph mt-4 ">
            {{banner.descriptionLong}}
          </p>
        </div>
      </div>
      <div class="family-covercraft__images w-100 family-covercraft__grid">
        <ng-container *ngFor="let item of banners">
          <family-covercraft-item [banner]="item"></family-covercraft-item>
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>
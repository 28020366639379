import { Component } from '@angular/core';
import { ProductFacetNavigationComponent } from '@spartacus/storefront';

@Component({
  selector: 'cx-product-facet-navigation',
  templateUrl: './custom-product-facet-navigation.component.html',
  styleUrls: ['./custom-product-facet-navigation.component.scss']
})
export class CustomProductFacetNavigationComponent extends ProductFacetNavigationComponent {

}

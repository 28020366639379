import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CmsNavigationComponent } from '@spartacus/core';
import { CmsComponentData, NavigationComponent, NavigationService } from '@spartacus/storefront';
import { Observable } from 'rxjs';

@Component({
  selector: 'cx-navigation',
  templateUrl: './custom-navigation.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomNavigationComponent extends NavigationComponent {

  

}

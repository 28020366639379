<cx-spinner class="overlay" *ngIf="isUpdating$ | async"> </cx-spinner>

<!-- <span class="h2 m-b-8 bold-common-font covercraft-header">
  {{'customUpdatePassword.title' | cxTranslate}}
</span> -->

<form (ngSubmit)="onSubmit()" [formGroup]="form">
  <label>
    <span class="label-content">{{
      'updatePasswordForm.oldPassword.label' | cxTranslate
    }}</span>
    <input
      required="true"
      class="form-control"
      type="password"
      name="oldPassword"
      placeholder="{{
        'updatePasswordForm.oldPassword.placeholder' | cxTranslate
      }}"
      formControlName="oldPassword"
      [attr.aria-label]="
        'updatePasswordForm.oldPassword.placeholder' | cxTranslate
      "
      cxPasswordVisibilitySwitch
    />
    <cx-form-errors [control]="form.get('oldPassword')"></cx-form-errors>
  </label>

  <label>
    <span class="label-content">{{
      'updatePasswordForm.newPassword.label' | cxTranslate
    }}</span>
    <input
      required="true"
      class="form-control"
      type="password"
      name="newPassword"
      placeholder="{{
        'updatePasswordForm.newPassword.placeholder' | cxTranslate
      }}"
      formControlName="newPassword"
      [attr.aria-label]="
        'updatePasswordForm.newPassword.placeholder' | cxTranslate
      "
      cxPasswordVisibilitySwitch
    />
    <cx-form-errors [control]="form.get('newPassword')"></cx-form-errors>
  </label>

  <label>
    <span class="label-content">{{
      'updatePasswordForm.confirmPassword.label' | cxTranslate
    }}</span>
    <input
      required="true"
      class="form-control"
      type="password"
      name="newPasswordConfirm"
      placeholder="{{
        'updatePasswordForm.confirmPassword.placeholder' | cxTranslate
      }}"
      formControlName="newPasswordConfirm"
      [attr.aria-label]="
        'updatePasswordForm.confirmPassword.placeholder' | cxTranslate
      "
      cxPasswordVisibilitySwitch
    />
    <cx-form-errors [control]="form.get('newPasswordConfirm')"></cx-form-errors>
  </label>

  <hr aria-hidden="true" class="divider-line"/>

  <button class="btn btn-block btn-secondary font-weight-bolder" [disabled]="form.disabled">
    {{ 'customUpdatePassword.save' | cxTranslate }}
  </button>
</form>

import { NgModule } from "@angular/core";
import { StandardTalkToUsComponent } from "./standard-talk-to-us.component";
import { CommonModule, NgIf } from "@angular/common";
import { PageComponentModule } from "@spartacus/storefront";

@NgModule({
  imports: [
    CommonModule,
    PageComponentModule
  ],
  declarations: [ StandardTalkToUsComponent ],
  exports: [ StandardTalkToUsComponent ],
})
export class StandardTalkToUsModule {

}

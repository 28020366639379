import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input } from '@angular/core';
import { FacetComponent, FacetService } from '@spartacus/storefront';
import { Facet } from './custom-facet.types';
import { CmsService, FacetValue } from "@spartacus/core";
import { take } from "rxjs/operators";

@Component({
  selector: 'cx-facet',
  templateUrl: './custom-facet.component.html',
  styleUrls: ['./custom-facet.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class CustomFacetComponent extends FacetComponent {
  isRatingRange: boolean = false;
  isCommonColor: boolean = false;
  isYmmLevelOne : boolean = false;
  ratingsNumbers: number[] = [];
  colorsImages: { [name: string]: string } = {};
  facetValues: FacetValue[] = [];
  @Input() url: string;

  constructor(
    protected cmsService: CmsService,
    facetService: FacetService,
    elementRef: ElementRef<HTMLElement>,
    cd: ChangeDetectorRef,
  ) {
    super(facetService, elementRef, cd);
  }

  // TODO: Refactory
  ngOnInit(): void {
    this.isRatingRange = this.facet.name == 'Review Rating Range';
    this.isCommonColor = this.facet.name == 'Color';

    this.cmsService.getCurrentPage().pipe(take(1)).subscribe(page => {
      if (page.template === 'YMMCategoryPageTemplate' && page.label === 'shop' && this.facet.code === 'levelOneCategories') {
        this.isYmmLevelOne = true;
      }
    })

    if (this.isRatingRange) {
      this.facet.values?.forEach((value, index) => {
        const newValue = {...value};
        const firstNumber = /[0-9]/.exec(value.name);
        if (firstNumber?.length) {
          newValue.rating = +firstNumber[0];
        }
        if (value.name) {
          const number = Number(value.name?.at(0));
          this.ratingsNumbers[index] = !Number.isNaN(number) ? number : 1;
        }
        this.facetValues.push(newValue);
      });
    }
  }

  getLevelOneUrl (value: FacetValue) {
    let cName = value.code;
    return '/' + cName;
  }

  @Input()
  override set facet(value: Facet) {
    this._facet = value;
    this.isMultiSelect = !!value.multiSelect;
    this.state$ = this.facetService.getState(value);
  }

  override get facet(): Facet {
    return this._facet;
  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomProductInstallationTabComponent } from './custom-product-installation-tab.component';
import { CmsConfig, I18nModule, provideConfig } from '@spartacus/core';
import { OutletModule, PageComponentModule } from '@spartacus/storefront';
import { SafeURLModule } from 'src/app/components/pipes/safe-url/safe-url.module';
import { StandardTalkToUsModule } from "../../../../../components/_SPA-Components/talk-to-us/components/standard-talk-to-us/standard-talk-to-us.module";

@NgModule({
  imports: [
    CommonModule,
    PageComponentModule,
    OutletModule,
    I18nModule,
    SafeURLModule,
    StandardTalkToUsModule,
  ],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        ProductInstallationTabComponent: {
          component: CustomProductInstallationTabComponent,
        },
      },
    }),
  ],
  declarations: [ CustomProductInstallationTabComponent ],
  exports: [ CustomProductInstallationTabComponent ],
})

export class CustomProductInstallationTabModule {
}

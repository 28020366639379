import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";
import { CmsBannerLOQComponentEntry } from "../../banner-legacy-of-quality.model";
import { BreakpointService } from "@spartacus/storefront";
import { environment } from "../../../../../../../../environments/environment";
import { map } from "rxjs/operators";
import { breakpointMediaMapKey } from "../../../../../../../components/utils/cms-utils";

@Component({
  selector: 'cx-banner-loq-item',
  templateUrl: './banner-loq-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BannerLegacyOfQualityItemComponent {
  /**
   * The banner with all necessary configuration
   */
  @Input() banner: CmsBannerLOQComponentEntry | undefined;
  /**
   * Background for the banner is calculated using
   * the current breakpoint.
   */
  bg$ = this.breakpointService.breakpoint$.pipe(
    map(breakpoint => {
      if (!this.banner?.media) {
        return {};
      }
      const mediaKey = breakpointMediaMapKey.get(breakpoint) ?? 'desktop';
      return { 'background-image': `url(${ environment.baseUrl }${ this.banner?.media[ mediaKey ]?.url })` };
    })
  );

  constructor(private breakpointService: BreakpointService) {
  }

}

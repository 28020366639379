<div class="container-radio  {{containerClass}}" [formGroup]="form">
  <label class="mb-1 {{labelClass}}" *ngIf="label">
    {{label}} {{required ? '*' : ''}}
  </label>

  <div class="form-check-container">
    <div class="form-check {{inputClass}} {{isDanger? 'danger-checkradio': 'default-checkradio'}}"
      [ngClass]="classError()" *ngFor="let option of options">
      <input class="form-check-input" type="radio" [value]="option.code" [formControlName]="name"
        [attr.id]="id + '-' + option.code" (change)="selectValue(option)" />
      <label class="form-check-label" [attr.for]="id + '-' + option.code"> {{option.value}} </label>
    </div>
  </div>

  <div class="invalid-feedback" [ngClass]="{'d-block': getError()}" *ngIf="{error: getError()} as dataError">
    <span *ngIf="dataError?.error?.params === undefined">{{ getError().description | cxTranslate}}</span>
    <span *ngIf="dataError?.error?.params"> {{ dataError.error && dataError.error.description| cxTranslate:{param:
      dataError.error.params} }}
    </span>
  </div>

</div>
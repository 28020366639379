import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconAndroidComponent } from './icon-android/icon-android.component';
import { IconAppleComponent } from './icon-apple/icon-apple.component';
import { IconPlusComponent } from './icon-plus/icon-plus.component';
import { IconMinusComponent } from './icon-minus/icon-minus.component';
import { TalkToUsIconComponent } from './talk-to-us-icon/talk-to-us-icon.component';
import { PhoneIconComponent } from './phone-icon/phone-icon.component';
import { DeliveryTruckIconComponent } from './delivery-truck-icon/delivery-truck-icon.component';
import { ShippingAlertIconComponent } from './shipping-alert-icon/shipping-alert-icon.component';
import { FreeShippingIconComponent } from './free-shipping-icon/free-shipping-icon.component';
import { LockPlaceOrderIconComponent } from './lock-place-order-icon/lock-place-order-icon.component';

@NgModule({
  declarations: [
    IconAndroidComponent,
    IconAppleComponent,
    IconPlusComponent,
    IconMinusComponent,
    TalkToUsIconComponent,
    PhoneIconComponent,
    DeliveryTruckIconComponent,
    ShippingAlertIconComponent,
    FreeShippingIconComponent,
    LockPlaceOrderIconComponent,
  ],
  imports: [CommonModule],
  exports: [
    IconAndroidComponent,
    IconAppleComponent,
    IconPlusComponent,
    IconMinusComponent,
    TalkToUsIconComponent,
    PhoneIconComponent,
    DeliveryTruckIconComponent,
    LockPlaceOrderIconComponent,
    ShippingAlertIconComponent,
    FreeShippingIconComponent,
  ],
})
export class IconsModule {}

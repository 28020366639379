import { DIALOG_TYPE, LayoutConfig } from "@spartacus/storefront";
import { LeadtimeDetailsModalComponent } from "./leadtime-details-modal/leadtime-details.component";

export const leadtimeDetailsModalConfig: LayoutConfig = {
  launch: {
    'LEADTIME_DETAILS': {
      inlineRoot: true,
      component: LeadtimeDetailsModalComponent,
      dialogType: DIALOG_TYPE.DIALOG,
    }
  }
};
import { Component, ElementRef, HostListener } from '@angular/core';
import { NavigationUIComponent } from '@spartacus/storefront';

@Component({
  selector: 'app-anonymous-acount-icon',
  templateUrl: './anonymous-acount-icon.component.html',
  styleUrls: ['./anonymous-acount-icon.component.scss'],
})
export class AnonymousAcountIconComponent {
  mostrarWrapper = false;

  constructor(private elRef: ElementRef) {}

  @HostListener('document:click', ['$event'])
  handleClickOutside(event: Event) {

      if (this.mostrarWrapper === true) {
              this.hideDivWrapper();

      }
    
  }


  showDivWrapper() {
    this.mostrarWrapper = true;
  }

  hideIfOpen() {
    if (this.mostrarWrapper === true) {
      this.hideDivWrapper();
    }
  }

  hideDivWrapper() {
    this.mostrarWrapper = false;
  }
}

import { ChangeDetectionStrategy, Component, HostListener, inject } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { CmsComponentData } from "@spartacus/storefront";
import { CmsParagraphComponent } from "@spartacus/core";
import { Router } from "@angular/router";
import { ViewportScroller } from "@angular/common";

@Component({
  selector: 'cx-paragraph',
  templateUrl: './custom-paragraph.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomParagraphComponent {
  protected sanitizer = inject(DomSanitizer);

  @HostListener('click', [ '$event' ])
  public handleClick(event: Event): void {
    if (event.target instanceof HTMLAnchorElement) {
      const element = event.target as HTMLAnchorElement;
      const href = element?.getAttribute('href');
      if (href.indexOf('#') >= 0) {
        const urlParts = href.split('#');
        if (!urlParts[ 0 ] || urlParts[ 0 ] === element.ownerDocument.URL) {
          event.preventDefault();
          this.viewportScroller.scrollToAnchor(urlParts[ urlParts.length - 1 ]);
          return;
        }
      }
      const documentHost = element.ownerDocument.URL
        .split('://')[ 1 ]
        .split('/')[ 0 ];
      if (href && documentHost === element.host) {
        event.preventDefault();
        this.router.navigateByUrl(href);
      }
    }
  }

  constructor(public component: CmsComponentData<CmsParagraphComponent>,
              protected router: Router,
              private viewportScroller: ViewportScroller) {
  }

  public bypassSecurityTrustHtml(html: string = ''): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

}

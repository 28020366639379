import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActiveCartFacade } from '@spartacus/cart/base/root';
import { CheckoutLoginComponent } from '@spartacus/checkout/base/components';
import { AuthRedirectService } from '@spartacus/core';
import { CustomFormValidators } from '@spartacus/storefront';
import { DataLayerService } from 'src/app/spartacus/features/data-layer/data-layer.service';


@Component({
  selector: 'app-custom-checkout-login',
  templateUrl: './custom-checkout-login.component.html',
})
export class CustomCheckoutLoginComponent extends CheckoutLoginComponent {
  override checkoutLoginForm: UntypedFormGroup = this.formBuilder.group(
    {
      email: ['', [Validators.required, CustomFormValidators.emailValidator]],
    },
  );

  constructor(
    formBuilder: UntypedFormBuilder,
    authRedirectService: AuthRedirectService,
    activeCartFacade: ActiveCartFacade,
    private dataLayerService: DataLayerService
  ) {
    super(formBuilder, authRedirectService, activeCartFacade);
  }

  ngOnInit(): void {
    this.dataLayerService.preCheckoutEvent();
  }
}

import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { map, mergeMap, switchMap, tap } from "rxjs/operators";
import { ProductConfigurationActions } from "./index";
import { ProductConfiguratorAdapter } from "../../occ/product-configuration-adapter";
import { Store } from "@ngrx/store";
import { ProductConfigurationState } from "./product-configuration.state";

@Injectable({ providedIn: 'root' })
export class ProductConfigurationEffect {
  constructor(private actions$: Actions,
              private adapter: ProductConfiguratorAdapter,
              private store: Store<ProductConfigurationState>) {
  }

  loadSubmodels$ = createEffect(
    () => this.actions$.pipe(
      ofType(ProductConfigurationActions.loadSubmodels),
      switchMap(({productCode, productLine, vehicleCode }) =>
        this.adapter.loadSubModels(productCode, productLine).pipe(
          map(({ submodels }) =>
            ProductConfigurationActions.loadSubmodelsOk({
              productCode,
              productLine,
              submodels,
              vehicleCode
            })
          )
        )
      )
    )
  );

  loadOptions$ = createEffect(
    () => this.actions$.pipe(
      ofType(ProductConfigurationActions.loadSubModelOptions),
      switchMap(({ productLine, subModel, vehicle }) =>
        this.adapter.loadOptions(productLine, subModel).pipe(
          map(({ options }) =>
            ProductConfigurationActions.loadSubModelOptionsOk({
              productLine,
              subModel,
              options,
              vehicle
            })
          )
        )
      )
    )
  );

  loadProductLines$ = createEffect(
    () => this.actions$.pipe(
      ofType(ProductConfigurationActions.loadProductConfiguration),
      tap(_ => this.store.dispatch(
        ProductConfigurationActions.loadProductConfigurationLoading({ loading: true })
      )),
      switchMap(({ payload }) =>
        this.adapter.loadConfigurationSteps(payload).pipe(
          mergeMap((configurator) => [
              ProductConfigurationActions.loadProductConfigurationOk({ config: configurator }),
              ProductConfigurationActions.loadProductConfigurationLoading({ loading: false })
            ]
          )
        )
      )
    )
  );

}

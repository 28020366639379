import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ImageGroup, Product, VariantOptionQualifier } from "@spartacus/core";
import { tap } from "rxjs/operators";
import { ConfiguratorVariantService } from "../../core/configurator-variant.service";
import { ProductDetailsPrice } from "../../../../../core/models/product-details.models";
import { AddonVariant } from "../../core/addons-model";
import { deepCloning } from "../../../../../../../../tools/tools";

@Component({
  selector: 'addons-variants',
  templateUrl: './addons-variants.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ ConfiguratorVariantService ]
})
export class AddonsVariantsComponent implements OnInit {
  @Input() product: Product;
  @Output() imageChange = new EventEmitter<ImageGroup>();
  @Output() priceChange = new EventEmitter<ProductDetailsPrice>();
  @Output() variantChanges = new EventEmitter<{ option: VariantOptionQualifier, qualifier: string }>();
  @Output() addonVariantChanges = new EventEmitter<AddonVariant[]>();
  @Input() selection: AddonVariant[] = [];

  variants$ = this.service.variants$
    .pipe(
      tap(variants => {
        this.addonVariantChanges.emit(variants);
        const withImages = variants.filter(v => !!v.gallery);
        const withPrice = variants.filter(v => !!v.selected);
        if (withPrice?.length) {
          this.priceChange.emit(withPrice[ withPrice.length - 1 ].price);
        }
        if (withImages?.length) {
          this.imageChange.emit(withImages[ withImages.length - 1 ]?.gallery);
        }
      })
    );


  constructor(private service: ConfiguratorVariantService) {
  }

  ngOnInit(): void {
    if (this.selection?.length) {
      this.service.setAddonVariantState(deepCloning(this.selection));
    } else {
      this.service.startVariants(this.product);
    }
  }

  trackByFn(i: number, item: AddonVariant): string {
    return item.qualifier;
  }

  onVariantChange(option: VariantOptionQualifier, addonVariant: AddonVariant) {
    this.service.setVariant(this.product.code, addonVariant.qualifier, option);
    this.variantChanges.emit({ option, qualifier: addonVariant.qualifier });
  }
}


import { Component, Input } from '@angular/core';
import { Order } from '@spartacus/order/root';

@Component({
  selector: 'custom-order-shared-totals',
  templateUrl: './custom-order-shared-totals.component.html',
})
export class CustomOrderSharedTotalsComponent {
  @Input() order: Order
}

import { NgModule } from '@angular/core';
import { SimpleInputComponent } from './components/simple-input/simple-input.component';
import { NgxMaskModule } from 'ngx-mask';
import { SimpleSelectComponent } from './components/simple-select/simple-select.component';
import { RadioComponent } from './components/radio/radio.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FloatSelectComponent } from './components/float-select/float-select.component';
import { IconModule } from '@spartacus/storefront';
import { SimpleToggleModule } from './components/simple-toggle/simple-toggle.module';
import { I18nModule } from '@spartacus/core';
import { SimpleTextAreaComponent } from './components/simple-text-area/simple-text-area.component';


@NgModule({
  declarations: [
    SimpleInputComponent,
    SimpleSelectComponent,
    RadioComponent,
    CheckboxComponent,
    FloatSelectComponent,
    SimpleTextAreaComponent,
  ],
  imports: [
    CommonModule,
    I18nModule,
    FormsModule,
    ReactiveFormsModule,
    IconModule,
    SimpleToggleModule,
    NgxMaskModule.forRoot(),

  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    SimpleInputComponent,
    SimpleSelectComponent,
    RadioComponent,
    CheckboxComponent,
    FloatSelectComponent,
    SimpleToggleModule,
    SimpleTextAreaComponent
  ],
})
export class CustomFormModule {}

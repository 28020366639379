import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CurrentProductService, LaunchDialogService } from "@spartacus/storefront";
import { distinctUntilKeyChanged, filter, map, shareReplay } from "rxjs/operators";
import { combineLatest } from "rxjs";
import { ProductDetailsService } from "../../../core/facade/product-details.service";
import { ProductConfiguratorStepsService } from "../../core/facade/product-configurator-steps.service";
import { GarageService } from "../../../../ymm/core/facade/garage.service";
import { ProductConfigurationService } from "../../core/facade/product-configuration.service";
import { ManagerFactoryService } from "../../core/facade/manager-factory.service";
import { isNotNullable } from "@spartacus/core";
import { isColor } from "../../core/facade/product-configuration.tools";
import { ProductPriceService } from "../../../core/facade/product-price.service";

@Component({
  selector: 'product-lines-dialog-header',
  templateUrl: './product-config-dialog-header.component.html',
  styleUrls: [ './product-config-dialog-header.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductConfigDialogHeaderComponent {
  product$ = this.currentProductService.getProduct().pipe(
    filter(isNotNullable),
    distinctUntilKeyChanged('code'),
    shareReplay(1)
  );

  isVehicle$ = this.stepService.currentStep$.pipe(
    map(step => step === 1)
  )

  price$ = combineLatest([
    this.productLinesService.getConfigSelection(),
    this.product$,
    this.productPriceService.price$
  ]).pipe(
    map(([ configSelection, product, variantPrice ]) =>
      this.manager.getPrice(product, configSelection.selections, variantPrice))
  )

  swatch$ = this.productDetailsService.getSelectedVariants()
    .pipe(
      map(variants =>
        variants.find(v => isColor(v.qualifier))
      )
    );

  data$ = combineLatest([
    this.product$,
    this.isVehicle$,
    this.swatch$,
    this.price$,
    this.garageService.getActiveVehicle()
  ]).pipe(
    map(([ product, isVehicleStep, swatch, price, vehicle ]) =>
      ( { product, isVehicleStep, swatch, price, vehicle } ))
  );

  constructor(private dialog: LaunchDialogService,
              private currentProductService: CurrentProductService,
              private stepService: ProductConfiguratorStepsService,
              private productDetailsService: ProductDetailsService,
              private garageService: GarageService,
              private productLinesService: ProductConfigurationService,
              private productPriceService: ProductPriceService,
              private manager: ManagerFactoryService) {
  }

  close(): void {
    this.stepService.setStep(1);
    this.dialog.closeDialog('close');
  }

  changeVehicle(): void {
    this.stepService.setStep(1);
  }
}

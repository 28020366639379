<ul class="cx-product-summary" *ngIf="product$ | async as product">
  <li>
    <span class="medium-common-font">
      <strong class="extra-bold-common-font" *ngIf="product.customMade">
        {{ 
          'productSummary.customMade' | cxTranslate
        }}
      </strong>
      <span *ngIf="product.leadTime">
        {{ 
          'productSummary.shipWithinBusinessDays' | cxTranslate: {
            days: product.leadTime
          }
        }}
      </span>
    </span>
    <img src="../../../../../assets/static/info-circle.svg" alt="info-icon" (click)="openLeadtimeDetails()" class="leadtime-details" />
  </li>

  <li *ngIf="product.calloutMessage">
    <span class="medium-common-font" [innerHTML]="product.calloutMessage" ></span>
  </li>

  <li>
    <div class="divider"></div>
  </li>
</ul>
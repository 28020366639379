import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CmsService, isNotNullable, Product } from "@spartacus/core";
import { map } from "rxjs/operators";
import { ProductDetailsPrice } from "../core/models/product-details.models";
import { SkuPageService } from "../../sku-landing-page/skupage.service";

@Component({
  selector: 'cx-product-price',
  templateUrl: './product-price.component.html',
  styleUrls: [ './product-price.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class ProductPriceComponent {
  /**
   * TODO: Remove this property and work only with the price
   */
  @Input() product: Product;
  @Input() price: ProductDetailsPrice;
  @Input() displayPriceFrom: boolean | undefined;

  get displayFrom(): boolean {
    return this.skuPageService.isSKUPage()
      ? false
      : isNotNullable(this.displayPriceFrom)
        ? this.displayPriceFrom
        : this.product.displayPriceFrom;
  }

  pageId$ = this.cmsService.getCurrentPage().pipe(map(page => page.pageId));

  constructor(private cmsService: CmsService,
              private skuPageService: SkuPageService) {
  }

  get activePrice() {
    return this.price &&
    Object.keys(this.price).length > 0
      ? this.price.prices
      : {
        price: this.product.price,
        priceWithDiscount: this.product.priceWithDiscount,
        discountPercentage: this.product.discountPercentage,
        discount: this.product.discount
      };
  }
}

import { common, translation } from './translations-en';
import { product } from './product';
import { productLines, ymm } from "./ymm";
import { address } from "./address";

export const en = {
  common,
  product,
  translation,
  ymm,
  productLines,
  address
};

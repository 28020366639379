import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActiveCartFacade } from "@spartacus/cart/base/root";
import { map } from "rxjs/operators";

@Component({
  selector: 'shipping-restriction-messages',
  templateUrl: './shipping-restriction-messages.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class ShippingRestrictionMessagesComponent {
  messages$ = this.activeCartService.getActive().pipe(
    map(cart => {
      const messages: string[] = [];
      if (!cart?.entries) {
        return undefined;
      }
      for (const entry of cart.entries) {
        if (entry.shippingRestriction
          && entry.shippingRestrictionMessage
          && messages.every(m => m !== entry.shippingRestrictionMessage)) {
          messages.push(entry.shippingRestrictionMessage);
        }
      }
      return messages?.length
        ? messages
        : undefined;
    })
  );

  constructor(private activeCartService: ActiveCartFacade) {
  }
}

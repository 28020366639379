<section class="cx-product-variants-container">

  <!-- Fit Information for vehicle specific product -->
  <product-fit></product-fit>

  <!-- Variants -->
  <ng-container *ngIf="variants$ | async as items">
    <ng-container *ngFor="let item of items; trackBy: trackBy">

      <ng-container *ngIf="item.variant.qualifier.toLowerCase().indexOf('color') >= 0; else variantSelector">
        <cx-product-variant-color-selector
          (changeVariant)="onVariantChange($event, item.variant.qualifier)"
          [selected]="item.variant.selected"
          [variants]="item.variant.options">
        </cx-product-variant-color-selector>
      </ng-container>

      <ng-template #variantSelector>
        <cx-product-variant-selector
          (changeVariant)="onVariantChange($event, item.variant.qualifier)"
          [name]="item.variant.qualifier"
          [selected]="item.variant.selected"
          [sizeLabel]="sizeLabel"
          [disabled]="isSkuPage"
          [variants]="item.variant.options">
        </cx-product-variant-selector>
      </ng-template>

    </ng-container>

    <!-- Warning Message -->
    <ng-container *ngIf="product?.warningMessage">
      <div [innerHTML]="product.warningMessage"
           class="message-box message-box__warning text-weight-500 mt-4">
      </div>
    </ng-container>

    <!-- Product Configurator -->
    <product-configurator></product-configurator>


    <!-- <div class="divider"></div> -->
  </ng-container>



</section>





<div class="container">
  <div class="row">
    <div class="col-lg-6 col-md-12 col-sm-12">
      <div class="newsletter-container">
        <form (ngSubmit)="submitForm()" [formGroup]="newsletterForm">
          <div class="form-group ">
            <label>
              <span class="label-content bold-common-font">
                {{ 'register.firstName.label' | cxTranslate }}
              </span>
              <input required="true" class="form-control" maxlength="100" type="text" name="firstname" placeholder="{{'register.firstName.placeholder' | cxTranslate }}" formControlName="firstName" />
              <cx-form-errors [control]="newsletterForm.get('firstName')"></cx-form-errors>
            </label>
          </div>

          <div class="form-group ">
            <label>
              <span class="label-content bold-common-font">{{'register.lastName.label' | cxTranslate}}</span>
              <input required="true" class="form-control" type="text" name="lastname" maxlength="100"
                placeholder="{{'register.lastName.placeholder' | cxTranslate }}" formControlName="lastName" />
              <cx-form-errors [control]="newsletterForm.get('lastName')"></cx-form-errors>
            </label>
          </div>

          <div class="form-group ">
            <label>
              <span class="label-content bold-common-font">{{'register.emailAddress.label' | cxTranslate }}</span>
              <input maxlength="100" required="true" class="form-control" type="emailAddress" id="emailAddress"
                name="emailAddress" placeholder="{{'register.emailAddress.placeholder' | cxTranslate }}"
                formControlName="emailAddress" />
              <cx-form-errors [control]="newsletterForm.get('emailAddress')"></cx-form-errors>
            </label>
          </div>

          <div class="form-group ml-0 p-0 w-100">
            <div class="form-check simple-checkbox danger-checkradio ml-0">
              <input type="checkbox" name="termsandconditions" class="form-check-input mt-0 col-2" formControlName="termsandconditions" required="true" />

              <label class="form-check-label col-10 mt-0" style="display: inline-flex;">
                <span>
                  <p class="mb-0">
                    {{'newsletter.latestNews' | cxTranslate }}
                  </p>
                </span>
              </label>

              <div class="row mt-0 d-block ml-1 mt-1">
                <cx-form-errors [control]="newsletterForm.get('termsandconditions')"></cx-form-errors>
              </div>
            </div>
          </div>

          <button type="submit" class="btn btn-block btn-primary  mb-2">
            {{'newsletter.signup' | cxTranslate }}
          </button>
        </form>
      </div>
    </div>

    <div class="col-lg-6 col-md-12 col-sm-12">
      <cx-page-slot position="Section3B"></cx-page-slot>
    </div>
  </div>
</div>

<div [id]="containerId" class="g-recaptcha" data-size="invisible"></div>

import { NgModule } from "@angular/core";
import { StoreModule } from "@ngrx/store";
import { GARAGE_KEY } from "./garage.state";
import { garageReducer } from "./garage.reducer";
import { EffectsModule } from "@ngrx/effects";
import { GarageEffects } from "./effects/garage.effects";

@NgModule({
  imports: [
    StoreModule.forFeature(GARAGE_KEY, garageReducer),
    EffectsModule.forFeature([ GarageEffects ])
  ]
})
export class GarageStoreModule {

}

import { Injectable } from "@angular/core";
import { ProductActions, ProductSearchService, SearchConfig } from "@spartacus/core";

@Injectable({ providedIn: 'root' })
export class CustomProductSearchService extends ProductSearchService{
  override search(query: string | undefined, searchConfig?: SearchConfig): void {
    if (query || searchConfig.ymm) {
      this.store.dispatch(
        new ProductActions.SearchProducts({
          queryText: query,
          searchConfig: searchConfig,
        })
      );
    }
  }
}

import { combineLatest, Observable } from "rxjs";
import { map, switchMap, take } from "rxjs/operators";
import { TranslationService } from "@spartacus/core";
import { CurrentProductService, LaunchDialogService } from "@spartacus/storefront";
import { Injectable } from "@angular/core";
import { ProductConfigStepType, ProductConfiguratorButton } from "../models/product-configuration.models";
import { ProductConfiguratorStepsService } from "./product-configurator-steps.service";
import { ProductConfigurationService } from "./product-configuration.service";
import { GarageService } from "../../../../ymm/core/facade/garage.service";


@Injectable({ providedIn: 'root' })
export class ConfiguratorManagerBase {

  cancelButton$: Observable<ProductConfiguratorButton> =
    combineLatest([
      this.translation.translate('productLines.actions.cancel'),
      this.stepService.currentStep$
    ]).pipe(
      map(([ label, step ]) => {
        return {
          label,
          order: 1,
          disabled: false,
          display: step === 1,
          css: 'btn btn-outline-optional-1',
          execute: () => {
            this.dialogService.closeDialog(label);
            this.productConfigService.clearConfiguratorState();
            combineLatest([
              this.currentProductService.getProduct(),
              this.garageService.currentFit$
            ]).pipe(take(1))
              .subscribe(([ product, fit ]) => {
                if (!fit) {
                  this.productConfigService.clearConfiguratorState(product.code);
                }
              })
          }
        }
      }),
    );

  backButton$: Observable<ProductConfiguratorButton> =
    combineLatest([
      this.translation.translate('productLines.actions.back'),
      this.stepService.currentStep$
    ]).pipe(
      map(([ label, step ]) => {
        return {
          label,
          order: 2,
          display: step > 1,
          disabled: false,
          css: 'btn btn-outline-optional-1',
          execute: () => this.stepService.back()
        }
      })
    );

  nextButton$: Observable<ProductConfiguratorButton> =
    combineLatest([
      this.translation.translate('productLines.actions.next'),
      this.stepService.currentStep$,
      this.stepService.steps$,
      this.garageService.currentFit$
    ]).pipe(
      map(([ label, step, steps, fit ]) => {
        const availableSteps = steps.filter(s => !s.hideStep);
        return {
          label,
          order: 4,
          disabled: false,
          display: fit && step === 1 && steps.length > 1,
          css: 'btn btn-secondary',
          execute: () => this.stepService.next()
        }
      })
    );

  saveButton$: Observable<ProductConfiguratorButton> =
    combineLatest([
      this.translation.translate('productLines.actions.save'),
      this.stepService.steps$,
      this.stepService.currentStep$,
      this.currentProductService.getProduct(),
      this.productConfigService.getConfigurationForCurrentProduct()
    ]).pipe(
      map(([ label, steps, currentStep, product, config ]) => {
        const productLines = config.steps.filter(s => s.stepType === ProductConfigStepType.PRODUCT_LINES);
        return {
          label,
          order: 5,
          display: !product.hasConfigurationSteps
            || ( steps?.length === 1 && productLines?.length === 1 )
            || ( currentStep  !== 1 ),
          disabled: false,
          css: 'btn btn-util-4',
          execute: () => {
            setTimeout(() => {
              this.dialogService.closeDialog('save');
            }, 200)
          }
        }
      })
    );

  constructor(protected translation: TranslationService,
              protected stepService: ProductConfiguratorStepsService,
              protected productConfigService: ProductConfigurationService,
              protected currentProductService: CurrentProductService,
              protected dialogService: LaunchDialogService,
              protected garageService: GarageService) {
  }
}

import { ConverterService, DynamicAttributes, OccEndpointsService, OccProductAdapter, OccRequestsOptimizerService } from "@spartacus/core";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { SkuPageService } from "../../../sku-landing-page/skupage.service";

@Injectable({ providedIn: 'root' })
export class CustomOccProductAdapter extends OccProductAdapter {

  constructor(http: HttpClient,
              occEndpoints: OccEndpointsService,
              converter: ConverterService,
              requestsOptimizer: OccRequestsOptimizerService,
              private skuService: SkuPageService) {
    super(http, occEndpoints, converter, requestsOptimizer);
  }

  protected override getEndpoint(code: string, scope?: string): string {
    const attributes: DynamicAttributes = {
      urlParams: { productCode: code },
      scope,
    };
    if (this.skuService.isSKUPage()) {
      attributes.queryParams = {
        sku: this.skuService.getCurrentPartNumber()
      }
    }
    return this.occEndpoints.buildUrl('product', attributes);
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { I18nModule } from '@spartacus/core';
import { AtMessageModule, IconModule, KeyboardFocusModule, TruncateTextPopoverModule } from '@spartacus/storefront';
import { CustomCardComponent } from './card.component';

@NgModule({
  imports: [
    CommonModule,
    AtMessageModule,
    I18nModule,
    IconModule,
    TruncateTextPopoverModule,
    KeyboardFocusModule,
  ],
  declarations: [CustomCardComponent],
  exports: [CustomCardComponent],
})
export class CustomCardModule {}

import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { GarageActions } from "../index";
import { map, mergeMap, switchMap, tap } from "rxjs/operators";
import { GarageAdapter } from "../../../occ/garage.adapter";
import { RoutingService } from "@spartacus/core";
import { Store } from "@ngrx/store";
import { GarageState } from "../garage.state";

@Injectable({ providedIn: 'root' })
export class GarageEffects {
  constructor(private actions$: Actions,
              private adapter: GarageAdapter,
              private routingService: RoutingService,
              private store: Store<GarageState>) {
  }

  loadYears$ = createEffect(
    () => this.actions$.pipe(
      ofType(GarageActions.loadYears),
      switchMap(
        () => this.adapter.loadYears()
          .pipe(
            map(
              years =>
                GarageActions.loadYearsSuccess({ years })
            )
          )
      )
    )
  );

  getMakes$ = createEffect(
    () => this.actions$.pipe(
      ofType(GarageActions.getMakes),
      switchMap(
        ({ year }) => this.adapter.getMakes(year)
          .pipe(
            map(
              makes => GarageActions.getMakesSuccess({ makes, year })
            )
          )
      )
    )
  );

  getModels$ = createEffect(
    () => this.actions$.pipe(
      ofType(GarageActions.getModels),
      switchMap(
        ({ year, make }) => this.adapter.getModels(year, make)
          .pipe(
            map(
              models => GarageActions.getModelsSuccess({ make, year, models })
            )
          )
      )
    )
  );

  setVehicle$ = createEffect(
    () => this.actions$.pipe(
      ofType(GarageActions.addVehicle),
      switchMap(
        ({ ymm }) => this.adapter.getVehicleDetails(ymm)
          .pipe(
            map(
              vehicle => {
                return GarageActions.addVehicleOk({ vehicle })
              }
            )
          )
      )
    )
  );

  updateFit$ = createEffect(
    () => this.actions$.pipe(
      ofType(GarageActions.updateFit),
      tap(_ => this.store.dispatch(GarageActions.updateFitBusy({ busy: true }))),
      switchMap(
        ({ code, ymm }) => this.adapter.updateFit(code, ymm)
          .pipe(
            mergeMap(
              (fit) => [
                GarageActions.updateFitOk(fit),
                GarageActions.updateFitBusy({ busy: false })
              ]
            )
          )
      )
    )
  );

}

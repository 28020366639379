import { ChangeDetectorRef, Component } from '@angular/core';
import { Router } from '@angular/router';
import { CartProceedToCheckoutComponent } from '@spartacus/cart/base/components';
import { ActiveCartFacade } from "@spartacus/cart/base/root";
import { ContentsData, MetaPixel } from '../../../meta-pixel/meta-pixel';
import { map, take } from 'rxjs/operators';
import { combineLatest, Subscription } from 'rxjs';


@Component({
  selector: 'custom-cart-proceed-to-checkout',
  templateUrl: './custom-cart-proceed-to-checkout.component.html',
})
export class CustomCartProceedToCheckoutComponent extends CartProceedToCheckoutComponent {
private eventInitiateCheckout: Subscription;

  constructor(
    router: Router,
    private metaPixel: MetaPixel,
    cd?: ChangeDetectorRef,
  ) {
    super(router, cd);
  };

  override ngOnInit(): void {
    this.cartValidationInProgress = false;
    this.cd.markForCheck();
  }

  override disableButtonWhileNavigation(): void {
    // super.disableButtonWhileNavigation();
    this.cartValidationInProgress = true;

    this.metaPixel.eventInitiateCheckout()
      .pipe(take(1))
      .subscribe(()=>{
        this.router.navigate(['/checkout']);
    })
    
    /*
    this.router.navigate(['/checkout']).then(() => {
      this.metaPixel.eventInitiateCheckout();
    })
    */
  }

  override ngOnDestroy(): void {
    if (this.eventInitiateCheckout) this.eventInitiateCheckout.unsubscribe();
    super.ngOnDestroy();
  }
}

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ProductListComponent, ProductListItemContext, ProductListItemContextSource } from '@spartacus/storefront';

@Component({
  selector: 'cx-product-list',
  templateUrl: './custom-product-list.component.html',
  styleUrls: ['./custom-product-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    ProductListItemContextSource,
    {
      provide: ProductListItemContext,
      useExisting: ProductListItemContextSource,
    },
  ],
})
export class CustomProductListComponent extends ProductListComponent {
  
}

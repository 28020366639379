<div class="form-check simple-checkbox default-checkradio align-items-center d-flex" >
    <input
      type="checkbox"
      class="form-check-input "
      (change)="onConsentChange()"
      [checked]="consentGiven"
      [name]="consentTemplate.id"
      [disabled]="isRequired(consentTemplate.id)"
    />
  <label class="form-check-label col-10">

    <span class="cx-be-bold">
      <ng-container *ngIf="consentTemplate?.name">
        {{ consentTemplate.name }}
        <br />
      </ng-container>
    </span>
    <span class="form-check-label">
      {{ consentTemplate.description }}
    </span>
  </label>
</div>

import { Component } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { OrderGuestRegisterFormComponent } from '@spartacus/order/components';
import { CustomFormValidators } from '@spartacus/storefront';

@Component({
  selector: 'custom-order-guest-register-form',
  templateUrl: './custom-order-guest-register-form.component.html',
  styleUrls: ['./custom-order-guest-register-form.component.scss'],
})
export class CustomOrderGuestRegisterFormComponent extends OrderGuestRegisterFormComponent {
  override guestRegisterForm: UntypedFormGroup = this.fb.group(
    {
      password: [
        '',
        [
          Validators.required,
          CustomFormValidators.passwordValidator,
          Validators.minLength(8)
        ],
      ],
    },
    {updateOn: 'submit'}
  );
}

<article class="addons-card">
  <div>
    <cx-media [container]="image"></cx-media>
    <addons-variants
      (imageChange)="onImageChange($event)"
      (priceChange)="this.onPriceChange($event)"
      (variantChanges)="toggleAddon(true)"
      (addonVariantChanges)="onVariantStateChanges($event)"
      [selection]="selection.variants"
      [product]="product">
    </addons-variants>
  </div>

  <section>

    <div>
      <h6 class="addons-card__name">
        {{ product.name }}
      </h6>
    </div>

    <div class="addons-card__price">
      <span class="addons-card__price--value">
        {{
          detailsPrice?.prices?.priceWithDiscount?.value
            ? detailsPrice?.prices?.priceWithDiscount?.formattedValue
            : detailsPrice?.prices?.price?.formattedValue
        }}
      </span>

      <ng-container *ngIf="detailsPrice?.prices?.discount?.value > 0">
        <span class="addons-card__price--sale">
          {{ 'productDetails.priceSale' | cxTranslate }}
        </span>

        <div class="addons-card__price--was">
          {{ 'productDetails.priceWas' | cxTranslate }}
          <span class="text-line-through">
            {{ detailsPrice?.prices?.price?.formattedValue }}
          </span>
        </div>

      </ng-container>
    </div>

  </section>

  <div class="addons-card__action">
    <button
      (click)="toggleAddon()"
      [disabled]="!detailsPrice?.sku"
      [ngClass]="selection?.selected ? 'btn-primary' : 'btn-secondary'"
      class="btn"
      type="button">
      {{ selection?.selected ? 'added' : 'add' }}
    </button>
  </div>
</article>

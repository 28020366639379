import { Vehicle, Year, YMMInventory } from "../models/garage.model";

export const GARAGE_KEY = 'garage';

export interface VehicleState {
  active: string;
  garage?: { [ key: string ]: Vehicle };
}

export interface GarageState {
  fit?: boolean;
  fitBusy?: boolean;
  vehicles: VehicleState;
  inventory: YMMInventory[];
  years: Year[];
}

export const garageInitialState: GarageState = {
  vehicles: {
    active: ''
  },
  inventory: [],
  years: []
};

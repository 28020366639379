import { ChangeDetectionStrategy, Component, Inject, OnInit, Renderer2 } from '@angular/core';
import {
  CmsComponentData,
  PageLayoutService,
  PageTitleComponent,
} from '@spartacus/storefront';
import { CmsBreadcrumbsComponent, CmsService, isNotNullable, PageMetaService } from '@spartacus/core';
import { filter, map } from 'rxjs/operators';
import { combineLatest } from "rxjs";
import { GarageService } from "../../../spartacus/features/ymm/core/facade/garage.service";
import { CustomBreadCrumbService } from './custom-breadcrumb.service';

@Component({
  selector: 'cx-breadcrumb',
  templateUrl: './custom-breadcrumb.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomBreadcrumbComponent
  extends PageTitleComponent
  implements OnInit
{
  forbiddenPageTitle = [
    'SearchResultsListPageTemplate',
    'YMMCategoryPageTemplate',
    'ProductListPageTemplate',
    'ProductDetailsPageTemplate',
  ];

  crumbs$ = this.breadcrumbService.crumbs$;

  contentWithTitle$ = combineLatest([
    this.pageMetaService.getMeta(),
    this.pageLayoutService.page$,
  ]).pipe(map(([meta, pageLayout]: any) => ({ meta, pageLayout })));

  customTitle$ = combineLatest([
    this.contentWithTitle$,
    this.cmsService.getCurrentPage(),
    this.garageService.getActiveVehicle(),
  ]).pipe(
    map(([contentWithTitle, page, activeVehicle]: any) => {
      if (this.forbiddenPageTitle.includes(page?.template)) {
        return undefined;
      }

      return (
        contentWithTitle.meta?.heading ||
        contentWithTitle.meta?.title ||
        contentWithTitle.pageLayout.name ||
        ''
      );
    })
  );

  eyebrow$ = combineLatest([
    this.pageMetaService.getMeta(),
    this.cmsService.getCurrentPage(),
  ]).pipe(
    map(([meta, page]) => {
      if (page?.template && this.forbiddenPageTitle.includes(page.template)) {
        return undefined;
      }

      return meta.eyebrow;
    })
  );

  constructor(
    component: CmsComponentData<CmsBreadcrumbsComponent>,
    pageMetaService: PageMetaService,
    private cmsService: CmsService,
    private garageService: GarageService,
    private pageLayoutService: PageLayoutService,
    private breadcrumbService: CustomBreadCrumbService
  ) {
    super(component, pageMetaService);
  }

  override ngOnInit(): void {
    this._setTitle();
  }

  _setTitle(): void {
    this.title$ = this.pageMetaService.getMeta().pipe(
      filter(isNotNullable),
      map((meta) => (meta.heading || meta.title) ?? '')
    );
  }

}


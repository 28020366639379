import { CmsConfig, OccEndpoints } from '@spartacus/core';

interface CustomCmsConfig extends CmsConfig {
  backend?: {
    occ?: {
      endpoints?: {
        pages?: string;
        page?: string;
        metaAddToCart?: string;
        metaViewProduct?: string;
        metaInitiateCheckout?: string;
        metaPurchase?: string;
      };
    };
  };
}


export const customCmsConfig: CustomCmsConfig = {
    backend: {
      occ: {
        endpoints: {
          pages: 'cms/pages?fields=FULL',
          page: 'cms/pages/${id}?fields=FULL',
          metaAddToCart: 'meta-pixel/add-to-cart',
          metaViewProduct: 'meta-pixel/view-product',
          metaInitiateCheckout: 'meta-pixel/initiate-checkout',
          metaPurchase: 'meta-pixel/purchase'
        },
      },
    },
  };
import { ChangeDetectionStrategy, Component, ElementRef, Renderer2 } from '@angular/core';
import { FacetListComponent, FacetService } from '@spartacus/storefront';
import { RoutingService } from "@spartacus/core";
import { map } from "rxjs/operators";

@Component({
  selector: 'cx-facet-list',
  templateUrl: './custom-facet-list.component.html',
  styleUrls: ['./custom-facet-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomFacetListComponent extends FacetListComponent {
  /**
   * TODO: Revisar e resolver esta questão de facets.
   */
  originalState: string = '';
  url$ = this.routingService.getRouterState().pipe(
    map((urlState) => {
      this.originalState = urlState.state.url;
      let modifiedUrl = this.originalState;
      const queryStringIndex = modifiedUrl.indexOf('?');
      if (queryStringIndex !== -1) {
        modifiedUrl = modifiedUrl.slice(0, queryStringIndex);
      }
      modifiedUrl = modifiedUrl
        .split('/')
        .filter((p) => p !== 'covercraft' && p !== 'USD' && p !== 'en')
        ?.join('/');
        
      modifiedUrl = modifiedUrl.replace(/%20/g, ' ');
      return modifiedUrl;
    })
  );
  constructor(
    facetService: FacetService,
    elementRef: ElementRef,
    renderer: Renderer2,
    private routingService: RoutingService
  ) {
    super(facetService, elementRef, renderer);
  }
}

<ng-container *ngIf="{
  fitmentList: fitmentList$ | async
} as contentPage">
  <div *ngIf="contentPage?.fitmentList?.length" class="container">
    <p class="h6 bold-common-font m-b-40">{{'fitmentDetails.title' | cxTranslate}}</p>

    <table class="custom-responsive-table">
      <thead>
        <tr>
          <th class="column-year semi-bold-common-font">{{'fitmentDetails.table.header.year' | cxTranslate}}</th>
          <th class="column-make semi-bold-common-font">{{'fitmentDetails.table.header.make' | cxTranslate}}</th>
          <th class="column-model semi-bold-common-font">{{'fitmentDetails.table.header.model' | cxTranslate}}</th>
          <th class="column-subModel semi-bold-common-font">{{'fitmentDetails.table.header.subModel' | cxTranslate}}</th>
          <th class="column-details semi-bold-common-font">{{'fitmentDetails.table.header.details' | cxTranslate}}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of contentPage.fitmentList">
          <td class="column-year">
            <div class="title-mobile">
              {{'fitmentDetails.table.header.year' | cxTranslate}}:
            </div>

            <div class="content">
              {{item.year}}
            </div>
          </td>

          <td class="column-make">
            <div class="title-mobile">
              {{'fitmentDetails.table.header.make' | cxTranslate}}:
            </div>

            <div class="content">
              {{item.make}}
            </div>
          </td>

          <td class="column-model">
            <div class="title-mobile">
              {{'fitmentDetails.table.header.model' | cxTranslate}}:
            </div>

            <div class="content">
              {{item.model}}
            </div>
          </td>

          <td class="column-subModel">
            <div class="title-mobile">
              {{'fitmentDetails.table.header.subModel' | cxTranslate}}:
            </div>

            <div class="content">
              {{item.subModel}}
            </div>
          </td>

          <td class="column-details">
            <div class="title-mobile">
              {{'fitmentDetails.table.header.details' | cxTranslate}}:
            </div>

            <div class="content">
              {{item.option}}
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</ng-container>


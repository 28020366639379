import { Component, EventEmitter, OnInit } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ToasterContainerModel, ToasterModel } from './toaster.model';
import { ToasterService } from './toaster.service';

@Component({
  selector: 'toaster-component',
  templateUrl: './toaster.component.html',
  styleUrls: ['./toaster.component.scss']
})

export class ToasterComponent implements OnInit {

  toasterContainer$: Observable<ToasterContainerModel> = this.toasterServ.toastersContainer$
  toasters$:  BehaviorSubject<Array<ToasterModel>>  = this.toasterServ.toasters$;
  toasters: Array<ToasterModel> = [];
  active: boolean = false;
  container: ToasterContainerModel  = {overLoader: false};

  constructor(private toasterServ: ToasterService) { }

  ngOnInit(): void {
    this.getToasters();
  }

  getToasters () {
    this.toasters$.subscribe(toasters => {
      this.addToasters(toasters);
    });

    this.toasterContainer$.subscribe(container => {
      this.container = container;
    })

    this.addToasters(this.toasterServ.allToaster);
  }

  addToasters (toasters: Array<ToasterModel>) {
    this.toasters = toasters;
    this.active = this.toasters.length >= 1;
  }

  closeIt (el: HTMLElement, toasterID: String) {
    
    if (el && el.parentElement && el.parentElement.className) {
      el.parentElement.className += ' init-close';
    }

    setTimeout(() => {
      this.toasterServ.closeIt(toasterID);
      
      if (!this.toasters.length) {
        this.container.overLoader = false;
      }
    }, 500);
  }
}

<div class="container">
  <ng-container *ngIf="order$ | async as order">
    <custom-order-shared-items
      [deliveryConsignments]="deliveryConsignments"
      [deliveryUnConsignedEntries]="deliveryUnConsignedEntries"
      [order]="order"
      [pickupConsignments]="pickupConsignments"
      [pickupUnconsignedEntries]="pickupUnconsignedEntries"
    />
  </ng-container>
</div>

import { NgModule } from "@angular/core";
import { CmsConfig, provideConfig } from "@spartacus/core";
import { listrakProvider } from "./core/listrak-provider";

@NgModule({
  providers: [
    listrakProvider,
    provideConfig({
      featureModules: {
        PREFERENCE_CENTER: {
          module: () => import('./preference-center/preference-center.module').then(m => m.PreferenceCenterModule),
          cmsComponents: [
            'PreferenceCenterComponent'
          ]
        }
      }
    } as CmsConfig),
  ]
})
export class ListrakFeatureModule {

}

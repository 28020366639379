import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FormStateService {
  private formStates = new BehaviorSubject<{ [key: string]: any }>({});

  setFormState(key: string, formValue: any) {
    const currentState = this.formStates.getValue();
    currentState[key] = formValue;
    this.formStates.next(currentState);
  }

  getFormState(key: string): any {
    return this.formStates.getValue()[key];
  }
}

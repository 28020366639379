import { createAction, props } from "@ngrx/store";
import { Vehicle, YMM } from "../../models/garage.model";

const SET_VEHICLE = '[GARAGE] Set Vehicle';

const ADD_VEHICLE = '[GARAGE] Add Vehicle';
const ADD_VEHICLE_OK = '[GARAGE] Add Vehicle Ok';

const REMOVE_VEHICLE = '[GARAGE] Remove Vehicle';

export const addVehicle = createAction(
  ADD_VEHICLE,
  props<{ ymm: YMM }>()
);

export const addVehicleOk = createAction(
  ADD_VEHICLE_OK,
  props<{ vehicle: Vehicle }>()
)

export const setActiveVehicle = createAction(
  SET_VEHICLE,
  props<{ code: string }>()
);

export const removeVehicle = createAction(
  REMOVE_VEHICLE,
  props<{ vehicle: Vehicle }>()
);


import { garageInitialState, GarageState } from "./garage.state";
import { createReducer, on } from "@ngrx/store";
import { GarageActions } from "./index";
import { deepCloning } from "../../../../../tools/tools";
import { environment } from "../../../../../../environments/environment";

export const garageReducer = createReducer(
  garageInitialState,
  on(GarageActions.setState,
    (_,
     { vehicles, inventory, years }) => {
      return {
        vehicles,
        inventory,
        years,
      }
    }),
  on(GarageActions.loadYearsSuccess, (state, { years }) => {
    return {
      ...state,
      years
    }
  }),
  on(GarageActions.setActiveVehicle, (state, { code }) => {
    return {
      ...state,
      vehicles: {
        garage: state.vehicles.garage,
        active: code
      }
    }
  }),
  on(GarageActions.addVehicleOk, (state, { vehicle }) => {
    return {
      ...state,
      vehicles: {
        active: vehicle.code,
        garage: {
          ...state.vehicles.garage,
          ...{ [ vehicle.code ]: vehicle }
        }
      }
    }
  }),
  on(GarageActions.getMakesSuccess, (state, { makes, year }) => {
    const inventory = deepCloning(state.inventory);
    for (const make of makes) {
      const lookup = inventory.find(y => y.make === make);
      if (lookup) {
        if (lookup.models.every(m => m.year !== year)) {
          lookup.models.push({ year, models: [] })
        }
      } else {
        inventory.push({ make, models: [ { year, models: [] } ] })
      }
    }
    return {
      ...state,
      inventory
    };
  }),
  on(GarageActions.getModelsSuccess, (state, { year, make, models }) => {
    const inventory = deepCloning(state.inventory);
    try {
      inventory.find(y => y.make === make).models
        .find(m => m.year === year)
        .models = models
    } catch {
      if (!environment.production) {
        console.warn("There are some inconsistency with the YMM tree and the model couldn't be updated");
      }
    }
    return {
      ...state,
      inventory
    }
  }),
  on(GarageActions.removeVehicle, (state, { vehicle }) => {
    const newState = deepCloning(state) as GarageState;
    delete newState.vehicles.garage[ vehicle.code ];
    if (newState.vehicles.active === vehicle.code) {
      const keys = Object.keys(newState.vehicles.garage)
        .sort((k1, k2) => k1 <= k2 ? -1 : 1);
      newState.vehicles.active = keys.length ? newState.vehicles.garage[ keys[ 0 ] ].code : '';
    }
    return newState;
  }),
  on(GarageActions.updateFitOk, (state, { fit }) => {
    return {
      ...state,
      fit
    };
  }),
  on(GarageActions.updateFitOk, (state, { fit }) => {
    return {
      ...state,
      fit,
      fitBusy: false
    };
  }),
  on(GarageActions.updateFitBusy, (state, { busy }) => {
    return {
      ...state,
      fitBusy: busy
    };
  })
);



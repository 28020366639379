import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BreakpointService, CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ToolsService } from 'src/app/components/tools.service';
import { breakpointMediaMapKey } from 'src/app/components/utils/cms-utils';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'family-covercraft-item',
  templateUrl: './family-covercraft-item.component.html'
})
export class FamilyCovercraftItemComponent implements OnInit {
  @Input() banner!: any
  middleIcon?: string
  isLogoVisible = false

  constructor(   
    protected component: CmsComponentData<any>,
    private breakpointService: BreakpointService,
    private router: Router,
    private toolsService: ToolsService
    ){}
  ngOnInit(): void {
    if(this.banner?.image?.url) {
      this.middleIcon = `${environment.baseUrl}${this.banner.image.url}`
      this.isLogoVisible = true
    }
  }
  
  bg$ = this.breakpointService.breakpoint$.pipe(
    map(breakpoint => {
      if (!this.banner?.media) {
        return {};
      }
      const mediaKey = breakpointMediaMapKey.get(breakpoint) ?? 'desktop';
      return  `${ environment.baseUrl }${this.banner?.media[ mediaKey ]?.url }` ;
    })
  );

  normalizeUrl(url: string) {
    if(url){
      return this.toolsService.createUrlToStoreFront(url);
    } else {
      return ''
    }
  }

  openLink(link:string) {
    this.router.navigate([link])
  }
}

import { Injectable } from '@angular/core';
import { ToolsService } from 'src/app/components/tools.service';
import { Location } from "@angular/common";

@Injectable({ providedIn: 'root' })
export class SkuPageService {
  private currentProductCode: string;

  constructor(private toolsService: ToolsService,
              private location: Location) {
  }

  isSKUPage() {
    return this.location.path().includes('/sku/');
  }

  getCurrentPartNumber() {
    const urlParts = this.location.path().split(`/`);
    const lastPart = this.toolsService.getLastItemFromArray(urlParts);
    if (lastPart) {
        const partBeforeQuestionMark = lastPart.split('?')[0];
        return partBeforeQuestionMark;
    }
    return undefined;
  }

  setCurrentProductCode(productCode: string) {
    this.currentProductCode = productCode;
  }

  getCurrentProductCode(): string {
    return this.currentProductCode;
  }
}

import { Component, OnDestroy } from '@angular/core';
import { FacetService, PageLayoutService, ProductListComponentService } from '@spartacus/storefront';
import { combineLatest, Observable, of, Subscription } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { Facet } from '../../../../spartacus/features/product/product-list/custom-product-facet-navigation/custom-facet/custom-facet.types';
import { WindowRef } from '@spartacus/core';

@Component({
  selector: 'search-results-list-page-template',
  templateUrl: './search-results-list-page-template.component.html',
  styleUrls: ['./search-results-list-page-template.component.scss']
})

export class SearchResultsListPageTemplateComponent implements OnDestroy {
  private subscription = new Subscription();
  products$ = this.productListComponentService.model$;
  hasProduct$ = this.products$.pipe(
    map((productModel => productModel.products.length > 0))
  )

  categoryData$: Observable<any>;
  page$: any;
  router: any;
  pageLinkService: any;
  //winRef: any;
  categoryDescription: string;
  // htmlContent: string;

  isYmmPage$ = combineLatest([
    this.productListComponentService.model$,
    this.pageLayoutService.page$
  ]).pipe(
    map(([productModel, page]) => {
      return page.template === 'YMMCategoryPageTemplate' && page.label === 'shop' && !productModel.currentQuery.url.includes('levelOneCategories');
    })
  );

  topCategories = new Array();
  carouselItemWidth = '68px';

  constructor(
    private productListComponentService: ProductListComponentService,
    private pageLayoutService: PageLayoutService,
    private facetService: FacetService,
    private winRef: WindowRef
  ) { }

  ngOnInit(): void {
    this.getcategoryDescription();

    // get top level categories
    this.subscription.add(
      this.facetService.facetList$.subscribe(list => {
        this.topCategories = [];
        if (list.facets) {
          for (let i=0; i<list.facets.length; i++) {
            let f: Facet = list.facets[i];
            if (f.category && f.code === 'levelOneCategories') {
              for (let j=0; j<list.facets[i].values.length; j++) {
                const ob = of(list.facets[i].values[j]);
                this.topCategories.push(ob);
              }
            }
          }
        }
      })
    );

    if (this.winRef.isBrowser() && window.innerWidth >= 768) {
      this.carouselItemWidth = '136px';
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  getcategoryDescription() {
    this.categoryData$ = this.pageLayoutService.page$;
    this.categoryData$.subscribe(categoryData => {
      if (categoryData && categoryData.categoryDescription) {
        this.categoryDescription = this.decodeHtml(categoryData.categoryDescription)
      } else {
        console.error('Category data or category description is missing');
      }
    },
      error => {
        console.error('Error fetching category data', error);
      }
    );
  }

  decodeHtml(html: string): string {
    const textArea = document.createElement('textarea');
    textArea.innerHTML = html;
    return textArea.value;
  }






}

<table role="table" class="w-100">
 <caption class="cx-visually-hidden">
  {{
  'cartItems.caption' | cxTranslate
  }}
 </caption>
 <thead *ngIf="hasHeader" class="cart-table-header" style=" border-bottom: 1px solid #D9d9d9;
">
  <!-- <tr role="row" class="cx-item-list-header">
   <th role="columnheader" class="cx-item-list-desc">
    {{ 'cartItems.description' | cxTranslate }}
   </th>
   <th role="columnheader" class="cx-item-list-qty">
    {{ 'cartItems.quantity' | cxTranslate }}
   </th>
   <ng-container *ngIf="options.isSaveForLater; else totalHeader">
    <th role="columnheader" class="cx-item-list-total">
     {{ 'saveForLaterItems.stock' | cxTranslate }}
    </th>
   </ng-container>
   <ng-container *ngIf="!readonly || options.isSaveForLater || options.displayAddToCart">
    <th role="columnheader" class="cx-item-list-actions">
     {{ 'cartItems.actions' | cxTranslate }}
    </th>
   </ng-container>
  </tr> -->
  <div class="order-mobile-hidden order-table-data-item">
      <div class="row ">
        <div class="col-2 py-4">
          Item(s)
        </div>
        <div class="col-10">
          <div class="row text-center">
            <div class="col-md-6 col-lg-6 col-xl-6 py-4">

            </div>
            <div class="col-md-2 col-lg-2 col-xl-2 py-4">
              Price
            </div>
            <div class="col-md-2 col-lg-2 col-xl-2 py-4">
              Quantity
            </div>
            <div class="col-md-2 col-lg-2 col-xl-2 py-4">
              Amount
            </div>
            
          </div>
        </div>
      </div>
  </div>

 </thead>
 <tbody class="cx-item-list-items">
  <ng-container *ngFor="let item of items; let i = index">
   <ng-container *ngIf="getControl(item) | async as control" [class.is-changed]="control.get('quantity')?.disabled">
    <tr custom-order-item-list-row role="row" class="custom-cart-item-list-row" [item]="item" style=" border-bottom: 1px solid #D9d9d9"
     [quantityControl]="getQuantityControl(control)" [readonly]="readonly" [promotionLocation]="promotionLocation"
     [options]="options"></tr>
   </ng-container>
  </ng-container>
 </tbody>
</table>

<ng-template #totalHeader>
 <th role="columnheader" class="cx-item-list-total">
  {{ 'cartItems.total' | cxTranslate }}
 </th>
</ng-template>
import { NgModule } from "@angular/core";
import { ProductConfigDialogHeaderComponent } from '../dialog/product-config-dialog-header/product-config-dialog-header.component';
import { ProductConfigDialogStepsComponent } from '../dialog/product-config-dialog-steps/product-config-dialog-steps.component';
import { ProductConfigDialogComponent } from "../dialog/product-config-dialog/product-config-dialog.component";
import { I18nModule, provideConfig } from "@spartacus/core";
import { DIALOG_TYPE, IconModule, LAUNCH_CALLER, LayoutConfig, MediaModule } from "@spartacus/storefront";
import { CommonModule } from "@angular/common";
import { SvgIconComponent } from "../../../../../components/_CUSTOM/svg/components/svg-icon/svg-icon.component";
import { ReactiveFormsModule } from "@angular/forms";
import { ProductConfigDialogFooterComponent } from '../dialog/product-config-dialog-footer/product-config-dialog-footer.component';
import { ProductConfiguratorComponent } from './product-configurator/product-configurator.component';
import { YmmFormComponent } from "../../../ymm/components/ymm-form/ymm-form.component";
import { productConfiguratorOccConfig } from "../occ/product-configurator-occ.config";
import { configuratorComponentsModule } from "../configurators/configurator-components.module";
import { AdditionalDetailsModule } from "../../additional-details/additional-details.module";

@NgModule({
  imports: [
    CommonModule,
    YmmFormComponent,
    SvgIconComponent,
    I18nModule,
    IconModule,
    ReactiveFormsModule,
    configuratorComponentsModule,
    MediaModule,
    AdditionalDetailsModule
  ],
  declarations: [
    ProductConfigDialogComponent,
    ProductConfigDialogHeaderComponent,
    ProductConfigDialogStepsComponent,
    ProductConfigDialogFooterComponent,
    ProductConfiguratorComponent
  ],
  exports: [
    ProductConfiguratorComponent
  ],
  providers: [
    provideConfig(productConfiguratorOccConfig),
    provideConfig({
      launch: {
        [ LAUNCH_CALLER.PRODUCT_CONFIGURATOR ]: {
          inlineRoot: true,
          component: ProductConfigDialogComponent,
          dialogType: DIALOG_TYPE.DIALOG,
        }
      }
    } as LayoutConfig)
  ]
})
export class ProductConfiguratorComponentsModule {

}

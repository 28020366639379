import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfigModule, RoutingConfig } from '@spartacus/core';

@NgModule({
  imports: [
    CommonModule,
    ConfigModule.withConfig({
      routing: {
        routes: {
          product: {
            paths: [
              `product/:slug/:productCode`,
              `product/:productCode`,
              `sku/:productSku`
            ],
          }
        },
      },
    } as RoutingConfig),
  ],
})

export class CustomRoutingModule { }

<form (ngSubmit)="verifyAddress()" [formGroup]="addressForm">
  <div>
    <div class="row">
      <div class="form-group col-md-6 col-lg-6 col-sm-6">
        <label>
          <span class="label-content required">{{
            'addressForm.firstName.label' | cxTranslate
            }}</span>
          <input required="true" class="form-control" type="text"
            placeholder="{{ 'addressForm.firstName.placeholder' | cxTranslate }}" formControlName="firstName" />
          <cx-form-errors [control]="addressForm.get('firstName')"></cx-form-errors>
        </label>
      </div>
      <div class="form-group col-md-6 col-lg-6 col-sm-6">
        <label>
          <span class="label-content required">{{
            'addressForm.lastName.label' | cxTranslate
            }}</span>
          <input required="true" type="text" class="form-control"
            placeholder="{{ 'addressForm.lastName.placeholder' | cxTranslate }}" formControlName="lastName" />
          <cx-form-errors [control]="addressForm.get('lastName')"></cx-form-errors>
        </label>
      </div>

    </div>
    <div class="row">
      <div class="form-group col-md-6 col-lg-6 col-sm-12">
        <label>
          <span class="label-content required">{{
            'customAddressForm.company' | cxTranslate
            }}</span>
          <input required="false" class="form-control" type="text"
            placeholder="{{ 'customAddressForm.company' | cxTranslate }}" formControlName="companyName" />
          <cx-form-errors [control]="addressForm.get('companyName')"></cx-form-errors>
        </label>
      </div>
      <div class="form-group col-md-6 col-lg-6 col-sm-6">
        <label>
          <span class="label-content">{{
            'customAddressForm.phoneNumber.label' | cxTranslate
            }}</span>
          <input type="tel" class="form-control" [mask]="us_ca_mask_phone" placeholder="{{
                  'addressForm.phoneNumber.placeholder' | cxTranslate
                }}" formControlName="phone"  required="true">
              <cx-form-errors [control]="addressForm.get('phone')"></cx-form-errors>

        </label>
      </div>
    </div>
  </div>
  <div>
    <div class="form-group" formGroupName="country">
      <ng-container *ngIf="countries$ | async as countries">
        <div *ngIf="countries.length !== 0">
          <label>
            <span class="label-content required">{{
              'addressForm.country' | cxTranslate
              }}</span>
            <ng-select [inputAttrs]="{ required: 'true' }" class="country-select" id="country-select"
              formControlName="isocode" [searchable]="true" [clearable]="false" [items]="countries" bindLabel="name"
              bindValue="isocode" placeholder="{{ 'addressForm.selectOne' | cxTranslate }}"
              (change)="countrySelected($event)" [cxNgSelectA11y]="{
                  ariaLabel: 'addressForm.country' | cxTranslate
                }" (change)="onSelectionChange($event)">
            </ng-select>
            <cx-form-errors [control]="addressForm.get('country.isocode')"></cx-form-errors>
          </label>
        </div>
      </ng-container>
    </div>
    <div class="form-group">
      <label>
        <span class="label-content required">{{
          'addressForm.address1' | cxTranslate
          }}</span>
        <input
          required="true"
          type="text"
          class="form-control"
          placeholder="{{ 'addressForm.streetAddress' | cxTranslate }}"
          formControlName="line1"
          pattern="^(?!.*(?:[Pp]\.?\s?[Oo]\.?\s\d+|[Bb][Oo][Xx]\s\d+?)).*$" />
        <cx-form-errors [control]="addressForm.get('line1')" prefix="customAddressForm.line1"></cx-form-errors>
      </label>
    </div>
    <div class="form-group">
      <label>
        <span class="label-content">{{
        'customAddressForm.line2.label' | cxTranslate
        }} </span>
        <input
          type="text"
          class="form-control"
          placeholder="{{ 'customAddressForm.line2.placeholder' | cxTranslate }}"
          formControlName="line2"
          pattern="^(?!.*(?:[Pp]\.?\s?[Oo]\.?\s\d+|[Bb][Oo][Xx]\s\d+?)).*$" />
        <cx-form-errors [control]="addressForm.get('line2')" prefix="customAddressForm.line2"></cx-form-errors>
      </label>
    </div>
    <div class="row">
      <div class="form-group col-sm-12 col-md-4 col-lg-4">
        <label>
          <span class="label-content required">{{
            'addressForm.city.label' | cxTranslate
            }}</span>
          <input required="true" type="text" class="form-control"
            placeholder="{{ 'addressForm.city.placeholder' | cxTranslate }}" formControlName="town" />
          <cx-form-errors [control]="addressForm.get('town')"></cx-form-errors>
        </label>
      </div>
      <ng-container *ngIf="regions$ | async as regions" formGroupName="region">
        <ng-container *ngIf="regions.length !== 0">
          <div class="form-group col-md-4 col-sm-12 col-lg-4">
            <label>
              <span class="label-content required">{{
                'addressForm.state' | cxTranslate
                }}</span>
              <ng-select [inputAttrs]="{ required: 'true' }" class="region-select" formControlName="isocode"
                [searchable]="true" [clearable]="false" [items]="regions"
                bindLabel="{{ regions[0].name ? 'name' : 'isocode' }}"
                bindValue="{{ regions[0].name ? 'isocode' : 'region' }}"
                placeholder="{{ 'addressForm.selectOne' | cxTranslate }}" id="region-select" [cxNgSelectA11y]="{
                            ariaLabel: 'addressForm.state' | cxTranslate
                          }" (change)="onSelectionChange($event)">
              </ng-select>
              <cx-form-errors [control]="addressForm.get('region.isocode')"></cx-form-errors>
            </label>
          </div>
        </ng-container>
      </ng-container>
      <div class="form-group col-md-4 col-sm-12 col-lg-4">
        <label>
          <span class="label-content required">{{
            'addressForm.zipCode.label' | cxTranslate
            }}</span>
          <input
            required="true"
            type="text"
            class="form-control"
            placeholder="{{ 'addressForm.zipCode.placeholder' | cxTranslate }}"
            formControlName="postalCode" 
            pattern="^\d{5}$"
            autocomplete="postal-code"/>
          <cx-form-errors [control]="addressForm.get('postalCode')" prefix="customAddressForm.postalCode"></cx-form-errors>
        </label>
      </div>
    </div>
  </div>
  <div class="form-group ml-0 p-0" *ngIf="(addresses$ | async)?.length && setAsDefaultField">
    <div class="form-check simple-checkbox default-checkradio">

      <input type="checkbox" class="form-check-input ml-0 " formControlName="defaultAddress"
        (change)="toggleDefaultAddress()" />
      <label class="form-check-label">
        <span class="">{{
          'addressForm.setAsDefault' | cxTranslate
          }}</span>
      </label>
    </div>
  </div>
  <div class="cx-address-form-btns">
    <div class="row">
      <div class="col-md-12 col-sm-12 col-lg-6  mt-md-1 order-2 order-lg-1" *ngIf="showCancelBtn">
        <button class="btn btn-block btn-optional-1" (click)="back()">
          {{ cancelBtnLabel || ('addressForm.chooseAddress' | cxTranslate) }}
        </button>
      </div>
      <div class="col-md-12 col-sm-12 mt-md-1 mt-sm-1 col-lg-6 order-1 order-lg-2">
        <button #submit class="btn btn-block btn-secondary" type="submit">
          {{ actionBtnLabel || ('common.continue' | cxTranslate) }}
        </button>
      </div>
    </div>
  </div>
</form>

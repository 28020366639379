import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { ProductDetailsService } from "../../../core/facade/product-details.service";
import { isNotNullable, Product, VariantOptionQualifier } from "@spartacus/core";
import { distinctUntilKeyChanged, filter, map, take, withLatestFrom } from "rxjs/operators";
import { combineLatest, Observable, Subscription } from "rxjs";
import { CurrentProductService } from "@spartacus/storefront";
import { environment } from "../../../../../../../environments/environment";
import { StoredVariants } from "../../../core/store/product-details.state";
import { QualifierOptionValue, VariantViewBag } from "../../../core/models/product-details.models";
import { ProductConfigurationService } from "../../../configurator/core/facade/product-configuration.service";
import { SkuPageService } from "../../../../sku-landing-page/skupage.service";

@Component({
  selector: 'cx-product-variants-container',
  templateUrl: './product-variants-container.component.html',
  styleUrls: [ './product-variants-container.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class CustomProductVariantsContainerComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();
  product: Product;
  isSkuPage = this.skuService.isSKUPage();

  variants$: Observable<{ variant: VariantViewBag; selected: QualifierOptionValue }[]> = combineLatest([
    this.service.variants$,
    this.service.getSelectedVariants()
  ]).pipe(
    map(([ variants, actives ]) => {
      return variants?.map(variant => {
        let selected: QualifierOptionValue;
        if (this.skuService.isSKUPage() && variant?.options?.length) {
          variant.selected = variant.options?.find(opt => opt.sku === this.skuService.getCurrentProductCode());
        }
        return {
          variant,
          selected
        }
      });
    })
  );

  get sizeLabel(): string {
    return this.product?.sizeLabel;
  }

  trackBy(i: number, { variant }: { variant: VariantViewBag }): string {
    return variant.qualifier;
  }

  constructor(private service: ProductDetailsService,
              private currentProductService: CurrentProductService,
              private productConfigurationService: ProductConfigurationService,
              private skuService: SkuPageService) {
  }

  ngOnInit(): void {
    this.subscription.add(
      this.currentProductService.getProduct()
        .pipe(
          filter(isNotNullable),
          distinctUntilKeyChanged('code'),
          filter(product => !!product?.code),
          withLatestFrom(this.service.getAllVariants()),
        ).subscribe(([ product, storedVariants ]) => {
        this.product = product;
        this.startVariants(product, storedVariants);
      })
    );

  }

  ngOnDestroy(): void {
    this.service.resetSelectedVariants();
    this.subscription.unsubscribe();
  }

  onVariantChange(option: VariantOptionQualifier, qualifier: string) {
    this.service.activateOptionForCurrentProduct(qualifier, option);
    this.productConfigurationService.clearConfiguratorState(this.product.code);
  }

  private startVariants(product: Product, storedVariants: StoredVariants): void {
    try {
      const options = product.baseOptions[ 0 ].options[ 0 ].variantOptionQualifiers.filter(v => !v.hiddenFromUi)
      if (options?.length) {
        const firstOption = options[ 0 ];
        if (!storedVariants?.[ product.code ]) {
          this.service.startVariantForProduct(product, options);
          this.service.loadVariantOptions(product, firstOption);
        }
        if (firstOption.qualifier.toLowerCase().indexOf('color') >= 0) {
          const defaultValue = product.baseOptions[ 0 ].selected?.variantOptionQualifiers
            ?.find(v => v.qualifier === firstOption.qualifier);
          if (defaultValue) {
            this.variants$.pipe(
              map(variants => variants && variants?.find(v => v.variant?.qualifier === defaultValue.qualifier)),
              filter(v => v?.variant?.options?.length > 0),
              take(1)
            ).subscribe(({ variant }) => {
              const opt = variant?.options?.find(o => o.value === defaultValue.value);
              if (!!opt) {
                this.service.activateOption(product.code, variant.qualifier, opt);
              }
            })
          }
        }
      }
    } catch {
      if (!environment.production) {
        console.info('The product neither is multidimensional nor it has baseOptions with appropriate qualifier');
      }
    }
  }


}

<div class="leadtime-modal-container">
  <div class="w-75">
    <div class="leadtime-modal-content">
      <div class="leadtime-modal-header">
        <p class="leadtime-description-title font-weight-bold">{{ 'productSummary.leadtimeModalTitle' | cxTranslate }}</p>
        <i class="fa-solid fa-x leadtime-close-btn" aria-hidden="true" (click)="close()"></i>
      </div>
        <p class="m-b-0">{{ 'productSummary.leadtimeModalNote' | cxTranslate }}</p>
    </div>
  </div>
</div>
<ng-container *ngIf="banner as b">
  <section [ngStyle]="bg$ | async" class="panel-banner-item">
    <div class="panel-banner-item__content ">
      <h4 [ngClass]="banner.titleColor" class="panel-banner-item__header">
        {{ banner.titleText }}
      </h4>

      <ng-container *ngIf="banner.urlLink">
        <cx-generic-link
          [class]="(banner.buttonColor ?? 'btn-primary') + ' btn panel-banner-item__cta'"
          [title]="banner.titleText"
          [url]="banner.urlLink!">
          {{ banner.buttonText }}
        </cx-generic-link>
      </ng-container>
    </div>
  </section>
</ng-container>

import { Component } from '@angular/core';

@Component({
  selector: 'landing-page2-template',
  templateUrl: './landing-page2-template.component.html',
  styleUrls: ['./landing-page2-template.component.scss']
})
export class LandingPage2TemplateComponent {

}

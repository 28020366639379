import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import {
  CmsConfig,
  FeaturesConfigModule,
  I18nModule,
  provideConfig,
  UrlModule,
} from '@spartacus/core';
import {
  AtMessageModule,
  defaultViewConfig,
  IconModule,
  ItemCounterModule,
  ListNavigationModule,
  MediaModule,
  OutletModule,
  PageComponentModule,
  SpinnerModule,
  StarRatingModule,
  ViewConfig,
} from '@spartacus/storefront';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { CustomProductGridItemComponent } from './custom-product-grid-item/custom-product-grid-item.component';
import { CustomProductScrollComponent } from './container/custom-product-scroll/custom-product-scroll.component';
import { CustomProductListComponent } from './container/custom-product-list.component';
import { defaultOccProductConfig } from './custom-occ-product-config';
import { ProductPriceModule } from '../product-price/product-price.module';
import { WishlistButtonModule } from '../../shared/components/wishlist-button/wishlist-button.module';
import { CustomProductCardModule } from '../custom-product-card/custom-product-card.module';

@NgModule({
  imports: [
    AtMessageModule,
    CommonModule,
    FeaturesConfigModule,
    I18nModule,
    IconModule,
    InfiniteScrollModule,
    ItemCounterModule,
    ListNavigationModule,
    MediaModule,
    OutletModule,
    PageComponentModule,
    RouterModule,
    SpinnerModule,
    StarRatingModule,
    UrlModule,
    ProductPriceModule,
    WishlistButtonModule,
    CustomProductCardModule
  ],
  providers: [
    provideConfig(defaultOccProductConfig),
    provideConfig(<ViewConfig>defaultViewConfig),
    provideConfig(<CmsConfig>{
      cmsComponents: {
        CMSProductListComponent: {
          component: CustomProductListComponent,
          data: {
            composition: {
              inner: ['ProductAddToCartComponent'],
            },
          },
        },
        ProductGridComponent: {
          component: CustomProductListComponent,
          data: {
            composition: {
              inner: ['ProductAddToCartComponent'],
            },
          },
        },
        SearchResultsListComponent: {
          component: CustomProductListComponent,
          data: {
            composition: {
              inner: ['ProductAddToCartComponent'],
            },
          },
        },
      },
    }),
  ],
  declarations: [
    CustomProductListComponent,
    CustomProductGridItemComponent,
    CustomProductScrollComponent,
  ],
  exports: [
    CustomProductListComponent,
    CustomProductGridItemComponent,
    CustomProductScrollComponent,
  ],
})
export class CustomProductListModule {}

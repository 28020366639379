import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ActiveCartFacade, Cart, OrderEntry } from '@spartacus/cart/base/root';
import { CheckoutDeliveryAddressComponent, CheckoutStepService } from '@spartacus/checkout/base/components';
import { CheckoutDeliveryAddressFacade, CheckoutDeliveryModesFacade } from '@spartacus/checkout/base/root';
import { Address, GlobalMessageService, TranslationService, UserAddressService, WindowRef } from '@spartacus/core';
import { Card } from '@spartacus/storefront';
import { AddressCardService } from 'src/app/components/_SPA-Components/custom-myaccount/address-book/address-card.service';
import { getCustomAddressNumbers } from 'src/app/components/utils/address-number-utils';
import { map, switchMap, take } from "rxjs/operators";
import { Observable } from 'rxjs';
import { DataLayerService } from 'src/app/spartacus/features/data-layer/data-layer.service';



@Component({
  selector: 'app-custom-checkout-delivery-address',
  templateUrl: './custom-checkout-delivery-address.component.html',
})
export class CustomCheckoutDeliveryAddressComponent extends CheckoutDeliveryAddressComponent {
  lastAddress: Address | undefined;
  cart$: Observable<Cart>;
  items: any[] = [];

  constructor(
    userAddressService: UserAddressService,
    checkoutDeliveryAddressFacade: CheckoutDeliveryAddressFacade,
    activatedRoute: ActivatedRoute,
    translationService: TranslationService,
    activeCartFacade: ActiveCartFacade,
    checkoutStepService: CheckoutStepService,
    checkoutDeliveryModesFacade: CheckoutDeliveryModesFacade,
    globalMessageService: GlobalMessageService,
    private addressCardService: AddressCardService,
    protected winRef: WindowRef,
    private dataLayerService: DataLayerService
  ) {
    super(
      userAddressService,
      checkoutDeliveryAddressFacade,
      activatedRoute,
      translationService,
      activeCartFacade,
      checkoutStepService,
      checkoutDeliveryModesFacade,
      globalMessageService,
    )
  };
  override ngOnInit(): void {
    this.loadAddresses();
    this.cards$ = this.createCards();
    this.isUpdating$ = this.createIsUpdating();
    this.trackBeginCheckout();
  }

  override getCardContent(
    address: Address,
    selected: any,
    textDefaultDeliveryAddress: string,
    textShipToThisAddress: string,
    textSelected: string,
    textPhone: string,
    textMobile: string
  ): Card {
    let region = '';
    const isoCode = address.region?.isocode ? address.region?.isocode.split('-')[ 1 ] : address.region?.isocode;
    const numbers = getCustomAddressNumbers(address, textPhone, textMobile);

    if (address.region && address.region.isocode) {
      region = ( isoCode || address.region?.isocode ) + ', ';
    }

    const isSelected = selected && selected.id === address.id;

    return {
      role: 'region',
      title: address.defaultAddress ? textDefaultDeliveryAddress : '',
      text: this.addressCardService.getAddressCard(address),
      actions: !isSelected && [ { name: textShipToThisAddress, event: 'send' } ],
      header: isSelected ? textSelected : '',
      selected: isSelected,
      label: address.defaultAddress
        ? 'addressBook.defaultDeliveryAddress'
        : 'addressBook.additionalDeliveryAddress',
    } as Card;
  }

  override addAddress(address: Address | undefined): void {
    this.lastAddress = undefined;
    if (!address && this.shouldUseAddressSavedInCart() && this.selectedAddress) {
      this.next();
    }
    if (!address) {
      return;
    }
    this.busy$.next(true);
    this.doneAutoSelect = true;
    this.checkoutDeliveryAddressFacade
      .createAndSetAddress(address)
      .pipe(switchMap(() => this.checkoutDeliveryModesFacade.clearCheckoutDeliveryMode()))
      .subscribe({
        complete: () => {
          // we don't call onSuccess here, because it can cause a spinner flickering
          this.next();
        },
        error: () => {
          this.lastAddress = address;
          this.onError();
          this.doneAutoSelect = false;
        },
      });
      
  }

  protected override onError(): void {
    this.busy$.next(false);
  }

  override showNewAddressForm(): void {
    this.addressFormOpened = true;
  }
  override next(): void {
    this.trackAddAddressInfo();
    this.checkoutStepService.next(this.activatedRoute);
  }

  protected trackBeginCheckout(): void {
    // Get the active cart as an observable
    this.cart$ = this.activeCartFacade.getActive();

    // Subscribe to the cart observable to access the cart data
    this.cart$.pipe(take(1)).subscribe(cart => {
      if (cart && cart.entries) {
        this.dataLayerService.beginCheckoutEvent(cart);
      }
    });
  }

  protected trackAddAddressInfo(): void {
    // Get the active cart as an observable
    this.cart$ = this.activeCartFacade.getActive();

    // Subscribe to the cart observable to access the cart data
    this.cart$.pipe(take(1)).subscribe(cart => {
      if (cart && cart.entries) {
        this.dataLayerService.addAddressInfoEvent(cart);
      }
    });
  }
}

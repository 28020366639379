import { Injectable } from "@angular/core";
import { BasePageMetaResolver, CanonicalUrlOptions, CmsService, Page, PageLinkService, RoutingPageMetaResolver, TranslationService, WindowRef } from "@spartacus/core";
import { defer, Observable, of } from "rxjs";
import { filter, map, startWith, switchMap } from "rxjs/operators";

import { PageKeywordsResolver } from "./custom-page-resolver";
import { NavigationEnd, Router } from "@angular/router";

@Injectable({ providedIn: 'root' })
export class CustomBasePageMetaResolver
  extends BasePageMetaResolver
  implements PageKeywordsResolver
{
  constructor(
    protected override cmsService: CmsService,
    protected override translation: TranslationService,
    protected override routingPageMetaResolver: RoutingPageMetaResolver,
    protected override router: Router,
    protected override pageLinkService: PageLinkService,
    private winRef: WindowRef
  ) {
    super(
      cmsService,
      translation,
      routingPageMetaResolver,
      router,
      pageLinkService
    );
  }

  protected override page$: Observable<Page> = defer(() =>
    this.cmsService.getCurrentPage()
  ).pipe(filter((p) => Boolean(p)));

  override resolveDescription(): Observable<string | undefined> {
    return this.page$.pipe(map((p) => p.metaDescription));
  }

  override resolveCanonicalUrl(
    options?: CanonicalUrlOptions
  ): Observable<string> {
    //console.log('2');

    return this.page$.pipe(
      switchMap((p) =>
        p.canonical
          ? of(p.canonical)
          : this.router.events.pipe(
              filter((ev) => ev instanceof NavigationEnd),
              startWith(null),
              map(() =>
                this.pageLinkService.getCanonicalUrl(
                  options,
                  this.removeTrailingSlash(
                    this.winRef.location.href.toLowerCase()
                  )
                )
              )
            )
      )
    );
  }

  resolveKeywords(): Observable<string | undefined> {
    return this.page$.pipe(map((p) => p.metaKeywords));
  }

  removeTrailingSlash(url: string): string {
    if (url.endsWith('/')) {
      return url.slice(0, -1);
    }
    return url;
  }
}

import { Injectable } from '@angular/core';
import { IconDimensions, IconDimensionsUnconverted } from './dimensions.model';

@Injectable({
  providedIn: 'root'
})

export class IconsService {

  constructor() { }

  setDimensions (dimensions : IconDimensionsUnconverted) : IconDimensions{
    const initial_size: number = parseInt( dimensions.size );
    const width = initial_size;
    const height = (initial_size * dimensions.height) / dimensions.width;
    const dimension: IconDimensions = {width, height};

    return dimension;
  }
}

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { Observable } from "rxjs";
import { map, switchMap, take, tap } from "rxjs/operators";
import { GarageService } from "../../spartacus/features/ymm/core/facade/garage.service";

@Injectable()
export class CustomHttpRequestInterceptor implements HttpInterceptor {
  constructor(public garageService: GarageService,
              @Inject(PLATFORM_ID) private platformId: any) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler):
    Observable<HttpEvent<any>> {
    return this.garageService.getActiveVehicle().pipe(
      map(vehicle => {
        return vehicle
          ? req.clone({
            headers: req.headers.set('activevehicle', vehicle.code),
          })
          : req
      }),
      take(1),
      switchMap(req => next.handle(req))
    );
  }
}

import { Component } from '@angular/core';
import { CmsComponentWithChildren, CmsService, Product } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable, combineLatest } from 'rxjs';
import { switchMap, distinctUntilChanged, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CustomCurrentProductService } from '../../current-product.service';

@Component({
  selector: 'app-custom-product-installation-tab',
  templateUrl: './custom-product-installation-tab.component.html',
  styleUrls: ['./custom-product-installation-tab.component.scss']
})

export class CustomProductInstallationTabComponent {
  environment = environment;
  isCollapsed = true
  product$: Observable<Product | null> = this.currentProductService.getProduct();
  children$: Observable<any[]> = this.componentData.data$.pipe(
    switchMap((data) =>
      combineLatest(
        (data?.children ?? '').split(' ').map((component) =>
          this.cmsService.getComponentData<any>(component).pipe(
            distinctUntilChanged(),
            map((child) => {
              if (!child) {
                return undefined;
              }

              if (!child.flexType) {
                child = {
                  ...child,
                  flexType: child.typeCode,
                };
              }

              return child;
            })
          )
        )
      )
    )
  );

  constructor(
    private currentProductService: CustomCurrentProductService,
    private componentData: CmsComponentData<CmsComponentWithChildren>,
    private cmsService: CmsService,
  ) {}
}

import { OccConfig } from "@spartacus/core";

export const productDetailsOccConfig = {
  backend: {
    occ: {
      endpoints: {
        loadVariants: 'products/variants',
        updatePrice: 'products/update-price',
        updateImages: 'products/update-image',
        product_default   : 'products/${productCode}?fields=DEFAULT,averageRating,images(FULL),classifications,manufacturer,numberOfReviews,categories(FULL),baseOptions,baseProduct,variantOptions,variantType',
        product_list      : 'products/${productCode}?fields=code,name,summary,price(formattedValue),images(DEFAULT,galleryIndex),baseProduct',
        product_details   : 'products/${productCode}?fields=averageRating,stock(DEFAULT),description,availableForPickup,code,url,price(DEFAULT),numberOfReviews,manufacturer,categories(FULL),priceRange,multidimensional,tags,images(FULL)',
        product_attributes: 'products/${productCode}?fields=classifications',
        product_price     : 'products/${productCode}?fields=price(formattedValue)',
        product_stock     : 'products/${productCode}?fields=stock(DEFAULT)',
      }
    }
  }
} as OccConfig;

<cx-spinner class="overlay" *ngIf="isUpdating$ | async"> </cx-spinner>

<!-- <span class="h2 m-b-8 bold-common-font covercraft-header">
  {{'customUpdateEmail.title' | cxTranslate}}
</span> -->

<form (ngSubmit)="onSubmit()" [formGroup]="form">
  <label>
    <span class="label-content">{{
      'updateEmailForm.newEmailAddress.label' | cxTranslate
    }}</span>
    <input
      required="true"
      type="email"
      id="emailAddress"
      name="email"
      formControlName="email"
      placeholder="{{
        'updateEmailForm.newEmailAddress.placeholder' | cxTranslate
      }}"
      class="form-control"
    />
    <cx-form-errors [control]="form.get('email')"></cx-form-errors>
  </label>

  <label>
    <span class="label-content">{{
      'updateEmailForm.confirmNewEmailAddress.label' | cxTranslate
    }}</span>
    <input
      required="true"
      type="email"
      name="confirmEmail"
      formControlName="confirmEmail"
      placeholder="{{
        'updateEmailForm.confirmNewEmailAddress.placeholder' | cxTranslate
      }}"
      class="form-control"
    />
    <cx-form-errors [control]="form.get('confirmEmail')"></cx-form-errors>
  </label>

  <label>
    <span class="label-content">{{
      'updateEmailForm.password.label' | cxTranslate
    }}</span>
    <input
      required="true"
      type="password"
      name="password"
      formControlName="password"
      placeholder="{{ 'updateEmailForm.password.placeholder' | cxTranslate }}"
      class="form-control"
      autocomplete="new-password"
      [attr.aria-label]="'updateEmailForm.password.placeholder' | cxTranslate"
      cxPasswordVisibilitySwitch
    />
    <cx-form-errors [control]="form.get('password')"></cx-form-errors>
  </label>

  <hr aria-hidden="true" class="divider-line"/>

  <button class="btn btn-block btn-secondary font-weight-bolder" [disabled]="form.disabled">
    {{ 'customUpdateEmail.save' | cxTranslate }}
  </button>
</form>

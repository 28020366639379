import { Component } from '@angular/core';
import { CheckoutOrderSummaryComponent } from '@spartacus/checkout/base/components';

@Component({
  selector: 'custom-checkout-order-summary',
  templateUrl: './custom-checkout-order-summary.component.html',
})
export class CustomCheckoutOrderSummaryComponent extends CheckoutOrderSummaryComponent {

}

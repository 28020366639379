import { Component, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { AnonymousConsentsService, WindowRef } from '@spartacus/core';
import { LAUNCH_CALLER, LaunchDialogService } from '@spartacus/storefront';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'cx-anonymous-consent-management-banner',
  templateUrl: './anonymous-consent-management-banner.component.html',
})
export class AnonymousConsentManagementBannerComponent implements OnInit, OnDestroy {
  private subscriptions = new Subscription();

  bannerVisible$ = new BehaviorSubject(false);

  constructor(
    protected anonymousConsentsService: AnonymousConsentsService,
    protected vcr: ViewContainerRef,
    private winRef: WindowRef,
    protected launchDialogService: LaunchDialogService
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.anonymousConsentsService.isBannerVisible().subscribe(shouldDisplay => this.bannerVisible$.next(shouldDisplay))
    )
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  viewDetails(): void {
    this.hideBanner();
    const dialog = this.launchDialogService.openDialog(
      LAUNCH_CALLER.ANONYMOUS_CONSENT,
      undefined,
      this.vcr
    );
    if (dialog) {
      this.subscriptions.add(dialog.subscribe());
    }
  }

  allowAll(): void {

    if (this.winRef.isBrowser()) {
      const _window: any = this.winRef.nativeWindow;
      _window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
      _window.dataLayer.push({
        "event":"consent_update",
        "consent": {
          "ad_storage": "granted",
          "ad_user_data": "granted",
          "ad_personalization": "granted",
          "analytics_storage": "granted",
          "functionality_storage": "granted",
          "personalization_storage": "granted",
          "security_storage": "granted"
        }
      })
    }

    this.subscriptions.add(
      this.anonymousConsentsService
        .giveAllConsents()
        .pipe(tap(() => this.hideBanner()))
        .subscribe()
    );


  }

  hideBanner(): void {
    this.anonymousConsentsService.toggleBannerDismissed(true);
  }
}

<ng-container >
    <div class="description-container">
        <div class="description-header" (click)="toggleCollapse()">
            <span class="description-title font-weight-bolder">{{ 'productDescription.title' | cxTranslate }}</span>
            <i class="icon" [ngClass]="{'fa-plus': isCollapsed, 'fas fa-minus': !isCollapsed}"></i>
        </div>
        <div class="description-content" *ngIf="!isCollapsed">
            <div class="row">
                <div class="col-6">
                    <p>The Technology Behind WeatherShield HP Over 25 years ago Covercraft partnered with Nextec Applications, inc., a technology leader that pioneered a new process for creating performance fabrics used primarily in the outdoor clothing industry. Through precise polymer placement and special chemistries, they were able to place protective finishes inside the fabric, encapsulating the actual woven fibers to transform ordinary fabric into extra-ordinary! The encapsulation processes, methods, and equipment are protected by more than 51 patents issued or pending in the U.S. and internationally. </p>
                </div>
                <div class="col-6">
                    <p class="description-badges-title font-weight-bold">
                        {{ 'productDescription.badgesTitle' | cxTranslate }}
                    </p>
                    <div class="description-badges-list" *ngFor="let badge of mockedBades">
                        <p class="mb-3">
                            <img src="{{badge.image}}" class="description-badges-image"/> 
                            {{badge.code}}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>
<aside class="custom-cart-item-list-row__child">
  <div class="row">
    <div class="col-2 cx-image-container">
      <cx-media [container]="item.product?.images?.['PRIMARY']" format="product"></cx-media>
    </div>

    <section class="col-10">

      <!-- PRODUCT NAME -->
      <div *ngIf="item.product?.name" class="cx-name">
        <div class="cx-link">
          <h3 class="bold-common-font">{{ item.product?.name }}</h3>
        </div>
      </div>

      <!-- PRICE -->
      <div *ngIf="item.basePrice">
        <div *ngIf="item?.product.priceWithDiscount.value !== item.product.price.value; else noDiscount" class="cx-value mt-2">
              <span class="custom-price bold-common-font">
                {{ item?.product.priceWithDiscount.formattedValue }}
                <span class="bold-common-font custom-price__promotion">Sale</span>
                <span class="custom-price__og-price medium-common-font">{{ item.product.price.formattedValue }}</span>
                <span class="custom-price__discount medium-common-font">save
                  {{ item?.product.discount.formattedValue }} ({{ item?.product.discountPercentage }}% off)</span>
              </span>
        </div>

        <ng-template #noDiscount>
          <div class="cx-value mt-2">
                <span class="custom-price bold-common-font">
                  {{ item?.basePrice.formattedValue }}
                </span>
          </div>
        </ng-template>
      </div>

      <!-- PART NUMBER -->
      <div *ngIf="item.partNumber" class="mt-1">
        <span class="bold-common-font">Part #: </span> {{ item.partNumber }}
      </div>

      <!-- Remove -->
      <ng-container *ngIf="!readonly">
        <div class=" cx-remove-btn">
          <button
            (click)="removeEntry()"
            [cxAtMessage]="'cartItems.itemRemoved' | cxTranslate" attr.aria-label="{{ 'addToCart.removeFromCart' | cxTranslate }}"
            class="btn btn-tertiary">
            {{ 'common.remove' | cxTranslate }}
          </button>
        </div>
      </ng-container>
    </section>

  </div>
</aside>

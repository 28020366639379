import { Component } from '@angular/core';
import { ActiveCartFacade, PaymentDetails } from '@spartacus/cart/base/root';
import { CheckoutStepService } from '@spartacus/checkout/base/components';
import { CheckoutPaymentFacade, CheckoutStepType } from '@spartacus/checkout/base/root';
import { Address, TranslationService } from '@spartacus/core';
import { paymentMethodCard } from '@spartacus/order/root';
import { Card, ICON_TYPE } from '@spartacus/storefront';
import { combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { CustomDeliveryAddressCard } from '../../order-cart-utils';
import { AddressCardService } from 'src/app/components/_SPA-Components/custom-myaccount/address-book/address-card.service';

@Component({
  selector: 'cx-checkout-review-payment',
  templateUrl: './custom-checkout-review-payment.component.html',
  styleUrls: [ './custom-checkout-review-payment.component.scss' ]
})

export class CustomCheckoutReviewPaymentComponent {
  iconTypes = ICON_TYPE;

  paymentDetailsStepRoute = this.checkoutStepService.getCheckoutStepRoute(
    CheckoutStepType.PAYMENT_DETAILS
  );

  addressDetailsStepRoute = this.checkoutStepService.getCheckoutStepRoute(
    CheckoutStepType.DELIVERY_ADDRESS
  );

  constructor(
    protected checkoutStepService: CheckoutStepService,
    protected checkoutPaymentFacade: CheckoutPaymentFacade,
    protected translationService: TranslationService,
    private activeCartService: ActiveCartFacade,
    private addressCardService: AddressCardService
  ) {
  }

  paymentDetails$: Observable<PaymentDetails | undefined> =
    this.checkoutPaymentFacade.getPaymentDetailsState().pipe(
      filter((state) => !state.loading && !state.error),
      map((state) => state.data)
    );

  getPaymentMethodCard(paymentDetails: PaymentDetails): Observable<Card> {
    return combineLatest([
      this.translationService.translate('paymentForm.payment'),
      this.translationService.translate('paymentCard.expires', {
        month: paymentDetails.expiryMonth,
        year: paymentDetails.expiryYear,
      }),
    ]).pipe(
      map(([ textTitle, textExpires ]) =>
        paymentMethodCard(textTitle, textExpires, paymentDetails)
      )
    );
  }

  getShippingAddressCard(): Observable<Card> {
    return this.activeCartService.getActive().pipe(
      map(cart => {
        const textTitle = 'Shipping Address';
        const textPhone = '';
        const textMobile = '';
        const deliveryAddress: Address = {
          defaultAddress: cart?.deliveryAddress?.defaultAddress,
          firstName: `${ cart?.deliveryAddress?.firstName } ${ cart?.deliveryAddress?.lastName }`,
          line1: `${ cart?.deliveryAddress?.line1 } ${ cart?.deliveryAddress?.line2 }`,
          town: cart?.deliveryAddress?.town,
          postalCode: cart?.deliveryAddress?.postalCode
        };
        const countryName = `${ cart?.deliveryAddress?.region?.isocodeShort }, ${ cart?.deliveryAddress?.country?.isocode }`;

        const addressCart = CustomDeliveryAddressCard(textTitle, textPhone, textMobile, deliveryAddress, countryName);
        addressCart.text = this.addressCardService.getAddressCard(cart?.deliveryAddress);

        return addressCart
      })
    )
  }
}

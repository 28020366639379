import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomFacetComponent } from './custom-facet.component';
import { AtMessageModule, IconModule, KeyboardFocusModule, StarRatingModule } from '@spartacus/storefront';
import { I18nModule, UrlModule } from '@spartacus/core';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    AtMessageModule,
    I18nModule,
    IconModule,
    KeyboardFocusModule,
    RouterModule,
    UrlModule,
    StarRatingModule
  ],
  declarations: [CustomFacetComponent],
  exports: [CustomFacetComponent],
})
export class CustomFacetModule { }

import { Component } from '@angular/core';
import { ActiveCartFacade } from "@spartacus/cart/base/root";

@Component({
  selector: 'free-shipping-success-messages',
  templateUrl: './free-shipping-success-messages.component.html',
  styleUrls: [ './free-shipping-success-messages.component.scss' ]
})

export class FreeShippingSuccessMessagesComponent {
  cart$ = this.activeCartService.getActive();

  constructor(
    private activeCartService: ActiveCartFacade
  ) {
  }
}

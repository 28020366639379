<div class="cx-product-list" *ngIf="model$ | async as model">
  <section
    tabindex="-1"
    [attr.aria-label]="'productList.productListResults' | cxTranslate"
  >
  <div id="product-results-list" class="cx-product-container">
    <!-- Product list when using pagination -->
    <cx-product-scroll
      [scrollConfig]="scrollConfig"
      [model]="model"
      [inputViewMode]="ViewModes.Grid"
    ></cx-product-scroll>
  </div>
  </section>
</div>

import { Injectable, OnDestroy } from "@angular/core";
import { ConfiguratorManagerBase } from "../../core/facade/configurator-manager-base.service";
import { combineLatest, Observable, Subscription } from "rxjs";
import { map } from "rxjs/operators";
import { ConfiguratorManagerContract } from "../../core/facade/configurator-manager.contract";
import { CurrentProductService } from "@spartacus/storefront";
import { ProductConfiguratorButton } from "../../core/models/product-configuration.models";
import { ProductConfigurationService } from "../../core/facade/product-configuration.service";
import { ConfigurationSelection, ProductConfigStepSelection } from "../../core/store/product-configuration.state";
import { EventService } from "@spartacus/core";
import { GarageService } from "../../../../ymm/core/facade/garage.service";

@Injectable({ providedIn: 'root' })
export class VehicleManagerService implements ConfiguratorManagerContract, OnDestroy {
  private subscription = new Subscription();

  getPrice(stepSelection: ProductConfigStepSelection) {
    return { price: 0, priceWithDiscount: 0 };
  }

  private saveButton$ = combineLatest([
    this.managerBase.saveButton$,
    this.productConfigService.getConfigSelection(),
    this.garageService.ymmFormState$
  ]).pipe(
    map(([ button, configSelection, ymmState ]) => {
      button.disabled = ymmState?.status !== 'VALID'
        || !configSelection.subModel
        || !configSelection.subModelOption;
      return button;
    })
  );
  private nextButton$ = combineLatest([
    this.managerBase.nextButton$,
    this.productConfigService.getConfigSelection(),
    this.currentProductService.getProduct(),
    this.productConfigService.getConfigurationForCurrentProduct()
  ]).pipe(
    map(([ button, configSelection, product, config ]) => {
      button.disabled = !configSelection.subModel
        || ( !product.hasAdditionalProductLines && !configSelection.subModelOption );
      return button;
    })
  );
  private buttons$ = combineLatest([
    this.managerBase.cancelButton$,
    this.nextButton$,
    this.saveButton$
  ]);

  constructor(private managerBase: ConfiguratorManagerBase,
              private productConfigService: ProductConfigurationService,
              private currentProductService: CurrentProductService,
              private eventService: EventService,
              private garageService: GarageService) {
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  getButtons(): Observable<ProductConfiguratorButton[]> {
    return this.buttons$;
  }

  getCartPayloadPart(selection: ConfigurationSelection): string[] {
    return [];
  }
}

<div class="row w-100">

<div class="col-lg-6 col-md-6 col-sm-12 checkout-login-container checkout-login-border">
  <h5 class="bold-common-font mb-4">Returning Customer</h5>
  <custom-checkout-login-form></custom-checkout-login-form>
</div>

<div class="col-lg-6 col-md-6 col-sm-12 checkout-login-container">

<form (ngSubmit)="onSubmit()" [formGroup]="checkoutLoginForm" class="custom-guest-checkout-form">
<h5 class="bold-common-font mb-4">New Customer</h5>
<p class="medium-common-font">Creating an account allows you to save payment info and easily keep track of your purchases. You may also create an
account at the end of the checkout process.</p>
 <div class="form-group">
  <label>
   <span class="label-content">{{
    'checkoutLogin.emailAddress.label' | cxTranslate
    }}</span>
   <input required="true" type="email" name="email" id="emailAddress" class="form-control" formControlName="email" placeholder="{{
          'checkoutLogin.emailAddress.placeholder' | cxTranslate
        }}" />
   <cx-form-errors [control]="checkoutLoginForm.get('email')"></cx-form-errors>
  </label>
 </div>

 <!-- <div class="form-group">
  <label>
   <span class="label-content">{{
    'checkoutLogin.confirmEmail.label' | cxTranslate
    }}</span>
   <input required="true" type="email" name="emailConfirmation" class="form-control" formControlName="emailConfirmation"
    placeholder="{{
          'checkoutLogin.confirmEmail.placeholder' | cxTranslate
        }}" />
   <cx-form-errors [control]="checkoutLoginForm.get('emailConfirmation')"></cx-form-errors>
  </label>
 </div> -->

 <button type="submit" class="btn btn-block btn-secondary">
    Guest Checkout
 </button>
 <div class="w-100 my-2 text-center">
  or
 </div>
  <button type="button" class="btn btn-block btn-optional-1" [routerLink]="{ cxRoute: 'register' } | cxUrl">
    Create Account
  </button>
</form>
</div>
</div>

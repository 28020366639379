import { Injectable } from "@angular/core";
import { combineLatest, Observable } from "rxjs";
import { ConfiguratorManagerBase } from "../../core/facade/configurator-manager-base.service";
import { map, withLatestFrom } from "rxjs/operators";
import { ConfiguratorManagerContract } from "../../core/facade/configurator-manager.contract";
import { CurrentProductService } from "@spartacus/storefront";
import { ProductConfigStepType, ProductConfiguratorButton } from "../../core/models/product-configuration.models";
import { ProductConfigurationService } from "../../core/facade/product-configuration.service";
import { ConfigurationSelection, LogoSelection, ProductConfigStepSelection } from "../../core/store/product-configuration.state";
import { uniteLatest } from "@spartacus/core";

@Injectable({ providedIn: 'root' })
export class LogoManagerService
  implements ConfiguratorManagerContract {

  canSaveOrSkip$ = combineLatest([
    this.currentProductService.getProduct(),
    this.productConfigService.getConfigSelection()
  ]).pipe(
    map(([ product, configSelection ]) => {
      return !product.hasMandatoryLogo
        || configSelection.selections
          ?.some(s => s.stepType === ProductConfigStepType.LOGOS);
    })
  );

  private saveButton$ = uniteLatest([
    this.managerBase.saveButton$,
    this.canSaveOrSkip$
  ]).pipe(
    map(([ button, canSaveOrSkip, ]) => {
      button.disabled = !canSaveOrSkip;
      return button;
    })
  );

  private buttons$ = combineLatest([
    this.managerBase.backButton$,
    this.managerBase.nextButton$,
    this.saveButton$
  ]);

  constructor(private managerBase: ConfiguratorManagerBase,
              private productConfigService: ProductConfigurationService,
              protected currentProductService: CurrentProductService) {
  }

  getButtons(): Observable<ProductConfiguratorButton[]> {
    return this.buttons$;
  }

  getCartPayloadPart(selection: ConfigurationSelection): string[] {
    const logo = selection.selections
      ?.find(s => s.stepType === ProductConfigStepType.LOGOS) as LogoSelection;
    if (!logo) {
      return [];
    }
    return [ `logo::${ logo.item.logoCode }` ];
  }

  getPrice(stepSelection: ProductConfigStepSelection) {
    const logoSelection = stepSelection as LogoSelection;
    return {
      price: logoSelection?.item?.price?.value ?? 0,
      priceWithDiscount: logoSelection?.item?.priceWithDiscounts?.value ?? 0
    };
  }


}

import { Injectable } from "@angular/core";
import { BreadcrumbMeta, PageBreadcrumbResolver, PageHeadingResolver, SearchPageMetaResolver } from "@spartacus/core";
import { combineLatest, Observable } from "rxjs";
import { map, switchMap } from "rxjs/operators";

@Injectable({ providedIn: 'root' })
export class CustomSearchPageMetaResolver
  extends SearchPageMetaResolver
  implements PageHeadingResolver, PageBreadcrumbResolver {

  /**
   * Breadcrumb for the home page.
   */
  protected homeBreadcrumb$: Observable<BreadcrumbMeta[]> = this.translation
    .translate('common.home')
    .pipe(map((label) => [ { label: label, link: '/' } ]));


  /**
   * Resolves the heading that is rendered for the
   * page title inside the 'breadcrumb' component.
   */
  resolveHeading(): Observable<string> {
    return this.query$.pipe(
      switchMap((query) => {
          return this.translation.translate(
            'pageMetaResolver.search.heading',
            { query }
          );
        }
      )
    );
  }

  resolveBreadcrumbs(): Observable<BreadcrumbMeta[]> {
    return combineLatest([
      this.homeBreadcrumb$,
      this.productSearchService.getResults()
    ]).pipe(
      map(([ home, results ]) => {
        return [ ...home, { label: results.freeTextSearch } ]
      })
    );
  }


}

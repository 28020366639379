<ng-container *ngIf="entries$ | async as entries">
  <ng-container *ngIf="entries.length > 0">

    <!-- DELIVERY MODE -->
    <div class="cx-review-summary-card-container">

      <div class="delivery-mode__title-container">
        <h2 class="custom-checkout-title  d-lg-block d-xl-block">
          {{ 'deliveryMethod.title' | cxTranslate }}
        </h2>
      </div>

      <ng-container *ngIf="deliveryMode$ | async as mode">
        <div class="d-flex">
          <article class="delivery-mode-card delivery-mode-card__selected">
            <delivery-truck-icon class="delivery-mode-card__icon"></delivery-truck-icon>
            <div class="delivery-mode-card__name"> {{ mode.name }}</div>
            <div class="delivery-mode-card__price"> {{ mode.deliveryCost?.formattedValue }}</div>
          </article>
        </div>
      </ng-container>

    </div>

    <h4 class="checkout-delivery-mode__title-container m-t-40" style="font-weight: 800; border-bottom: none;">
      {{ 'reviewCheckout.reviewItems' | cxTranslate }}
    </h4>

    <ng-container *ngIf="cart$ | async as cart">
      <custom-cart-promotion [promotions]="(cart.appliedOrderPromotions || []).concat( cart.potentialOrderPromotions || [])"
                             cssClasses="cart-details-promotion medium-common-font mb-2">
      </custom-cart-promotion>
    </ng-container>

    <div class="cx-review-cart-item">
      <ng-template [cxOutletContext]="{ items: entries, readonly: true }" cxOutlet="customCartItemList">
      </ng-template>
    </div>
  </ng-container>
</ng-container>

export enum CurrentDevice {
  MOBILE = `MOBILE`,
  TABLET = `TABLET`,
  DESKTOP = `DESKTOP`,
  WIDESCREEN = `WIDESCREEN`,
}

export enum BreakPoints {
  MOBILE = 575,
  TABLET = 991,
  DESKTOP = 1199
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SimpleToggleComponent } from './simple-toggle.component';
import { I18nModule } from '@spartacus/core';



@NgModule({
  declarations: [
    SimpleToggleComponent
  ],
  imports: [
    CommonModule,
    I18nModule
  ],
  exports: [
    SimpleToggleComponent
  ]
})
export class SimpleToggleModule { }

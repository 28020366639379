import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomProductSearchDetailsComponent } from './custom-product-search-details.component';
import { CmsConfig, I18nModule, provideConfig } from '@spartacus/core';
import { ListNavigationModule } from '@spartacus/storefront';
import { NoResultsFoundModule } from '../../no-results-found/no-results-found.module';
import { CustomProductFacetNavigationModule } from '../custom-product-facet-navigation/custom-product-facet-navigation.module';
import { CustomActiveFacetsComponent } from "../custom-product-facet-navigation/custom-active-facets/custom-active-facets.component";

@NgModule({
  imports: [
    CommonModule,
    I18nModule,
    ListNavigationModule,
    CustomActiveFacetsComponent,
    NoResultsFoundModule,
    CustomProductFacetNavigationModule
  ],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        ProductSearchDetailsComponent: {
          component: CustomProductSearchDetailsComponent,
        },
      },
    }),
  ],
  declarations: [ CustomProductSearchDetailsComponent ],
  exports: [ CustomProductSearchDetailsComponent ],
})
export class CustomProductSearchDetailsModule {
}

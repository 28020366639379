import { ChangeDetectionStrategy, Component, Injectable, Input } from '@angular/core';
import { SearchBoxComponentService } from '@spartacus/storefront';


@Component({
    selector: 'cx-product-card',
    templateUrl: './product-card.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomProductCardComponent  {
 @Input() product: any;
 @Input() config: any;
}

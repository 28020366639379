<ng-container *ngIf="canRender">
  <ng-container *ngIf="{
  title: title$ | async,
  items: items$ | async
} as data">
    <div class="container">
      <div [class]="'product-carousel__header'+ (categoryFilledComponent ? '--category' : '')">
        <div *ngIf="data.items?.length > 0" [class]="'product-carousel__title'+ (categoryFilledComponent ? '--category' : '')">
          {{ data.title }}
        </div>

        <ng-container *ngIf="category$ | async as category">
          <div (click)="viewAll(category)" class="product-carousel__category">
            <p class="product-carousel__category--text">
              {{ 'productCarousel.viewAll' | cxTranslate }}
            </p>
          </div>
        </ng-container>
      </div>

      <cx-custom-carousel [attr.aria-label]="'productCarousel.carouselLabel' | cxTranslate: { title: title$ | async }" [autoplayOn]="false" [items]="data.items" [showArrowButtons]="true"
                          [template]="carouselItem" itemWidth="230px"
                          role="region">
      </cx-custom-carousel>
    </div>
  </ng-container>

  <ng-template #carouselItem let-item="item">
    <custom-product-carousel-item [product]="item"></custom-product-carousel-item>
  </ng-template>
</ng-container>

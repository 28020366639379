import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { BannerLegacyOfQualityComponent } from './components/banner-loq/banner-legacy-of-quality.component';
import { CmsConfig, provideConfig } from '@spartacus/core';
import { GenericLinkModule, MediaModule } from "@spartacus/storefront";
import { BannerLegacyOfQualityItemComponent } from './components/banner-loq-item/banner-loq-item.component';

@NgModule({
  declarations: [
    BannerLegacyOfQualityComponent,
    BannerLegacyOfQualityItemComponent,
  ],
  imports: [
    CommonModule,
    NgOptimizedImage,
    MediaModule,
    GenericLinkModule,
  ],
  exports: [ BannerLegacyOfQualityComponent ],
  providers: [
    provideConfig({
      cmsComponents: {
        LegacyOfQualityBannerCollectionComponent: {
          component: BannerLegacyOfQualityComponent,
        },
      },
    } as CmsConfig),
  ]
})

export class BannerLegacyOfQualityModule {
}

<div class="family-covercraft__background" [style.backgroundImage]="'url(' + (bg$ | async )+ ')'">
    <div class="family-covercraft__content">
        <div class="row family-covercraft__row">
            <div class="family-covercraft__item col-12 " *ngIf="isLogoVisible">
                <img [src]="middleIcon" alt="covercraft-logo" class="family-covercraft__logo ">
            </div>
            <div [class]="(isLogoVisible ? 'family-covercraft__item--button--logo' : 'family-covercraft__item--button--noLogo') + ' col-12 '">
                <ng-container >
                    <cx-generic-link
                      [class]="(banner?.buttonColor ?? 'btn-light') + ' btn family-covercraft__cta '"
                      [title]="banner?.buttonText"
                      [url]="normalizeUrl(banner?.urlLink)">
                      <span [class]="(banner?.buttonTextColor ?? 'text-secondary') + ' family-covercraft__cta--text'">
                        {{ banner?.buttonText }}
                      </span>
                    </cx-generic-link>
                </ng-container>
            </div>
        </div>
    </div>
</div>
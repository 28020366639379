import { Component } from '@angular/core';
import { CartDetailsComponent } from '@spartacus/cart/base/components';
import { map } from 'rxjs/operators';

@Component({
  selector: 'cart-top-content',
  templateUrl: './cart-top-content.component.html',
  styleUrls: ['./cart-top-content.component.scss'],
})
export class CartTopContentComponent extends CartDetailsComponent {
  promotions$ = this.activeCartService
    .getActive()
    .pipe(map((cart) => cart.appliedOrderPromotions));

  removeZeros(inputNumber: string): string {
    const result = parseInt(inputNumber, 10).toString();
    return result;
  }
}



import { defaultPageMetaConfig, PageMetaConfig } from "@spartacus/core";

export const customPageMetaConfig: PageMetaConfig = {
  pageMeta: {
    enableInDevMode: true,
    resolvers: [
      ...defaultPageMetaConfig?.pageMeta?.resolvers ?? [],
      {
        property: 'keywords',
        method: 'resolveKeywords',
      },
      {
        property: 'eyebrow',
        method: 'resolveEyebrow',
      },
    ],
    canonicalUrl: {
      forceHttps: false,
      forceWww: false,
      removeQueryParams: true,
      forceTrailingSlash: false,
    },
  },
};

import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CustomFormValidators } from '@spartacus/storefront';
import { GuestOrderTrackingService } from './guest-order-tracking.service';
import { RecaptchaFactoryService } from '../../../recaptcha/recaptcha-factory.service';
import { RecaptchaWidget } from '../../../recaptcha/recaptcha.widget';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { filter, switchMap, take } from 'rxjs/operators';
import { Consignment, Order, ReplenishmentOrder } from '@spartacus/order/root/model';
import { GlobalMessageService, GlobalMessageType, isNotNullable } from '@spartacus/core';
import { OrderEntry } from '@spartacus/cart/base/root';

@Component({
  selector: 'app-guest-order-tracking',
  templateUrl: './guest-order-tracking.component.html',
  styleUrls: ['./guest-order-tracking.component.scss'],
})
export class GuestOrderTrackingComponent implements AfterViewInit, OnDestroy {
  orderGuestTrackingForm: UntypedFormGroup = this.fb.group({
    orderNumber: ['', Validators.required],
    emailAddress: [
      '',
      [Validators.required, CustomFormValidators.emailValidator],
    ],
  });

  containerId = 'guestOrderTracking';
  private captchaWidget: RecaptchaWidget | undefined;
  private subscription = new Subscription();

  pickupConsignments: Consignment[] | undefined;
  deliveryConsignments: Consignment[] | undefined;

  pickupUnconsignedEntries: OrderEntry[] | undefined;
  deliveryUnConsignedEntries: OrderEntry[] | undefined;

  order$ = new BehaviorSubject<Order>(undefined);
  constructor(
    protected fb: UntypedFormBuilder,
    protected guestOrderService: GuestOrderTrackingService,
    private recaptchaFactory: RecaptchaFactoryService,
    protected globalMessageService: GlobalMessageService
  ) {}

  ngAfterViewInit(): void {
    this.captcha();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  captcha() {
    this.subscription.add(
      this.recaptchaFactory.create(this.containerId).subscribe((widget) => {
        this.captchaWidget = widget;
      })
    );
  }

  submitForm() {
    if (this.orderGuestTrackingForm.valid) {
      this.captchaWidget
        ?.validate()
        .pipe(
          switchMap((token) => {
            const data = this.collectDataFromGuestOrderTrackingForm(
              this.orderGuestTrackingForm.value
            );
            data.recaptchaToken = token;
            return this.guestOrderService.getOrder(data);
          }),
          filter(isNotNullable),
          take(1)
        )
        .subscribe(
          (order) => {
            this.order$.next(order);
          },
          (error) => {
            this.globalMessageService.add(
              'Order not found',
              GlobalMessageType.MSG_TYPE_ERROR
            );
          }
        );
    } else {
      this.orderGuestTrackingForm.markAllAsTouched();
    }
  }

  clearOrder() {
    this.order$ = new BehaviorSubject<Order>(undefined);
  }

  collectDataFromGuestOrderTrackingForm(formData: any) {
    const { orderNumber, emailAddress, recaptchaToken } = formData;

    return {
      orderNumber,
      emailAddress: emailAddress.toLowerCase(),
      recaptchaToken,
    };
  }

  protected getGroupedConsignments(
    order: Order,
    pickup: boolean
  ): Consignment[] | undefined {
    const consignments = pickup
      ? order.consignments?.filter(
          (consignment) => consignment.deliveryPointOfService !== undefined
        )
      : order.consignments?.filter(
          (consignment) => consignment.deliveryPointOfService === undefined
        );

    return this.groupConsignments(consignments);
  }

  protected getUnconsignedEntries(
    order: Order,
    pickup: boolean
  ): OrderEntry[] | undefined {
    if ((order as ReplenishmentOrder).replenishmentOrderCode) {
      return [];
    }
    return pickup
      ? order.unconsignedEntries?.filter(
          (entry) => entry.deliveryPointOfService !== undefined
        )
      : order.unconsignedEntries?.filter(
          (entry) => entry.deliveryPointOfService === undefined
        );
  }

  protected groupConsignments(
    consignments: Consignment[] | undefined
  ): Consignment[] | undefined {
    const grouped = consignments?.reduce((result, current) => {
      const key = this.getStatusGroupKey(current.status || '');
      result[key] = result[key] || [];
      result[key].push(current);
      return result;
    }, {} as { [key: string]: Consignment[] });

    return grouped
      ? [...(grouped[1] || []), ...(grouped[0] || []), ...(grouped[-1] || [])]
      : undefined;
  }

  private getStatusGroupKey(status: string): number {
    if (['DELIVERY_COMPLETED', 'PICKUP_COMPLETE'].includes(status)) {
      return 0;
    }
    if (['CANCELLED'].includes(status)) {
      return -1;
    }
    return 1;
  }
}

<div class="delivery-mode__title-container">

  <h2 class="custom-checkout-title  d-lg-block d-xl-block">
    {{ 'deliveryMethod.title' | cxTranslate }}
  </h2>
</div>

<ng-container *ngIf=" !(isUpdating$ | async) && (filteredSupportedDeliveryModes$ | async) as filteredSupportedDeliveryModes; else loading">
  <div role="status" [attr.aria-label]="'common.loaded' | cxTranslate"></div>

  <div [formGroup]="mode" class="cx-delivery-mode-wrapper">
    <div class="shipping-methods-container" role="radiogroup d-flex">
      <ng-container *ngFor="let mode of filteredSupportedDeliveryModes">
        <div class="form-check" (click)='canEdit ?? selectMode(mode)' [ngClass]="{
          'isActive': mode === selectedMode || selectedCode === mode.code,
          'c-pointer': canEdit,
          'pe-none': !canEdit,
          }">
          <div class="delivery-mode__card">
            <input [attr.aria-checked]="getAriaChecked(mode.code)" class="form-check-input" role="radio" type="radio" id="deliveryMode-{{ mode.code }}" (change)="changeMode(mode.code)" [value]="mode.code" formControlName="deliveryModeId" hidden />

            <label class="cx-delivery-label form-check-label form-radio-label" for="deliveryMode-{{ mode.code }}">
              <delivery-truck-icon></delivery-truck-icon>
              <div class="custom-delivery-mode"> {{ mode.name }} </div>
              <div class="custom-delivery-price"> {{ mode.deliveryCost?.formattedValue }} </div>
              <div class="cx-delivery-details"></div>
            </label>
          </div>
        </div>
      </ng-container>
    </div>

    <ng-container *cxFeatureLevel="'6.4'">
      <ng-template [cxOutlet]="CartOutlets.DELIVERY_MODE" [cxOutletContext]="{ item: activeCartFacade.getActive() | async }"> </ng-template>
    </ng-container>
  </div>

  <div class="row cx-checkout-btns" *ngIf="!hideButtons">
    <div class="col-md-12 col-lg-6 order-md-2 order-sm-2 order-lg-1">
      <button class="btn btn-block btn-optional-1 w-100" (click)="back()">
        {{ backBtnText | cxTranslate }}
      </button>
    </div>

    <div class="col-md-12 col-lg-6 order-md-1 order-sm-1 order-lg-2">
      <button class="btn btn-block btn-secondary w-100"
              [disabled]="deliveryModeInvalid || (isSetDeliveryModeHttpError$ | async)" (click)="next()">
        {{ 'common.continue' | cxTranslate }}
      </button>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="activeCartFacade.hasPickupItems() | async">
  <h2 class="cx-checkout-title d-none d-lg-block d-xl-block"> {{ 'checkoutMode.deliveryEntries' | cxTranslate }} </h2>
  <ng-template [cxOutlet]="CartOutlets.CART_ITEM_LIST" [cxOutletContext]="{ items: activeCartFacade.getDeliveryEntries() | async, readonly: true }"></ng-template>
</ng-container>

<ng-template #loading>
  <div class="cx-spinner">
    <cx-spinner></cx-spinner>
  </div>
</ng-template>

<ng-container cxInnerComponentsHost></ng-container>



import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { ProductDetailsActions } from "./index";
import { catchError, map, mergeMap, switchMap, tap } from "rxjs/operators";
import { ProductDetailsOccAdapter } from "../occ/product-details-occ.adapter";
import { buildKeyChainForPrice, buildKeyChainForVariantOptions } from "../../configurator/core/facade/product-configuration.tools";
import { of } from "rxjs";
import { CustomCurrentProductService } from "../../current-product.service";
import { Store } from "@ngrx/store";
import { ProductDetailsState } from "./product-details.state";

@Injectable({ providedIn: 'root' })
export class ProductDetailsEffects {
  constructor(private actions$: Actions,
              private adapter: ProductDetailsOccAdapter,
              private customCurrentProductService: CustomCurrentProductService,
              private store: Store<ProductDetailsState>) {
  }

  loadProduct$ = createEffect(
    () => this.actions$.pipe(
      ofType(ProductDetailsActions.loadProduct),
      switchMap(
        ({ code, scope }) => {
          return this.customCurrentProductService.loadProduct(code, scope).pipe(
            map((product) => ProductDetailsActions.loadProductSuccess({ product, scope }))
          )
        }
      ),
      catchError(error => of(ProductDetailsActions.loadProductFail()))
    )
  );

  loadVariants$ = createEffect(
    () => this.actions$.pipe(
      ofType(ProductDetailsActions.loadVariantOptions),
      tap(_ => this.store.dispatch(ProductDetailsActions.loadVariantsProcessing({ isProcessing: true }))),
      mergeMap(
        ({ product, qualifier, selectedOptions }) => {
          return this.adapter.loadVariantOptions(product, selectedOptions).pipe(
            mergeMap((variantsResponse) => [
              ProductDetailsActions.loadVariantOptionOk({
                product,
                qualifier,
                selectedOptions,
                options: variantsResponse.variants
              }),
              ProductDetailsActions.loadVariantsProcessing({ isProcessing: false })
            ])
          )
        }
      )
    )
  );

  updatePrice$ = createEffect(
    () => this.actions$.pipe(
      ofType(ProductDetailsActions.updatePrice),
      mergeMap(
        ({ product, options, subModelOption }) => {
          return this.adapter.loadPrice(product, options, subModelOption).pipe(
            map((price) => {
              return ProductDetailsActions.updatePriceOk({
                price,
                keyChain: buildKeyChainForPrice(product, options, subModelOption)
              })
            })
          )
        }
      )
    )
  );

  loadGallery = createEffect(
    () => this.actions$.pipe(
      ofType(ProductDetailsActions.loadGallery),
      switchMap(
        ({ product, options }) => {
          return this.adapter.loadGallery(product, options).pipe(
            map((images) => {
              return ProductDetailsActions.loadGalleryOk({
                images,
                keyChain: buildKeyChainForVariantOptions(product, options, true)
              })
            })
          )
        }
      )
    )
  );
}

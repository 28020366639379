import { CxEvent } from "@spartacus/core";
import { Make, Year, YmmFormStateChangeEvent } from "../models/garage.model";

export class YearChangedEvent extends CxEvent {
  constructor(public year: Year) {
    super();
  }
}

export class MakeChangedEvent extends CxEvent {
  constructor(public year: Year,
              public make: Make) {
    super();
  }
}


export class YmmFormChangedEvent extends CxEvent {
  constructor(public formState: YmmFormStateChangeEvent) {
    super();
  }
}


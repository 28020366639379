import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'custom-footer',
  templateUrl: './custom-footer.component.html',
  styleUrls: ['./custom-footer.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomFooterComponent {

}

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CmsConfig, provideConfig } from "@spartacus/core";
import { CustomParagraphComponent } from "./custom-paragraph.component";
import { SupplementHashAnchorsModule } from "@spartacus/storefront";

@NgModule({
  imports: [
    CommonModule,
    SupplementHashAnchorsModule
  ],
  declarations: [ CustomParagraphComponent ],
  providers: [
    provideConfig({
      cmsComponents: {
        CMSParagraphComponent: {
          component: CustomParagraphComponent,
        },
      }
    } as CmsConfig)
  ]
})
export class CustomParagraphModule {

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomFacetListComponent } from './custom-facet-list.component';
import { CustomFacetModule } from '../custom-facet/custom-facet.module';
import { IconModule, KeyboardFocusModule } from '@spartacus/storefront';
import { I18nModule } from '@spartacus/core';

@NgModule({
  imports: [
    CommonModule,
    I18nModule,
    IconModule,
    CustomFacetModule,
    KeyboardFocusModule,
  ],
  declarations: [CustomFacetListComponent],
  exports: [CustomFacetListComponent],
})
export class CustomFacetListModule { }

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CmsConfig, I18nModule, provideDefaultConfig } from '@spartacus/core';
import { CustomCategoryNavigationComponent } from './custom-category-navigation.component';
import { CustomNavigationModule } from '../custom-navigationUI/custom-navigation.module';

@NgModule({
  imports: [CommonModule, CustomNavigationModule, I18nModule],
  providers: [
    provideDefaultConfig(<CmsConfig>{
      cmsComponents: {
        CategoryNavigationComponent: {
          component: CustomCategoryNavigationComponent,
          data: {
            resetMenuOnClose: true,
          },
        },
      },
    }),
  ],
  declarations: [CustomCategoryNavigationComponent],
  exports: [CustomCategoryNavigationComponent],
})
export class CustomCategoryNavigationModule {}

import { QualifierOptionValue } from "../spartacus/features/product/core/models/product-details.models";

export const hasClass = (element: any, className: string): boolean => {
  while (element) {
    if (element.classList.contains(className)) {
      return true;
    }

    element = element.parentElement;
  }
  return false;
}

export const findParents = (element: any, className: string): any => {
  if (element.classList.contains(className)) {
    return element;
  }

  if (element.parentNode) {
    return findParents(element.parentNode, className);
  }

  return null;
}

export const findChild = (element: any, className: string): any => {
  return element.querySelector(`.${ className }`);
}

export const toggleClass = (element: any, className: string): any => {
  element.classList.contains(className) ? element.classList.remove(className) : element.classList.add(className);
}

export const cloneElement = (element: any): HTMLElement | null => {

  if (element && element[ 0 ]) {
    return element[ 0 ].cloneNode(true);
  }

  if (element) {
    return element;
  }

  return null;
}

export const deepCloning = <T>(obj: T): T => {
  return obj
    ? JSON.parse(JSON.stringify(obj))
    : null;
}

export const fillBannersLink = (banners: any[]): any => {
  return banners.map(banner => {
    banner.urlLink = getBannerLink(banner);
    return banner;
  });
};

export const getBannerLink = (banner: any): any =>
  banner.urlLink
    ? banner.urlLink
    : banner.product
      ? `product/${ banner.product }`
      : banner.category
        ? `c/${ banner.category }`
        : '';


export const variantSelectionChanged = (
  previousSelection: QualifierOptionValue[],
  currentSelection: QualifierOptionValue[]) => {
  const previous = previousSelection?.map(v => `${ v.qualifier }:${ v.option?.value }`).join('|');
  const current = currentSelection?.map(v => `${ v.qualifier }:${ v.option?.value }`).join('|');
  return previous === current;
}



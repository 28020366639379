import { NgModule } from '@angular/core';
import { CmsConfig, I18nConfig, provideConfig } from "@spartacus/core";
import { productImageZoomTranslationChunksConfig, productImageZoomTranslations } from "@spartacus/product/image-zoom/assets";
import { ProductImageZoomRootModule, PRODUCT_IMAGE_ZOOM_FEATURE } from "@spartacus/product/image-zoom/root";
import { CustomProductImageZoomModule } from './product-image-zoom/product-image-zoom.module';

// TODO: Verify if this module can be imported in the 'CustomProductModule'
@NgModule({
  declarations: [],
  imports: [
    ProductImageZoomRootModule,
    CustomProductImageZoomModule
  ],
  providers: [
    provideConfig(<CmsConfig>{
      featureModules: {
        [PRODUCT_IMAGE_ZOOM_FEATURE]: {
          module: () =>
            import('@spartacus/product/image-zoom').then(
              (m) => m.ProductImageZoomModule
            ),
        },
      },
    }),
    provideConfig(<I18nConfig>{
      i18n: {
        resources: productImageZoomTranslations,
        chunks: productImageZoomTranslationChunksConfig,
      },
    }),
  ],
})
export class ProductImageZoomFeatureModule {}

import { Injectable } from "@angular/core";
import { combineLatest, Observable } from "rxjs";
import { ConfiguratorManagerBase } from "../../../core/facade/configurator-manager-base.service";
import { ConfiguratorManagerContract } from "../../../core/facade/configurator-manager.contract";
import { CurrentProductService } from "@spartacus/storefront";
import { AddonsSelection, ConfigurationSelection, ProductConfigStepSelection } from "../../../core/store/product-configuration.state";
import { ProductConfigStepType, ProductConfiguratorButton } from "../../../core/models/product-configuration.models";

@Injectable({ providedIn: 'root' })
export class AddonsManagerService
  implements ConfiguratorManagerContract {

  private buttons$ = combineLatest([
    this.managerBase.backButton$,
    this.managerBase.nextButton$,
    this.managerBase.saveButton$
  ]);

  constructor(private managerBase: ConfiguratorManagerBase,
              protected currentProductService: CurrentProductService) {
  }

  getButtons(): Observable<ProductConfiguratorButton[]> {
    return this.buttons$;
  }

  getCartPayloadPart(selection: ConfigurationSelection): string[] {
    const addonSelection = selection.selections
      ?.find(e => e.stepType === ProductConfigStepType.ADDONS) as AddonsSelection;
    return !addonSelection || !addonSelection.addons?.length
      ? []
      : addonSelection.addons.map(a => {
        return `addonsSku::${ a.sku }`;
      });

  }

  getPrice(stepSelection: ProductConfigStepSelection) {
    const addonSelection = stepSelection as AddonsSelection;
    if (!addonSelection?.addons?.length) {
      return {
        price: 0,
        priceWithDiscount: 0
      }
    }
    return addonSelection.addons.map(
      a => ( {
        price: a.price?.prices?.price.value ?? 0,
        priceWithDiscount: a.price?.prices?.priceWithDiscount?.value ?? 0
      } )
    ).reduce((p, acc) => {
      return {
        price: acc.price + p.price,
        priceWithDiscount: acc.priceWithDiscount + p.priceWithDiscount
      }
    })
  }

}

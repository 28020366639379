import { APP_INITIALIZER, NgModule } from "@angular/core";
import { CmsConfig, provideConfig, RoutingConfig } from "@spartacus/core";
import { GarageStoreModule } from "./core/store/garage-store.module";
import { GarageStatePersistenceService } from "./core/facade/garage-state-persistence.service";
import { garageOccConfig } from "./occ/garage-occ.config";
import { RouterModule, Routes } from "@angular/router";
import { CmsPageGuard, PageLayoutComponent } from "@spartacus/storefront";

function garageStatePersistenceFactory(garagePersistenceService: GarageStatePersistenceService) {
  return () => garagePersistenceService.sync()
}

const routes: Routes = [
  {
    path: 'shop/:ymm',
    component: PageLayoutComponent,
    canActivate: [ CmsPageGuard ],
  }
];

@NgModule({
  imports: [
    GarageStoreModule,
    RouterModule.forChild(routes)
  ],
  providers: [
    provideConfig(garageOccConfig),
    {
      provide: APP_INITIALIZER,
      useFactory: garageStatePersistenceFactory,
      deps: [ GarageStatePersistenceService ],
      multi: true
    },
    provideConfig({
      featureModules: {
        'YMM_FEATURE': {
          module: () => import('./ymm.module').then(m => m.YmmModule),
          cmsComponents: [
            'YMMSearchFlexComponent',
            'YearMakeModelFlexComponent',
            'MyGarageFlexComponent'
          ]
        }
      }
    } as CmsConfig),
    provideConfig({
      routing: {
        routes: {
          ymm: {
            paths: [ 'shop/:ymm' ],
          },
        },
      },
    } as RoutingConfig)
  ]
})
export class YmmFeatureModule {

}

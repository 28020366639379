<!-- Heading -->
<h5 class="configurator-heading">
  Optional add-ons
</h5>

<ng-container *ngIf="addons$ | async as addons">
      <section class="addons-list">
        <addons-card
          (toggleSelection)="onToggleAddon($event)"
          *ngFor="let addon of addons; trackBy: trackByFn"
          [selection]="addon.selection"
          [product]="addon.product">
        </addons-card>
      </section>
</ng-container>

import { Component } from '@angular/core';

@Component({
  selector: 'custom-templates',
  templateUrl: './templates.component.html',
  styleUrls: ['./templates.component.scss']
})

export class TemplatesComponent {

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AllCartMessagesComponent } from './all-cart-messages.component';
import { ShippingRestrictionMessagesComponent } from './components/shipping-restriction-messages/shipping-restriction-messages.component';
import { FreeShippingSuccessMessagesComponent } from './components/free-shipping-success-messages/free-shipping-success-messages.component';
import { FreeShippingCTAMessagesComponent } from './components/free-shipping-ctamessages/free-shipping-ctamessages.component';
import { I18nModule } from '@spartacus/core';

@NgModule({
  declarations: [
    AllCartMessagesComponent,
    ShippingRestrictionMessagesComponent,
    FreeShippingSuccessMessagesComponent,
    FreeShippingCTAMessagesComponent
  ],
  imports: [
    CommonModule,
    I18nModule,
  ],
  exports: [
    AllCartMessagesComponent,
    ShippingRestrictionMessagesComponent,
    FreeShippingSuccessMessagesComponent,
    FreeShippingCTAMessagesComponent
  ]
})

export class AllCartMessagesModule { }

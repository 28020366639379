import { ChangeDetectionStrategy, Component } from '@angular/core';
import { combineLatest } from "rxjs";
import { map } from "rxjs/operators";
import { ProductConfiguratorStepsService } from "../../core/facade/product-configurator-steps.service";
import { ProductConfigurationService } from "../../core/facade/product-configuration.service";
import { GarageService } from "../../../../ymm/core/facade/garage.service";

@Component({
  selector: 'product-lines-dialog-step',
  templateUrl: './product-config-dialog-steps.component.html',
  styleUrls: [ './product-config-dialog-steps.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductConfigDialogStepsComponent {

  steps$ = combineLatest([
    this.stepsService.steps$,
    this.stepsService.currentStep$,
    this.productConfigService.getCurrentSelection(),
    this.garageService.ymmFormState$,
    this.garageService.currentFit$
  ]).pipe(
    map(([ steps, active, selection, ymmFormState, fit ]) => {
      const showAllSteps = selection.subModel
        && ymmFormState.status === 'VALID'
        && fit
      const items = showAllSteps ? steps : steps.slice(0, 1);
      return {
        items,
        active
      }
    })
  );

  constructor(private stepsService: ProductConfiguratorStepsService,
              private productConfigService: ProductConfigurationService,
              private garageService: GarageService) {
  }
}

import { ChangeDetectionStrategy, Component } from "@angular/core";

@Component({
  selector: 'svg-sprite',
  templateUrl: './svg-sprite.component.html',
  styleUrls: [ './svg-sprite.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true
})
export class SvgSpriteComponent {

}

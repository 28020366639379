import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, } from '@angular/core';
import { ImageGroup } from '@spartacus/core';
import { map } from 'rxjs/operators';
import { ProductDetailsService } from '../../core/facade/product-details.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'product-videos-thumbnails',
  templateUrl: './product-videos-thumbnails.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductVideosThumbnailsComponent {
  @Input() active: number;
  @Input() maxGalleryItems = 4;
  @Output() changeMedia = new EventEmitter<{
    container: ImageGroup;
    index: number;
    mediaType: string;
    videoUrl: SafeResourceUrl;
  }>();

  thumbs$ = this.productDetailsService.product$.pipe(
    map((product) => {
      return product.galleryVideos;
    })
  );

  constructor(
    private productDetailsService: ProductDetailsService,
    private sanitizer: DomSanitizer
  ) {
  }

  openImage(
    container: ImageGroup,
    index: number,
    mediaType: string,
    videoUrl: string
  ) {
    this.changeMedia.emit({
      container,
      index,
      mediaType,
      videoUrl: this.sanitizer.bypassSecurityTrustResourceUrl(videoUrl),
    });
  }


  sanitize(url: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}

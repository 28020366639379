import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CmsConfig, provideConfig, provideDefaultConfig } from '@spartacus/core';
import { OutletModule } from '@spartacus/storefront';
import { CustomCheckoutOrderSummaryComponent } from './custom-checkout-order-summary.component';
import { CustomCheckoutPlaceOrderModule } from '../custom-checkout-place-order/custom-checkout-place-order.module';

@NgModule({
  imports: [CommonModule, OutletModule, CustomCheckoutPlaceOrderModule,],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        CheckoutOrderSummary: {
          component: CustomCheckoutOrderSummaryComponent,
        },
      },
    }),
  ],
  declarations: [CustomCheckoutOrderSummaryComponent],
  exports: [CustomCheckoutOrderSummaryComponent],
})
export class CustomCheckoutOrderSummaryModule {}

import { Component, Input } from '@angular/core';
import { IconDimensions } from '../dimensions.model';
import { IconsService } from '../icons.service';

@Component({
  selector: 'talk-to-us-icon',
  templateUrl: './talk-to-us-icon.component.html',
})
export class TalkToUsIconComponent {
  @Input() className: string = '';
  @Input() size: string = '16';

  width: number = 16;
  height: number = 16;

  constructor(private iconService: IconsService) {}

  ngOnInit(): void {
    const dimensions: IconDimensions = this.iconService.setDimensions({
      size: this.size,
      width: this.width,
      height: this.height,
    });
    this.width = dimensions.width;
    this.height = dimensions.height;
  }
}

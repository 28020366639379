import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfigSelectIconComponent } from './config-select-icon.component';



@NgModule({
  declarations: [
    ConfigSelectIconComponent
  ],
  imports: [
    CommonModule
  ],
  exports:[
    ConfigSelectIconComponent
  ]
})
export class ConfigSelectIconModule { }

<form class="cx-place-order-form form-check p-0 m-0" [formGroup]="checkoutSubmitForm">
  <button (click)="submitForm()" class="btn btn-util-4 btn-block justify-content-center" [disabled]="isDisabled$ | async" [cxAtMessage]="'checkoutReview.orderInProcess' | cxTranslate" style="display: flex; align-items: center;">
    <span class="m-r-4">
      <lock-place-order-icon></lock-place-order-icon>
    </span>

    <div>
      {{ 'checkoutReview.placeOrder' | cxTranslate }}
    </div>
  </button>
</form>

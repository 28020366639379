import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchResultsListPageTemplateComponent } from './search-results-list-page-template.component';
import { OutletModule, OutletRefModule, PageLayoutModule, PageSlotModule } from '@spartacus/storefront';
import { PageTitlePLPModule } from './components/page-title-plp/page-title-plp.module';
import { CustomCarouselModule } from "../../../../components/_SPA-Components/custom-carousel";
import {
  CustomCategoryCarouselItemModule
} from "./components/custom-category-carousel-item/custom-category-carousel-item.module";

@NgModule({
  declarations: [
    SearchResultsListPageTemplateComponent,
  ],
  imports: [
    CommonModule,
    OutletModule,
    OutletRefModule,
    PageLayoutModule,
    PageSlotModule,
    PageTitlePLPModule,
    CustomCarouselModule,
    CustomCategoryCarouselItemModule
  ],
  exports: [
    PageTitlePLPModule,
    SearchResultsListPageTemplateComponent
  ]
})

export class SearchResultsListPageTemplateModule { }

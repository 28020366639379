import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FamilyCovercraftComponent } from './family-covercraft.component';
import { CmsConfig, provideConfig } from '@spartacus/core';
import { FamilyCovercraftItemComponent } from './family-covercraft-item/family-covercraft-item.component';
import { GenericLinkModule } from '@spartacus/storefront';



@NgModule({
  declarations: [
    FamilyCovercraftComponent,
    FamilyCovercraftItemComponent,
  ],
  imports: [
    CommonModule,
    GenericLinkModule
  ],
  exports:[
    FamilyCovercraftComponent,
    FamilyCovercraftItemComponent,
  ],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        FamilyCovercraftComponentCollection: {
          component: FamilyCovercraftComponent,
        },
      },
    }),
  ]
  
})
export class FamilyCovercraftModule { }
<div [attr.aria-label]="'searchBox.productSearch' | cxTranslate" role="search">
  <label class="searchbox" [class.dirty]="!!searchInput.value">
    <form [formGroup]="searchForm" class="w-100">
    <input
      #searchInput
      [placeholder]="'searchBox.placeholder' | cxTranslate"
      autocomplete="off"
      aria-describedby="initialDescription"
      aria-controls="results"
      [attr.aria-label]="'searchBox.placeholder' | cxTranslate"
      (focus)="
        open();
        checkEmptyValue()
      "
      (click)="open()"
      (input)="search(searchInput.value)"
      (keydown.escape)="close($any($event))"
      (keydown.enter)="submit($event, searchInput)"
      (keydown.arrowup)="focusPreviousChild($any($event))"
      (keydown.arrowdown)="focusNextChild($any($event))"
      value="{{ chosenWord }}"
      class="custom-label"
      formControlName="query"
      name="query"
    />
  </form>

    <button [attr.aria-label]="'common.reset' | cxTranslate" (mousedown)="clear(searchInput)" (keydown.enter)="clear(searchInput)" class="reset">
      <cx-icon [type]="iconTypes.RESET"></cx-icon>
    </button>

    <div role="presentation" class="search-icon" (click)="submit($event, searchInput)">
      <cx-icon [type]="iconTypes.SEARCH"></cx-icon>
    </div>

    <button [attr.aria-label]="'common.search' | cxTranslate" class="search" (click)="submit($event, searchInput)">
      <cx-icon [type]="iconTypes.SEARCH"></cx-icon>
    </button>
  </label>
</div>

<div *ngIf="results$ | async as result" id="results" class="results" role="dialog">
  <div *ngIf="result.message" class="message" [innerHTML]="result.message"></div>

  <div class="vehicle" *ngIf="isVehicleActive">
    <div class="bullet">
      {{activeVehicle.name}}
      <button type="button" class="close" (mousedown)="removeActiveVehicleFilter(searchInput.value)">
        <span>&times;</span>
      </button>
    </div>
  </div>

  <ng-container *ngIf="result?.suggestions?.length > 0">
  <ul
    [class]="(searchInput.value.length < 4 ? 'suggestions-alt' : '') + ' suggestions'"
    attr.aria-label="{{ 'searchBox.ariaLabelSuggestions' | cxTranslate }}"
    role="listbox"
  >
    <li *ngFor="let suggestion of result.suggestions">
      <a
        role="option"
        [routerLink]="
          {
            cxRoute: 'search',
            params: { query: suggestion }
          } | cxUrl
          "
        (keydown.arrowup)="focusPreviousChild($any($event))"
        (keydown.arrowdown)="focusNextChild($any($event))"
        (keydown.enter)="close($any($event), true)"
        (keydown.escape)="close($any($event), true)"
        (blur)="close($any($event))"
        (mousedown)="preventDefault($event)"
        (click)="
          dispatchSuggestionEvent({
            freeText: searchInput.value,
            selectedSuggestion: suggestion,
            searchSuggestions: result.suggestions ?? []
          });
          updateChosenWord(suggestion);
          close($any($event), true);
          "
        class="keywords-item"

      >
        <span *ngIf="isVehicleActive">{{activeVehicle.name}} </span>
        <span class="font-weight-bolder">{{suggestion}}</span>
      </a>
    </li>
  </ul>
  </ng-container>


  <ng-container *ngIf="result?.partNumbers?.length > 0 && searchInput.value.length >= 4">
    <p class="section-title">{{ 'searchBox.partNumber' | cxTranslate }}</p>
    <div class="custom-partnumber-rows">
      <ul class="custom-list">
        <li *ngFor="let number of result.partNumbers">
            <a
            [innerHTML]="number"
            (keydown.arrowup)="focusPreviousChild($any($event))"
            (keydown.arrowdown)="focusNextChild($any($event))"
            (keydown.enter)="close($any($event), true)"
            (keydown.escape)="close($any($event), true)"
            (blur)="close($event)"
            (mousedown)="preventDefault($event)"
            class="partnumber-item"
            (click)="goToSkuPage($event, number)">
            </a>
        </li>
      </ul>
    </div>
  </ng-container>

  <ul
    class="custom-products"
    *ngIf="result.products"
    attr.aria-label="{{ 'searchBox.ariaLabelProducts' | cxTranslate }}"
    role="listbox"
  >
  <li *ngFor="let product of result.products.slice(0, 12)">
    <a
      role="option"
      [routerLink]="
        {
          cxRoute: 'product',
          params: product
        } | cxUrl
      "
      [class.has-media]="config.displayProductImages"
      (keydown.arrowup)="focusPreviousChild($any($event))"
      (keydown.arrowdown)="focusNextChild($any($event))"
      (keydown.enter)="close($any($event), true)"
      (keydown.escape)="close($any($event), true)"
      (blur)="close($any($event))"
      (mousedown)="preventDefault($event)"
      (click)="
        dispatchProductEvent({
          freeText: searchInput.value,
          productCode: product.code
        });
        trackSelectItem(product);
        close($any($event), true);
      "
      class="product-item"
    >
      <cx-media
        *ngIf="config.displayProductImages"
        [container]="product.images?.PRIMARY"
        format="thumbnail"
        role="presentation"
      ></cx-media>
      <div class="name font-weight-bolder" [innerHTML]="product.nameHtml"></div>
      <div class="price">
        <ng-container *ngIf="product.priceWithDiscount?.value > 0; else regularPrice">
          {{ product.priceWithDiscount?.formattedValue }}
           <span class="sale-info">
            {{ 'searchBox.sale' | cxTranslate }}
          </span>
        </ng-container>
        <ng-template #regularPrice>
          {{ product.price?.formattedValue }}
        </ng-template>
      </div>
    </a>
  </li>
</ul>
  <ng-container *ngIf="result?.productsExceed">
    <div class="more-results">
      <button class="btn btn-secondary" (mousedown)="disableClose()" (click)="
      launchSearchResult($any($event), searchInput.value);
      updateChosenWord(searchInput.value);
      close($any($event), true);
      ">{{ 'searchBox.seeMore' | cxTranslate }}</button>
    </div>
  </ng-container>
  <span id="initialDescription" class="cx-visually-hidden">
    {{ 'searchBox.initialDescription' | cxTranslate }}
  </span>
  <div
    *ngIf="result.suggestions?.length || result.products?.length"
    aria-live="assertive"
    class="cx-visually-hidden"
  >
    {{
      'searchBox.suggestionsResult'
        | cxTranslate: { count: result.suggestions?.length }
    }}
    {{
      'searchBox.productsResult'
        | cxTranslate: { count: result.products?.length }
    }}
    {{ 'searchBox.initialDescription' | cxTranslate }}
  </div>
</div>

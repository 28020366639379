import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActiveCartFacade, Cart, DeliveryMode, OrderEntry, } from '@spartacus/cart/base/root';
import { CheckoutDeliveryModesFacade, } from '@spartacus/checkout/base/root';
import { TranslationService } from '@spartacus/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';


@Component({
  selector: 'cx-checkout-review-shipping',
  templateUrl: './custom-checkout-review-shipping.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomCheckoutReviewShippingComponent {

  constructor(protected activeCartFacade: ActiveCartFacade,
              protected checkoutDeliveryModesFacade: CheckoutDeliveryModesFacade,
              protected activeCartService: ActiveCartFacade,
              protected translationService: TranslationService) {
  }

  protected cart$: Observable<Cart> = this.activeCartService.getActive();

  entries$: Observable<OrderEntry[]> =
    this.activeCartFacade.getDeliveryEntries();

  deliveryMode$: Observable<DeliveryMode | undefined> =
    this.checkoutDeliveryModesFacade.getSelectedDeliveryModeState().pipe(
      filter((state) => !state.loading && !state.error),
      map((state) => state.data)
    );

}

import { ChangeDetectorRef, Component, OnDestroy, OnInit, inject } from '@angular/core';
import { PaginationModel, Product } from '@spartacus/core';
import { ProductListComponentService, ProductScrollComponent } from '@spartacus/storefront';
import { Subscription } from 'rxjs';

@Component({
  selector: 'cx-product-scroll',
  templateUrl: './custom-product-scroll.component.html',
  styleUrls: ['./custom-product-scroll.component.scss']
})
export class CustomProductScrollComponent extends ProductScrollComponent implements OnInit, OnDestroy {
  private _subscription: Subscription = new Subscription();
  private _ref: ChangeDetectorRef = inject(ChangeDetectorRef);
  private _service: ProductListComponentService = inject(ProductListComponentService);

  products: Product[] = [];
  freeTextSearch: string = '';
  nextPageSize: number = 0;
  currentPage: number = 0;
  currentSize: number = 0;
  pageSize: number = 0;
  total: number = 0;
  
  ngOnInit(): void {
    this._subscription.add(
      this._service.model$.subscribe((model) => {
        this.products = model.products as Product[];

        // Pagination info
        this.freeTextSearch = model?.freeTextSearch ?? '';
        this.pageSize = model.pagination?.pageSize ?? 0;
        this.total = model.pagination?.totalResults ?? 0;
        this.currentPage = model.pagination?.currentPage ?? 0;
        this.currentSize = (this.currentPage + 1) * this.pageSize;
        this.currentSize = this.currentSize <= this.total 
          ? this.currentSize 
          : this.total;
        const diff = this.total - this.currentSize;
        this.nextPageSize = diff < this.pageSize ? diff : this.pageSize;
      })
    );
  }

  loadMoreProducts(): void {
    this.appendProducts = true;
    const nextPage = (this.currentPage ?? 0) + 1;
    this._ref.markForCheck();
    this._service.getPageItems(nextPage);
  }
  
  override ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }
}

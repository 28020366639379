import { Injectable } from '@angular/core';
import { SearchBoxComponentService, SearchBoxConfig } from '@spartacus/storefront';
import { CustomProductSearchPage, CustomSearchResults } from './custom-search-box.model';
import { Observable, combineLatest, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { Vehicle } from 'src/app/spartacus/features/ymm/core/models/garage.model';

const HAS_SEARCH_RESULT_CLASS = 'has-searchbox-results';

@Injectable({
  providedIn: 'root',
})
export class CustomSearchBoxComponentService extends SearchBoxComponentService {

  override getResults(config: SearchBoxConfig): Observable<CustomSearchResults> {
    return combineLatest([
      this.getProductResults(config),
      this.getProductSuggestions(config),
      this.getSearchMessage(config),
    ]).pipe(
      map(([productResults, suggestions, message]) => {
        const resultsExceed = productResults?.products?.length > 12
        return {
          products: productResults ? productResults.products : undefined,
          suggestions,
          message,
          partNumbers: productResults.partNumbers ? productResults.partNumbers.slice(0, 18) : undefined,
          productsExceed: resultsExceed
        };
      }),
      tap((results) =>this.toggleBodyClass(HAS_SEARCH_RESULT_CLASS, this.hasResults(results)))
    );
  }

  protected override getProductResults(
    config: SearchBoxConfig
  ): Observable<CustomProductSearchPage> {
    if (config.displayProducts) {
      return this.searchService.getResults();
    } else {
      return of({});
    }
  }

  override search(query: string, config: SearchBoxConfig, activeVehicle?:Vehicle): void {
    if (!query || query === '') {
      this.clearResults();
      return;
    }

    if (
      config.minCharactersBeforeRequest &&
      query.length < config.minCharactersBeforeRequest
    ) {
      return;
    }

    if (config.displayProducts) {
      if(activeVehicle) {
        this.searchService.search(query, {
          pageSize: config.maxProducts,
          ymm: activeVehicle.code
        });
      } else {
        this.searchService.search(query, {
          pageSize: config.maxProducts,
        });
      }
    }

    if (config.displaySuggestions) {
      if(activeVehicle) {
        this.searchService.searchSuggestions(query, {
          pageSize: config.maxSuggestions,
          ymm: activeVehicle.code
        });
      } else {
        this.searchService.searchSuggestions(query, {
          pageSize: config.maxSuggestions,
        });
      }
    }
  }

  protected override getSearchMessage(
    config: SearchBoxConfig
  ): Observable<string | undefined> {
    return combineLatest([
      this.getProductResults(config),
      this.getProductSuggestions(config),
    ]).pipe(
      switchMap(([productResult, suggestions]) => {
        if (
          productResult &&
          productResult.products &&
          productResult.products.length === 0 &&
          productResult.partNumbers &&
          productResult.partNumbers.length === 0 &&
          suggestions &&
          suggestions.length === 0 
        ) {
          return this.customFetchTranslation('searchBox.help.noMatch');
        } else {
          return of(undefined);
        }
      })
    );
  }

  protected override hasResults(results: CustomSearchResults): boolean {
    return (
      (!!results.products && results.products.length > 0) ||
      (!!results.suggestions && results.suggestions.length > 0) ||
      (!!results.partNumbers && results.partNumbers.length > 0) ||
      !!results.message
    );
  }

  customFetchTranslation(
    translationKey: string,
    options?: any
  ): Observable<string> {
    return this.translationService.translate(translationKey, options);
  }

}

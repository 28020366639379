import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { YoutubeIconComponent } from './icon-youtube.component';

@NgModule({
  declarations: [
    YoutubeIconComponent
  ],
  imports: [
    CommonModule
  ]
  ,
  exports: [
    YoutubeIconComponent
  ]
})
export class YoutubeIconModule { }

import { Component } from '@angular/core';
import { OrderConfirmationThankYouMessageComponent } from '@spartacus/order/components';

@Component({
  selector: 'app-custom-order-guest-form-flex',
  templateUrl: './custom-order-guest-form-flex.component.html',
  styleUrls: ['./custom-order-guest-form-flex.component.scss']
})
export class CustomOrderGuestFormFlexComponentComponent extends OrderConfirmationThankYouMessageComponent {

}

import { APP_INITIALIZER, Provider } from "@angular/core";
import { ListrakService } from "./listrak.service";

export const listrakProvider: Provider = {
  provide: APP_INITIALIZER,
  deps: [ ListrakService ],
  multi: true,
  useFactory: (listrakService: ListrakService) => {
    return () => listrakService.load()
  }
}

/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { I18nModule } from '@spartacus/core';
import { FormErrorsModule, IconModule, KeyboardFocusModule, NgSelectA11yModule } from '@spartacus/storefront';
import { CustomAddressFormComponent } from './address-form.component';
import { CustomSuggestedAddressDialogComponent } from './suggested-addresses-dialog/suggested-addresses-dialog.component';
import { NgxMaskModule } from 'ngx-mask';

@NgModule({
  imports: [
    NgSelectA11yModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    NgSelectModule,
    IconModule,
    I18nModule,
    FormErrorsModule,
    KeyboardFocusModule,
    NgxMaskModule.forRoot(),
  ],
  declarations: [
    CustomAddressFormComponent,
    CustomSuggestedAddressDialogComponent,
  ],
  providers: [],
  exports: [ CustomAddressFormComponent, CustomSuggestedAddressDialogComponent ],
})
export class AddressFormModule {
}

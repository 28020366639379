import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CartTopContentComponent } from './cart-top-content.component';
import { CmsConfig, I18nModule, UrlModule, provideConfig } from '@spartacus/core';
import { CartCouponModule, CartValidationWarningsModule } from '@spartacus/cart/base/components';
import { CustomCartPromotionModule } from '../custom-cart-promotion/custom-cart-promotion.module';
import { RouterModule } from '@angular/router';
import { CustomItemListModule } from '../custom-item-list/custom-item-list.module';

@NgModule({
  declarations: [
    CartTopContentComponent
  ],
  imports: [
    CommonModule,
    I18nModule,
    CartValidationWarningsModule,
    CustomCartPromotionModule, //PromotionsModule,
  ],
  exports: [
    CartTopContentComponent
  ],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        CartTopContent: {
          component: CartTopContentComponent,
        },
      },
    }),
  ]
})

export class CartTopContentModule { }

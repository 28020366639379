import { Injectable, OnDestroy } from "@angular/core";
import { Store } from "@ngrx/store";
import { GarageState } from "../store/garage.state";
import { GarageActions, GarageSelectors } from "../store";
import { BehaviorSubject, combineLatest, Observable, Subscription } from "rxjs";
import { Make, Model, Vehicle, Year, YMM, YmmFormStateChangeEvent } from "../models/garage.model";
import { buildYmmCode } from "./ymm.service";
import { filter, map, shareReplay, take } from "rxjs/operators";
import { CurrentProductService, LAUNCH_CALLER, LaunchDialogService } from "@spartacus/storefront";
import { GarageAdapter } from "../../occ/garage.adapter";
import { DataLayerService } from 'src/app/spartacus/features/data-layer/data-layer.service';

@Injectable({ providedIn: 'root' })
export class GarageService implements OnDestroy {
  private subscription = new Subscription();
  private years$: Observable<Year[]>;
  currentFit$ = this.store.select(GarageSelectors.selectFit).pipe(shareReplay(1));
  fitIsBusy$ = this.store.select(GarageSelectors.selectFitBusy);
  private makes$: { [ key: string ]: Observable<Make[]> } = {};
  private models$: { [ key: string ]: Observable<Model[]> } = {};
  private fits: { [ key: string ]: Observable<boolean> } = {};

  private _garageStateEvent = new BehaviorSubject<'FORM' | 'ACTIVE'>(null);
  garageState$ = this._garageStateEvent.asObservable();

  private _ymmFormStateEvent = new BehaviorSubject<YmmFormStateChangeEvent>({ status: 'INVALID' });
  ymmFormState$ = this._ymmFormStateEvent.asObservable().pipe(shareReplay(1));

  constructor(private store: Store<GarageState>,
              private dialogService: LaunchDialogService,
              private currentProductService: CurrentProductService,
              private garageAdapter: GarageAdapter,
              private dataLayerService: DataLayerService) {
    this.subscription.add(
      combineLatest([
        this.currentProductService.getProduct(),
        this.getActiveVehicle()
      ]).subscribe(([ product, vehicle ]) => {
        if (product?.code && vehicle?.ymm) {
          this.updateFit(product.code, vehicle.ymm);
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  getYears(): Observable<Year[]> {
    if (this.years$) {
      return this.years$;
    }
    this.store.dispatch(GarageActions.loadYears());
    this.years$ = this.store.select(GarageSelectors.selectYears);
    return this.years$;
  }

  changeYmmFormState(state: YmmFormStateChangeEvent): void {
    this._ymmFormStateEvent.next(state);
  }

  getMakes(year: Year): Observable<Make[]> {
    const makeKey = `${ year }`;
    if (!this.makes$[ makeKey ]) {
      this.store.dispatch(GarageActions.getMakes({ year }));
      this.makes$[ makeKey ] = this.store.select(
        GarageSelectors.selectMakes(year)
      ).pipe(
        filter(makes => makes.length > 0)
      );
    }
    return this.makes$[ makeKey ];
  }

  getModels(year: Year, make: Make): Observable<Model[]> {
    const modelKey = `${ year }__${ make }`;
    if (!this.models$[ modelKey ]) {
      this.store.dispatch(GarageActions.getModels({ year, make }));
      this.models$[ modelKey ] = this.store.select(
        GarageSelectors.selectModels(year, make)
      ).pipe(filter(models => models.length > 0))
    }
    return this.models$[ modelKey ];
  }

  changeGarageState(state: 'FORM' | 'ACTIVE'): void {
    this._garageStateEvent.next(state);
  }


  getActiveVehicle(): Observable<Vehicle | undefined> {
    return this.store.select(GarageSelectors.selectActiveVehicle);
  }

  getActiveCode(): Observable<string | undefined> {
    return this.store.select(GarageSelectors.selectActiveVehicleCode);
  }

  setVehicle(ymm: YMM): void {
    this.store.select(GarageSelectors.selectVehicles)
      .pipe(take(1))
      .subscribe(garage => {
        const code = buildYmmCode(ymm);
        const vehicle = garage.find(v => v.code === code);
        if (vehicle) {
          this.store.dispatch(GarageActions.setActiveVehicle({ code }));
        } else {
          this.store.dispatch(GarageActions.addVehicle({ ymm }));
        }
        this.dataLayerService.registerCarEvent(ymm, garage.length);
      })
  }

  updateFit(code: string, ymm: YMM): void {
    this.store.dispatch(GarageActions.updateFit({ code, ymm }));
  }

  getFit(code: string, ymm: YMM): Observable<boolean> {
    const key = `${ code }|${ ymm.year }|${ ymm.make }|${ ymm.model }`;
    if (!this.fits[ key ]) {
      this.fits[ key ] = this.garageAdapter.updateFit(code, ymm)
        .pipe(map(response => response.fit), shareReplay());
    }
    return this.fits[ key ];
  }

  getVehicles(): Observable<Vehicle[]> {
    return this.store.select(GarageSelectors.selectVehicles);
  }

  removeVehicle(vehicle: Vehicle) {
    this.store.dispatch(
      GarageActions.removeVehicle({ vehicle })
    );

    this.dataLayerService.deleteCarEvent();
  }

  openGarageDialog(): void {
    this.dialogService.openDialog(LAUNCH_CALLER.GARAGE)
      .pipe(take(1))
      .subscribe();

    this.dataLayerService.viewGarageEvent()
  }
}


import { ProductConfigMetaData } from "../models/product-configuration.models";
import { QualifierOptionValue } from "../../../core/models/product-details.models";

export const DEFAULT_DELIMITER = '||';
const COLOR = 'color';

export const buildKeyChainForProductLines = ({ productCode, color, subModel, ymm }: ProductConfigMetaData) => {
  return [
    productCode,
    color,
    ymm.year,
    ymm.make,
    ymm.model,
    subModel
  ].join(DEFAULT_DELIMITER);
}


export function buildKeyChainForVariantOptions(productCode: string, options: QualifierOptionValue[], gallery = false): string {
  return `${ productCode }||${ options
    ?.filter(opt => gallery ? isColor(opt.qualifier) : true)
    ?.map(opt => opt.option?.value || '*')
    ?.join(DEFAULT_DELIMITER) }`;
}

export function buildKeyChainForPrice(productCode: string, options: QualifierOptionValue[], partNumber?: string): string {
  const builder: string[] = [
    productCode
  ];
  if (options?.length) {
    builder.push(...options
      ?.map(opt => opt?.option.value || '*')
      ?.join(DEFAULT_DELIMITER)
    );
  }
  if (partNumber) {
    builder.push(partNumber);
  }
  return builder.join(DEFAULT_DELIMITER);
}

export function isColor(qualifier: string): boolean {
  return qualifier?.indexOf(COLOR) >= 0;
}

export const embroideryFontSampleMap: { [ key: string ]: { font: string, sample: string } } = {
  [ 'SC' ]: {
    font: '/assets/static/embroidery/font-script.png',
    sample: '/assets/static/embroidery/script-sample.png'
  },
  [ 'BL' ]: {
    font: '/assets/static/embroidery/font-block.png',
    sample: '/assets/static/embroidery/block-sample.png'
  }
};


import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ManagerFactoryService } from "../../core/facade/manager-factory.service";
import { ProductConfiguratorStepsService } from "../../core/facade/product-configurator-steps.service";
import { map, switchMap } from "rxjs/operators";


@Component({
  selector: 'product-lines-dialog-footer',
  templateUrl: './product-config-dialog-footer.component.html',
  styleUrls: [ './product-config-dialog-footer.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductConfigDialogFooterComponent {

  manager$ = this.stepService.currentConfigStep$.pipe(
    map(configStep => {
      return this.managerFactory.getManagerForStepType(configStep.stepType);
    })
  )

  buttons$ = this.manager$.pipe(
    switchMap(manager => manager.getButtons())
  );

  constructor(private managerFactory: ManagerFactoryService,
              private stepService: ProductConfiguratorStepsService) {
  }

}

<div style="display: inline-block;" class="float-left fs-3 close-hamburger-btn">
  <span class="bi bi-x pl-2 mt-2" (click)="closeHamburger()"></span>
</div>

<ng-container *ngIf="user$ | async as user; else login">
  <div class="cx-login-greet pt-2 text-right bold-common-font text-capitalize" style="font-size: 12px;" (click)="openMenu()">
    {{ user.name }}

    <div style="display: inline-block; vertical-align: middle; border: 1px solid white; border-radius: 50%; height: 30px; width: 30px;" class="ml-2">
      <div class="d-flex align-items-center justify-content-center">
        <span class="bi bi-person mt-1 "></span>
      </div>
    </div>
  </div>

  <div class="m-t-12 p-l-20">
    <cx-page-slot position="SiteLogoMobile"></cx-page-slot>
  </div>

  <cx-page-slot id="account-nav" position="HeaderLinks"></cx-page-slot>
</ng-container>

<ng-template #login>
  <div class="anonymous-hamburger bold-common-font text-right pr-3 pt-2">
    <a role="link" [routerLink]="{ cxRoute: 'login' } | cxUrl" style="display: inline-block !important;" class="bold-common-font d-flex align-items-center">
      Sign In
    </a>

    or

    <a role="link" [routerLink]="{ cxRoute: 'register' } | cxUrl" style="display: inline-block !important;" class="bold-common-font">
      Create Account
    </a>

    <div style="display: inline-block; vertical-align: middle; border: 1px solid white; border-radius: 50%; height: 30px; width: 30px;" class="ml-2">
      <div class="d-flex align-items-center justify-content-center">
        <span class="bi bi-person mt-1"></span>
      </div>
    </div>
  </div>

  <div class="p-l-20 m-t-12">
    <cx-page-slot position="SiteLogoMobile"></cx-page-slot>
  </div>
</ng-template>

import { AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, OnInit, QueryList, ViewChildren, } from '@angular/core';
import { CmsService, CMSTabParagraphContainer, WindowRef, } from '@spartacus/core';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay, switchMap, take, tap } from 'rxjs/operators';
import { CmsComponentData, ComponentWrapperDirective, CurrentProductService } from '@spartacus/storefront';
import { ToolsService } from 'src/app/components/tools.service';
import { TurnToService } from "../../turnto/core/turnto.service";

@Component({
  selector: 'cx-tab-paragraph-container',
  templateUrl: './custom-tab-paragraph-container.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabParagraphContainerComponent implements AfterViewInit, OnInit, OnDestroy {
  activeTabNum = 0;
  ariaLabel: string;

  @ViewChildren(ComponentWrapperDirective)
  children!: QueryList<ComponentWrapperDirective>;

  tabTitleParams: ( Observable<any> | null )[] = [];

  components$: Observable<any[]> = this.componentData.data$.pipe(
    distinctUntilChanged((x, y) => x?.components === y?.components),
    tap((data: CMSTabParagraphContainer) => {
      this.ariaLabel = `${ data?.uid }.tabPanelContainerRegion`;
    }),
    switchMap((data) =>
      combineLatest(
        ( data?.components ?? '' ).split(' ').map((component) =>
          this.cmsService.getComponentData<any>(component).pipe(
            distinctUntilChanged(),
            map((tab) => {
              if (!tab) {
                return undefined;
              }

              if (!tab.flexType) {
                tab = {
                  ...tab,
                  flexType: tab.typeCode,
                };
              }
              return {
                ...tab,
                title: `${ data.uid }.tabs.${ tab.uid }`,
              };
            })
          )
        )
      )
    ),
    shareReplay(1)
  );


  constructor(public componentData: CmsComponentData<CMSTabParagraphContainer>,
              protected cmsService: CmsService,
              protected winRef: WindowRef,
              private toolsService: ToolsService,
              private currentProductService: CurrentProductService,
              private turnToService: TurnToService) {
  }


  private subscription = new Subscription();


  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit(): void {
    this.activeTabNum =
      this.winRef?.nativeWindow?.history?.state?.activeTab ?? this.activeTabNum;
  }

  ngAfterViewInit(): void {
    // If the sub cms components data exist, the components created before ngAfterViewInit are called.
    // In this case, the title parameters are directly pulled from them.
    if (this.children.length > 0) {
      this.getTitleParams(this.children);
    }
    this.subscription.add(
      this.components$.pipe(
        filter(c => c.length > 0),
        switchMap(_ =>
          this.currentProductService.getProduct()
            .pipe(distinctUntilChanged())
        ),
      ).subscribe(product => {
        this.turnToService.initializePdpWidget(product.code);
      })
    );
  }


  select(tabNum: number, event?: MouseEvent): void {
    this.activeTabNum = this.activeTabNum === tabNum ? -1 : tabNum;
    const isMobile = this.toolsService.getCurrentDevice() == 'MOBILE'
    if (isMobile) {
      const target = event.target as HTMLElement;
      setTimeout(() => {
        target.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
      }, 10)
    }
  }

  tabCompLoaded(componentRef: any): void {
    this.tabTitleParams.push(componentRef.instance.tabTitleParam$);
  }

  private getTitleParams(children: QueryList<ComponentWrapperDirective>) {
    children.forEach((comp) => {
      this.tabTitleParams.push(comp[ 'cmpRef' ]?.instance.tabTitleParam$ ?? null);
    });
  }

}

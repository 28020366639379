<section class="product-gallery">
  
  <!-- Product Title -->
  <cx-product-title class="visible-xs"></cx-product-title>
  <!-- MAIN IMAGE -->
  <div class="product-gallery__main">
    <ng-container *ngIf="gallery$ | async as gallery">
      <ng-container *ngIf="activeContainer | async as main">
        <cx-media [container]="main" (click)="openGallery()"></cx-media>
        <div class="product-gallery__representative">
          {{ 'productCarousel.representativeImageShown' | cxTranslate }}
        </div>
      </ng-container>
    </ng-container>
  </div>

  <!-- THUMBNAILS -->
  <product-medias-thumbnails
    (changeMedia)="openImage($event.container, $event.index)"
    (openGallery)="openGallery($event)"
    [active]="active">
  </product-medias-thumbnails>

</section>



import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CmsConfig, I18nModule, provideConfig } from '@spartacus/core';
import { FitmentDetailsComponent } from './fitment-details/fitment-details.component';

@NgModule({
  declarations: [
    FitmentDetailsComponent
  ],
  imports: [
    CommonModule,
    I18nModule
  ],
  exports: [
    FitmentDetailsComponent
  ],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        fitmentDetails: {
          component: FitmentDetailsComponent,
        },
      },
    }),
  ],
})
export class FitmentDetailsModule { }

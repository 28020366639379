import { NgModule } from '@angular/core';
import {
  BasePageMetaResolver,
  CategoryPageMetaResolver,
  CMS_PAGE_NORMALIZER,
  CmsService,
  ContentPageMetaResolver,
  OccCmsComponentAdapter,
  ProductPageMetaResolver,
  provideConfig,
  SearchPageMetaResolver
} from '@spartacus/core';
import { customCmsConfig } from './occ/custom-occ-cms-config';
import { CustomBasePageMetaResolver } from './resolvers/custom-base-page-meta-resolvers';
import { customPageMetaConfig } from './seo/custom-page-meta-config';
import { CustomOccCmsPageNormalizer } from './occ/custom-occ-cms-page-normalizer';
import { CustomSeoMetaService } from './seo/custom-seo-meta-service';
import { PAGE_LAYOUT_HANDLER, SeoMetaService } from '@spartacus/storefront';
import { CustomContentPageResolver } from './resolvers/custom-content-page-resolvers';
import { CustomProductPageMetaResolver } from './resolvers/custom-product-page-meta-resolver';
import { CustomCategoryPageMetaResolver } from "./resolvers/custom-category-page-meta.resolver";
import { CustomSearchPageMetaResolver } from "./resolvers/custom-search-page-meta-resolver";
import { SearchResultsPageListResolver } from 'src/app/spartacus/features/product/search-results-page-list.resolver';
import { CustomCmsService } from './facade/cms.service';
import { CustomOccCmsComponentAdapter } from './occ/adapter/occ-cms-component.adapter';
import { PageMetaService } from '@spartacus/core';
import { CustomPageMetaService } from './facade/page-meta.service';


@NgModule({
  providers: [
    provideConfig(customCmsConfig),
    provideConfig(customPageMetaConfig),
    {
      provide: CMS_PAGE_NORMALIZER,
      useExisting: CustomOccCmsPageNormalizer,
      multi: true
    },
    {
      provide: BasePageMetaResolver,
      useExisting: CustomBasePageMetaResolver
    },
    {
      provide: ContentPageMetaResolver,
      useExisting: CustomContentPageResolver
    },
    {
      provide: SeoMetaService,
      useExisting: CustomSeoMetaService
    },
    {
      provide: ProductPageMetaResolver,
      useExisting: CustomProductPageMetaResolver,
    },
    {
      provide: CategoryPageMetaResolver,
      useExisting: CustomCategoryPageMetaResolver
    },
    {
      provide: SearchPageMetaResolver,
      useExisting: CustomSearchPageMetaResolver
    },
    {
      provide: PAGE_LAYOUT_HANDLER,
      useExisting: SearchResultsPageListResolver,
      multi: true
    },
    {
      provide: CmsService,
      useClass: CustomCmsService
    },
    {
      provide:OccCmsComponentAdapter,
      useClass: CustomOccCmsComponentAdapter
    },
    {
      provide: PageMetaService,
      useClass: CustomPageMetaService
    }
  ]
})
export class CustomCmsModule {
}

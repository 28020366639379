<cx-spinner *ngIf="isUpdating$ | async" class="overlay"></cx-spinner>

<form (ngSubmit)="onSubmit()" [formGroup]="form" class="w-100">
 <label>
  <span class="label-content">{{
   'loginForm.emailAddress.label' | cxTranslate
   }}</span>
  <input required="true" type="email" class="form-control" formControlName="userId" id="emailAddress"
   placeholder="{{ 'loginForm.emailAddress.placeholder' | cxTranslate }}" />
  <cx-form-errors [control]="form.get('userId')" style="font-weight: bold;"></cx-form-errors>
 </label>
 <label>
  <span class="label-content">{{
   'loginForm.password.label' | cxTranslate
   }}</span>
  <input required="true" type="password" class="form-control"
   placeholder="{{ 'loginForm.password.placeholder' | cxTranslate }}" formControlName="password"
   [attr.aria-label]="'loginForm.password.placeholder' | cxTranslate" cxPasswordVisibilitySwitch />
  <cx-form-errors [control]="form.get('password')" style="font-weight: bold;"></cx-form-errors>
 </label>
 <div class="col-12 p-0 my-2">

  <a [routerLink]="{ cxRoute: 'forgotPassword' } | cxUrl" class="link-secondary bold-common-font ">
   {{ 'loginForm.forgotPassword' | cxTranslate }}
  </a>
 </div>
 <div class="col mt-1">
  <div class="row">
   <div class="col-md-7 col-sm-12 p-0">
    <button type="submit" class="btn btn-block btn-secondary" [disabled]="form.disabled">
     {{ 'customLoginForm.signToCheckout' | cxTranslate }}
    </button>

   </div>
  </div>
 </div>


</form>
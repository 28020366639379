<!-- Heading -->
<ng-container *ngIf="categories$ | async as categories">
<configurator-accordion-item title="LOGOS" [isAnySelected]="selectedLogo !== undefined? true: false" [step]="step" [numberOfOptions]="sumOfLogosLengths(categories)">
  <nav class="d-flex mt-4" style="flex-direction: column;">
    <ng-container *ngFor="let category of categories">
          <div class="configurator-category-title">
            {{category.categoryName}}
          </div>
          <div class="configurator-category-price">
            <span class="plus-icon"></span>
            {{category.logos[0]?.price.formattedValue}}
          </div>
        <article class="configurator-list">
          <label *ngFor="let logo of category.logos" [for]="logo.logoCode"
            class="configurator-card configurator-card__shadowed">

            <input (click)="selectItem(logo)" [checked]="selectedLogo?.logoCode === logo.logoCode" [id]="logo.logoCode"
              [name]="logo.logoCode" type="radio">

            <!-- Media -->
            <div class="configurator-card__media">
              <cx-media [container]="logo.image"></cx-media>
            </div>

            <!-- Name -->
            <section class="configurator-card-neck">
              <div class="configurator-card-neck__container">
                <div class="configurator-card-neck__title">
                  {{ logo.name }}
                </div>
                <config-select-icon [isSelected]="logo.logoCode === selectedLogo?.logoCode">
                </config-select-icon>
              </div>
            </section>

          </label>
        </article>
        <div class="logos-divider"></div>
    </ng-container>
  </nav>
  <ng-container *ngIf="price$ | async as price">
    <section class="configurator-footer d-none">
      <div class="configurator-footer__price">
        {{ price.formattedValue }}
      </div>
    </section>
  </ng-container>
</configurator-accordion-item>
</ng-container>
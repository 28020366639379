<ng-template cxOutletRef="CartPageTemplate" let-model>
  <div *ngIf="{
    isLoading: isLoading$ | async,
    cart: cart$ | async,
    hasItems: hasItems$ | async
  } as contentPage" class="container">
    <cx-page-slot *ngIf="contentPage.hasItems" position="TopContent"></cx-page-slot>
      <div class="row cart-row">
        <div class="col-12 {{contentPage.hasItems ? 'col-md-8 col-lg-8': ''}} container-CenterLeftContentSlot">
          <cx-page-slot *ngIf="contentPage.hasItems" position="CenterLeftContentSlot"></cx-page-slot>
          <cx-page-slot *ngIf="!contentPage.isLoading && !contentPage.hasItems" position="CenterLeftContentSlot-empty"></cx-page-slot>
        </div>

        <div *ngIf="contentPage.hasItems" class="col-12 col-md-4 col-lg-4 container-CenterRightContentSlot">
          <cx-page-slot position="CenterRightContentSlot"></cx-page-slot>
        </div>
      </div>
  </div>
</ng-template>

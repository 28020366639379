/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { Injectable } from '@angular/core';
import {
  CmsNavigationComponent,
  CmsNavigationNode,

} from '@spartacus/core';
import {  EMPTY, Observable } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { NavigationNode } from '@spartacus/storefront';
import { NavigationService } from '@spartacus/storefront';

@Injectable({
  providedIn: 'root',
})
export class CustomNavigationService extends NavigationService {
  public override getNavigationNode(
    data$: Observable<CmsNavigationComponent>
  ): Observable<NavigationNode> {
    if (!data$) {
      return EMPTY;
    }
    return data$.pipe(
      filter((data) => !!data),
      switchMap((data) => {
        const navigation = data.navigationNode ? data.navigationNode : data;
        return this.cmsService
          .getNavigationEntryItems(navigation.uid ?? '')
          .pipe(
            tap((items) => {
              if (items === undefined) {
                this.customLoadNavigationEntryItems(navigation, true);
                return;
              }
              // we should check whether the existing node items are what expected
              const expectedItems: {
                superType: string | undefined;
                id: string | undefined;
              }[] = [];
              this.customLoadNavigationEntryItems(
                navigation,
                false,
                expectedItems
              );
              const existingItems = Object.keys(items).map(
                (key) => items[key].uid ?? ''
              );
              const missingItems = expectedItems.filter(
                (it) => it.id && !existingItems.includes(it.id)
              );
              if (missingItems.length > 0) {
                this.cmsService.loadNavigationItems(
                  navigation.uid ?? '',
                  missingItems
                );
              }
            }),
            filter(Boolean),
            map(
              (items) =>
                {
                  return this.customPopulateNavigationNode(navigation, items) ?? {}}
            )
          );
      })
    );
  }

  private customLoadNavigationEntryItems(
    nodeData: CmsNavigationNode,
    root: boolean,
    itemsList: { superType: string | undefined; id: string | undefined }[] = []
  ): void {
    if (nodeData.entries && nodeData.entries.length >= 0) {
      nodeData.entries.forEach((entry) => {
        itemsList.push({
          superType: entry.itemSuperType,
          id: entry.itemId,
        });
      });
    }

    if (nodeData.children && nodeData.children.length > 0) {
      nodeData.children.forEach((child) =>
        this.customLoadNavigationEntryItems(child, false, itemsList)
      );
    }

    if (root && nodeData.uid) {
      this.cmsService.loadNavigationItems(nodeData.uid, itemsList);
    }
  }

  private customPopulateNavigationNode(
    nodeData: any,
    items: any
  ): NavigationNode | null {
    const node: NavigationNode = {};

    if (nodeData.title) {
      // the node title will be populated by the first entry (if any)
      // if there's no nodeData.title available
      node.title = nodeData.title;
    }

    // populate style classes to apply CMS driven styling
    if (nodeData.styleClasses) {
      node.styleClasses = nodeData.styleClasses;
    }
    // populate style attributes to apply CMS driven styling
    if (nodeData.styleAttributes) {
      node.styleAttributes = nodeData.styleAttributes;
    }

    if (nodeData.entries && nodeData.entries.length > 0) {
      this.customPopulateLink(node, nodeData.entries[0], items);
    }

    if (nodeData.children?.length > 0) {
      const children = nodeData.children
        .map((child: any) => this.customPopulateNavigationNode(child, items))
        .filter(Boolean);
      if (children.length > 0) {
        node.children = children;
      }
    }

    // return null in case there are no children
    return Object.keys(node).length === 0 ? null : node;
  }

  private customPopulateLink(node: NavigationNode, entry: any, items: any) {
    const item = items[`${entry.itemId}_${entry.itemSuperType}`];

    // now we only consider CMSLinkComponent
    if (item && entry.itemType === 'CMSLinkComponent') {
      if (!node.title) {
        node.title = item.linkName;
      }
      const url = this.getLink(item);
      // only populate the node link if we have a visible node
      if (node.title && url) {
        node.url = url;
        // the backend provide boolean value for the target
        // in case the link should be opened in a new window
        if (item.target === 'true' || item.target === true) {
          node.target = '_blank';
        }
      }
      // populate style classes to apply CMS driven styling
      if (item.styleClasses) {
        node.styleClasses = item.styleClasses;
      }
      // populate style attributes to apply CMS driven styling
      if (item.styleAttributes) {
        node.styleAttributes = item.styleAttributes;
      }

      if (item.image) {
        node.iconImage = item.image;

      }
    }
  }
}

// CHECK SONAR

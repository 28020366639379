import * as garageActions from './actions/garage.actions';
import * as ymmActions from './actions/ymm.actions';
import * as persistenceActions from './actions/persistence.actions';
import * as GarageSelectors from './selectors/garage.selectos';



export {
  GarageSelectors,
};

export const GarageActions = {
  ...garageActions,
  ...ymmActions,
  ...persistenceActions
}

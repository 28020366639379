import { Injectable } from '@angular/core';
import { Product, ProductScope, ProductService, RoutingService, StateWithProduct, isNotUndefined } from '@spartacus/core';
import { Observable, combineLatest, of } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { currentProduct } from './core/store/product-details.selectors';
import { PageLayoutService } from '@spartacus/storefront';
import { SkuPageService } from '../sku-landing-page/skupage.service';
import { ProductDetailsOccAdapter } from './core/occ/product-details-occ.adapter';

@Injectable({ providedIn: 'root' })
export class CustomCurrentProductService {
  constructor(
    private skuPageService: SkuPageService,
    private pageLayoutService: PageLayoutService,
    private routingService: RoutingService,
    private productService: ProductService,
    private store: Store<StateWithProduct>,
    private productDetailsOccAdapter: ProductDetailsOccAdapter
  ) {}

  protected readonly DEFAULT_PRODUCT_SCOPE = ProductScope.DETAILS;
  protected readonly productCode$ = this.pageLayoutService.page$.pipe( map(page => page?.productCode ) )

  getProduct(scopes?: (ProductScope | string)[] | ProductScope | string): Observable<Product | null> {
    const product$ = this.getCode().pipe(
      distinctUntilChanged(),
      switchMap((productCode: string) => {
        return productCode
          ? this.productService.get( productCode, scopes || this.DEFAULT_PRODUCT_SCOPE ).pipe(
              map(product => {
                return product
              })
            )
          : of(null);
      }),
      filter(isNotUndefined)
    );

    return combineLatest([
      product$,
      this.getProductFromStore()
    ]).pipe(
      map(([product, productFromStore]) => this.skuPageService.isSKUPage() ? productFromStore : product)
    )
  }

  getProductFromStore (): Observable<Product | null> {
    return this.store.select(currentProduct);
  }

  loadProduct (code: string, scope?: string | ProductScope) : Observable<Product> {
    return this.productDetailsOccAdapter.loadProduct(code, scope);
  }

  protected getCode(): Observable<string> {
    return combineLatest([
      this.routingService.getRouterState(),
      this.productCode$
    ]).pipe(map(([state, productCode]) => this.skuPageService.isSKUPage() ? productCode : state.state.params['productCode']));
  }
}

import { NgModule } from '@angular/core';
import { AnonymousConsentsModule, AuthModule, CostCenterOccModule, ExternalRoutesModule, ProductModule, ProductOccModule, UserModule, UserOccModule } from "@spartacus/core";
import {
  AnonymousConsentsDialogModule,
  BannerModule,
  CmsParagraphModule,
  FooterNavigationModule,
  HamburgerMenuModule,
  HomePageEventModule,
  LinkModule,
  LoginRouteModule,
  LogoutModule,
  MyAccountV2Module,
  MyInterestsModule,
  NavigationEventModule,
  NotificationPreferenceModule,
  PDFModule,
  ProductDetailsPageModule,
  ProductImagesModule,
  ProductListingPageModule,
  ProductPageEventModule,
  ScrollToTopModule,
  SiteContextSelectorModule,
  StockNotificationModule,
  VideoModule,
  JsonLdBuilderModule
} from "@spartacus/storefront";
import { UserFeatureModule } from './features/user/user-feature.module';
import { CartBaseFeatureModule } from './features/cart/cart-base-feature.module';
import { CartSavedCartFeatureModule } from './features/cart/cart-saved-cart-feature.module';
import { WishListFeatureModule } from './features/cart/wish-list-feature.module';
import { CartQuickOrderFeatureModule } from './features/cart/cart-quick-order-feature.module';
import { CartImportExportFeatureModule } from './features/cart/cart-import-export-feature.module';
import { OrderFeatureModule } from './features/order/order-feature.module';
import { CheckoutFeatureModule } from './features/checkout/checkout-feature.module';
import { PersonalizationFeatureModule } from './features/tracking/personalization-feature.module';
import { TagManagementFeatureModule } from './features/tracking/tag-management-feature.module';
import { AsmFeatureModule } from './features/asm/asm-feature.module';
import { AsmCustomer360FeatureModule } from './features/asm/asm-customer360-feature.module';
import { SmartEditFeatureModule } from './features/smartedit/smart-edit-feature.module';
import { ProductVariantsFeatureModule } from './features/product/product-variants-feature.module';
import { ProductImageZoomFeatureModule } from './features/product/product-image-zoom-feature.module';
import { CustomUpdateProfileModule } from '../components/_SPA-Components/custom-myaccount/custom-update-profile';
import { CustomUpdateEmailModule } from '../components/_SPA-Components/custom-myaccount/custom-update-email';
import { CustomCloseAccountModule } from '../components/_SPA-Components/custom-myaccount/custom-close-account';
import { CustomMyCouponsModule } from '../components/_SPA-Components/custom-myaccount/custom-my-coupons';
import { CustomPaymentMethodsModule } from '../components/_SPA-Components/custom-myaccount/custom-payment-methods/custom-payment-methods.module';
import { CustomUpdatePasswordModule } from '../components/_SPA-Components/custom-myaccount/custom-update-password/custom-update-password.module';
import { CustomFormModule } from '../components/_CUSTOM/form/form.module';
import { CustomLoginFormModule } from './features/user/account/components/login-form';
import { CustomBreadcrumbModule } from '../components/_SPA-Components/custom-breadcrumb/custom-breadcrumb.module';
import { CustomAddressBookModule } from '../components/_SPA-Components/custom-myaccount/address-book';
import { CustomConsentManagementModule } from '../components/_SPA-Components/custom-myaccount/custom-consent-management';
import { CustomRoutingModule } from './features/routing/custom-routing/custom-routing.module';
import { CustomNavigationModule } from '../components/_SPA-Components/custom-navigationUI/custom-navigation.module';
import { CustomCategoryNavigationModule } from '../components/_SPA-Components/custom-category-navigation/custom-category-navigation.module';
import { CustomCoreModule } from "../core/custom-core.module";
import { ListrakFeatureModule } from "./features/listrak/listrak-feature.module";
import { NewsletterModule } from './features/newsletter/newsletter.module';
import { CustomProductSummaryModule } from './features/product/product-summary/product-summary.module';
import { CustomProductIntroModule } from './features/product/product-intro/product-intro.module';
import { CustomProductVariantsContainerModule } from './features/product/variants/components/product-variants-container/product-variants-container.module';
import { RecaptchaModule } from "./features/recaptcha/recaptcha.module";
import { CustomProductListModule } from "./features/product/product-list/custom-product-list.module";
import { CustomProductFacetNavigationModule } from "./features/product/product-list/custom-product-facet-navigation/custom-product-facet-navigation.module";
import { CustomProductSearchDetailsModule } from "./features/product/product-list/custom-product-search-details/custom-product-search-details.module";
import { CustomProductModule } from "./features/product/custom-product.module";
import { CustomCheckoutLoginModule } from './features/checkout/custom-checkout-login/custom-checkout-login.module';
import { ProductDescriptionModule } from '../components/_CUSTOM/product-description-section/product-description-section.module';
import { BannerSatisfiedCustomerItemModule } from './features/marketing/components/banner-satisfied-cutomer/banner-rotating-item/banner-satisfied-customers-item.module';
import { CustomCarouselModule } from '../components/_SPA-Components/custom-carousel';
import { CustomBannerCarouselItemModule } from './features/marketing/components/banner-rotating/banner-rotating-item/custom-banner-carousel-item.module';
import { CustomProductInstallationTabModule } from './features/product/custom-product-tabs/custom-product-installation-guide/custom-product-installation-tab.module';
import { PageTitleModule } from './features/shared/components/page-title/page-title.module';
import { FamilyCovercraftModule } from './features/family-covercraft/family-covercraft.module';
import { YoutubeIconModule } from '../components/_CUSTOM/icons/icon-youtube/icon-youtube.module';
import { DigitalPaymentsFeatureModule } from './features/digital-payments/digital-payments-feature.module';
import { CustomSearchBoxModule } from '../components/_SPA-Components/custom-searchbox';
import { CustomProductCardModule } from '../components/_SPA-Components/custom-searchbox/product-card/product-card.module';
import { CustomProductReferencesModule } from './features/product/carousel/custom-product-references/custom-product-references.module';
import { CustomProductCarouselModule } from './features/product/carousel/custom-product-carousel/custom-product-carousel.module';
import { FitmentDetailsModule } from '../components/_SPA-Components/fitment-details/fitment-details.module';
import { HandlerFitmentDetailsModule } from '../components/_SPA-Components/handler-fitment-details/handler-fitment-details.module';
import { SkuLandingPageModule } from './features/sku-landing-page/sku-landing-page.module';
import { GuestOrderTrackingModule } from './features/order/Components/guest-order-tracking/guest-order-tracking.module';
import { CustomProductStampsModule } from './features/product/custom-product-stamps/custom-product-stamps.module';
import { CustomOrderGuestFormFlexModule } from './features/order/Components/custom-order-confirmation/custom-order-guest-form-flex-component/custom-order-guest-form-flex.module';
import { CustomCmsComponentsModule } from "../components/_SPA-Components/custom-cms-components.module";
import { MarketingFeatureModule } from "./features/marketing/marketing-feature.module";
import { CustomAnonymousConsentManagementBannerModule } from "../components/_SPA-Components/anonymous-consent-management/anonymous-consent-management.module";
import { YmmFeatureModule } from "./features/ymm/ymm-feature.module";

@NgModule({
  declarations: [],
  imports: [
    AuthModule.forRoot(),
    LogoutModule,
    LoginRouteModule,
    HamburgerMenuModule,
    SiteContextSelectorModule,
    LinkModule,
    BannerModule,
    CmsParagraphModule,
    CustomCarouselModule, //BannerCarouselModule,
    CustomCategoryNavigationModule, // CategoryNavigationModule
    CustomNavigationModule, // NavigationModule
    FooterNavigationModule,
    CustomBreadcrumbModule, //BreadcrumbModule,
    ScrollToTopModule,
    VideoModule,
    PDFModule,
    UserModule,
    UserOccModule,
    CustomAddressBookModule,//AddressBookModule,
    CustomPaymentMethodsModule, //PaymentMethodsModule
    NotificationPreferenceModule,
    MyInterestsModule,
    MyAccountV2Module,
    StockNotificationModule,
    CustomConsentManagementModule,//ConsentManagementModule,
    CustomMyCouponsModule, //MyCouponsModule
    AnonymousConsentsModule.forRoot(),
    AnonymousConsentsDialogModule,
    CustomAnonymousConsentManagementBannerModule, //AnonymousConsentManagementBannerModule,
    ProductModule.forRoot(),
    ProductOccModule,
    SkuLandingPageModule,
    ProductDetailsPageModule,
    ProductListingPageModule,
    CustomProductListModule, // ProductListModule,
    CustomSearchBoxModule, //SearchBoxModule,
    CustomProductFacetNavigationModule, // ProductFacetNavigationModule,
    CustomProductReferencesModule,//ProductReferencesModule,
    ProductImagesModule,
    CustomProductSummaryModule, // ProductSummaryModule,
    CustomProductIntroModule, // ProductIntroModule,
    CostCenterOccModule,
    NavigationEventModule,
    HomePageEventModule,
    ProductPageEventModule,
    ExternalRoutesModule.forRoot(),
    UserFeatureModule,
    CartBaseFeatureModule,
    CartSavedCartFeatureModule,
    WishListFeatureModule,
    CartQuickOrderFeatureModule,
    CartImportExportFeatureModule,
    OrderFeatureModule,
    CustomProductStampsModule,
    CustomOrderGuestFormFlexModule,
    CheckoutFeatureModule,
    DigitalPaymentsFeatureModule,
    PersonalizationFeatureModule,
    TagManagementFeatureModule,
    AsmFeatureModule,
    AsmCustomer360FeatureModule,
    SmartEditFeatureModule,
    ProductVariantsFeatureModule,
    ProductImageZoomFeatureModule,
    CustomUpdateProfileModule, // UpdateProfileModule
    CustomUpdatePasswordModule, // UpdatePasswordModule
    CustomUpdateEmailModule, // UpdateEmailModule
    CustomCloseAccountModule, //loseAccountModule
    CustomFormModule,
    CustomLoginFormModule,
    CustomRoutingModule,
    CustomProductSearchDetailsModule,
    NewsletterModule,
    ListrakFeatureModule,
    CustomCoreModule,
    CustomProductVariantsContainerModule,
    RecaptchaModule,
    CustomProductModule,
    CustomCheckoutLoginModule,
    ProductDescriptionModule,
    BannerSatisfiedCustomerItemModule,
    CustomBannerCarouselItemModule,
    GuestOrderTrackingModule,
    CustomProductInstallationTabModule,
    FamilyCovercraftModule, // ProductCarouselModule,
    CustomProductCarouselModule,
    YoutubeIconModule,
    CustomProductCardModule,
    // TODO: Try to group all CMS components in one Module and import all at once here
    PageTitleModule,
    FitmentDetailsModule,
    HandlerFitmentDetailsModule,
    CustomCmsComponentsModule,

    // Lazy
    MarketingFeatureModule,
    YmmFeatureModule,
    JsonLdBuilderModule
  ],
})
export class SpartacusFeaturesModule {
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LocalLoaderComponent } from './local-loader.component';
import { I18nModule } from '@spartacus/core';


@NgModule({
  declarations: [
    LocalLoaderComponent
  ],
  imports: [
    CommonModule,
    I18nModule
  ],
  exports: [
    LocalLoaderComponent
  ]
})
export class LocalLoaderModule { }

import { Component } from '@angular/core';

@Component({
  selector: 'all-cart-messages',
  templateUrl: './all-cart-messages.component.html',
  styleUrls: ['./all-cart-messages.component.scss']
})

export class AllCartMessagesComponent {

}

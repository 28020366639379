import { ChangeDetectionStrategy, Component, Inject, OnInit, ViewEncapsulation } from "@angular/core";
import { map, shareReplay, take, tap } from "rxjs/operators";
import { Price, WindowRef } from "@spartacus/core";
import { BehaviorSubject, combineLatest } from "rxjs";
import { CurrencyPipe } from "@angular/common";
import { Logo, LogoCategory, LogoItem, ProductConfigStepType } from "../../core/models/product-configuration.models";
import { ProductConfigurationService } from "../../core/facade/product-configuration.service";
import { LogoSelection } from "../../core/store/product-configuration.state";
import { STEP_INDEX } from "../../dialog/product-config-dialog/product-config-dialog.component";
import { ProductConfiguratorStepsService } from "../../core/facade/product-configurator-steps.service";

@Component({
  selector: 'logos',
  templateUrl: './logos.component.html',
  styleUrls: ['./logos.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class LogosComponent implements OnInit {
  selectedCategory: LogoCategory;
  selectedLogo: LogoItem;
  price$ = new BehaviorSubject<Price>(undefined);

  logo$ = this.service.getConfigurationForCurrentProduct().pipe(
    map(
      (config) =>
        config.steps?.find(
          (s) => s.stepType === ProductConfigStepType.LOGOS
        ) as Logo
    ),
    tap((logo) => {
      if (logo && !this.selectedCategory) {
        this.selectedCategory = logo.categories[0];
      }
    }),
    shareReplay(1)
  );

  categories$ = this.logo$.pipe(
    map((logo) => {
      return logo.categories?.length >= 1 ? logo.categories : null;
    })
  );

  constructor(
    private service: ProductConfigurationService,
    private currencyPipe: CurrencyPipe,
    private stepService: ProductConfiguratorStepsService,
    private winRef: WindowRef,
    @Inject(STEP_INDEX) public step: number
  ) {}

  ngOnInit(): void {
    combineLatest([this.logo$, this.service.getConfigSelection()])
      .pipe(take(1))
      .subscribe(([logo, selection]) => {
        const logoSelection = selection.selections?.find(
          (s) => s.stepType === ProductConfigStepType.LOGOS
        ) as LogoSelection;
        const firstLogo = logo.categories?.[0]?.logos?.[0];
        if (logoSelection) {
          this.selectedLogo = logoSelection.item;
        }
        const pr = this.selectPrice(logoSelection?.item ?? firstLogo);
        this.price$.next(pr);
      });
  }

  changeCategory(category: LogoCategory) {
    this.selectedCategory = category;
  }

  selectItem(logo: LogoItem) {
    if (this.selectedLogo?.logoCode === logo.logoCode) {
      this.service.clearLogo();
      this.selectedLogo = undefined;
      this.price$.next({
        value: 0,
        formattedValue: this.currencyPipe.transform(0),
      });
    } else {
      this.selectedLogo = logo;
        if (this.winRef.isBrowser()) {
          const $elements = document.querySelectorAll('.js-show-container');
          $elements.forEach(function ($el) {
            $el.classList.remove('show-container');
          });
        }
      setTimeout(() => {
        this.service.setLogo(logo);
        this.price$.next(this.selectPrice(logo));
        this.stepService.next();
      }, 1000);
    }
  }

  public sumOfLogosLengths(arrays: { logos: any[] }[]): number {
    return arrays.reduce(
      (sum, currentObject) =>
        sum + (currentObject.logos ? currentObject.logos.length : 0),
      0
    );
  }
  private selectPrice = (item: LogoItem) => {
    return !item
      ? { value: 0, formattedValue: '$0.00' }
      : item.priceWithDiscounts?.value > 0
      ? item.priceWithDiscounts
      : item.price;
  };
}

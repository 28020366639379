import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomCartPromotionComponent } from './custom-cart-promotion.component';

@NgModule({
  declarations: [
    CustomCartPromotionComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    CustomCartPromotionComponent
  ]
})

export class CustomCartPromotionModule { }

import {
  ChangeDetectionStrategy,
  Component,
} from '@angular/core';
import { CouponCardComponent } from '@spartacus/storefront';

@Component({
  selector: 'cx-coupon-card',
  templateUrl: './custom-coupon-card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomCouponCardComponent extends CouponCardComponent {
  
}

import { NgModule } from "@angular/core";
import { CustomParagraphModule } from "./custom-paragraph/custom-paragraph.module";
import { TalkToUsModule } from "./talk-to-us/components/talk-to-us/talk-to-us.module";

@NgModule({
  imports: [
    CustomParagraphModule,
    TalkToUsModule
  ]
})
export class CustomCmsComponentsModule {


}

/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import {
  CmsConfig,
  I18nModule,
  provideConfig,
  provideDefaultConfig,
  UrlModule,
} from '@spartacus/core';
import { PageSlotModule } from '@spartacus/storefront';
import { CustomLoginComponent } from './login.component';

@NgModule({
  imports: [CommonModule, RouterModule, UrlModule, PageSlotModule, I18nModule],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        LoginComponent: {
          component: CustomLoginComponent,
        },
      },
    }),
  ],
  declarations: [CustomLoginComponent],
  exports: [CustomLoginComponent]
})
export class LoginModule {}

<svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
 <g clip-path="url(#clip0_4816_127087)">
  <path
   d="M8.4831 5.32529C8.4831 4.66651 9.50374 4.67161 9.50374 5.32529V11.3513C9.50374 12.0102 8.4831 12.0102 8.4831 11.3513V5.32529Z"
   fill="#131415" />
  <path fill-rule="evenodd" clip-rule="evenodd"
   d="M9.66195 13.227C9.63041 13.1135 9.57443 13.0102 9.48841 12.9241C9.36084 12.7965 9.17716 12.7199 8.99342 12.7199C8.60559 12.7199 8.30452 13.0417 8.29431 13.4195C8.2841 13.7974 8.61579 14.114 8.98321 14.1192C9.1618 14.1243 9.35572 14.0476 9.48329 13.92C9.6211 13.7822 9.68744 13.6136 9.69759 13.4195C9.70141 13.3534 9.68814 13.2879 9.66195 13.227ZM8.68002 13.4873C8.67913 13.4852 8.67814 13.483 8.67703 13.4808C8.67815 13.4838 8.67931 13.4868 8.68048 13.4898L8.68002 13.4873Z"
   fill="#131415" />
  <path fill-rule="evenodd" clip-rule="evenodd"
   d="M18 9.49749C17.9847 5.73375 15.6016 2.33772 12.0755 1.03548C8.56468 -0.261651 4.4466 0.856744 2.07373 3.75231C0.889849 5.19754 0.175436 6.9594 0.0274499 8.82338C-0.120536 10.6771 0.333627 12.5718 1.29808 14.1652C3.26272 17.4028 7.11544 19.0727 10.8202 18.3221C14.428 17.5867 17.3366 14.5482 17.8878 10.9069C17.9592 10.4372 18 9.97239 18 9.49749ZM11.7438 16.9994C14.8668 15.8708 16.9642 12.8016 16.9794 9.49749C16.9794 9.09405 16.9539 8.6855 16.8927 8.28717C16.3671 4.98816 13.8207 2.3173 10.5497 1.65851C7.29915 1.00484 3.89548 2.52157 2.1809 5.35075C0.445891 8.20546 0.690833 11.9947 2.83917 14.5737C3.87507 15.8146 5.25797 16.7338 6.81436 17.1781C8.432 17.6377 10.1619 17.5714 11.7438 16.9994Z"
   fill="#131415" />
 </g>
 <defs>
  <clipPath id="clip0_4816_127087">
   <rect width="18" height="18" fill="white" transform="translate(0 0.5)" />
  </clipPath>
 </defs>
</svg>
import { Address } from "@spartacus/core";
import { Card, getAddressNumbers } from "@spartacus/storefront";

export function CustomDeliveryAddressCard(
  textTitle: string,
  textPhone: string,
  textMobile: string,
  deliveryAddress: Address,
  countryName?: string
): Card {
  if (!countryName) {
    countryName = deliveryAddress?.country?.name as string;
  }

  let region = '';
  if (
    deliveryAddress &&
    deliveryAddress.region &&
    deliveryAddress.region.isocode
  ) {
    region = deliveryAddress.region.isocode + ', ';
  }

  const numbers = getAddressNumbers(deliveryAddress, textPhone, textMobile);
  let fullName;
  if (deliveryAddress.firstName && deliveryAddress.lastName) {
    fullName = deliveryAddress.firstName + ' ' + deliveryAddress.lastName;
  } else if (deliveryAddress.firstName) {
    fullName = deliveryAddress.firstName;
  } else if (deliveryAddress.lastName) {
    fullName = deliveryAddress.lastName;
  }

  return {
    title: textTitle,
  } as Card;
}

import { ChangeDetectionStrategy, Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { LoginFormComponentService } from './login-form-component.service';
import { ListrakService } from 'src/app/spartacus/features/listrak/core/listrak.service';
import { UserProfileFacade } from '@spartacus/user/profile/root';
import { distinctUntilChanged, first } from 'rxjs/operators';
import { DataLayerService } from 'src/app/spartacus/features/data-layer/data-layer.service';

@Component({
  selector: 'cx-login-form',
  templateUrl: './login-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class CustomLoginFormComponent implements OnInit, OnDestroy {
  @HostBinding('class.user-form') style = false;

  form: UntypedFormGroup = this.service.form;
  isUpdating$: Observable<boolean> = this.service.isUpdating$;
  private subscriptions = new Subscription();

  constructor(
    protected service: LoginFormComponentService,
    protected listrakService: ListrakService,
    protected userProfileFacade: UserProfileFacade,
    protected dataLayerService: DataLayerService
  ) {}

  ngOnInit(): void {
    this.listrakService.emailCapture();

    this.subscriptions.add(
      this.userProfileFacade.get().pipe(distinctUntilChanged()).subscribe(user => {
        if (user) {
          this.dataLayerService.loginEvent(user);
        }
      }
    ));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  onSubmit(): void {
    this.service.login();
  }
}

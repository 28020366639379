<div (scrolled)="scrollPage((model?.pagination?.currentPage ?? 0) + 1)" [infiniteScrollDisabled]="isMaxProducts || isLastPage || isEmpty" [infiniteScrollDistance]="5" [infiniteScrollThrottle]="50"
     infiniteScroll>
  <div class="row">
    <cx-product-grid-item
      *ngFor="let product of model?.products"
      [product]="product"
      class="col-12 col-sm-6 col-md-4 mt-5" />
  </div>

  <div [className]=" !isLastPage && (model?.pagination?.currentPage ?? 0) > 0 ? 'cx-double-btn-container grid-btn-padding' : 'cx-single-btn-container grid-btn-padding'">
    <div (click)="scrollToTop()" *ngIf=" (isMaxProducts || isLastPage) && (model?.pagination?.currentPage ?? 0) > 0 " class="btn  btn-outline-secondary">
      {{ 'productList.backToTopBtn' | cxTranslate }}
    </div>

    <button (click)="loadNextPage((model?.pagination?.currentPage ?? 0) + 1)" *ngIf="isMaxProducts && !isLastPage" class="btn btn-outline-secondary">
      {{ 'productList.showMoreBtn' | cxTranslate: { number: nextPageSize } }}
    </button>
  </div>

  <div *ngIf="appendProducts" class="cx-spinner">
    <cx-spinner></cx-spinner>
  </div>
</div>

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  isDevMode,
} from '@angular/core';
import { WindowRef } from '@spartacus/core';
import { CarouselComponent, CarouselService } from '@spartacus/storefront';
import { Subscription, interval } from 'rxjs';
import { switchMap, take, tap } from 'rxjs/operators';

@Component({
  selector: 'cx-custom-carousel',
  templateUrl: './custom-carousel.component.html',
  styleUrls: ['custom-carousel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class CustomCarouselComponent extends CarouselComponent implements OnInit, OnDestroy {
  @Input() autoplayOn: boolean = true;
  @Input() autoplayInterval: number = 5000;
  @Input() showArrowButtons: boolean = false;
  subscription = new Subscription();

  constructor(
    protected override el: ElementRef,
    protected override service: CarouselService,
    protected ref: ChangeDetectorRef,
    private winRef: WindowRef
  ) {
    super(el, service);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  override ngOnInit(): void {
      super.ngOnInit();

      if( this.winRef.isBrowser() && this.autoplayOn ){
        this.subscription = this.size$
          .pipe(
            take(1),
            switchMap((size) =>
              interval(this.autoplayInterval).pipe(
                tap(() => {
                  this.activeSlide =
                    this.activeSlide > this.items.length - size - 1
                      ? 0
                      : this.activeSlide + size;
                  this.ref.markForCheck();
                })
              )
            )
          ).subscribe();
      }
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductPriceComponent } from './product-price.component';
import { I18nModule } from '@spartacus/core';

@NgModule({
  imports: [
    CommonModule,
    I18nModule
  ],
  declarations: [ProductPriceComponent],
  exports: [ProductPriceComponent],
})
export class ProductPriceModule { }

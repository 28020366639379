<ng-container>
    <div class="product-container">
        <cx-media
            *ngIf="config.displayProductImages"
            [container]="product.images?.PRIMARY"
            format="thumbnail"
            role="presentation"
        ></cx-media>
        <div class="name" [innerHTML]="product.nameHtml"></div>
        <span class="price">{{ product.price?.formattedValue }}</span>
    </div>
</ng-container>
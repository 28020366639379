<ng-container *ngIf="thumbs$ | async as thumbs">
  <section class="product-gallery-thumbnails">

    <!-- BULLETS (Only Mobile) -->
    <div class="product-gallery-thumbnails__bullets">
      <ng-container *ngFor="let thumb of thumbs; index as i">
        <button (click)="openImage(thumb, i, 'video', thumb.videoUrl)" [class.active]="active === i" class="product-gallery-thumbnails__bullets--btn">
        </button>
      </ng-container>
    </div>

    <!-- THUMBNAILS -->
    <aside class="product-gallery-thumbnails__videos d-block">
      <div style="display: flex; flex-direction: column; text-align: start;">
        <ng-container *ngFor="let thumb of thumbs | slice: 0: (maxGalleryItems > 0 ? maxGalleryItems - 1 : thumbs.length); index as i">
          <table>
            <button class="product-gallery-thumbnails__thumbs--item mt-2 gallery-video-list">
              <div (focus)="openImage(thumb, i, 'video', thumb.videoUrl)" (click)="openImage(thumb, i, 'video', thumb.videoUrl)" class="float-left" style="display: flex; text-align: start;">
                <div style="max-height: 70px; max-width: 125px; font-size: 12px;" [ngClass]="{'active': active === i}">
                  <cx-media [container]="thumb" format="product"> </cx-media>
                </div>

                <div style="padding-left: 20px;">
                  <div style="font-weight: 700;">
                    {{thumb.altText}}
                  </div>
                </div>
              </div>
            </button>
          </table>
        </ng-container>
      </div>
      <!-- More buttons:
           If there are more than 4 images, then show it -->
      <ng-container *ngIf="thumbs.length > 4">
        <button class="product-gallery-thumbnails__more">
          <cx-media [container]="thumbs[4]" format="thumbnail" tabindex="0"> </cx-media>

          <div class="product-gallery-thumbnails__overlay">
            <div class="product-gallery-thumbnails__overlay--highlight">
              +{{ thumbs.length - 4 }}
            </div>

            <div>{{ 'productCarousel.images' | cxTranslate }}</div>
          </div>
        </button>
      </ng-container>

      <!-- Vídeos Button -->
      <button class="product-gallery-thumbnails__videos">
        <div class="product-gallery-thumbnails__overlay">
          <i class="bi bi-play-btn"></i>
          <div>{{ 'productCarousel.videos' | cxTranslate }}</div>
        </div>
      </button>
    </aside>
  </section>
</ng-container>

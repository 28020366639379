import { HostListener, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Option } from '../../model/radio.models';
import { ICON_TYPE } from '@spartacus/storefront';
import { hasClass } from 'src/app/tools/tools';

@Component({
  selector: 'float-select',
  templateUrl: './float-select.component.html',
  styleUrls: ['./float-select.component.scss']
})

export class FloatSelectComponent implements OnInit {
  @Input() options?: Option[];
  @Input() label?: string
  @Input() activeItem?: string;
  @Output() callbackOnChange = new EventEmitter<Option>();

  iconTypes = ICON_TYPE;
  currentOption: Option | undefined;
  showOptions: boolean = false;

  ngOnInit(): void {
    this.currentOption = this.options?.find(option => option.code === this.activeItem);
  }

  open () {
    this.showOptions = true;
  }

  selectOption (option: Option) {
    this.currentOption = option;
    this.callbackOnChange.emit(this.currentOption);
  }

  @HostListener('document:click', ['$event'])
  clickOut(event: any) : boolean | void {
    const element = event.target as HTMLElement;
    const isSelect = hasClass(element, 'js-floating-select');

    if (isSelect) {
      return false;
    }

    this.showOptions = false;
  }
}

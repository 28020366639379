import { BREAKPOINT } from "@spartacus/storefront";
import { CmsResponsiveBannerComponentMedia } from "@spartacus/core/src/model/cms.model";

/**
 * Maps the breakpoint to the desired
 * media size. Can be used to determine the
 * media based on the current breakpoint
 */
export const breakpointMediaMapKey = new Map<BREAKPOINT, keyof CmsResponsiveBannerComponentMedia>([
  [ BREAKPOINT.xs, 'mobile' ],
  [ BREAKPOINT.sm, 'mobile' ],
  [ BREAKPOINT.md, 'tablet' ],
  [ BREAKPOINT.lg, 'desktop' ],
  [ BREAKPOINT.xl, 'widescreen' ],
]);

import { EventEmitter, Injectable } from '@angular/core';
import {ToasterContainerModel, ToasterModel} from './toaster.model';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: "root",
})

export class ToasterService {
  allToaster: Array<ToasterModel> = [];
  toasters$ = new BehaviorSubject<Array<ToasterModel>>([]);
  toastersContainer$ = new EventEmitter<ToasterContainerModel>();

  constructor() {}

  ID() : string {
    return Math.random().toString(36).substr(2, 8);
  }

  init(toaster: ToasterModel) {
    toaster.id = this.ID();
    this.allToaster.push(toaster);
    this.toasters$.next( this.allToaster );

    !toaster.preventClose && setTimeout(() => {
      toaster.id && this.closeIt(toaster.id);
    }, toaster.timeToClose || 5000);
  }

  toasterContainer (container: ToasterContainerModel) {
    this.toastersContainer$.emit(container)
  }

  closeIt(toasterID: String) {
    this.allToaster = this.allToaster.filter(e => e.id != toasterID);
    this.toasters$.next( this.allToaster );
  }

  closeALL() {
    this.allToaster = [];
    this.toasters$.next( this.allToaster );
  }
}

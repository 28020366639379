import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import {
  CmsConfig,
  I18nModule,
  provideConfig,
  provideDefaultConfig,
  UrlModule,
} from '@spartacus/core';
import { CardModule, IconModule, OutletModule } from '@spartacus/storefront';

import { CommonModule } from '@angular/common';
import { CheckoutAuthGuard, CartNotEmptyGuard } from '@spartacus/checkout/base/components';
import { CheckoutReviewShippingComponent } from '@spartacus/checkout/base/components/checkout-review/checkout-review-shipping/checkout-review-shipping.component';
import { CustomCheckoutReviewShippingComponent } from './custom-checkout-review-shipping.component';
import { CustomCheckoutDeliveryModeModule } from '../custom-checkout-delivery-mode/custom-checkout-delivery-mode.module';
import { CustomCartPromotionModule } from '../../../cart/components/custom-cart-promotion/custom-cart-promotion.module';
import { IconsModule } from "../../../../../components/_CUSTOM/icons/icons.module";

@NgModule({
  declarations: [CustomCheckoutReviewShippingComponent],
  exports: [CustomCheckoutReviewShippingComponent],
  imports: [
    CommonModule,
    I18nModule,
    CardModule,
    UrlModule,
    RouterModule,
    IconModule,
    OutletModule,
    CustomCheckoutDeliveryModeModule,
    CustomCartPromotionModule,
    IconsModule
  ],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        CheckoutReviewShipping: {
          component: CustomCheckoutReviewShippingComponent,
          guards: [CheckoutAuthGuard, CartNotEmptyGuard],
        },
      },
    }),
  ],
})
export class CustomCheckoutReviewShippingModule {}

<ng-container *ngIf="model$ | async as model">
  <div class="container">
    <div class="no-results__container no-results-spacing">
      <h1 class="no-results__title no-results-spacing">Sorry</h1>

      <div class="no-results__quantity no-results-spacing">
        WE FOUND {{model.pagination.totalResults}} RESULTS FOR "{{model.freeTextSearch}}"
      </div>

      <div class="no-results__suggestions">
        We think you might be interested in the items below.
      </div>
    </div>
  </div>
</ng-container>

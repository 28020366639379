<section class="product-lines-footer">
  <ng-container *ngFor="let btn of buttons$ | async">
    <ng-container *ngIf="btn.display && btn.label != 'Save'">
      <button
        (click)="btn.execute()"
        [disabled]="btn.disabled"
        [ngClass]="btn.css"
        type="button">
        {{ btn.label }}
      </button>
    </ng-container>
    <ng-container *ngIf="btn.display && btn.label == 'Save'">
      <button
      (click)="btn.execute()"
      [disabled]="btn.disabled"
      class="btn btn-util-4"
      type="button">
      <span class="cart-icon px-2"><i class="bi bi-cart"></i></span>
      <span>{{'addToCart.addToCart' | cxTranslate }}</span>
    </button>
    </ng-container>
  </ng-container>
</section>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  AnonymousConsentsService,
  CmsConfig,
  DeferLoadingStrategy,
  FeaturesConfigModule,
  I18nModule,
  provideDefaultConfig,
} from '@spartacus/core';
import { AnonymousConsentManagementBannerComponent } from './banner/anonymous-consent-management-banner.component';
import { defaultAnonymousConsentLayoutConfig } from './default-anonymous-consent-layout.config';
import { AnonymousConsentOpenDialogComponent } from './open-dialog/anonymous-consent-open-dialog.component';
import { ConsentManagementModule, IconModule, KeyboardFocusModule, SpinnerModule } from '@spartacus/storefront';
import { CustomAnonymousConsentsService } from './anonymous-consents.service';
import { CustomAnonymousConsentDialogComponent } from './custom-anonymous-consent-dialog/custom-anonymous-consent-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    I18nModule,
    FeaturesConfigModule,
    KeyboardFocusModule,
    IconModule,
    SpinnerModule,
    ConsentManagementModule,
  ],
  providers: [
    provideDefaultConfig(defaultAnonymousConsentLayoutConfig),
    provideDefaultConfig(<CmsConfig>{
      cmsComponents: {
        AnonymousConsentManagementBannerComponent: {
          component: AnonymousConsentManagementBannerComponent,
          disableSSR: true,
          deferLoading: DeferLoadingStrategy.INSTANT
        },
        AnonymousConsentOpenDialogComponent: {
          component: AnonymousConsentOpenDialogComponent,
          disableSSR: true
        },
      },
    }),
    {
      provide: AnonymousConsentsService,
      useClass: CustomAnonymousConsentsService
    }
  ],
  declarations: [
    AnonymousConsentManagementBannerComponent,
    AnonymousConsentOpenDialogComponent,
    CustomAnonymousConsentDialogComponent,
  ],
  exports: [
    AnonymousConsentManagementBannerComponent,
    AnonymousConsentOpenDialogComponent,
  ],
})
export class CustomAnonymousConsentManagementBannerModule {}

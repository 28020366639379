<form class="custom-latest-news-input" (ngSubmit)="submitForm()" [formGroup]="newsletterForm">
    <input  required="true"  class="form-control"  type="emailAddress"  name="emailAddress" id="footerEmailAddress" maxlength="100"  placeholder="{{'register.emailAddress.placeholder' | cxTranslate }}"  formControlName="emailAddress" />

    <button type="submit" class="btn btn-block btn-primary  mb-2">
        {{'newsletter.send' | cxTranslate }}
    </button>
</form>

<cx-form-errors [control]="newsletterForm.get('emailAddress')"></cx-form-errors>

<div [id]="containerId" class="g-recaptcha" data-size="invisible">

<div class="cx-custom-review-summary w-100" *ngIf="paymentDetails$ | async as paymentDetails">
  <div class="row m-t--20">
    <div class="col-12 col-sm-12 col-md-6 col-lg-6 m-t-20">
      <div class="cx-review-summary-card cx-review-summary-payment-card">
        <cx-card [content]="getShippingAddressCard() | async"></cx-card>

        <div class="cx-review-summary-edit-step">
          <a [attr.aria-label]="'checkoutReview.editDeliveryDetails' | cxTranslate" [routerLink]="{ cxRoute: addressDetailsStepRoute } | cxUrl">
            <cx-icon aria-hidden="true" [type]="iconTypes.PENCIL"></cx-icon>
          </a>
        </div>
      </div>
    </div>

    <div class="col-12 col-sm-12 col-md-6 col-lg-6 m-t-20">
      <div class="cx-review-summary-card cx-review-summary-payment-card">
        <cx-card [content]="getPaymentMethodCard(paymentDetails) | async"></cx-card>

        <div class="cx-review-summary-edit-step">
          <a [attr.aria-label]="'checkoutReview.editPaymentDetails' | cxTranslate" [routerLink]="{ cxRoute: paymentDetailsStepRoute } | cxUrl">
            <cx-icon aria-hidden="true" [type]="iconTypes.PENCIL"></cx-icon>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>

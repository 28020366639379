<ng-container *ngIf="product$ | async as product">

  <!-- SKU page info -->
  <ng-template #skuInfo>
    <div class="product-lines-title">
      Additional Details
    </div>
    <addition-details></addition-details>
    <div class="product-lines-cta__btn">
      <button (click)="redirectToPDP()" *ngIf="isSKUPage" class="btn btn-secondary">
        <span class="d-center"> <i class="icomoon-seat fs-20 m-r-4"></i> VERIFY FITMENT</span>
      </button>
    </div>
  </ng-template>

  <ng-container *ngIf="!isSKUPage; else skuInfo">

    <!-- Product Config -->
    <ng-template #configuratorButton>

      <!-- Button for when there are no Product configuration selected yet -->
      <section class="product-lines-cta">
        <div class="product-lines-cta__btn">
          <button
            (click)="openDetailsDialog()"
            [disabled]="disabled$ | async"
            class="btn btn-primary">
            <span>Confirm details</span>
          </button>
        </div>
        <div class="product-lines-cta__copy">
          Get the best fit possible by confirming your vehicle details
        </div>
      </section>
    </ng-template>


    <ng-container *ngIf="product.vehicleSpecific">
      <div class="product-lines-title">
        STEP 2: SELECT VEHICLE DETAILS
      </div>

      <ng-container *ngIf="selection$ | async;">

        <section class="product-lines-selected">

          <!-- VEHICLE -->
          <ng-container *ngIf="vehicle$ | async as vehicle">
            <div class="product-lines-selected__style">
              <div class="product-lines-selected__style--heading">
                Vehicle
              </div>
              <div class="product-lines-selected__info">
                <div class="product-lines-selected__info--copy">
                  {{ vehicle.name }} {{ subModel$ | async }}
                </div>
              </div>

              <ng-container *ngIf="subModelOptions$ | async as subModelOption">
                <div class="product-lines-selected__info">
                  <div class="product-lines-selected__info--copy">
                    <span class="bold-common-font">Option: </span>{{ subModelOption }}
                  </div>
                </div>
              </ng-container>
            </div>
          </ng-container>


          <!-- PRODUCT LINES -->
          <ng-container *ngIf="productLines$ | async as productLines">
            <div *ngFor="let line of productLines"
                 class="product-lines-selected__style">
              <div class="product-lines-selected__style--heading">
                {{ line.description }}
              </div>

              <div class="product-lines-selected__info d-flex align-items-center">
                <div class="product-lines-selected__info--copy">
                  {{ line.option?.bullets?.[0] }}
                </div>

                <div class="product-lines-selected__info--price">
                  {{
                    line.option?.priceWithDiscount?.value > 0
                      ? line.option.priceWithDiscount.formattedValue
                      : line.option?.price?.formattedValue
                  }}
                </div>
              </div>
            </div>
          </ng-container>


          <ng-container *ngIf="embroideryAndLogo$ | async as el">
            <section class="product-lines-selected__style">
              <div class="product-lines-selected__style--heading mb-3">
                Options
              </div>

              <!-- EMBROIDERY -->
              <ng-container *ngIf="el?.embroidery as embroidery">
                <div class="product-lines-selected__style--item">
                  <!-- <cx-media [container]="embroidery.font?.image"></cx-media>-->
                  <div class="product-lines-selected__style--heading">Embroidery</div>
                  <div class="product-lines-selected__info">Font: {{ embroidery.font?.name }}</div>
                  <!-- <div class="product-lines-selected__info">{{ embroidery.text?.length }} characters</div>-->
                  <div class="product-lines-selected__info">
                    Custom Text: <span class="text-uppercase">{{ embroidery.text }}</span>
                  </div>
                </div>
              </ng-container>

              <!-- LOGOS -->
              <ng-container *ngIf="el.logo as logo">
                <div class="product-lines-selected__style--item mt-3">
                  <div class="product-lines-selected__style--heading">Logo</div>
                  <div class="product-lines-selected__info mb-1">
                    {{ logo.item?.name }}
                  </div>
                  <cx-media [container]="logo.item?.image"></cx-media>
                </div>
              </ng-container>

            </section>
          </ng-container>

          <!-- ADDONS -->
          <ng-container *ngIf="addons$ | async as addonsSelection">
            <section class="product-lines-selected__style d-flex">
              <div class="product-lines-selected__style--item">
                <div class="product-lines-selected__style--heading">Addons</div>
                <div *ngFor="let addon of addonsSelection.addons"
                     class="product-lines-selected__info d-flex align-items-center">
                  <div class="product-lines-selected__info--copy">
                    {{ addon.product?.name }}
                  </div>
                  <div class="product-lines-selected__info--price"> {{
                      addon.price?.prices?.priceWithDiscount?.value > 0
                        ? addon.price?.prices?.priceWithDiscount.formattedValue
                        : addon.price?.prices?.price?.formattedValue
                    }}
                  </div>
                </div>
              </div>
            </section>
          </ng-container>

        </section>

        <!-- Edit your elections -->
        <div class="product-lines-selected__action">
          <button
            (click)="openDetailsDialog()"
            [disabled]="disabled$ | async"
            class="btn btn-secondary">
            <svg-icon iconId="iconSeat"></svg-icon>
            <div>Edit your selections</div>
          </button>
        </div>

      </ng-container>
    </ng-container>
  </ng-container>

</ng-container>

<div class="form-group {{containerClass}}" [formGroup]="form" >
  <label [for]="id" class="mb-1 {{labelClass}}" *ngIf="label">
      {{label}} {{required ? '*' : ''}}
  </label>
  <select [id]="id " class="form-control" [ngClass]="classError()" [formControlName]="name" [attr.aria-label]="label" (change)="selectValue($event)" [disabled]="disabled" >
    <option *ngIf="placeholder" value="">{{placeholder}}</option>
    <option [value]="option.code" *ngFor="let option of options">
          {{option.value}}
      </option>
  </select>

      <div class="invalid-feedback" *ngIf="{error: getError()} as dataError">
        <span *ngIf="dataError?.error?.params === undefined">{{ getError().description | cxTranslate}}</span>
        <span *ngIf="dataError?.error?.params">
      
          {{ dataError.error && dataError.error.description| cxTranslate:{param: dataError.error.params.param} }}
        </span>
      </div>
</div>
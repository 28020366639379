import { BasePageMetaResolver, CategoryPageMetaResolver, CmsService, Page, PageDescriptionResolver, PageLinkService, PageType, ProductSearchPage, ProductSearchService, RoutingService, TranslationService, WindowRef } from "@spartacus/core";
import { Injectable } from "@angular/core";
import { Observable, defer, of } from "rxjs";
import { filter, map, startWith, switchMap, tap } from "rxjs/operators";
import { CustomBasePageMetaResolver } from "./custom-base-page-meta-resolvers";

import { PageKeywordsResolver } from "./custom-page-resolver";
import { NavigationEnd, Router } from "@angular/router";

/**
 * Customize the Category Resolver that apply the
 * meta tag values for SEO purposes
 */
@Injectable({ providedIn: 'root' })
export class CustomCategoryPageMetaResolver
  extends CategoryPageMetaResolver
  implements PageDescriptionResolver, PageKeywordsResolver
{
  protected page$: Observable<Page> = defer(() =>
    this.cms.getCurrentPage()
  ).pipe(filter((p) => Boolean(p)));

  constructor(
    protected override productSearchService: ProductSearchService,
    protected override cms: CmsService,
    protected override translation: TranslationService,
    protected override basePageMetaResolver: BasePageMetaResolver,
    protected pageLinkService: PageLinkService,
    protected router: Router,
    private winRef: WindowRef
  ) {
    super(productSearchService, cms, translation, basePageMetaResolver);
    this.pageType = PageType.CATEGORY_PAGE;
  }

  override resolveTitle(): Observable<string> {
    return (<Observable<ProductSearchPage | Page>>this.searchPage$).pipe(
      map(
        (p: ProductSearchPage | Page) =>
          (p as ProductSearchPage).breadcrumbs?.[0]?.facetValueName ??
          (p as Page).title
      )
    );
  }

  /**
   * Resolves the description, delegating it to
   * the default implementation
   */
  resolveDescription(): Observable<string | undefined> {
    return this.basePageMetaResolver.resolveDescription();
  }

  /**
   * Resolves the keyword using the base implementation
   */
  resolveKeywords(): Observable<string | undefined> {
    return (
      this.basePageMetaResolver as CustomBasePageMetaResolver
    ).resolveKeywords();
  }

  override resolveCanonicalUrl(): Observable<string> {
    return this.page$.pipe(
      switchMap((p) => {
        const categoryUrl = this.simplifyUrl(this.winRef.location.href);

        return this.router.events.pipe(
            filter((ev) => ev instanceof NavigationEnd),
            startWith(null),
            map(() => this.pageLinkService.getCanonicalUrl({}, categoryUrl))
          );
        })
    );
  }

  simplifyUrl(url: string): string {
  const urlObj = new URL(url);
  const origin = urlObj.origin;
  const pathname = urlObj.pathname;
  
  const simplifiedPathname = pathname.replace(/^(.+\/)/, '/c/');
  
  return origin + simplifiedPathname + urlObj.search + urlObj.hash;
  }
}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Badge } from '../custom-product-list.types';

@Component({
  selector: 'cx-product-badges',
  templateUrl: './custom-product-badges.component.html',
  styleUrls: [ './custom-product-badges.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomProductBadgesComponent {
  @Input() badges: Badge[] = [];
}

import { Component, Input } from '@angular/core';
import { PromotionsComponent } from '@spartacus/storefront';

@Component({
  selector: 'custom-cart-promotion',
  templateUrl: './custom-cart-promotion.component.html',
  styleUrls: ['./custom-cart-promotion.component.scss'],
})

export class CustomCartPromotionComponent extends PromotionsComponent {
  @Input() cssClasses: string;
  promotionsText: string[][] = [];

  convertToHTML(stringHTML: string): HTMLElement {
    const element = document.createElement('div');
    element.innerHTML = stringHTML;
    return element;
  }

  normalizeHTML(htmlString: any): string[] | any {
    const content = this.convertToHTML(htmlString);
    const $p = content.querySelectorAll('p');

    if ($p.length > 1) {
      'text-primary text-weight-700 m-r-4'.split(' ').forEach(className => {
        content.querySelectorAll('p')[0].classList.add(className);
      });
    }

    return content.innerHTML;
  }
}

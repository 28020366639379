import { combineLatest, Observable } from "rxjs";
import { ConfiguratorManagerBase } from "../../core/facade/configurator-manager-base.service";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { ConfiguratorManagerContract } from "../../core/facade/configurator-manager.contract";
import { ConfigurationSelection, ProductConfigStepSelection, ProductLineSelection } from "../../core/store/product-configuration.state";
import { ProductConfigurationService } from "../../core/facade/product-configuration.service";
import { ProductConfiguratorStepsService } from "../../core/facade/product-configurator-steps.service";
import { ProductConfigStepType, ProductConfiguratorButton, ProductLine } from "../../core/models/product-configuration.models";
import { TranslationService } from "@spartacus/core";

@Injectable({ providedIn: 'root' })
export class ProductLineManagerService implements ConfiguratorManagerContract {

  private selectedProductLines$ = this.productConfigService.getConfigSelection()
    .pipe(
      map(selection => selection.selections
        ?.filter(s => s.stepType === ProductConfigStepType.PRODUCT_LINES)
      )
    );

  private nextButton$ = combineLatest([
    this.managerBase.nextButton$,
    this.selectedProductLines$
  ]).pipe(
    map(([ button, selectedProductLines ]) => {
      button.disabled = !selectedProductLines?.length;
      return button;
    })
  );

  private saveButton$ = combineLatest([
    this.managerBase.saveButton$,
    this.selectedProductLines$
  ]).pipe(
    map(([ button, productLines ]) => {
      button.disabled = !productLines?.length;
      return button;
    })
  );

  private buttons$ = combineLatest([
    this.managerBase.backButton$,
    this.nextButton$,
    this.saveButton$
  ]);

  constructor(private managerBase: ConfiguratorManagerBase,
              private productConfigService: ProductConfigurationService,
              private stepService: ProductConfiguratorStepsService,
              private translation: TranslationService) {
  }

  getButtons(): Observable<ProductConfiguratorButton[]> {
    return this.buttons$;
  }

  getCartPayloadPart(selection: ConfigurationSelection, i: number): string[] {
    const productLine = selection.selections[ i ] as ProductLineSelection;
    if (!productLine) {
      return [];
    }
    return [ `basePartNumber::${ productLine.option.sku }|${ productLine.option.basePartNumber }` ];
  }

  getPrice(stepSelection: ProductConfigStepSelection) {
    const line = stepSelection as ProductLineSelection;
    return {
      price: line.option?.price?.value ?? 0,
      priceWithDiscount: line.option?.priceWithDiscount?.value ?? 0,
    };
  }

}

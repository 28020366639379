import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ConverterService, OccEndpointsService, PRODUCT_NORMALIZER, PRODUCT_REFERENCES_NORMALIZER } from "@spartacus/core";
import { Observable, throwError } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { Year } from "../../../ymm/core/models/garage.model";

 
 @Injectable({
   providedIn: 'root',
 })
 export class CustomProductReferenceService {
   constructor(
     private http: HttpClient,
     private endpointService: OccEndpointsService,
     private converter: ConverterService,
   ) {}

   loadReferences(productCode: string): Observable<any[]> {
     const url =
       this.endpointService.buildUrl('productReferences', {
         urlParams: { productCode },
       }) +
       '&referenceType=CROSSELLING&referenceType=SIMILAR&referenceType=UPSELLING&referenceType=ACCESSORIES&referenceType=BASE_PRODUCT&referenceType=CONSISTS_OF&referenceType=DIFF_ORDERUNIT&referenceType=FOLLOWUP&referenceType=MANDATORY&referenceType=SELECT&referenceType=OTHERS&referenceType=SPAREPART';
     


     return this.http
       .get<any[]>(url).pipe(map((source: any)=>{
         source?.references.forEach((reference: any)=>{
         reference.target = this.converter.convert(reference.target, PRODUCT_NORMALIZER)

        })
        return source
       }))

   }
 }
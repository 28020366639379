import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NoResultsFoundComponent } from './no-results-found.component';
import { IconsModule } from 'src/app/components/_CUSTOM/icons/icons.module';
import { TalkToUsCardsModule } from '../../../../components/_SPA-Components/talk-to-us/components/talk-to-us-cards/talk-to-us-cards.module';
import { CmsConfig, provideConfig } from '@spartacus/core';



@NgModule({
  declarations: [NoResultsFoundComponent],
  imports: [CommonModule, TalkToUsCardsModule],
  exports: [NoResultsFoundComponent],
  providers: [
    provideConfig({
      cmsComponents: {
        NoResultsFoundPage: {
          component: NoResultsFoundComponent,
        },
      },
    } as CmsConfig),
  ],
})
export class NoResultsFoundModule {}

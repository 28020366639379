import { NgModule } from "@angular/core";
import { CmsConfig, provideConfig } from "@spartacus/core";
import { MARKETING_FEATURE_COMPONENTS } from "./components/marketing-feature-name";

@NgModule({
  providers: [
    provideConfig({
      featureModules: {
        [ MARKETING_FEATURE_COMPONENTS ]: {
          module: () => import('./components/marketing-components.module')
            .then(m => m.MarketingComponentsModule),
          cmsComponents: [
            'BannerTopCategoryComponent',
            'LegacyOfQualityBannerCollectionComponent',
            'BannerPanelComponent',
            'CovercraftRotatingBannerComponentCollection',
            'SatisfiedCustomerBannerCollectionComponent',
            'SubcategorySuggestionComponentCollection',
            'YoutubeComponent',
            'BestProductsComponent',
            'CategoryPageRespBannerComponent',
            'PromoBannerComponent'
          ]
        }
      }
    } as CmsConfig)
  ]
})
export class MarketingFeatureModule {

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductDetailsPageTemplateComponent } from './product-details-page-template.component';
import { OutletModule, OutletRefModule, PageLayoutModule, PageSlotModule } from '@spartacus/storefront';

@NgModule({
  declarations: [
    ProductDetailsPageTemplateComponent
  ],
  imports: [
    CommonModule,
    OutletModule,
    OutletRefModule,
    PageLayoutModule,
    PageSlotModule,
  ],
  exports: [
    ProductDetailsPageTemplateComponent
  ]
})

export class ProductDetailsPageTemplateModule { }

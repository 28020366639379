<ng-container *ngIf="banners$ | async as banners">
  <div class="banner-container" [ngStyle]="bg$ | async">
    <div class="container">
      <div class="row custom-row">
        <div class="col-12 banner-container__title">
          <p [class]="(banner.titleColor ?? 'text-primary') + ' banner-container__title--text font-weight-bolder'" >{{banner.title}}</p>
        </div> 
        <div class="col-12 banner-container__cards">
          <div class="row custom-banners-row">
            <cx-banner-loq-item
              *ngFor="let banner of banners"
              [banner]="banner">
            </cx-banner-loq-item>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

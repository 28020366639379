import { NgModule } from "@angular/core";
import { CmsConfig, provideConfig } from "@spartacus/core";
import { ProductConfigurationStoreModule } from "./core/store/product-configuration-store.module";
import { productConfiguratorOccConfig } from "./occ/product-configurator-occ.config";
import { PRODUCT_CONFIGURATION_NORMALIZER } from "./occ/converter/product-configuration.converter";
import { ProductConfigurationNormalizer } from "./occ/converter/product-configuration-normalizer";


@NgModule({
  imports: [
    ProductConfigurationStoreModule
  ],
  providers: [
    provideConfig({
      featureModules: {
        'YMM_PRODUCT_CONFIGURATOR': () => import('./product-configurator.module')
          .then(m => m.ProductConfiguratorModule)
      }
    } as CmsConfig),
    provideConfig(productConfiguratorOccConfig),
    {
      provide: PRODUCT_CONFIGURATION_NORMALIZER,
      useExisting: ProductConfigurationNormalizer,
      multi: true
    }
  ]
})
export class ProductConfiguratorFeatureModule {

}

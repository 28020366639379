import { createFeatureSelector, createSelector } from "@ngrx/store";
import { PRODUCT_DETAILS_FEATURE_KEY, ProductDetailsState } from "./product-details.state";

const featureSelector = createFeatureSelector<ProductDetailsState>(PRODUCT_DETAILS_FEATURE_KEY);


export const currentProduct = createSelector(
  featureSelector,
  state => state.product
);

export const selectActiveVariantOptions = createSelector(
  featureSelector,
  state => state.selectedVariants
);

export const allVariants = createSelector(
  featureSelector,
  state => state.variants
);

export const selectVariants = (product: string) => createSelector(
  featureSelector,
  state => state.variants[ product ]
);

export const selectPrices = createSelector(
  featureSelector,
  state => state.prices
);

export const selectGallery = createSelector(
  featureSelector,
  state => state.gallery
);

export const selectSku = createSelector(
  featureSelector,
  state => state.sku
);


import { NgModule } from "@angular/core";
import { CmsConfig, provideConfig } from "@spartacus/core";
import { CustomTabParagraphContainerModule } from "./custom-tab-paragraph-component/custom-tab-paragraph-container.module";

@NgModule({
  imports: [
    CustomTabParagraphContainerModule
  ],
  providers: [
    provideConfig({
      featureModules: {
        'CUSTOM_PRODUCT_TABS': {
          module: () => import('./custom-product-tabs/custom-product-tabs.module')
            .then(m => m.CustomProductTabsModule),
          cmsComponents: [
            'ProductDescriptionTabComponent',
            'ProductDetailsTabComponent',
            'ProductInstallationTabComponent',
            'ProductKeyFeaturesTabComponent',
            'ProductQATabComponent',
            'ProductReviewsTabComponent'
          ]
        }
      }
    } as CmsConfig)
  ]
})
export class ProductTabsFeatureModule {

}

import { Component, Input } from '@angular/core';
import { IconsService } from '../icons.service';
import { IconDimensions } from '../dimensions.model';

@Component({
  selector: 'icon-apple',
  templateUrl: './icon-apple.component.html',
  styleUrls: ['./icon-apple.component.scss']
})

export class IconAppleComponent {
  @Input() className: string = '';
  @Input() size: string = '16';

  width: number = 16;
  height: number = 16;

  constructor(private iconService: IconsService) { }

  ngOnInit(): void {
    const dimensions: IconDimensions = this.iconService.setDimensions({size: this.size, width: this.width, height: this.height})
    this.width = dimensions.width;
    this.height = dimensions.height;
  }
}

import { ChangeDetectionStrategy, Component } from "@angular/core";
import { CurrentProductService } from "@spartacus/storefront";
import { map } from "rxjs/operators";
import { CommonModule } from "@angular/common";

@Component({
  selector: 'cx-product-overview',
  templateUrl: './product-overview.component.html',
  standalone: true,
  imports: [
    CommonModule
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductOverviewComponent {
  overview$ = this.currentProductService.getProduct().pipe(map(product => product?.summary));
  
  constructor(
    private currentProductService: CurrentProductService,
  ) { }
}

import { ProductFacetService } from "@spartacus/storefront";
import { Injectable } from "@angular/core";
import { ActivatedRouterStateSnapshot, PageType, ProductSearchPage } from "@spartacus/core";

@Injectable({ providedIn: 'root' })
export class CustomProductFacetService extends ProductFacetService {
  protected override filterForPage(
    state: ActivatedRouterStateSnapshot,
    page: ProductSearchPage): boolean {
    if (state.context.type === PageType.CONTENT_PAGE &&
      state.context.id.indexOf('shop') >= 0) {
      return true;
    }
    if (!page.currentQuery?.query?.value) {
      return false;
    }
    if (state.context.type === PageType.CATEGORY_PAGE) {
      return (
        page.currentQuery.query.value.indexOf(
          `allCategories:${ state.context.id }`
        ) > -1
      );
    }

    if (
      state.context.type === PageType.CONTENT_PAGE &&
      state.context.id === 'search'
    ) {
      return page.freeTextSearch === state.params[ "query" ].split(':')[ 0 ];
    }
    return false;
  }
}

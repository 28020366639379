import { Component } from '@angular/core';
import { MyCouponsComponent } from '@spartacus/storefront';

@Component({
  selector: 'cx-my-coupons',
  templateUrl: './custom-my-coupons.component.html',
})
export class CustomMyCouponsComponent extends MyCouponsComponent {
  
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AnonymousAcountIconComponent } from './anonymous-acount-icon.component';
import { CmsConfig, provideConfig } from '@spartacus/core';
import { NavigationModule } from '@spartacus/storefront';



@NgModule({
  declarations: [AnonymousAcountIconComponent],
  imports: [CommonModule, NavigationModule],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        MyAnonymousAccountFlexComponent: {
          component: AnonymousAcountIconComponent,
        },
      },
    }),
  ],
})
export class AnonymousAcountIconModule {}

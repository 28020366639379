import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { CmsService, PageMetaConfig, PageMetaService, UnifiedInjector } from "@spartacus/core";
import { Title } from '@angular/platform-browser';

@Injectable({ providedIn: 'root' })
export class CustomPageMetaService extends PageMetaService {

  constructor(
    cms: CmsService,
    unifiedInjector: UnifiedInjector,
    pageMetaConfig: PageMetaConfig,
    private titleService: Title,
    @Inject(PLATFORM_ID) override platformId: string
  ) {
    super( cms, unifiedInjector, pageMetaConfig, platformId )
  }

  setPageTitle (title: string) {
    this.titleService.setTitle(title);
  }
}

import { Component } from '@angular/core';
import { ProductListItemContext, ProductListItemContextSource } from '@spartacus/storefront';
import { CustomProductCardComponent } from '../../custom-product-card/custom-product-card.component';

@Component({
  selector: 'custom-product-carousel-item',
  templateUrl: './custom-product-carousel-item.component.html',
  styleUrls: [ './custom-product-carousel-item.component.scss' ],
  providers: [
    ProductListItemContextSource,
    {
      provide: ProductListItemContext,
      useExisting: ProductListItemContextSource,
    },
  ],
})
export class CustomProductCarouselItemComponent extends CustomProductCardComponent {


}


export interface ListTrak {
  LtkPrefCenterIframe: {
    Render: (arg?: any) => void;
  };
  SCA: any;
  loggedIn:any;
  Activity: any;
  Order: any;
}

/**
 * Mimic SCAItem class from the listrak framework
 * _ltk.SCA.AddItemEx() should accept this object as long
 * as the properties match
 */
export class SCAItem {
  Sku: string;
  Quantity: number;
  Price: number;
  Name: string;
  ImageUrl: string;
  LinkUrl: string;
  Size: string;
  Meta1: string;
  Meta2: string;
  Meta3: string;
  Meta4: string;
  Meta5: string;
  _isIndexable = !0;
  _varmap = {
    _type: "_i",
    Sku: "s",
    Quantity: "q",
    Price: "p",
    Name: "n",
    ImageUrl: "iu",
    LinkUrl: "lu",
    Size: "sz",
    Meta1: "m1",
    Meta2: "m2",
    Meta3: "m3",
    Meta4: "m4",
    Meta5: "m5"
  };

  constructor(sku: string, quantity: number, price: number, name: string) {
    this.Sku = sku
    this.Quantity = quantity
    this.Price = price
    this.Name = name
  }

  
}
<div class="cx-product-facet-navigation">
  <button
    #trigger
    class="btn btn-secondary btn-block dialog-trigger"
    (click)="launch()"
  >
    <i class="bi bi-filter-right icon-filter"></i>
    {{ 'productList.filters' | cxTranslate }}
    <i class="bi bi-chevron-down icon-chevron"></i>
  </button>

  <cx-facet-list
    *ngIf="isOpen$ | async"
    [isDialog]="hasTrigger"
    (closeList)="close()"
    [class.active]="isActive$ | async"
    [class.dialog]="hasTrigger"
  ></cx-facet-list>
</div>

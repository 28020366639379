import { Injectable } from "@angular/core";
import { Store, select } from "@ngrx/store";
import { CmsActions, CmsSelectors, CmsService, PageContext, PageType, RoutingService, StateWithCms, WindowRef } from "@spartacus/core";
import { LoaderState } from "@spartacus/core/src/state/utils/loader";
import { Observable, of } from "rxjs";
import { catchError, filter, map, take, tap } from "rxjs/operators";
import { ToolsService } from "src/app/components/tools.service";
import { SkuPageService } from "src/app/spartacus/features/sku-landing-page/skupage.service";

@Injectable({ providedIn: 'root' })
export class CustomCmsService extends CmsService {

  constructor(
    store: Store<StateWithCms>,
    routingService: RoutingService,
    private toolsService: ToolsService,
    private skuPageService: SkuPageService
  ) {
    super(store, routingService)
  }

  override refreshLatestPage(): void {
    this.routingService
      .getPageContext()
      .pipe(take(1))
      .subscribe((pageContext) => {
        this.store.dispatch(new CmsActions.LoadCmsPageData(this.verifyPageContext(pageContext)))
      });
  }

  override refreshPageById(pageId: string): void {
    const pageContext: PageContext = { id: pageId };
    this.store.dispatch(new CmsActions.LoadCmsPageData(this.verifyPageContext(pageContext)));
  }

  override hasPage(pageContext: PageContext, forceReload = false): Observable<boolean> {
    return this.store.pipe(
      select(CmsSelectors.getPageStateIndexLoaderState(pageContext)),
      tap((entity: LoaderState<string>) => {
        const attemptedLoad = entity.loading || entity.success || entity.error;
        const shouldReload = forceReload && !entity.loading;
        if (!attemptedLoad || shouldReload) {
          this.store.dispatch(new CmsActions.LoadCmsPageData(this.verifyPageContext(pageContext)));
          forceReload = false;
        }
      }),
      filter((entity) => {
        if (!entity.hasOwnProperty('value')) {
          // if we have incomplete state from SSR failed load transfer state,
          // we should wait for reload and actual value
          return false;
        }
        return Boolean(entity.success || (entity.error && !entity.loading));
      }),
      map((loaderState) => !!loaderState.success),
      catchError(() => of(false))
    );
  }

  protected verifyPageContext (pageContext: PageContext) {

    if (pageContext.id.includes(`/sku/`)) {
      pageContext.id = this.toolsService.getLastItemFromArray( pageContext.id.split(`/`) );
      pageContext.type = PageType.PRODUCT_PAGE;
    }

    return pageContext;
  }
}

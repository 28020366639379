<cx-spinner class="overlay" *ngIf="isUpdating$ | async"> </cx-spinner>

<form (ngSubmit)="onSubmit()" [formGroup]="form">
  <div class="row w-100 d-block">
    <div class="col-12">
      <label>
        <span class="label-content"> {{'updateProfileForm.firstName.label' | cxTranslate}} </span>
        <input required="true" type="text" class="form-control" name="firstName" placeholder="{{ 'updateProfileForm.firstName.placeholder' | cxTranslate }}" formControlName="firstName"/>
        <cx-form-errors [control]="form.get('firstName')"></cx-form-errors>
      </label>
    </div>
  </div>

  <div class="row w-100 d-block">
    <div class="col-12">
      <label>
        <span class="label-content">{{ 'updateProfileForm.lastName.label' | cxTranslate }}</span>
        <input required="true" type="text" class="form-control" name="lastName" placeholder="{{ 'updateProfileForm.lastName.placeholder' | cxTranslate }}" formControlName="lastName" />
        <cx-form-errors [control]="form.get('lastName')"></cx-form-errors>
      </label>
    </div>
  </div>

  <div class="row w-100">
    <div class="col-12">
      <div class="w-100 border-top border-width-1 border-black m-tb-16"></div>
    </div>
  </div>

  <div class="row w-100">
    <div class="col-12 col-sm-6 col-md-4 col-lg-4">
      <button class="btn btn-secondary font-weight-bolder w-100" [disabled]="form.disabled">
        {{ 'customUpdateProfileForm.save' | cxTranslate }}
      </button>
    </div>
    <div class="col-12 col-sm-6 col-md-8 col-lg-8"></div>
  </div>
</form>


import { Injectable } from '@angular/core';
import { ConfigAddressCard } from './address-card.model';
import { normalizePhone } from 'src/app/components/utils/address-number-utils';
import { Address } from '@spartacus/core';
import { CapitalizePipe } from 'src/app/components/pipes/capitalize/capitalize';

@Injectable({ providedIn: 'root' })
export class AddressCardService {

  capitalize(value: string): string {
    const capitalizePipe = new CapitalizePipe();
    return capitalizePipe.transform(value);
  }

  getAddressCard (address: Address): Array<string> {
    if(!address) return [];
    const countryIso = address.country?.isocode
      ?? address.region?.countryIso
      ?? address.region?.isocode?.split('-')?.[0]
      ?? '';
    const regionIso = address?.region?.isocodeShort
      ? address.region?.isocodeShort
      : address.region?.isocode?.split(`-`)?.[1] || ``;
    const addressCard : string[] = [];
    const name = `${this.capitalize(address.firstName ?? '')} ${this.capitalize(address.lastName ?? '')}`;
    name.trim() && addressCard.push(name.trim());
    address.companyName && addressCard.push(address.companyName);
    address.line1 && addressCard.push(address.line1);
    address.line2 && addressCard.push(address.line2);
    let addressCodes = '';
    address.town && (addressCodes += address.town);
    regionIso && (addressCodes += `, ${regionIso}`);
    address.postalCode && (addressCodes += ` ${address.postalCode}`);
    addressCodes !== `` && addressCard.push(addressCodes);
    countryIso && addressCard.push(countryIso);
    address.phone && addressCard.push(normalizePhone(address.phone));
    return addressCard;
  }
}

import { ChangeDetectionStrategy, Component } from "@angular/core";
import { map, tap } from "rxjs/operators";
import { talkToUsNormalizer } from "../../core/talk-to-us.helper";
import { CmsComponentData } from "@spartacus/storefront";
import { TalkToUs } from "../../core/talk-to-us.model";

@Component({
  selector: 'contact-us',
  templateUrl: './talk-to-us.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TalkToUsComponent {
  data$ = this.componentData.data$.pipe(
    map(data => talkToUsNormalizer(data))
  );

  constructor(private componentData: CmsComponentData<TalkToUs>) {
  }
}

<ng-container *ngIf="cards$ | async as cards">
  <ng-container *ngIf="!(isUpdating$ | async); else loading">
    <div [attr.aria-label]="'common.loaded' | cxTranslate" role="status"></div>
    <ng-container *ngIf="
        cards?.length && !addressFormOpened;
        then showExistingAddresses;
        else newAddressForm
      ">
    </ng-container>

    <ng-template #showExistingAddresses>
      <ng-container *cxFeatureLevel="'!6.3'">
        <div class="cx-checkout-btns row">
          <div class="col-sm-12 col-md-12 col-lg-6">
            <button (click)="showNewAddressForm()" class="btn btn-block btn-secondary">
              {{ 'checkoutAddress.addNewAddress' | cxTranslate }}
            </button>
          </div>
        </div>
      </ng-container>

      <ng-container *cxFeatureLevel="'6.3'">
        <div class="cx-checkout-btns cx-checkout-btns-top mb-2">
          <button (click)="showNewAddressForm()" class="btn btn-block btn-secondary">
            {{ 'checkoutAddress.addNewAddress' | cxTranslate }}
          </button>
        </div>
      </ng-container>

      <div class="cx-checkout-body row">
        <div *ngFor="let card of cards; let i = index" class="cx-delivery-address-card col-md-12 col-lg-6 mt-2">
          <div (click)="selectAddress(card.address)" class="cx-delivery-address-card-inner h-100">
            <cx-card (sendCard)="selectAddress(card.address)" [border]="true" [content]="card.card" [fitToContainer]="true" [index]="i" class="mt-2"></cx-card>
          </div>
        </div>
      </div>

      <div class="cx-checkout-btns row mt-2 ">
        <div class="col-md-12 col-lg-6 order-md-2 order-sm-2 order-lg-1">
          <button (click)="back()" class="cx-btn btn btn-block btn-optional-1 mt-2 ">
            {{ backBtnText | cxTranslate }}
          </button>
        </div>
        <div class="col-md-12 col-lg-6 order-md-1 order-sm-1 order-lg-2">
          <button (click)="next()" [disabled]="!(selectedAddress$ | async)?.id" class="cx-btn btn btn-block btn-secondary mt-2 ">
            {{ 'common.continue' | cxTranslate }}
          </button>
        </div>
      </div>
    </ng-template>

    <ng-template #newAddressForm>
      <cx-address-form
        (backToAddress)="hideNewAddressForm(false)"
        (submitAddress)="addAddress($event)"
        *ngIf="cards.length; else initialAddressForm"
        [addressData]="lastAddress"
        [showTitleCode]="true"/>
      <ng-template #initialAddressForm>
        <cx-address-form
          (backToAddress)="hideNewAddressForm(true)"
          (submitAddress)="addAddress($event)"
          [addressData]="lastAddress ?? selectedAddress"
          [cancelBtnLabel]="backBtnText | cxTranslate"
          [setAsDefaultField]="!isGuestCheckout"
          [showTitleCode]="true"/>
      </ng-template>
    </ng-template>
  </ng-container>
</ng-container>

<ng-template #loading>
  <div class="cx-spinner">
    <cx-spinner></cx-spinner>
  </div>
</ng-template>

import { Injectable, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { ProductSearchService } from "@spartacus/core";
import { BREAKPOINT, PageLayoutHandler, ProductListComponentService } from "@spartacus/storefront";
import { Observable, combineLatest } from "rxjs";
import { filter, map, skip, withLatestFrom } from "rxjs/operators";

@Injectable({
  providedIn: 'root',
})
export class SearchResultsPageListResolver
  implements PageLayoutHandler
{
  constructor(
    private productSearchService: ProductSearchService
  ) {
  }

  handle(
    slots: Observable<string[]>,
    pageTemplate?: string,
  ): Observable<string[]> {
    if (pageTemplate === 'SearchResultsListPageTemplate') {
      const exclude = (arr: string[], args: string[]) =>
        arr.filter((item) => args.every((arg) => arg !== item));


      return combineLatest([slots, this.productSearchService.getResults()]).pipe(
        map(([slots, results]) => {

          return results?.products?.length > 0
            ? //
              exclude(slots, [
                'NoResultsFound',
                'NoResultsFoundSlot',
                'NoResultsFoundSlot2',
              ])
            : exclude(slots, [
                'ProductSearchDetails',
                'ProductLeftRefinements',
                'SearchResultsListSlot',
              ]);
        })
      );
    }

    return slots;
  }
}

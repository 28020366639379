import { TalkToUs } from "./talk-to-us.model";

export const talkToUsNormalizer = (component: any): TalkToUs => {
  if (!component || Object.keys(component)?.length === 0) {
    return null;
  }
  return {
    ...component,
    isActiveHourComponent: component.isActiveHourComponent === 'true',
    isActiveLiveChatComponent: component.isActiveLiveChatComponent === 'true'
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TalkToUsCardsComponent } from './talk-to-us-cards.component';
import { IconsModule } from 'src/app/components/_CUSTOM/icons/icons.module';
import { CmsConfig, I18nModule, provideConfig } from '@spartacus/core';
import { SvgIconComponent } from "../../../../_CUSTOM/svg/components/svg-icon/svg-icon.component";

@NgModule({
  imports: [
    CommonModule,
    IconsModule,
    I18nModule,
    SvgIconComponent
  ],
  declarations: [ TalkToUsCardsComponent ],
  exports: [ TalkToUsCardsComponent ],
})
export class TalkToUsCardsModule {
}

import { Injectable } from "@angular/core";
import { CmsStructureModel, Converter, Occ } from "@spartacus/core";

@Injectable({ providedIn: 'root' })
export class CustomOccCmsPageNormalizer
  implements Converter<Occ.CMSPage, CmsStructureModel> {
  convert(source: Occ.CMSPage, target?: CmsStructureModel | undefined): CmsStructureModel {
    if (source) {
      target = target ?? {}
      target.page = target?.page ?? {}
      target.page.metaDescription = source.metaDescription,
      target.page.categoryDescription = source.categoryDescription,
      target.page.canonical = source.canonical
      target.page.metaKeywords = source.metaKeywords
      target.page.breadcrumb = source.breadcrumb
      target.page.typeCode = source.typeCode
      target.page.year = +source.year
      target.page.make = source.make
      target.page.model = source.model
      target.page.vehicleType = source.vehicleType
      target.page.productCode = source.productCode
      target.page.title = source.title
      target.page.vehicleType = source.vehicleType
    }
    return target!
  }
}

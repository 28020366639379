import { Component } from '@angular/core';
import { LoginFormComponentService } from '@spartacus/user/account/components';
import { CustomLoginFormComponent } from '../../../user/account/components/login-form';
@Component({
  selector: 'custom-checkout-login-form',
  templateUrl: './custom-checkout-login-form.component.html'
})
export class CustomCheckoutLoginFormComponent extends CustomLoginFormComponent  {

}

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { CheckoutDeliveryModeComponent, CheckoutAuthGuard, CartNotEmptyGuard } from "@spartacus/checkout/base/components";
import { I18nModule, FeaturesConfigModule, provideDefaultConfig, CmsConfig, provideConfig } from "@spartacus/core";
import { SpinnerModule, OutletModule, PageComponentModule } from "@spartacus/storefront";
import { CustomCheckoutDeliveryModeComponent } from "./custom-checkout-delivery-mode.component";
import { IconsModule } from "src/app/components/_CUSTOM/icons/icons.module";

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    I18nModule,
    SpinnerModule,
    OutletModule,
    PageComponentModule,
    FeaturesConfigModule,
    IconsModule
  ],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        CheckoutDeliveryMode: {
          component: CustomCheckoutDeliveryModeComponent,
          data: {
            composition: {
              inner: ['PickupInStoreDeliveryModeComponent'],
            },
          },
          guards: [CheckoutAuthGuard, CartNotEmptyGuard],
        },
      },
    }),
  ],
  declarations: [CustomCheckoutDeliveryModeComponent],
  exports: [CustomCheckoutDeliveryModeComponent],
})
export class CustomCheckoutDeliveryModeModule {}

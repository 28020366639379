import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomProductBadgesComponent } from './custom-product-badges.component';
import { MediaModule } from '@spartacus/storefront';

@NgModule({
  declarations: [
    CustomProductBadgesComponent
  ],
  imports: [
    CommonModule,
    MediaModule
  ],
  exports: [
    CustomProductBadgesComponent
  ]
})

export class CustomProductBadgesModule { }

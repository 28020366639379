import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Option } from '../../model/radio.models';
import { InputService } from '../../input.service.service';
import { CustomFeedbackInputError } from '../../model/input.model';

@Component({
  selector: "simple-select",
  templateUrl: "./simple-select.component.html",
  styleUrls: ["./simple-select.component.scss"],
})
export class SimpleSelectComponent {
  @Input() form!: FormGroup;
  @Input() id!: string;
  @Input() name!: string;
  @Input() label?: string;
  @Input() type?: string = "text";
  @Input() required!: string;
  @Input() customErrorMessage!: string;
  @Input() autocomplete?: string;
  @Input() placeholder?: string;
  @Input() maxLength: number = 255;
  @Input() containerClass?: string;
  @Input() labelClass?: string;
  @Input() inputClass?: string;
  @Input() mask: string = "";
  @Input() hasIcon?: boolean;
  @Input() options?: Option[];
  @Output() callbackOnChange = new EventEmitter<Option>();
  private _disabled:boolean | null = false;
  private _defaultValue?: string;

  public get defaultValue(): string {
    return this._defaultValue;
  }

  @Input() public set defaultValue(value: string) {
    this._defaultValue = value;
    this.form.get(this.name).setValue(this._defaultValue)
  }

  public get disabled() {
    return this._disabled;
  }

  selectedValue!: any;

  @Input() public set disabled(value) {
    const control = this.form.get(this.name)
    if(control){
      if(value){
        control.disable()
      } else {
        control.enable()
      }
    }
    this._disabled = value;
  }

  hasError: boolean = false;
  selectFormControl: FormControl = new FormControl();

  // constructor(private translate: TranslateService, private inputService: InputService) { }
  constructor(private inputService: InputService) {}

  ngOnInit(): void {
    // this.placeholder && this.translate.get(this.placeholder).subscribe(res => this.placeholder = res);
    // this.label && this.translate.get(this.label).subscribe(res => this.label = res);
  }

  selectValue(event: any) {
    this.callbackOnChange.emit({
      code: event.currentTarget.value,
      value: event.currentTarget.selectedOptions[0].innerText,
    });
  }


  getError(): CustomFeedbackInputError {
    const errors = this.getErrors();
    this.hasError = errors.length > 0;

    return errors[0];
  }

  getErrors(): CustomFeedbackInputError[] {
    return this.inputService.getErrors(this.name, this.form);
  }

  classError() {
    const inputClass = this.inputClass
    return {
      "is-invalid input-danger": this.getError(),
      "has-feedback": this.hasError,
      inputClass
    };
  }
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { PageLayoutService } from '@spartacus/storefront';
import { Subscription } from 'rxjs';
import { ProductDetailsActions } from 'src/app/spartacus/features/product/core/store';
import { SkuPageService } from 'src/app/spartacus/features/sku-landing-page/skupage.service';

@Component({
  selector: 'product-details-page-template',
  templateUrl: './product-details-page-template.component.html',
  styleUrls: ['./product-details-page-template.component.scss']
})

export class ProductDetailsPageTemplateComponent implements OnInit, OnDestroy{

  subscription = new Subscription();
  isSkuPage = this.skuPageService.isSKUPage();

  constructor (
    private store: Store,
    private skuPageService: SkuPageService,
    private pageLayoutService: PageLayoutService
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.pageLayoutService.page$.subscribe(() => {
        const productCode = this.skuPageService.getCurrentProductCode();
        this.skuPageService.isSKUPage() && this.store.dispatch(ProductDetailsActions.loadProduct({code: productCode}));
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}

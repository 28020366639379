import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LoaderConfig } from './loader.model';

@Injectable({
  providedIn: 'root'
})

export class LoaderService {
  loader$ = new BehaviorSubject<LoaderConfig>({status: false});

  toggle (config: LoaderConfig) {
    this.loader$.next(config);
  }
}

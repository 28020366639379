/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import {
  CmsConfig,
  I18nModule,
  provideConfig,
  provideDefaultConfig,
  UrlModule,
} from '@spartacus/core';
import {
  CarouselModule,
  MediaModule,
  PageComponentModule,
  ProductCarouselComponent,
  ProductCarouselItemComponent,
  StarRatingModule,
} from '@spartacus/storefront';
import { CustomProductCarouselItemComponent } from '../custom-product-carousel-item/custom-product-carousel-item.component';
import { CustomProductListModule } from '../../product-list/custom-product-list.module';
import { CustomProductGridItemComponent } from '../../product-list/custom-product-grid-item/custom-product-grid-item.component';
import { WishlistButtonModule } from '../../../shared/components/wishlist-button/wishlist-button.module';
import { ProductPriceModule } from '../../product-price/product-price.module';
import { CustomProductCardModule } from '../../custom-product-card/custom-product-card.module';

@NgModule({
  imports: [
    CommonModule,
    CarouselModule,
    MediaModule,
    RouterModule,
    UrlModule,
    I18nModule,
    PageComponentModule,
    CustomProductListModule,
    StarRatingModule,
    WishlistButtonModule,
    ProductPriceModule,
    CustomProductCardModule
  ],
  declarations: [CustomProductCarouselItemComponent],
  exports: [CustomProductCarouselItemComponent, CustomProductGridItemComponent],
})
export class CustomProductCarouselItemModule {}

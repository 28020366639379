<div class="form-group {{containerClass}}" [ngClass]="{'has-label': label}" [formGroup]="form">
 <label [attr.for]="id" class="mb-1  {{labelClass}}" *ngIf="label">
  {{label}} {{required ? '*' : ''}}
 </label>

 <div class="input-group">
  <textarea [attr.id]="id" [formControlName]="name" class="form-control textarea-control {{inputClass}}" [ngClass]="{
        'is-invalid': getError(),
          'has-feedback': hasError,
          'has-icon': hasIcon
      }" [attr.type]="type" [attr.placeholder]="placeholder" [attr.autocomplete]="autocomplete"
   [attr.maxLength]="maxLength" [attr.maxLength]="maxLength" [mask]="mask" (focus)="onFocus($event)"
   (change)="onChange($event)" rows="{{rows}}"></textarea>

  <div class="input-group-append" *ngIf="hasIcon">
   <div class="input-group-text fix-border-radius">
    <ng-content></ng-content>
   </div>
  </div>

      <div class="invalid-feedback" *ngIf="{error: getError()} as dataError">
        <span *ngIf="dataError?.error?.params === undefined">{{ getError().description | cxTranslate}}</span>
        <span *ngIf="dataError?.error?.params">
      
          {{ dataError.error && dataError.error.description| cxTranslate:{param: dataError.error.params.param} }}
        </span>
      </div>

 </div>
</div>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomProductTitleComponent } from './custom-product-title.component';
import { I18nModule } from '@spartacus/core';
import { StarRatingModule } from '@spartacus/storefront';

@NgModule({
  declarations: [
    CustomProductTitleComponent
  ],
  imports: [
    CommonModule,
    I18nModule,
    StarRatingModule,
  ],
  exports:[CustomProductTitleComponent]
})
export class CustomProductTitleModule { }

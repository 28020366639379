<div class="container" *ngIf="product$ | async as product">
  <ng-container *ngIf="product.stamps?.length > 0">
    <div class="product-stamps">
      <div class="row">
        <div class="col-lg-3 col-xl-3 col-md-4 col-sm-12 col-xs-12">
          <h2 class="product-stamps__title">
            The<br>Covercraft<br>Difference
          </h2>
        </div>
        <div class="col-lg-9 col-xl-9 col-md-8 col-sm-12 col-xs-12">
          <div class="product-stamps__container">
            <div class="product-stamps__icons-container" *ngFor="let stamp of product.stamps">
              <cx-media [container]="stamp.image" class="product-stamps__icon"></cx-media>
              <span class="product-stamps__paragraph">{{stamp.code}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

</div>
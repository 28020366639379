<div class="container" *ngIf="!(order$ | async)">
 <div class="row">
    <div style="padding: 15px; font-weight: 500;">To view the status of your order, enter your Email Address and Order Number below:</div>
    <div class="col-lg-10 col-xl-10 col-md-10 col-sm-12 ">
     <form (ngSubmit)="submitForm()" [formGroup]="orderGuestTrackingForm">
      <div class="form-group ">
       <label>
        <span class="label-content bold-common-font">{{'register.emailAddress.label' | cxTranslate }}</span>
        <input maxlength="100" required="true" class="form-control" type="emailAddress" id="emailAddress" name="emailAddress"
         placeholder="{{'register.emailAddress.placeholder' | cxTranslate }}" formControlName="emailAddress" />
        <cx-form-errors [control]="orderGuestTrackingForm.get('emailAddress')"></cx-form-errors>
       </label>
      </div>
      <div class="form-group ">
       <label>
        <span class="label-content bold-common-font">
         Order Number
        </span>
        <input required="true" class="form-control" maxlength="100" type="text" name="orderNumber"
         placeholder="Order Number" formControlName="orderNumber" />
        <cx-form-errors [control]="orderGuestTrackingForm.get('orderNumber')"></cx-form-errors>
       </label>
      </div>
     
      <button type="submit" class="btn  btn-secondary  mb-2">
       Submit
      </button>
     </form>
     </div>
 </div>

</div>
<div [id]="containerId" class="g-recaptcha" data-size="invisible"></div>


<ng-container *ngIf="order$ | async as order">
 <div class="container">
  <button class="btn btn-secondary my-2" (click)="clearOrder()">
   Back
  </button>
 </div>
<custom-order-shared-overview [order]="order"></custom-order-shared-overview>
<custom-order-shared-items [order]="order" 
    [pickupConsignments]="getGroupedConsignments(order,true)"
    [deliveryConsignments]="getGroupedConsignments(order,false)"
    [pickupUnconsignedEntries]="getUnconsignedEntries(order,true)"
    [deliveryUnConsignedEntries]="getUnconsignedEntries(order,false)"
    ></custom-order-shared-items>
<div class="mt-4">   
<custom-order-shared-totals [order]="order"></custom-order-shared-totals>
</div>

</ng-container>

import { Component, Input } from '@angular/core';
import { ProductImageZoomViewComponent } from '@spartacus/product/image-zoom/components';
import { ImageGroup } from '@spartacus/core';

@Component({
  selector: 'cx-product-image-zoom-view',
  templateUrl: './product-image-zoom-view.component.html',
  styleUrls: [ './product-image-zoom-view.component.scss' ]
})
export class CustomProductImageZoomViewComponent extends ProductImageZoomViewComponent {

  @Input() container: ImageGroup;


}

import { LayoutConfig } from "@spartacus/storefront";

export const customLayoutConfig: LayoutConfig = {
  layoutSlots: {
    header_1: {
      lg: {
        slots: [ 'TopBrands', 'BeforeHeader' ],
      },
      slots: [ 'BeforeHeader' ],
    },
    header_2: {
      lg: {
        slots: [
          'PreHeader',
          'SiteLogo',
          'SearchBox',
          'HeaderHelpers',
          'MiniCart',
        ],
      },
      slots: [ 'PreHeader', 'SiteLogo', 'HeaderHelpers', 'MiniCart' ],
    },
    header_3: {
      lg: {
        slots: [ 'NavigationBar' ],
      },
      slots: [ 'SearchBox' ],
    },
    header_4: {
      lg: {
        slots: [ 'NavigationBar', 'BottomHeaderSlot' ],
      },
      slots: [ 'SearchBox' ],
    },
    navigation: {
      lg: { slots: [] },
      slots: [ 'SiteLogin', 'NavigationBar' ],
    },
    ContentPage1Template: {
      slots: [ 'YearMakeModel', 'Section2A', 'Section2B' ],
    },
    YMMCategoryPageTemplate: {
      pageFold: 'ProductSearchDetails'
    },
    CategoryPageTemplate: {
      pageFold: 'ProductSearchDetails',
      slots: [ 'Section1', 'Section2', 'Section3' ],
    },
    ProductGridPageTemplate: {
      pageFold: 'ProductSearchDetails',
      slots: [ 'YearMakeModel', 'ProductLeftRefinements', 'ProductGridSlot' ],
    },
    SearchResultsGridPageTemplate: {
      pageFold: 'ProductSearchDetails',
      slots: [
        'Section2',
        'YearMakeModel',
        'ProductLeftRefinements',
        'SearchResultsGridSlot',
        'Section4',
      ],
    },
    LandingPage2Template: {
      pageFold: 'section1'
    },
    ProductDetailsPageTemplate: {
      pageFold: 'Upselling',
    },
    ProductListPageTemplate: {
      pageFold: 'ProductSearchDetails'
    },
    SearchResultsListPageTemplate: {
      pageFold: 'ProductSearchDetails'
    },
    AccountPageTemplate: {
      slots: [ 'BodyContent', 'SideContent' ],
    },
    LoginPageTemplate: {
      slots: [ 'LeftContentSlot', 'RightContentSlot' ],
    },
    ErrorPageTemplate: {
      slots: [ 'TopContent', 'MiddleContent', 'BottomContent' ],
    },
    OrderConfirmationPageTemplate: {
      slots: [ 'BodyContent', 'SideContent' ],
    },
    MultiStepCheckoutSummaryPageTemplate: {
      slots: [ 'TopContent', 'BodyContent', 'SideContent', 'BottomContent' ],
    },
    CheckoutLoginPageTemplate: {
      slots: [ 'RightContentSlot' ],
    },
    MyAccountViewPageTemplate: {
      slots: [ 'LeftContentSlot', 'RightContentSlot' ],
    },
  },
};

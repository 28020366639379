<ng-container *ngIf="model$ | async as model">

<div class="search-details" >

  <!-- LIST RESULT INFO -->
  <ng-container *ngIf="pagination$ | async as p">
    <section class="search-details__info">
      <div class="search-details__info--quantity">
        {{
          'productList.paginationInfo' | cxTranslate:{
            start: p.firstResult,
            end: p.lastResult,
            total: p.totalResults,
          }
        }}
      </div>

      <ng-container *ngIf="p.freeTextSearch">
        <div class="search-details__info--for">
          {{ 'productList.paginationForResult' | cxTranslate: { search: p.freeTextSearch } }}
        </div>
      </ng-container>
    </section>
  </ng-container>

  <!-- ACTIVE FACETS / SORTING -->
  <section class="container-facets-and-query">
    <div class="cx-active-facets">
      <cx-active-facets></cx-active-facets>
    </div>

    <div class="cx-facets">
      <cx-product-facet-navigation></cx-product-facet-navigation>
    </div>

    <div class="cx-sort-dropdown">
      <ng-container *ngIf="model$ | async as model">
        <cx-sorting
          (sortListEvent)="sortList($event)"
          [ariaLabel]="'productList.sortResults' | cxTranslate"
          [selectedOption]="model.pagination?.sort"
          [sortOptions]="model.sorts"
          ariaControls="product-results-list"
          placeholder="{{ 'productList.sortBy' | cxTranslate }}"
        ></cx-sorting>
      </ng-container>
    </div>
  </section>
</div>
</ng-container>


<ng-container *ngIf="cart$ | async as cart">
  <ng-container *ngIf="entries$ | async as entries">
    <div *ngIf="cart.totalItems !== undefined" class="cart-details-wrapper">
      <div *ngIf="cart.totalItems > 0" class="cart-details-wrapper">
        <custom-cart-item-list [items]="entries" [cartIsLoading]="!(cartLoaded$ | async)" [promotionLocation]="promotionLocation" [options]="{ isSaveForLater: false, optionalBtn: saveForLaterBtn }"></custom-cart-item-list>
      </div>
    </div>
  </ng-container>
</ng-container>

<ng-template let-ctx #saveForLaterBtn>
  <button *ngIf="selectiveCartEnabled" class="btn btn-tertiary cx-sfl-btn" [disabled]="ctx.loading" (click)="saveForLater(ctx.item)" type="button">
    {{ 'saveForLaterItems.saveForLater' | cxTranslate }}
  </button>
</ng-template>

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ConverterService, DynamicAttributes, OccCmsComponentAdapter, OccEndpointsService, USER_CMS_ENDPOINTS } from "@spartacus/core";
import { SkuPageService } from "src/app/spartacus/features/sku-landing-page/skupage.service";

@Injectable({ providedIn: 'root' })
export class CustomOccCmsComponentAdapter extends OccCmsComponentAdapter {

  constructor(
    http: HttpClient,
    occEndpoints: OccEndpointsService,
    converter: ConverterService,
    private skuPageService: SkuPageService
  ) {
    super(http, occEndpoints, converter);
  }

  override getComponentsEndpoint(
    requestParams: any,
    fields: string,
    userId?: string
  ): string {
    if (this.featureConfigService.isEnabled(USER_CMS_ENDPOINTS)) {
      const queryParams = { fields, ...requestParams };

      const attributes = userId
        ? {
          urlParams: { userId },
          queryParams,
        }
        : { queryParams };

      return this.occEndpoints.buildUrl('components', this.verifyParams(attributes));
    }

    return this.occEndpoints.buildUrl('components', {
      queryParams: { fields, ...this.verifyParams(requestParams) },
    });
  }

  protected verifyParams(requestParams: any): DynamicAttributes {

    if (this.skuPageService.isSKUPage() && requestParams.productCode) {
      requestParams.productCode = this.skuPageService.getCurrentProductCode();
    }

    return requestParams;
  }
}

<div class="box-loader" *ngIf="loader.status">
    <div class="lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>

    <div class="lds-ellipsis-text">
        {{'common.loading' | cxTranslate}}
    </div>
</div>

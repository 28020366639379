import { Injectable } from '@angular/core';
import { BreakPoints, CurrentDevice } from './others.model';
import { WindowRef } from "@spartacus/core";
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class ToolsService {

  constructor(
    private winRef: WindowRef
  ) { }

  getCurrentDevice(): CurrentDevice {
    const screenSize = this.getSizeScreen();

    if (screenSize.width > BreakPoints.DESKTOP) {
      return CurrentDevice.WIDESCREEN;
    }

    if (screenSize.width > BreakPoints.TABLET) {
      return CurrentDevice.DESKTOP;
    }

    if (screenSize.width > BreakPoints.MOBILE) {
      return CurrentDevice.TABLET;
    }

    return CurrentDevice.MOBILE;
  }

  getSizeScreen() {
    if (!this.winRef.isBrowser()) {
      return { width: 0, height: 0 };
    }
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    }
  }

  createUrlToStoreFront(url: string) {
    if (url?.includes(`http`)) {
      return url;
    }

    return environment.storefrontUrl + url;
  }

  getLastItemFromArray<T>(array: T[]): T | undefined {
    if (array?.length === 0) {
      return undefined;
    } else {
      return array[array.length - 1];
    }
  }

  getParamFromURL(paramName: string): string | undefined {
    if (this.winRef.isBrowser()) {
      const urlParams = new URLSearchParams(window.location.search);
      return urlParams.has(paramName) ? urlParams.get(paramName) : undefined;
    }

    return undefined
  }
}

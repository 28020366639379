<ng-container *ngIf="{
  isActive: isActive$ | async,
  product: product$ | async,
  skuOptions: skuOptions$ | async,
  attributes: attributes$ | async,
  image: image$ | async,
} as pageContent">
  <div *ngIf="pageContent.isActive" class="additional-table">
    <div class="line">
      <p class="h7 bold-common-font">
        {{'additionalDetails.title' | cxTranslate}}:
      </p>

      <ng-container *ngIf="pageContent?.image">
        <cx-media [container]="pageContent.image"></cx-media>
      </ng-container>

      <ng-container *ngIf="pageContent?.skuOptions?.length">
        <ul class="p-0">
          <li *ngFor="let skuOption of pageContent?.skuOptions">{{skuOption}}</li>
        </ul>
      </ng-container>
    </div>

    <div class="line" *ngIf="pageContent?.product?.fabric">
      <span class="title bold-common-font">{{'additionalDetails.attributes.fabric' | cxTranslate | uppercase}}: </span> <span class="content">{{pageContent.product.fabric.name}}</span>
    </div>

    <ng-container *ngIf="pageContent?.attributes">
      <div *ngFor="let attribute of pageContent?.attributes" class="line">
        <span class="title bold-common-font">{{attribute?.qualifier?.replace('_' , ' ') | uppercase}}: </span> <span class="content">{{attribute?.name}}</span>
      </div>
    </ng-container>

    <div class="line">
      <span class="title bold-common-font">{{'additionalDetails.attributes.part' | cxTranslate | uppercase}} #: </span> <span class="content">{{partNumber}}</span>
    </div>
  </div>
</ng-container>



export enum InputFeedbackType {
 REQUIRED = 'REQUIRED',
 MIN_LENGTH = 'MIN_LENGTH',
 MAX_LENGTH = 'MAX_LENGTH',
 EMAIL = 'EMAIL',
 DATE = 'DATE',
 CUSTOM = 'CUSTOM'
}

export interface CustomFeedbackInputError {
 description: string,
 params?: any,
 type: InputFeedbackType,
}
<div class="form-check simple-checkbox {{containerClass}} {{isDanger? 'danger-checkradio': 'default-checkradio'}}" [formGroup]="form">
  <input
  #checkbox
  [attr.id]="id"
  [formControlName]="name"
  class="form-check-input {{inputClass}}"
  type="checkbox"
  />

  <label class="form-check-label {{labelClass}} {{getError(name, form)}}" [ngClass]="{'is-invalid': hasError}" (click)="markThis()" [attr.for]="id" *ngIf="label">
    <span [innerHTML]="label"> </span> {{required ? '*' : ''}}
  </label>

      <div class="invalid-feedback" *ngIf="{error: getError(name, form)} as dataError">
        <span *ngIf="dataError?.error?.params === undefined">{{ getError(name, form).description | cxTranslate}}</span>
        <span *ngIf="dataError?.error?.params">
      
          {{ dataError.error && dataError.error.description| cxTranslate:{param: dataError.error.params.param} }}
        </span>
      </div>
</div>

import { OccConfig } from "@spartacus/core";

export const recaptchaOccConfig:OccConfig = {
  backend: {
    occ: {
      endpoints: {
        getRecaptchaKey: 'recaptcha/get-recaptcha-site'
      }
    } as any
  }
};

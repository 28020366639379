import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomFooterLatestNewsComponent } from './custom-footer-latest-news.component';
import { CmsConfig, I18nModule, provideDefaultConfig } from '@spartacus/core';
import { FormErrorsModule } from '@spartacus/storefront';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [CustomFooterLatestNewsComponent],
  imports: [CommonModule, I18nModule, FormErrorsModule, ReactiveFormsModule],
  providers: [
    provideDefaultConfig(<CmsConfig>{
      cmsComponents: {
        LatestNewsFlexComponent: {
          component: CustomFooterLatestNewsComponent,
        },
      },
    }),
  ],
})
export class CustomFooterLatestNewsModule {}

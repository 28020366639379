import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from "@angular/core";

@Component({
  selector: 'svg-icon',
  template: '<svg><use [attr.href]="\'#\' + iconId"/></svg>',
  styles: [ ':host {display: block; width: 100%; height: 100%;}' ],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SvgIconComponent {
  @Input() iconId: string;

}

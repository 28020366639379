import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { MediaComponent } from "@spartacus/storefront";
import { CommonModule } from "@angular/common";

@Component({
  selector: 'cx-custom-media',
  templateUrl: './custom-media.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule
  ]
})
export class CustomMediaComponent extends MediaComponent {
  @Input() width = 0;
  @Input() height = 0;
  @Input() fetchPriority = 'auto';
}

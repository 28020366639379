import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CmsConfig, I18nModule, provideConfig } from '@spartacus/core';
import { GenericLinkModule } from '@spartacus/storefront';
import { ProductDescriptionComponent } from './product-description-section.component';

@NgModule({
  declarations: [ProductDescriptionComponent],
  imports: [CommonModule, GenericLinkModule, I18nModule],
  exports: [ProductDescriptionComponent],
})

export class ProductDescriptionModule { }

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HandlerFitmentDetailsComponent } from './handler-fitment-details/handler-fitment-details.component';
import { CmsConfig, provideConfig } from '@spartacus/core';
import { FitmentDetailsModule } from '../fitment-details/fitment-details.module';

@NgModule({
  declarations: [
    HandlerFitmentDetailsComponent
  ],
  imports: [
    CommonModule,

    FitmentDetailsModule
  ],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        handlerFitmentDetails: {
          component: HandlerFitmentDetailsComponent,
        },
      },
    }),
  ],
})

export class HandlerFitmentDetailsModule { }

import { Injectable } from "@angular/core";
import { ProductDetailsPrice, QualifierOptionValue } from "../models/product-details.models";
import { ProductDetailsActions, ProductDetailsSelectors } from "../store";
import { Store } from "@ngrx/store";
import { combineLatest, Observable } from "rxjs";
import { distinctUntilChanged, distinctUntilKeyChanged, filter, map, shareReplay } from "rxjs/operators";
import { buildKeyChainForPrice } from "../../configurator/core/facade/product-configuration.tools";
import { isNotNullable } from "@spartacus/core";
import { CurrentProductService } from "@spartacus/storefront";
import { ProductDetailsOccAdapter } from "../occ/product-details-occ.adapter";
import { ProductDetailsService } from "./product-details.service";
import { ProductConfigurationService } from "../../configurator/core/facade/product-configuration.service";
import { ProductDetailsState } from "../store/product-details.state";

@Injectable({ providedIn: 'root' })
export class ProductPriceService {

  product$ = this.currentProductService.getProduct()
    .pipe(
      filter(isNotNullable),
      distinctUntilKeyChanged('code'),
      shareReplay(1)
    );

  price$ = combineLatest([
    this.product$,
    this.productDetailsService.getSelectedVariants(),
    this.store.select(ProductDetailsSelectors.selectPrices),
    this.productConfigurationService.subModelOptionChange$
  ]).pipe(
    map(([ product, activeOptions, prices, subModelOption ]) => {
      if (!activeOptions) {
        return null;
      }
      const keyChain = buildKeyChainForPrice(product.code, activeOptions, subModelOption);
      return prices[ keyChain ];
    })
  );


  constructor(private store: Store<ProductDetailsState>,
              private currentProductService: CurrentProductService,
              private productDetailsService: ProductDetailsService,
              private productConfigurationService: ProductConfigurationService) {
  }

  updatePrice(productCode: string, activeOptions: QualifierOptionValue[], subModelOption?: string): void {
    this.store.dispatch(
      ProductDetailsActions.updatePrice({
        product: productCode,
        options: activeOptions,
        subModelOption
      })
    );
  }
}

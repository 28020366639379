import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomCheckoutLoginFormComponent } from './custom-checkout-login-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { I18nModule, UrlModule } from '@spartacus/core';
import { FormErrorsModule, PasswordVisibilityToggleModule, SpinnerModule } from '@spartacus/storefront';
import { LoginModule } from '@spartacus/user/account/components';
import { LoginFormComponentService } from '../../../user/account/components/login-form';



@NgModule({
  declarations: [CustomCheckoutLoginFormComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    UrlModule,
    I18nModule,
    FormErrorsModule,
    SpinnerModule,
    PasswordVisibilityToggleModule,
  ],
  exports: [CustomCheckoutLoginFormComponent],
  providers: [LoginFormComponentService],
})
export class CustomCheckoutLoginFormModule {}

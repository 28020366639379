import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ConverterService, isObject, OccEndpointsService } from "@spartacus/core";
import { DescriptionValuePair } from "../../../../../core/model/common";
import { Observable } from "rxjs";
import { ProductConfigRequest, ProductConfiguration, } from "../core/models/product-configuration.models";
import { map } from "rxjs/operators";
import { PRODUCT_CONFIGURATION_NORMALIZER } from "./converter/product-configuration.converter";
import { isArray } from "rxjs/internal-compatibility";

@Injectable({ providedIn: 'root' })
export class ProductConfiguratorAdapter {
  constructor(private http: HttpClient,
              private endpointService: OccEndpointsService,
              private converter: ConverterService) {
  }

  loadSubModels(productCode: string, productLine: string): Observable<{ submodels: DescriptionValuePair[] }> {
    const url = this.endpointService.buildUrl(
      'subModels',
      { queryParams: { productCode, productLine } }
    )
    return this.http.post<{ submodels: DescriptionValuePair[] }>(url, {});
  }

  loadOptions(productLine: string, submodel: string): Observable<{ options: DescriptionValuePair[] }> {
    const url = this.endpointService.buildUrl(
      'subModelOptions',
      { queryParams: { productLine, submodel } }
    )
    return this.http.post<{ options: { code: string, description: string }[] }>(url, {})
      .pipe(
        map(item => ( {
          options: item.options.map(
            opt => ( {
              value: opt.code,
              description: opt.description
            } )
          )
        } ))
      );
  }

  /**
   * @param request
   */
  loadConfigurationSteps(request: ProductConfigRequest): Observable<ProductConfiguration> {
    const url = this.endpointService.buildUrl('configurationSteps', {
      queryParams: request
    });
    return this.http.get(url).pipe(
      map((response: any) => {
        const config = {} as any;
        for (const k of Object.keys(response)) {
          const step = response[ k ];
          if (step) {
            if (isArray(step) && step?.length > 0
              || isObject(step) && Object.keys(step)?.length > 0) {
              config[ k ] = step;
            }
          }
        }
        const { productCode, color, year, make, model, subModel, hasProductLines } = request;
        return {
          ...config,
          metaData: {
            productCode,
            hasProductLines,
            color,
            subModel,
            ymm: { year, make, model }
          }
        }
      }),
      this.converter.pipeable(PRODUCT_CONFIGURATION_NORMALIZER)
    );
  }
}



<ng-container *ngIf="variants?.length">
  <div class="product-variants">

    <!-- Variants -->
    <button
      *ngFor="let variant of variants.slice(0, maxColorsView)"
      class="product-variants__item"
      type="button"
      (click)="setCurrentVariant(variant)"
      >
      <cx-custom-media
        [alt]="variant.variantCategoryValueColorName"
        [container]="{url: variant.variantCategoryImage}"
        [width]="20"
        [height]="20"
        fetchPriority="low"
        class="product-variants__media">
      </cx-custom-media>
    </button>

    <!-- + Variants -->
    <ng-container *ngIf="variants.length - maxColorsView > 0">
      <a [routerLink]="{ cxRoute: 'product', params: product } | cxUrl"
         class="product-variants__more">
        {{ variants.length - maxColorsView }}+
      </a>
    </ng-container>
  </div>
</ng-container>

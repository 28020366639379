<div class="col-lg-12 col-md-12">
  <div class="container">
    <div class="row">
      <div class="col-sm-4">
        <button #element class="btn btn-secondary font-weight-bolder" (click)="openModal()">
          {{ 'closeAccount.closeMyAccount' | cxTranslate }}
        </button>
      </div>
    </div>
  </div>
</div>

<ng-template cxOutletRef="ProductDetailsPageTemplate" let-model>

  <div class="container">
    <div class="row">
      <div class="col-12 col-sm-12 col-md-6 col-lg-6">
        <cx-page-slot position="images"></cx-page-slot>
      </div>
      <div class="col-12 col-sm-12 col-md-6 col-lg-6">
        <cx-page-slot position="Summary"></cx-page-slot>
      </div>
    </div>
  </div>

  <cx-page-slot position="UpSelling"></cx-page-slot>
  <cx-page-slot position="CrossSelling"></cx-page-slot>
  <cx-page-slot position="ProductOverview"></cx-page-slot>
  <cx-page-slot *ngIf="!isSkuPage" position="Tabs"></cx-page-slot>
  <cx-page-slot position="PlaceholderContentSlot"></cx-page-slot>
</ng-template>

import { Component, ViewEncapsulation } from '@angular/core';
import { WindowRef } from '@spartacus/core';
import { ProductListComponentService } from '@spartacus/storefront';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, takeUntil } from "rxjs/operators";
import { DataLayerService } from 'src/app/spartacus/features/data-layer/data-layer.service';

@Component({
  selector: 'cx-custom-product-search-details',
  templateUrl: './custom-product-search-details.component.html',
  styleUrls: [ './custom-product-search-details.component.scss' ],
  encapsulation: ViewEncapsulation.None,
})
export class CustomProductSearchDetailsComponent {
  model$ = this.productListComponentService.model$;
  private ngUnsubscribe = new Subject<void>();
  private prevModel: any;
 
  pagination$ = this.model$
    .pipe(map(model => {
      const {
        currentPage,
        pageSize,
        totalPages,
        totalResults
      } = model.pagination;

      return {
        firstResult: ( currentPage * pageSize ) + 1,
        lastResult: totalPages <= 1 || currentPage + 1 === totalPages
          ? totalResults
          : (currentPage + 1) * pageSize,
        totalResults,
        freeTextSearch: model.freeTextSearch
      };
    }));

  constructor(private productListComponentService: ProductListComponentService,
    protected winRef: WindowRef, private dataLayerService: DataLayerService) {
  }

  ngOnInit(): void {
    this.prevModel = {};
    this.trackViewItemList();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  sortList(sortCode: string): void {
    this.productListComponentService.sort(sortCode);
  }

  private trackViewItemList(): void {
    this.model$
      .pipe(
        takeUntil(this.ngUnsubscribe),
        distinctUntilChanged((prev, curr) => JSON.stringify(prev) == JSON.stringify(curr)),
        debounceTime(1000))
      .subscribe(model => {
        if (this.shouldFireSearch(model, this.prevModel)) {
          this.dataLayerService.searchEvent(model);
        }
        if (model && model.products) {
          this.dataLayerService.viewItemListEvent(model.products);
        }

        this.prevModel = model;
    })
  }

  // search event should only fire when search happens or when user
  // uses pagination feature.
  // Filter out facet changes
  private shouldFireSearch(model: any, prev: any): boolean {
    return model 
      && model.freeTextSearch 
      && (
        (prev && prev.currentQuery?.url == model.currentQuery?.url)
        ||
        (prev && prev.freeTextSearch != model.freeTextSearch)
      );
  }

}

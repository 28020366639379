/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  AuthGuard,
  CmsConfig,
  I18nModule,
  provideConfig,
  provideDefaultConfig,
} from '@spartacus/core';
import { SpinnerModule } from '@spartacus/storefront';
import { IconModule } from '@spartacus/storefront';
import { CustomConsentManagementFormComponent } from './components/consent-form/consent-management-form.component';
import { CustomConsentManagementComponent } from './components/consent-management.component';
import { ConsentManagementComponentService } from './consent-management-component.service';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SpinnerModule,
    I18nModule,
    IconModule,
  ],
  providers: [
    ConsentManagementComponentService,
    provideConfig(<CmsConfig>{
      cmsComponents: {
        ConsentManagementComponent: {
          component: CustomConsentManagementComponent,
          guards: [AuthGuard],
        },
      },
    }),
  ],
  declarations: [
    CustomConsentManagementComponent,
    CustomConsentManagementFormComponent,
  ],
  exports: [
    CustomConsentManagementComponent,
    CustomConsentManagementFormComponent,
  ],
})
export class CustomConsentManagementModule {}

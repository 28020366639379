import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AuthService, ConverterService, OccEndpointsService, PRODUCT_NORMALIZER } from "@spartacus/core";
import { RecaptchaWidget } from "../../../recaptcha/recaptcha.widget";
import { Observable, Subscription } from "rxjs";
import { Order } from "@spartacus/order/root";
import { map } from "rxjs/operators";


export interface GuestOrderTrackingModel {}

@Injectable({
  providedIn: 'root',
})
export class GuestOrderTrackingService {
  constructor(
    private http: HttpClient,
    private endpointService: OccEndpointsService,
    private authService: AuthService,
    private converter: ConverterService,
  ) {}


  getOrder(formData: any): Observable<Order> {
    let userIsLogged;
    this.authService.isUserLoggedIn().subscribe((isUserLoggedIn)=>{
      userIsLogged = isUserLoggedIn;
      
    })

    
    
    const endpoint = this.endpointService.buildUrl(
      `/orders/guest-order-tracking?email=${formData.emailAddress}&orderNumber=${formData.orderNumber}`,
      {
        queryParams: {
          'g-recaptcha-response': formData.recaptchaToken,
        },
      }
    );
    

    return this.http.get<Order>(endpoint).pipe(
      map((order)=>{
       order.entries = order.entries.map((entry)=>{
          entry.product = this.converter.convert(entry.product, PRODUCT_NORMALIZER)

          return entry
        })
       order.unconsignedEntries = order.unconsignedEntries.map((entry) => {
          entry.product = this.converter.convert(entry.product, PRODUCT_NORMALIZER)


         return entry;
       }); 

        return order
      })
    );
  }
}
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {
  CmsConfig,
  FeaturesConfigModule,
  I18nModule,
  provideDefaultConfig,
} from '@spartacus/core';
import {
  OrderConfirmationItemsComponent,
  OrderConfirmationThankYouMessageComponent,
  OrderConfirmationTotalsComponent,
  OrderGuestRegisterFormComponent,
  OrderConfirmationShippingComponent,
  OrderConfirmationGuard,
  OrderOverviewComponent,
  OrderDetailsService,
  OrderDetailBillingComponent,
  OrderConfirmationOrderEntriesContext,
} from '@spartacus/order/components';
import {
  OrderConfirmationOrderEntriesContextToken,
  OrderFacade,
  OrderOutlets,
} from '@spartacus/order/root';
import {
  CardModule,
  FormErrorsModule,
  OutletModule,
  PasswordVisibilityToggleModule,
  PromotionsModule,
  PwaModule,
  provideOutlet,
} from '@spartacus/storefront';
import { TalkToUsCardsModule } from 'src/app/components/_SPA-Components/talk-to-us/components/talk-to-us-cards/talk-to-us-cards.module';
import { CustomOrderConfirmationItensComponent } from '../custom-order-confirmation-itens/custom-order-confirmation-itens.component';
import { CustomOrderConfirmationNewsletterComponent } from '../custom-order-confirmation-newsletter/custom-order-confirmation-newsletter.component';
import { CustomOrderConfirmationShippingComponent } from '../custom-order-confirmation-shipping/custom-order-confirmation-shipping.component';
import { CustomOrderConfirmationThankYouMessageComponent } from '../custom-order-confirmation-thank-you-message/custom-order-confirmation-thank-you-message.component';
import { CustomOrderConfirmationTotalsComponent } from '../custom-order-confirmation-totals/custom-order-confirmation-totals.component';
import { CustomOrderGuestRegisterFormComponent } from './custom-order-guest-register-form.component';



@NgModule({
    imports: [
        CommonModule,
        CardModule,
        PwaModule,
        PromotionsModule,
        I18nModule,
        ReactiveFormsModule,
        FormErrorsModule,
        PasswordVisibilityToggleModule,

    ],

  declarations: [CustomOrderGuestRegisterFormComponent],
  exports: [CustomOrderGuestRegisterFormComponent],
})
export class CustomOrderGuestRegisterFormModule {}

<div *ngIf="banners$ | async as bannerProps">
    <div class="rotating-banner__content">
        <div class="row">
            <div class="col-12 col-md-6 rotating-banner__content--product" [ngStyle]="productImage$ | async"></div>
            <div class="col-12 col-md-6 rotating-banner__content--review">
                <div class="rotating-banner__content--review--stars">
                    <span *ngFor="let star of stars" class="star">
                        <i class="bi bi-star-fill"></i>
                    </span>
                </div>
                <div [class]="(bannerProps.headerColor ?? 'text-light') + ' rotating-banner__content--review--description'">
                    <p class="description font-weight-bold" [innerHTML]="bannerProps.text"></p>
                </div>
                <div [class]="(bannerProps.headerColor ?? 'text-light') + ' rotating-banner__content--review--reviewer'" >
                    <p class="reviewer font-weight-bold" [innerHTML]="bannerProps.reviewerName"></p>
                </div>
            </div>
        </div>
    </div>
</div>
import { Address } from '@spartacus/core';

/**
 * Get strings for phone and mobile numbers
 */
export function getCustomAddressNumbers(
    address: Address,
    textPhone: string,
    textMobile: string
): string | undefined {
    if (address.cellphone && address.phone) {
        if (address.cellphone === address.phone) {
            return textMobile + ': ' + address.cellphone;
        }
        return `${textPhone}: ${address.phone}\n${textMobile}: ${address.cellphone}`;
    }

    if (address.cellphone) {
        return textMobile + ': ' + normalizePhone(address.cellphone);
    }

    if (address.phone) {
        return textPhone + ': ' + normalizePhone(address.phone);
    }

    return undefined;
}

export function normalizePhone(phone: string) {
    //normalizePhone string and remove all unnecessary characters
    phone = phone.replace(/[^\d]/g, "");

    //check if number length equals to 10
    if (phone.length == 10) {
        //reformat and return phone number
        return phone.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
    }

    return null;
}
